const PaperClipIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2635 11.8995L11.546 18.617C9.78861 20.3744 6.93937 20.3744 5.18201 18.617V18.617C3.42465 16.8596 3.42465 14.0104 5.18201 12.253L13.3137 4.12132C14.4853 2.94975 16.3848 2.94975 17.5564 4.12132V4.12132C18.728 5.29289 18.728 7.19239 17.5564 8.36396L9.32364 16.5967C8.73785 17.1825 7.7881 17.1825 7.20232 16.5967V16.5967C6.61653 16.0109 6.61653 15.0612 7.20232 14.4754L14.0208 7.65685"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

export { PaperClipIcon };
