const WindowIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29847 6.25C1.66598 3.42873 4.07855 1.25 7 1.25H17C19.9214 1.25 22.334 3.42873 22.7015 6.25H1.29847ZM1.25 7.75V17C1.25 20.1756 3.82436 22.75 7 22.75H17C20.1756 22.75 22.75 20.1756 22.75 17V7.75H1.25Z"
      fill="currentColor"
    />
  </svg>
);

export { WindowIcon };
