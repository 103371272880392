// core
import React from 'react';
//hooks

// utils

// locales

// types
import { EChatUserType } from 'types/models';

// components(atoms)
import { SupportChatMessage } from '../SupportChatMessage';

// styles
import './index.scss';

//data

const SupportChatView = ({ messages }) => {
  return (
    <div className="chat">
      <div className="chat__container">
        {messages?.length ? (
          messages.map((message, index) => {
            return (
              <div
                key={index}
                className={
                  'chat__item' +
                  (message.memberId === EChatUserType.User
                    ? ' chat__item--user'
                    : ' chat__item--support')
                }
              >
                <SupportChatMessage message={message} />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export { SupportChatView };
