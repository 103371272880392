export const MEDIA = {
  M1920: 1920,
  M1760: 1760,
  M1680: 1680,
  M1440: 1440,
  M1360: 1360,
  M1280: 1280,
  M1140: 1140,
  M1024: 1024,
  M768: 768,
  M640: 640,
  M480: 480,
  M425: 425,
};
