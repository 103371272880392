import React from 'react';
import { NavLink } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';

// store
import { useAuthStore } from 'store';

// types
import { IPropsType } from './types';

// components
import {
  CartIcon,
  GridIcon,
  TimeIcon,
  FileIcon,
  HelpIcon,
  BlogIcon,
} from 'components/icons';

// styles
import './index.scss';

//data

const HeaderNav = ({ onTo }: IPropsType) => {
  const { t } = useTranslation();
  return (
    <ul className="header-nav">
      <li>
        <NavLink
          to="/market"
          onClick={() => onTo()}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item--pending'
              : isActive
              ? 'header-nav-item--active'
              : ''
          }
        >
          <>
            <CartIcon />
            {t('market')}
          </>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/inventory/stock"
          onClick={() => onTo()}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item--pending'
              : isActive
              ? 'header-nav-item--active'
              : ''
          }
        >
          <>
            <GridIcon />
            {t('inventory')}
          </>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/deals/trade"
          onClick={() => onTo()}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item--pending'
              : isActive
              ? 'header-nav-item--active'
              : ''
          }
        >
          <>
            <FileIcon />
            {t('transactions')}
          </>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/support/faq"
          onClick={() => onTo()}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item--pending'
              : isActive
              ? 'header-nav-item--active'
              : ''
          }
        >
          <>
            <HelpIcon />
            {t('support')}
          </>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/blog"
          onClick={() => onTo()}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item--pending'
              : isActive
              ? 'header-nav-item--active'
              : ''
          }
        >
          <>
            <BlogIcon />
            {t('blog')}
          </>
        </NavLink>
      </li>
    </ul>
  );
};
export { HeaderNav };
