export enum ECardSizeType {
  Small = 'small',
  Default = 'default',
  Minimal = 'minimal',
}

export enum ECardColorType {
  Dark = 'dark',
  Light = 'light',
}
export interface IPropsType {
  title?: string;
  description?: string;
  isHideImg?: boolean;
  size?: ECardSizeType;
  color?: ECardColorType;
  isError?: boolean;
}
