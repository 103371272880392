import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { DELETE_USER } from '../../../api/profile';

export const useMutateDeleteProfile = () => {
  return useMutation(DELETE_USER, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while deleting user';
      console.error(message);
      return message;
    },
  });
};
