import { $api } from 'utils/request';
import { stringify } from 'query-string';
// types
import {
  TArticlesRequestParams,
  TArticlesResponse,
  TArticleRequestParams,
  TArticleResponse,
} from 'types/api/blog';

// services
export const GET_ARTICLES = async (query: TArticlesRequestParams) => {
  const url = `articles?${stringify(query)}`;
  const response = await $api.get<TArticlesResponse>(url);
  return response.data;
};
export const GET_ARTICLE = async (slug: TArticleRequestParams) => {
  const url = `articles/${slug}?include=related`;
  const response = await $api.get<TArticleResponse>(url);
  return response.data;
};
