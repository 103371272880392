// core
import React from 'react';
import { Outlet } from 'react-router-dom';

//hooks
// utils
// locales
// store
// types

// components(atoms)
import { DealsSEO } from './SEO';
// styles
import './index.scss';

// data

// func
const Deals = () => {
  return (
    <>
      <DealsSEO />
      <div className="deals">
        {/*TABS*/}
        <Outlet />
      </div>
    </>
  );
};

export { Deals };
