import { $api } from 'utils/request';
import { stringify } from 'query-string';

//types

import {
  TSupportFaqResponse,
  TSupportFaqRequestParams,
  TTicketListRequestParams,
  TTicketListResponse,
  ITicketCreateRequestParams,
  TTicketCreateResponse,
  TTicketRequestParams,
  TTicketResponse,
  TChatMessagesRequestParams,
  TChatMessagesResponse,
  ISendChatMessageRequestParams,
  TSendChatMessageResponse,
  IChangeTicketStatusRequestParams,
  TChangeTicketStatusResponse,
} from 'types/api';

// services
export const GET_SUPPORT_FAQ = async (query: TSupportFaqRequestParams) => {
  const url = `faq?${stringify(query)}`;
  const response = await $api.get<TSupportFaqResponse>(url);
  return response.data;
};
export const GET_TICKET_LIST = async (query: TTicketListRequestParams) => {
  const url = `support-tickets?${stringify(query)}`;
  const response = await $api.get<TTicketListResponse>(url);
  return response.data;
};

export const CREATE_TICKET = async ({
  formData,
}: ITicketCreateRequestParams | any) => {
  const url = `support-tickets`;
  const response = await $api.post<TTicketCreateResponse>(url, formData);
  return response.data;
};

export const GET_TICKET = async (id: TTicketRequestParams) => {
  const url = `support-tickets/${id}`;
  const response = await $api.get<TTicketResponse>(url);
  return response.data;
};

export const CHANGE_TICKET_STATUS = async ({
  payload,
  id,
}: IChangeTicketStatusRequestParams | any) => {
  const url = `support-tickets/${id}`;
  const response = await $api.patch<TChangeTicketStatusResponse>(url, payload);
  return response.data;
};

export const GET_CHAT_MESSAGES = async (id: TChatMessagesRequestParams) => {
  const url = `chats/${id}/messages`;
  const response = await $api.get<TChatMessagesResponse>(url);
  return response.data;
};

export const SEND_CHAT_MESSAGE = async ({
  payload,
  chatId,
}: ISendChatMessageRequestParams | any) => {
  const url = `chats/${chatId}/messages`;
  const response = await $api.post<TSendChatMessageResponse>(url, payload);
  return response.data;
};
