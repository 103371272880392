// core
import React from 'react';
import { Button } from 'antd';

//locales
import { useTranslation } from 'react-i18next';
// utils

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { IPropsType } from './types';
// components
import { LoadingBigIcon } from 'components/icons';

// styles
import './index.scss';

// data

const RemoveTradeModal = ({
  modalId,
  onRemove,
  onClose,
  income,
}: IPropsType) => {
  const { t } = useTranslation();
  // locales hook
  const { sModalClose } = useAppStore();

  return (
    <div className="remove-trade-modal">
      <div className="remove-trade-modal__body">
        <div className="remove-trade-modal__icon">
          <LoadingBigIcon />
        </div>
        <div className="remove-trade-modal__title">{t('removeTradeTitle')}</div>
        <div className="remove-trade-modal__text">
          {t('removeTradeDesc')}{' '}
          {!income ? t('removeTradeSell') : t('removeTradeBuy')}?{' '}
          {t('removeTradeWarning')}
        </div>
      </div>

      <div className="remove-trade-modal__actions">
        <Button
          className="remove-trade-modal__btn-remove"
          block
          size={EButtonSizeType.Large}
          type={EButtonTypeType.Primary}
          onClick={() => {
            sModalClose(modalId);
            if (onRemove) {
              onRemove();
            }
          }}
        >
          {t('removeTradeDelete')}
        </Button>
        <Button
          className="remove-trade-modal__btn-cancel ant-btn-text--error"
          size={EButtonSizeType.Middle}
          type={EButtonTypeType.Text}
          onClick={() => {
            sModalClose(modalId);
            if (onClose) {
              onClose();
            }
          }}
        >
          {t('deny')}
        </Button>
      </div>
    </div>
  );
};

export { RemoveTradeModal };
