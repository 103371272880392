// core
import React, { useEffect } from 'react';

//hooks
import { useQueryFetchArticles } from 'hooks/api';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
// utils

// locales
import { useTranslation } from 'react-i18next';
// types
import {
  EButtonSizeType,
  EButtonTypeType,
  EInputSizeType,
  ESelectSizeType,
} from 'types/units';

import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
// components(atoms)
import { Button, Input, Select, Pagination } from 'antd';
import { BlogSEO } from './SEO';
import { BlogCard } from './BlogCard';
import { EmptyCard } from 'components/features';
import { SearchIcon, ArrowDownIcon } from 'components/icons';
import { AppLoader } from 'components/atoms';
// styles
import './index.scss';

//data
import { selectItems } from './mockData';
const { Option } = Select;

const Blog = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 8),
    order: withDefault(StringParam, selectItems[0].value),
    query: withDefault(StringParam, undefined),
  });
  const {
    page: pageParam,
    per_page: takeParam,
    order: orderByParam,
    query: searchParam,
  } = query;

  const { data: articlesList, isLoading: isLoadingArticlesList } =
    useQueryFetchArticles(query);

  const onChangeSearch = ({ target }) => {
    setQuery({ query: target.value.length ? target.value : undefined });
  };
  const onChangeSelect = (value: string) => {
    setQuery({ order: value });
  };

  useEffect(() => {
    if (!isLoadingArticlesList && articlesList) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesList, isLoadingArticlesList]);

  return (
    <>
      <BlogSEO />
      <div className="blog">
        <div className="app-container app-container--small">
          <div className="blog__wrap">
            <div className="blog__head">
              <div className="blog__head--left">
                <h1>{t('blog')}</h1>
                <span>{t('blogDesc')}</span>
              </div>
              <div className="blog__head--right">
                <Input
                  className="blog__head-search"
                  prefix={<SearchIcon />}
                  value={searchParam}
                  size={EInputSizeType.Large}
                  placeholder={t('search')}
                  onChange={onChangeSearch}
                />
                <Select
                  className="ant-select--light blog__head-select"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  defaultValue={orderByParam}
                  onChange={onChangeSelect}
                  dropdownAlign={{ offset: [0, 0] }}
                >
                  {selectItems?.length &&
                    selectItems.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.value}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
            <div className="blog__grid">
              {isLoadingArticlesList ? (
                <div className="blog__grid-loader">
                  <AppLoader type={ELoaderTypeType.Component} />
                </div>
              ) : (
                <>
                  {articlesList?.data?.length ? (
                    articlesList.data?.map(article => {
                      return (
                        <div className="blog__grid--item" key={article.id}>
                          <BlogCard article={article} />
                        </div>
                      );
                    })
                  ) : (
                    <div className="blog__empty">
                      <EmptyCard
                        title={t('emptyBlogTitle')}
                        description={t('emptyBlogDesc')}
                      />
                      <Button
                        type={EButtonTypeType.Primary}
                        size={EButtonSizeType.Large}
                      >
                        {t('emptyButton')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            {!isLoadingArticlesList &&
              articlesList &&
              articlesList?.meta.last_page > 1 && (
                <div className="blog__pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={pageParam}
                    pageSize={takeParam}
                    total={articlesList.meta.total}
                    onChange={page => setQuery({ page: page })}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export { Blog };
