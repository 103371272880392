import { $apiAuth } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { TLoginRequestParams, ILoginResponse } from 'types/api/auth';
import {
  IRefreshTokenResponse,
  IRegisterRequestParams,
  IRegisterResponse,
  IResetPasswordRequestParams,
  IRestorePasswordRequestParams,
  IRestorePasswordResponse,
  TAuthLinkResponse,
  TRefreshTokenRequestParams,
} from 'types/api/auth';

export const GET_AUTH_LINK = async () => {
  const url = `steam-sign-in`;
  const response = await $apiAuth.get<TAuthLinkResponse>(url);
  return response.data;
};
export const POST_LOGIN = async (params: TLoginRequestParams) => {
  const url = `auth?token=${params}`;
  const response = await $apiAuth.get<ILoginResponse>(url);
  return response.data;
};

export const POST_LOGOUT = async () => {
  const response: AxiosResponse = await $apiAuth.get('log-out');
  return response;
};

export const POST_SIGNUP = async (params: IRegisterRequestParams) => {
  const response: AxiosResponse<IRegisterResponse> =
    await $apiAuth.post<IRegisterResponse>(apiEndpoints.authSignUp, params);
  return response.data;
};

export const POST_RESET_PASSWORD = async (
  params: IResetPasswordRequestParams,
) => {
  const response: AxiosResponse<unknown> = await $apiAuth.post<unknown>(
    apiEndpoints.authResetPassword,
    params,
  );
  return response.data;
};

export const POST_RESTORE_PASSWORD = async (
  params: IRestorePasswordRequestParams,
) => {
  const response: AxiosResponse<IRestorePasswordResponse> =
    await $apiAuth.post<IRestorePasswordResponse>(
      apiEndpoints.authRestorePassword,
      params,
    );
  return response.data;
};

export const POST_REFRESH_TOKEN = async (token: TRefreshTokenRequestParams) => {
  const response: AxiosResponse<IRefreshTokenResponse> =
    await $apiAuth.get<IRefreshTokenResponse>(
      `refresh-token?refreshToken=${token}`,
    );
  return response.data;
};
