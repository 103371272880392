import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { GET_BUY_BARGAIN_LIST } from '../../../api/transactions';

export const useMutationFetchOutcomeBargain = () => {
  return useMutation(GET_BUY_BARGAIN_LIST, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err
        ? err?.message
        : 'Error while get outcome transactions';
      console.error(message);
      return message;
    },
  });
};
