import { useQuery } from '@tanstack/react-query';

//types
// services
import { GET_RANDOM_PRODUCTS } from 'api/market';

export const useQueryFetchRandomProducts = () => {
  return useQuery(['useGetRandomProducts'], () => GET_RANDOM_PRODUCTS(), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching referral link';
      console.error(message);
      return message;
    },
  });
};
