import { $api } from 'utils/request';

import { TCartCheckRequestPayload, TCartCheckResponse } from '../types/api';

export const CHECK_CART = async (payload: TCartCheckRequestPayload) => {
  const url = `market/check`;
  const response = await $api.post<TCartCheckResponse>(url, payload);
  return response.data;
};

export const BUY_CART = async (payload: TCartCheckRequestPayload) => {
  const url = `market/buy`;
  const response = await $api.post<TCartCheckResponse>(url, payload);
  return response.data;
};

export const BUY_BARGAIN = async (payload: TCartCheckRequestPayload) => {
  const url = `market/bargain/new`;
  const response = await $api.post<TCartCheckResponse>(url, payload);
  return response.data;
};
