// core
import React from 'react';

//hooks

// utils
import { useTranslation } from 'react-i18next';

// locales

// types

// components(atoms)
/*@ts-ignore*/
import HomeP2PBuyer from 'assets/img/HomeP2PBuyer.png';
/*@ts-ignore*/
import HomeP2PSeller from 'assets/img/HomeP2PSeller.png';

// styles
import './index.scss';

//tempo

// func
const HomeP2P = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="home-p2p">
        <div className="app-container app-container--small">
          <div className="home-p2p__wrap">
            <div className="home-p2p__title">
              {t('p2p')} <span>{t('or')}</span> {t('whyWeBetter')}
            </div>
            <div className="home-p2p__desc">{t('whatDifference')}</div>
            <div className="home-p2p__item-wrapper">
              <div className="home-p2p__item">
                <div className="home-p2p__item__image home-p2p__item__image--left">
                  <img src={HomeP2PBuyer} alt="Skinsty" />
                </div>
                <div className="home-p2p__item__content">
                  <div className="home-p2p__item__content-title">
                    {t('buyer')}
                  </div>
                  <div className="home-p2p__item__content-desc">
                    {t('fastSellOrTrade')}
                  </div>
                </div>
              </div>
              <div className="home-p2p__item">
                <div className="home-p2p__item__image">
                  <img src={HomeP2PSeller} alt="Skinsty" />
                </div>
                <div className="home-p2p__item__content">
                  <div className="home-p2p__item__content-title">
                    {t('seller')}
                  </div>
                  <div className="home-p2p__item__content-desc">
                    {t('secureTradeToSeller')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeP2P };
