const VisaCardIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_580_65357)">
      <path
        d="M26.5816 15.399C26.0889 15.2036 25.3075 14.9878 24.3413 14.9878C21.8747 14.9878 20.1378 16.3031 20.1271 18.1836C20.1066 19.571 21.3707 20.3416 22.3162 20.8042C23.2825 21.277 23.611 21.5855 23.611 22.0069C23.6011 22.654 22.8301 22.9523 22.111 22.9523C21.1138 22.9523 20.5795 22.7985 19.7675 22.4385L19.4386 22.2841L19.0891 24.4525C19.6749 24.7194 20.7542 24.9563 21.8747 24.9666C24.4955 24.9666 26.2017 23.6717 26.2219 21.6677C26.2319 20.5681 25.5644 19.7255 24.1254 19.037C23.2518 18.595 22.7168 18.297 22.7168 17.8447C22.727 17.4336 23.1693 17.0125 24.1554 17.0125C24.9674 16.9919 25.564 17.1871 26.016 17.3824L26.2419 17.485L26.5816 15.399Z"
        fill="white"
      />
      <path
        d="M17.5271 24.8227H15.0294L16.5916 15.1629H19.0892L17.5271 24.8227Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9843 15.1629H31.0523C30.4566 15.1629 30.0039 15.3374 29.7469 15.9644L26.0368 24.8225H28.6576C28.6576 24.8225 29.0891 23.6303 29.1818 23.3735H32.3887C32.4604 23.7127 32.6867 24.8225 32.6867 24.8225H34.9993L32.9843 15.1629ZM29.9012 21.4006C30.1069 20.8456 30.8984 18.6978 30.8984 18.6978C30.8942 18.7061 30.9264 18.6169 30.9744 18.4838L30.975 18.4822L30.9754 18.4811C31.0471 18.2824 31.1535 17.9873 31.227 17.773L31.4016 18.6054C31.4016 18.6054 31.8746 20.9176 31.9773 21.4006H29.9012Z"
        fill="white"
      />
      <path
        d="M12.9432 15.1629L10.4971 21.7499L10.2298 20.414L10.2296 20.4132L9.35622 15.9749C9.21239 15.3579 8.77041 15.1832 8.22571 15.1629H4.20713L4.16602 15.3581C5.14613 15.6087 6.02276 15.9699 6.79086 16.4189L9.01708 24.8124H11.6583L15.5843 15.1629H12.9432Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_65357">
        <rect
          width="30.8333"
          height="9.97884"
          fill="white"
          transform="translate(4.16602 14.9878)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { VisaCardIcon };
