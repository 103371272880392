import React from 'react';
import { Alert } from 'antd';

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';

// types
import { EAlertType } from 'types/units';
import { IPropsType } from './types';

// components
import { CloseIcon } from 'components/icons';
import { HeaderAlertTimer } from './HeaderAlertTimer';

// styles
import './index.scss';

//data

const HeaderAlert = ({ alertType, blockTime }: IPropsType) => {
  const { t } = useTranslation();
  const { sModalOpen } = useAppStore();
  // const { accessToken: isAuth } = useAuthStore();

  const renderText = (type, t) => {
    if (type === EAlertType.Warning) {
      return (
        <>
          {t('temporaryBlocked')}: {<HeaderAlertTimer targetDate={blockTime} />}
          . {t('clickForMoreBanInfo')}
        </>
      );
    } else {
      return <>{t('serverProblemsInfo')}</>;
    }
  };

  const onOpenModal = type => {
    if (type === EAlertType.Warning) {
      sModalOpen('blockWarning');
    } else {
      sModalOpen('hackedAttention');
    }
  };
  return (
    <div className="header-alert">
      <Alert
        message={
          <div
            className="header-alert__message"
            onClick={() => {
              onOpenModal(alertType);
            }}
          >
            {renderText(alertType, t)}
          </div>
        }
        showIcon={false}
        banner
        type={alertType}
        // @ts-ignore
        closeIcon={alertType !== EAlertType.Warning ? <CloseIcon /> : null}
      />
    </div>
  );
};
HeaderAlert.defaultProps = {
  alertType: EAlertType.Info,
  blockTime: null,
};

export { HeaderAlert };
