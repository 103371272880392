const SteamIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 4C14.288 4 12.0789 6.08335 12.0065 8.67031L8.94824 12.8672C8.72161 12.826 8.48892 12.8 8.25 12.8C7.45973 12.8 6.72803 13.0382 6.12272 13.4406L2 11.875V16.1766L4.58789 17.1594C4.95245 18.7804 6.45413 20 8.25 20C10.0864 20 11.6119 18.7241 11.9316 17.0484L16.7428 13.5875C16.8285 13.5917 16.9133 13.6 17 13.6C19.7575 13.6 22 11.4472 22 8.8C22 6.1528 19.7575 4 17 4ZM17 5.6C18.8383 5.6 20.3333 7.0352 20.3333 8.8C20.3333 10.5648 18.8383 12 17 12C15.1617 12 13.6667 10.5648 13.6667 8.8C13.6667 7.0352 15.1617 5.6 17 5.6ZM17 6.4C16.337 6.4 15.7011 6.65286 15.2322 7.10294C14.7634 7.55303 14.5 8.16348 14.5 8.8C14.5 9.43652 14.7634 10.047 15.2322 10.4971C15.7011 10.9471 16.337 11.2 17 11.2C17.663 11.2 18.2989 10.9471 18.7678 10.4971C19.2366 10.047 19.5 9.43652 19.5 8.8C19.5 8.16348 19.2366 7.55303 18.7678 7.10294C18.2989 6.65286 17.663 6.4 17 6.4ZM8.25 13.6C9.85833 13.6 11.1667 14.856 11.1667 16.4C11.1667 17.944 9.85833 19.2 8.25 19.2C7.0656 19.2 6.04845 18.5163 5.59212 17.5406L7.4834 18.2594C7.73506 18.3554 7.99337 18.4016 8.24837 18.4016C9.07837 18.4016 9.86352 17.9231 10.1868 17.1375C10.611 16.1103 10.0866 14.947 9.0166 14.5406L7.11881 13.8188C7.46676 13.6777 7.84918 13.6 8.25 13.6Z"
      fill="currentColor"
    />
  </svg>
);

export { SteamIcon };
