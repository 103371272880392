import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ENoticeType } from '../types/models/notice';

type NoticeState = {
  notice: Array<any>;
  sSetNoticeData: (list: Array<any>) => void;
};

const useNoticeStore = create(
  devtools<NoticeState>(
    (set, get) => ({
      notice: [
        {
          id: 1,
          type: ENoticeType.Offer,
          img: 'https://picsum.photos/200/150?random=4351',
          title: 'Предложение от',
          sub_title: 'Galil AR | Фосфор',
          user: 'FLN',
          userLink: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
          old_price: 500,
          price: 480,
        },
        {
          id: 2,
          type: ENoticeType.BalanceIn,
          title: 'Пополнение баланса',
          sub_title: 'Карта',
          num_card: '4520',
          price: 500,
        },
        {
          id: 3,
          type: ENoticeType.BalanceOut,
          title: 'Вывод средств завершен',
          sub_title: 'Кошелек Binance',
          price: 500,
        },
        // {
        //   id: 4,
        //   type: ENoticeType.Support,
        //   title: 'Ответ от поддержки',
        //   sub_title:
        //     '«Здравствуйте. Мы глубоко сочувствуем вам за вашу потерю, вот план дейтсвий”',
        //   chatId: 23,
        // },
        {
          id: 5,
          type: ENoticeType.BalanceRequestOut,
          title: 'Вывод средств создан',
          price: 500,
        },
        {
          id: 6,
          type: ENoticeType.UserProductPurchased,
          img: 'https://picsum.photos/200/150?random=145',
          title: 'Ваш предмет куплен',
          sub_title: 'Galil AR | Фосфор',
          price: 520,
        },
        {
          id: 7,
          type: ENoticeType.UserPurchase,
          img: 'https://picsum.photos/200/150?random=123',
          title: 'Покупка предмета',
          sub_title: 'Galil AR | Фосфор',
          price: 520,
        },
      ],

      sSetNoticeData: list => {
        set(state => ({
          notice: list,
        }));
      },
    }),
    { name: 'accessToken' },
  ),
);
export { useNoticeStore };
