import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
// locale
// store
import { useAppStore, useInventoryStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
// components

// styles
import './index.scss';
import { useMutationInventoryItemsToMarket } from '../../../../../hooks/api';
import { formatPrice } from '../../../../../utils/commons';

//data

const InventoryMobileNav = ({ onExposedMultiple }) => {
  const { modal, commission_percentage, sModalOpen, sModalClose } =
    useAppStore();
  const { inventoryExpose } = useInventoryStore();
  const [summary, setSummary] = useState<{
    count: number;
    total: number;
    netTotal: number;
  }>({
    count: 0,
    total: 0,
    netTotal: 0,
  });
  const {
    mutate: mutateInventoryToMarket,
    isLoading: isLoadingInventoryToMarket,
    isSuccess: isSuccessInventoryToMarket,
  } = useMutationInventoryItemsToMarket();

  useEffect(() => {
    let summaryData = inventoryExpose.reduce(
      (acc, item) => {
        return {
          count: acc.count + item._saleCount,
          total: acc.total + item.price * item._saleCount,
          netTotal: acc.netTotal + item._netPrice * item._saleCount,
        };
      },
      { count: 0, total: 0, netTotal: 0 },
    );

    setSummary(summaryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inventoryExpose)]);

  useEffect(() => {
    if (!isLoadingInventoryToMarket && isSuccessInventoryToMarket) {
      onExposedMultiple();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInventoryToMarket, isSuccessInventoryToMarket]);

  const onCloseMobileExpose = () => {
    sModalClose('mobileExpose');
  };

  const onShowMobileExpose = () => {
    sModalOpen('mobileExpose');
  };

  const onExposeProducts = () => {
    mutateInventoryToMarket(
      inventoryExpose.map(item => {
        return {
          itemId: item.id,
          price: item.price,
        };
      }),
    );
  };

  return (
    <div className="inventory-mobile-nav">
      <div className="inventory-mobile-nav__summary">
        Всего, с комиссией:{' '}
        <span>
          {summary.total ? `$${formatPrice(summary.total)}` : '$ 0.00'}
        </span>
      </div>
      <div className="inventory-mobile-nav__note">
        Доход пользовотеля:{' '}
        <span>
          {summary.netTotal ? `$${formatPrice(summary.netTotal)}` : '$ 0.00'}
        </span>
      </div>
      <div className="inventory-mobile-nav__actions">
        {modal['mobileExpose'] ? (
          <Button
            ghost
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            onClick={() => onCloseMobileExpose()}
          >
            Добавить
          </Button>
        ) : (
          <Button
            ghost
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            onClick={() => onShowMobileExpose()}
          >
            К предметам
          </Button>
        )}

        <Button
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          disabled={isLoadingInventoryToMarket}
          onClick={() => {
            onExposeProducts();
          }}
        >
          Продать
        </Button>
      </div>
    </div>
  );
};

export { InventoryMobileNav };
