const BroomIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 576 512"
    {...props}
  >
    <path d="M569 41c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L335 207l-39-39c-5.1-5.1-12.1-8-19.4-8c-12.2 0-23 8.1-26.3 19.9l-15.9 55.6-.6-.1c-46.4-7.1-93.5 8.3-126.7 41.5L97 287c-18.4 18.4-29.7 42.4-32.4 68c-.4 3.8-.6 7.7-.6 11.6c0 9.6 7.8 17.3 17.3 17.3H104L81.3 404.9 57.1 427.3 7.2 473.4C2.6 477.6 0 483.5 0 489.7C0 502 10 512 22.3 512l172.7 0c39.1 0 76.6-15.5 104.2-43.2c33.2-33.2 48.6-80.2 41.5-126.7l-.1-.6 55.6-15.9c11.8-3.4 19.9-14.1 19.9-26.3c0-7.3-2.9-14.2-8-19.4l-39-39L569 41zM278.8 254.8l8-28.1 62.4 62.4-28.1 8-42.3-42.3zm-52.3 28l9.8 1.5 55.4 55.4 1.5 9.8c4.8 31.3-5.6 63-28 85.4c-18.6 18.6-43.9 29.1-70.3 29.1L88.1 464l48.5-44.7c14.5-13.4 19.3-34.3 12.1-52.7c-5.4-13.7-16.6-23.9-30.1-28.3c3.1-6.4 7.3-12.2 12.4-17.3l10.2-10.2c22.4-22.4 54.1-32.8 85.4-28z" />
  </svg>
);

export { BroomIcon };
