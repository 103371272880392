import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { EGridType, EInventoryAsideType } from 'types/units';
import { setInventoryGrid } from '../services/inventory';

type InventoryState = {
  inventoryExpose: Array<any>;
  inventoryExposeModal: any;
  inventoryGrid: EGridType | string;
  inventoryAside: EInventoryAsideType | string;
  inventoryProduct: any;
  inventorySteamUpdateDate: any;
  sSetInventoryProduct: (inventoryProduct: any) => void;
  sSetInventoryExpose: (inventoryExpose: Array<any>) => void;
  sSetInventoryExposeModal: (inventoryExposeModal: any) => void;
  sSetInventoryGrid: (inventoryGrid: EGridType | string) => void;
  sSetInventoryAside: (inventoryAside: EInventoryAsideType | string) => void;
  sSetInventorySteamUpdateDate: (inventorySteamUpdateDate: number) => void;
};

const getInventoryGrid = () => {
  let inventoryGrid = localStorage.getItem('inventory_grid');
  return inventoryGrid ? inventoryGrid : EGridType.Default;
};

const getInventorySteamUpdateDate = () => {
  let inventorySteamUpdateDate = localStorage.getItem('inventory_update');
  return inventorySteamUpdateDate ? Number(inventorySteamUpdateDate) : null;
};
const useInventoryStore = create(
  devtools<InventoryState>(
    (set, get) => ({
      inventoryExpose: [],
      inventoryExposeModal: null,
      inventoryGrid: getInventoryGrid(),
      inventoryAside: EInventoryAsideType.Empty,
      inventoryProduct: null,
      inventorySteamUpdateDate: getInventorySteamUpdateDate(),
      sSetInventoryGrid: inventoryGrid => {
        setInventoryGrid(inventoryGrid);
        set(state => ({
          inventoryGrid,
        }));
      },
      sSetInventoryAside: inventoryAside => {
        set(state => ({
          inventoryAside,
        }));
      },
      sSetInventoryExpose: inventoryExpose => {
        set(state => ({
          inventoryExpose,
        }));
      },
      sSetInventoryExposeModal: inventoryExposeModal => {
        set(state => ({
          inventoryExposeModal,
        }));
      },
      sSetInventoryProduct: inventoryProduct => {
        set(state => ({
          inventoryProduct,
        }));
      },
      sSetInventorySteamUpdateDate: inventorySteamUpdateDate => {
        localStorage.setItem(
          'inventory_update',
          inventorySteamUpdateDate.toString(),
        );
        set(state => ({
          inventorySteamUpdateDate,
        }));
      },
    }),
    { name: 'accessToken' },
  ),
);
export { useInventoryStore };
