import dayjs from 'dayjs';
import { formatPrice } from '../utils/commons';
export const generalChartConfig = {
  renderer: 'svg',
  xAxis: {
    range: [0, 1],
    tickCount: 6,
    label: {
      offsetY: 12,
      formatter: value => {
        return dayjs(value).format('D MMMM');
      },
      style: {
        textAlign: 'left',
        color: '#939199',
        fontSize: 12,
        fontFamily: 'Inter',
        textBaseline: 'bottom',
      },
    },
    line: {
      style: {
        stroke: 'transparent',
      },
    },
  },
  yAxis: {
    position: 'right',
    tickCount: 4,
    tickMethod: 'd3-linear', // time
    grid: {
      line: {
        style: {
          stroke: '#5D5C66',
          lineWidth: 1,
          lineDash: [6, 6],
          strokeOpacity: 1,
        },
      },
    },
    label: {
      formatter: value => `$ ${formatPrice(Number(value))}`,
      style: {
        color: '#939199',
        fontSize: 12,
        fontFamily: 'Inter',
      },
    },
  },
  line: {
    size: 2,
    color: '#A66DFF',
  },
  color: '#A66DFF',
  areaStyle: () => {
    return {
      fill: 'l(90) 0:#4A356A 1:#2A2237',
    };
  },
  smooth: true,
  tooltip: false,
};

export const generalTooltipConfig = {
  showCrosshairs: true,
  crosshairs: {
    type: 'x',
    line: {
      style: {
        stroke: '#EFE5FF',
      },
    },
  },
  showMarkers: true,
  marker: {
    fill: '#EFE5FF',
    stroke: '#EFE5FF',
    lineWidth: 5,
  },
  position: 'top',
};
