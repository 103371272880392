import React, { useState, useEffect } from 'react';
import { Button, Table } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDate, formatPrice } from '../../../../utils/commons';

// locale
import { useTranslation } from 'react-i18next';

//hooks
import { useQueryFetchWalletHistory } from '../../../../hooks/api';

// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType } from 'types/units';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
import { EDealStatusType, EGameType } from 'types/models';

// components
import { FloatRange } from 'components/atoms/FloatRange';
import {
  EmptyCard,
  MobileBottomNav,
  TableCellImage,
  TableCellStickers,
} from 'components/features';
import { ArrowLeftLightIcon, ArrowRightIcon } from 'components/icons';

// styles
import './index.scss';
import { HistoryHead } from '../HistoryHead';

const getStatus = (status, t) => {
  if (status === EDealStatusType.Success) {
    return (
      <span className="history-sale__status history-sale__status--success">
        {t('historyStatusDone')}
      </span>
    );
  } else if (status === EDealStatusType.Processing) {
    return (
      <span className="history-sale__status history-sale__status--processing">
        {t('historyStatusPending')}
      </span>
    );
  } else if (status === EDealStatusType.Rejected) {
    return (
      <span className="history-sale__status history-sale__status--rejected">
        {t('historyStatusDeclined')}
      </span>
    );
  }
  return null;
};
const HistorySale = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [historyFilter, setHistoryFilter] = useState({
    search: '',
    sort: {},
    page: 1,
    take: 10,
    type: pathname.replace(/^\/history\//, ''),
  });
  const { data: historyData, isLoading: isLoadingHistoryData } =
    useQueryFetchWalletHistory(historyFilter);

  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (!isLoadingHistoryData && historyData) {
      setTableData(transformData(historyData.data));
    }
  }, [isLoadingHistoryData, historyData]);

  const transformData = list => {
    return list.map((el, index) => {
      let { tID, resolvedAt, status, item, amount } = el;
      let {
        price,
        image,
        preview,
        name,
        float,
        shortenExterior,
        stickers,
        gems,
        provider,
        marketName,
      } = item;
      return {
        id: index,
        dealsId: tID,
        date: formatDate(resolvedAt, 'DD.MM.YYYY HH:mm'),
        status: status,
        price: amount,
        img: image ? image : preview,
        name: name,
        short: shortenExterior,
        float: float,
        provider: provider,
        marketName: marketName,
        stickers: stickers.map(el => {
          return { image: el.preview, title: el.title, price: el.valueExtra };
        }),
        gems: gems.map(gem => {
          return {
            image: gem.preview,
            title: gem.title,
            description: gem.valueExtra,
          };
        }),
      };
    });
  };

  const onTableChange = pagination => {
    setHistoryFilter({ ...historyFilter, page: pagination.current });
  };

  const onFilterChange = value => {
    if (value) {
      setHistoryFilter({
        ...historyFilter,
        sort: {
          [value?.key]: value?.data,
        },
      });
    } else {
      let { sort, ...clearedFields } = historyFilter;
      setHistoryFilter({ ...clearedFields, sort: {} });
    }
  };

  const columns = [
    {
      key: 'date',
      dataIndex: 'date',
      render: date => <div className="ant-table-cell__date">{date}</div>,
    },
    {
      key: 'dealsId',
      dataIndex: 'dealsId',
      render: (_, record) => (
        <span className="ant-table-cell__nowrap">
          ID: <span className="ant-table-cell__value">{record.dealsId}</span>
        </span>
      ),
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: (_, record) => (
        <TableCellImage src={record.img} gameType={record.provider} alt={''} />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        <>
          <div className="ant-table-cell__text">{record.marketName.first}</div>
          <div className="ant-table-cell__type">{record.marketName.second}</div>
        </>
      ),
    },
    {
      key: 'float',
      dataIndex: 'float',
      render: (_, record) => (
        <>
          {record.provider === EGameType.CSGO && (
            <>
              {record.float > 0 && (
                <div className="ant-table-cell__float">
                  {record.short}
                  <span>{record.float}</span>
                </div>
              )}
              <FloatRange
                isTooltip={!record.float}
                size={EFloatRangeSizeType.Small}
                maxValue={record.float}
              />
            </>
          )}
        </>
      ),
    },
    {
      key: 'stickers',
      dataIndex: 'stickers',
      width: '50%',
      render: (_, record) => (
        <TableCellStickers
          gameType={record.provider}
          collection={
            record.provider === EGameType.CSGO ? record.stickers : record.gems
          }
        />
      ),
    },
    {
      key: 'price',
      dataIndex: 'price',
      render: price => (
        <span className="ant-table-cell__nowrap ant-table-cell__value">
          $ {formatPrice(price)}
        </span>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: '240px',
      render: status => (
        <div className="ant-table-cell__nowrap">
          {t('status')}: <>{getStatus(status, t)}</>
        </div>
      ),
    },
  ];

  return (
    <>
      <HistoryHead
        filterEnabled={true}
        filterData={historyFilter}
        onChangeFilter={value => {
          onFilterChange(value);
        }}
        onChangeSearch={value => {
          setHistoryFilter({ ...historyFilter, search: value });
        }}
      />
      <div className="history-sale">
        <div className="app-container">
          <Table
            showHeader={false}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            loading={isLoadingHistoryData}
            pagination={
              isLoadingHistoryData
                ? false
                : {
                    position: ['bottomCenter'],
                    current: historyData?.meta?.page,
                    pageSize: historyData?.meta?.take,
                    total: historyData?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            className="history-sale__table"
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '980px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    color={ECardColorType.Light}
                    title={t('emptyHistoryTitle')}
                    description={t('emptyHistoryDesc')}
                  />
                  <MobileBottomNav
                    breakPoint={768}
                    className="history-sale__empty-actions"
                  >
                    <Button
                      className="history-sale__empty-btn-market"
                      type="primary"
                      block={width <= MEDIA.M768}
                      size={EButtonSizeType.Large}
                      onClick={() => {
                        navigate('/market');
                      }}
                    >
                      {t('goToMarket')}
                    </Button>
                  </MobileBottomNav>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export { HistorySale };
