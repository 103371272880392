import { useQuery } from '@tanstack/react-query';

//types
import { TArticleRequestParams } from 'types/api/blog';

// services
import { GET_ARTICLE } from 'api/blog';

export const useQueryFetchArticle = (slug: TArticleRequestParams) => {
  return useQuery(['useGetArticle'], () => GET_ARTICLE(slug), {
    placeholderData: () => null,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Article';
      console.error(message);
      return message;
    },
  });
};
