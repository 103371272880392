import { useMutation } from '@tanstack/react-query';

//types

// services
import { BUY_BARGAIN } from '../../../api/cart';

export const useMutationBuyBargain = () => {
  return useMutation(BUY_BARGAIN, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while buy bargain';
      console.error(message);
      return message;
    },
  });
};
