const PersonOutlineIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="4"
      cy="4"
      r="4"
      transform="matrix(-1 0 0 1 15.9996 3)"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M4.99963 16.9347C4.99963 16.0743 5.54049 15.3068 6.35073 15.0175V15.0175C10.0037 13.7128 13.9956 13.7128 17.6485 15.0175V15.0175C18.4588 15.3068 18.9996 16.0743 18.9996 16.9347V18.5783C18.9996 19.5926 18.1013 20.3717 17.0973 20.2282L15.1816 19.9546C13.071 19.6531 10.9283 19.6531 8.81765 19.9546L6.902 20.2282C5.89795 20.3717 4.99963 19.5926 4.99963 18.5783V16.9347Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </svg>
);

export { PersonOutlineIcon };
