const ArrowLeftIcon = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.00013 6L3.53046 8.46967C3.23757 8.76256 3.23757 9.23744 3.53046 9.53033L6.00013 12M3.75013 9.00001L14.2501 9.00001"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

export { ArrowLeftIcon };
