import { $api } from 'utils/request';

import {
  TInventoryAcceptTradeRequestParams,
  TNotificationListResponse,
  TNotificationsReadRequestParams,
} from 'types/api';

export const GET_NOTIFICATION_LIST = async () => {
  const url = `notifications`;
  const response = await $api.get<TNotificationListResponse>(url);
  return response.data;
};

export const READ_ALL_NOTIFICATIONS = async () => {
  const url = `notifications/reads`;
  const response = await $api.post(url);
  return response.data;
};

export const READ_NOTIFICATION = async (
  notificationId: TNotificationsReadRequestParams,
) => {
  const url = `notifications/read/${notificationId}`;
  const response = await $api.post<any>(url);
  return response.data;
};
