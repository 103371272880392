import { useQuery } from '@tanstack/react-query';

//types

// services
import { GET_INCOME_REQUEST_LIST } from 'api/transactions';

export const useQueryFetchRequestList = (sku: string) => {
  return useQuery(
    ['useGetBuyRequestList', sku],
    () => GET_INCOME_REQUEST_LIST(sku),
    {
      refetchOnMount: 'always',
      onSuccess: data => {
        return data;
      },
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching request list';
        console.error(message);
        return message;
      },
    },
  );
};
