import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Radio, RadioChangeEvent } from 'antd';
import {
  generalChartConfig,
  generalTooltipConfig,
} from '../../../../config/charts';
// types

// locale
import { useTranslation } from 'react-i18next';
// store
import { useQueryFetchPriceHistory } from 'hooks/api';
// types
import { ERadioButtonSizeType, ERadioButtonStyleType } from 'types/units';

// components

// styles
import './index.scss';

//data
import { dateTypes } from './data';
import { Area } from '@ant-design/plots';
import { useAppStore } from 'store';
import { EmptyCard } from 'components/features';
import { ECardSizeType } from '../../../../components/features/Cards/EmptyCard/types';

const ProductStats = ({ marketName, gameType, extra, onLoaded }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);
  const [filterData, setFilterData] = useState({
    ...dateTypes[0].range,
    firstName: marketName.first,
    secondName: marketName.second,
    provider: gameType,
    extra: extra,
  });
  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 6 },
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {moment(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">$ {data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  useEffect(() => {
    if (!isLoadingRawChart && rawChartData) {
      setData(rawChartData);
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawChartData, isLoadingRawChart]);

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        ...value,
        firstName: marketName.first,
        secondName: marketName.second,
        extra: extra,
        provider: gameType,
      });
    } else {
      setFilterData({
        firstName: marketName.first,
        secondName: marketName.second,
        extra: extra,
        provider: gameType,
      });
    }
  };

  return (
    <div className="product-stats" id="statistics">
      <div className="product-stats__head">
        <div className="product-stats__title">{t('statistics')}</div>
        <div className="product-stats__actions">
          <Radio.Group
            buttonStyle={ERadioButtonStyleType.Solid}
            value={chartDateType}
            size={ERadioButtonSizeType.Middle}
            onChange={onChangeChartDateType}
          >
            {dateTypes?.length &&
              dateTypes.map(item => {
                return (
                  <Radio.Button value={item.range} key={item.value}>
                    {t(item.value)}
                  </Radio.Button>
                );
              })}
          </Radio.Group>
        </div>
      </div>
      <div className="product-stats__card-chart">
        <div className="product-stats__chart">
          {config?.data?.length ? (
            <>
              {/*@ts-ignore*/}
              <Area {...config} />
            </>
          ) : (
            <EmptyCard
              size={ECardSizeType.Minimal}
              title={t('emptyChartTitle')}
              description={t('emptyChartDesc')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ProductStats };
