// core
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//hooks

// utils

// locales

// types

// components(atoms)
import {
  CardSmallGlowIcon,
  CardGlowLogoIcon,
  SteamIcon,
} from 'components/icons';

// styles
import './index.scss';
import { useAppStore } from 'store';

//tempo

// func
const renderPriceDiscount = (price, marketPrice) => {
  let currentPrice = price ? price : marketPrice;
  let percent = currentPrice
    ? (100 - (100 / currentPrice) * marketPrice).toFixed(0)
    : 0;

  return <span>{Number(percent) > 300 ? '>300' : percent}%</span>;
};
const HomeMarqueeCard = ({ item, index, color }) => {
  const { sSetGameType } = useAppStore();
  const navigate = useNavigate();
  const redirectToMarket = (provider, name) => {
    sSetGameType(provider);
    navigate(`/market?GameType=${provider}&NameSearch=${encodeURI(name)}`);
  };

  return (
    <>
      <div
        className={'home-marquee-item'}
        style={{
          background: `linear-gradient(#19181C, #19181C) padding-box,
        linear-gradient(to top, #19181C, #${color}) border-box`,
        }}
        onClick={() => {
          redirectToMarket(item.provider, item.originalMarketName);
        }}
      >
        <div
          className="home-marquee-item__glow"
          style={{
            color: `#${color}`,
          }}
        >
          <CardSmallGlowIcon />
        </div>
        <div className="home-marquee-item__sale">
          <span
            style={{
              color: `#${color}`,
            }}
          >
            <SteamIcon />
          </span>
          {renderPriceDiscount(item.price, item.marketPrice)}
        </div>
        <div className="home-marquee-item__wrap">
          <div className="home-marquee-item__image">
            <div
              className="home-marquee-item__logo"
              style={{
                color: `#${color}`,
              }}
            >
              <CardGlowLogoIcon id={'key' + index} />
            </div>
            <img src={item.image ? item.image : item.preview} alt={item.name} />
          </div>
          <div className="home-marquee-item__title">
            {item.originalMarketName}
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeMarqueeCard };
