// core
import React from 'react';
import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
// utils
import { formatPrice } from '../../../../../utils/commons';
// locales

// store
import { useAppStore } from 'store';
// types
import { IPropsType } from './types';
import { EGameType } from 'types/models';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';

// components
import { TrashIcon } from 'components/icons';
import { FloatRange } from 'components/atoms';
// styles
import './index.scss';
import { formatFloat } from '../../../../../utils/commons';

const getStickers = collection => {
  return (
    <div className="cart-item__stickers">
      {collection.map(item => (
        <img
          src={item.image}
          key={item.id}
          alt={item.name}
          className="cart-item__sticker"
        />
      ))}
    </div>
  );
};

const CartItem = ({ data, onDelete }: IPropsType) => {
  const gameType = data?.provider;
  const { width } = useWindowSize();
  return (
    <div className="cart-item">
      <div className="cart-item__product">
        <RouterLink to={`/market/${data.id}`} className="cart-item__img">
          <img
            src={data.image?.length ? data.image : data.preview}
            alt={data.name}
          />
        </RouterLink>
        <div className="cart-item__info">
          <div className="cart-item__title">{data.marketName.first}</div>
          {gameType === EGameType.CSGO ? (
            <>
              <div className="cart-item__label">{data.marketName.second}</div>
              {width <= MEDIA.M1024 && (
                <>
                  {gameType === EGameType.CSGO ? (
                    <>
                      {data.stickers?.length > 0 && getStickers(data.stickers)}
                    </>
                  ) : (
                    <>{data.gems?.length > 0 && getStickers(data.gems)}</>
                  )}
                </>
              )}
              {data.float > 0 && (
                <div className="cart-item__float">
                  <div className="cart-item__float-grid">
                    <span>{data.shortenExterior}</span>
                    <span>{formatFloat(data.float)}</span>
                  </div>
                  <FloatRange
                    isTooltip={!!data.float}
                    size={EFloatRangeSizeType.Small}
                    maxValue={data.float ? formatFloat(data.float) : 0}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="cart-item__label">{data.slot}</div>
            </>
          )}
        </div>
        {width > MEDIA.M1024 && (
          <>
            {gameType === EGameType.CSGO ? (
              <>{data.stickers?.length > 0 && getStickers(data.stickers)}</>
            ) : (
              <>{data.gems?.length > 0 && getStickers(data.gems)}</>
            )}
          </>
        )}
      </div>
      <div className="cart-item__price">$ {formatPrice(data.price)}</div>
      <Button
        ghost
        className="ant-btn-ghost--error cart-item__btn-delete"
        icon={<TrashIcon />}
        onClick={() => onDelete(data)}
      />
    </div>
  );
};
export { CartItem };
