// useSocket.ts
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`; // Replace with your backend server URL

export const useRandomProductSocket = () => {
  const [socket, setSocket] = useState<any | null>(null);
  const [lastProduct, setLastProduct] = useState<any | null>(null);

  useEffect(() => {
    const socketInstance = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
    });
    socketInstance.on('connect', () => {});

    // Add event listeners for any custom events you want to handle
    socketInstance.on('buyMarketItem', (data: any) => {
      setLastProduct(data);
      // You can update state or perform any other actions here
    });

    // Set the socket instance in state
    setSocket(socketInstance);

    // Cleanup the socket connection when the component unmounts
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return lastProduct;
};

export default useRandomProductSocket;
