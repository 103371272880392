import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { ENABLE_TWO_FACTOR } from '../../../api/profile';

export const useMutationEnableTwoFactor = () => {
  return useMutation(ENABLE_TWO_FACTOR, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while enable twoFactor';
      console.error(message);
      return message;
    },
  });
};
