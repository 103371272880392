// General styles
import 'sanitize.css';
import './styles/global.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { parse, stringify } from 'query-string';
import { queryClient } from './config/queryClient';
import { IoProvider } from 'socket.io-react-hook';

// Initialize languages
import './locales/i18n';

import App from './App';

import './styles/toast/_index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
        >
          <IoProvider>
            <App />
          </IoProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </HelmetProvider>
  </QueryClientProvider>,
);

// Hot reloadable translation json files
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
