import { useQuery } from '@tanstack/react-query';

//types
// services
import { GET_REFERRAL_LINK } from 'api/referral';

export const useQueryFetchReferralLink = () => {
  return useQuery(['useGetReferralLink'], () => GET_REFERRAL_LINK(), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching referral link';
      console.error(message);
      return message;
    },
  });
};
