const PurpleGlowIcon = props => (
  <svg
    width="562"
    height="834"
    viewBox="0 0 562 834"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.25" filter="url(#filter0_f_527_60636)">
      <circle cx="562" cy="562" r="262" fill="#8D5DD9" />
    </g>
    <defs>
      <filter
        id="filter0_f_527_60636"
        x="0"
        y="0"
        width="1124"
        height="1124"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="150"
          result="effect1_foregroundBlur_527_60636"
        />
      </filter>
    </defs>
  </svg>
);

export { PurpleGlowIcon };
