import React from 'react';
import { Modal } from 'antd';
// core

// utils

// locales

//store
import { useAppStore } from 'store';

// types
import { IPropsType } from './types';

// components(atoms)
import { CloseBigIcon } from 'components/icons';

// styles
import './index.scss';
import { useWindowSize } from 'react-use';

// data

// func
const ModalTeleport = ({
  modalId,
  width,
  title,
  maskClosable,
  closeIcon,
  modalClass,
  centered,
  onClose,
  children,
  mobileBreakpoint,
  zIndex,
}: IPropsType) => {
  const { modal, headerTopOffset, sModalClose } = useAppStore();
  const { width: windowWidth } = useWindowSize();

  const onCancel = () => {
    sModalClose(modalId);
    if (typeof onClose === 'function') {
      onClose(modalId);
    }
  };

  return (
    <Modal
      open={modal[modalId]}
      width={width}
      onCancel={() => onCancel()}
      destroyOnClose={true}
      closeIcon={closeIcon ? <CloseBigIcon /> : null}
      footer={null}
      maskClosable={maskClosable}
      centered={!!centered}
      zIndex={zIndex ? zIndex : 1000}
      className={
        (modalClass ? modalClass : '') +
        (mobileBreakpoint && mobileBreakpoint > windowWidth
          ? ' ant-modal--mobile'
          : '')
      }
      style={
        mobileBreakpoint && mobileBreakpoint > windowWidth
          ? { maxHeight: `calc(100vh - ${headerTopOffset}px)` }
          : {}
      }
    >
      {children}
    </Modal>
  );
};

ModalTeleport.defaultProps = {
  width: 520,
  mobileBreakpoint: 1024,
  closeIcon: true,
} as { width: number };

export { ModalTeleport };
