import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { SelectProps } from 'antd/es/select';
import { uniqBy } from 'lodash';
// core
// utils
// locales
import { useTranslation } from 'react-i18next';
//store

// types
import { ESelectSizeType } from 'types/units';
import { IPropsType } from './types';

// components(atoms)
import { ArrowRightIcon, CloseIcon, SearchIcon } from 'components/icons';
// styles
import './index.scss';
import { AppLoader } from '../AppLoader';
import { ELoaderTypeType } from '../AppLoader/types';

// data

// func

const getCustomOptions = (options: any[]) =>
  options.map((item, idx) => {
    return {
      key: idx,
      value: item.originalMarketName,
      label: (
        <div className="app-autocomplete__option">
          <div className="app-autocomplete__item">
            <img src={item.preview} alt={item.originalMarketName} />
            <div className="app-autocomplete__info">
              <div className="app-autocomplete__title">
                {item.originalMarketName}
              </div>
              {/*<div className="app-autocomplete__subtitle">!!!!!</div>*/}
            </div>
          </div>
          <ArrowRightIcon />
        </div>
      ),
    };
  });
const AppAutocomplete = ({
  className,
  popupClassName,
  size,
  popupMatchSelectWidth,
  options,
  current,
  isGetPopupContainer,
  isLoading,
  isDefault,
  onSelect,
  onSearch,
  onInputKeyDown,
  onFocus,
  onBlur,
  onReset,
}: IPropsType) => {
  const [customOptions, setCustomOptions] = useState<
    SelectProps<object>['options']
  >([]);

  const { t } = useTranslation();

  useEffect(() => {
    setCustomOptions(options?.length ? getCustomOptions(options) : []);
  }, [options]);
  const onSelectSearch = (value: string) => {
    if (onSelect) {
      onSelect(value);
    }
  };

  const onInnerReset = () => {
    setCustomOptions([]);
    onReset!();
  };

  return (
    <AutoComplete
      size={size ? size : ESelectSizeType.Middle}
      className={
        className ? `app-autocomplete ${className}` : 'app-autocomplete'
      }
      popupClassName={
        popupClassName
          ? `app-autocomplete__dropdown ${popupClassName}`
          : 'app-autocomplete__dropdown'
      }
      // @ts-ignore
      getPopupContainer={() => {
        return isGetPopupContainer
          ? document.querySelector('.app-autocomplete')
          : document.body;
      }}
      dropdownAlign={{ offset: [0, 0] }}
      popupMatchSelectWidth={
        popupMatchSelectWidth !== undefined ? popupMatchSelectWidth : true
      }
      value={current}
      options={customOptions}
      onSelect={onSelectSearch}
      onSearch={onSearch}
      onFocus={onFocus}
      onBlur={onBlur}
      onInputKeyDown={event => {
        if (onInputKeyDown) {
          onInputKeyDown(event);
        }
      }}
      notFoundContent={
        isDefault && (
          <>
            {isLoading ? (
              <div className="app-autocomplete__loader">
                <AppLoader type={ELoaderTypeType.Relative} size={24} />
              </div>
            ) : (
              <div className="app-autocomplete__loader">
                {t('nothingFound')}
              </div>
            )}
          </>
        )
      }
    >
      <Input
        placeholder={t('search')}
        prefix={<SearchIcon />}
        suffix={
          current ? (
            <CloseIcon onClick={() => (onReset ? onInnerReset() : null)} />
          ) : null
        }
      />
    </AutoComplete>
  );
};

AppAutocomplete.defaultProps = {
  options: [],
  isDefault: true,
} as { options: SelectProps<object>['options'] };

export { AppAutocomplete };
