const NotificationTooltipIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666ZM8.00008 5.16675C7.53984 5.16675 7.16675 5.53984 7.16675 6.00008C7.16675 6.27622 6.94289 6.50008 6.66675 6.50008C6.39061 6.50008 6.16675 6.27622 6.16675 6.00008C6.16675 4.98756 6.98756 4.16675 8.00008 4.16675C9.0126 4.16675 9.83342 4.98756 9.83342 6.00008C9.83342 6.36431 9.72669 6.70513 9.54263 6.99117C9.42734 7.17033 9.29256 7.34138 9.16828 7.49584L9.10125 7.57892L9.10124 7.57892L9.10124 7.57893C8.99812 7.70656 8.90347 7.82372 8.816 7.94393C8.60028 8.24041 8.50008 8.46321 8.50008 8.66675V9.00008C8.50008 9.27622 8.27622 9.50008 8.00008 9.50008C7.72394 9.50008 7.50008 9.27622 7.50008 9.00008V8.66675C7.50008 8.1339 7.76169 7.69326 8.00739 7.35558C8.11121 7.2129 8.22497 7.07218 8.32841 6.94421L8.32842 6.94421L8.38918 6.86894C8.51264 6.7155 8.61773 6.58051 8.70168 6.45005C8.78502 6.32054 8.83342 6.16672 8.83342 6.00008C8.83342 5.53984 8.46032 5.16675 8.00008 5.16675ZM8 10.1667C8.27614 10.1667 8.5 10.3906 8.5 10.6667V11.3334C8.5 11.6096 8.27614 11.8334 8 11.8334C7.72386 11.8334 7.5 11.6096 7.5 11.3334V10.6667C7.5 10.3906 7.72386 10.1667 8 10.1667Z"
      fill="currentColor"
    />
  </svg>
);

export { NotificationTooltipIcon };
