import React, { ReactNode } from 'react';

// utils

// locales

// store
import { useAppStore } from 'store';
// types

// components(atoms)
import { Button } from 'antd';
import { BurgerIcon } from 'components/icons';
// styles

// data

// func
const DashboardHead = ({ title, children }) => {
  const { sDrawerOpen } = useAppStore();
  const onOpenDrawer = () => {
    sDrawerOpen('dashboardDrawer');
  };

  return (
    <div className="dashboard__main--head">
      <h1>{title}</h1>
      <div className="dashboard__main--head-button">
        <div className="dashboard__main--head-burger">
          <Button
            type="text"
            size="middle"
            onClick={() => {
              onOpenDrawer();
            }}
          >
            <BurgerIcon />
          </Button>
        </div>
        {children}
      </div>
    </div>
  );
};
export { DashboardHead };
