import React, { useEffect } from 'react';

// utils
import { formatPartsPrice, formatPrice } from '../../../../utils/commons';
import * as yup from 'yup';
//locales
import { useTranslation } from 'react-i18next';

//hooks
import { useMutationCreateWithdrawRequest } from '../../../../hooks/api';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { Button } from 'antd';
import { ArrowLeftLightIcon } from 'components/icons';
/*@ts-ignore*/
// import WithdrawBalanceConfirmationBg from 'assets/img/WithdrawBalanceConfirmationBg.png';
import WithdrawBalanceConfirmationBg from 'assets/img/WithdrawBalanceConfirmationNew.png';

// styles
import './index.scss';
import { WithdrawBalancePassword } from '../WithdrawBalancePassword';
import { ModalTeleport } from 'components/atoms';
import { toast } from 'react-toastify';

// data
const formatCard = card => {
  if (card.length >= 8) {
    return `${card.slice(0, 4)}....${card.slice(-4)}`;
  } else {
    return card;
  }
};
const WithdrawBalanceConfirmation = ({ formData, modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();

  const {
    data: withdrawRequestData,
    isLoading: isLoadingWithdrawRequest,
    mutate: mutateWithdrawRequest,
  } = useMutationCreateWithdrawRequest();
  const onConfirm = () => {
    sModalOpen('withdrawPassword');
  };
  const onDecline = id => {
    sModalClose(id);
    sModalOpen('withdrawForm');
  };

  useEffect(() => {
    if (!isLoadingWithdrawRequest && withdrawRequestData) {
      sModalClose(modalId);
      sModalClose('withdrawForm');
      toast.success(t('toastWithdrawRequest'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawRequestData, isLoadingWithdrawRequest]);
  const onSubmitPassword = payload => {
    const { amount, tax, commission, service, ...clearedData } = formData;
    const withdrawPayloadData = {
      amount: Number(amount),
      service: service,
      password: payload.password,
      form: { ...clearedData },
    };
    mutateWithdrawRequest(withdrawPayloadData);
  };
  return (
    <div className="withdraw-balance-confirmation">
      <div className="withdraw-balance-confirmation__head">
        <div className="withdraw-balance-confirmation__head--bg">
          <img src={WithdrawBalanceConfirmationBg} alt="Skinsty" />
        </div>
        <div className="withdraw-balance-confirmation__head--action">
          <Button
            type={EButtonTypeType.Primary}
            className={'ant-btn-ghost--white'}
            ghost
            size={EButtonSizeType.Large}
            onClick={() => {
              onDecline(modalId);
            }}
          >
            <ArrowLeftLightIcon />
            {t('change')}
          </Button>
        </div>
        <div className="withdraw-balance-confirmation__head--content">
          <div className="withdraw-balance-confirmation__head--content-left">
            <h3>{t('decreaseFunds')}</h3>
            <p>{t('checkDataForTransaction')}</p>
          </div>
          <div className="withdraw-balance-confirmation__head--content-right">
            <p>{t('decreaseAmount')}</p>
            <div className="withdraw-balance-confirmation__head--content-right-sum">
              ${' '}
              <span>
                {formatPartsPrice(Number(formData.amount)).significantDigit}
                <span>
                  .{formatPartsPrice(Number(formData.amount)).fractionDigit}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw-balance-confirmation__body">
        <div className="withdraw-balance-confirmation__body--card">
          <div className="withdraw-balance-confirmation__body--card-title">
            $ {formatPrice(Number(formData.amount))}
          </div>
          <div className="withdraw-balance-confirmation__body--card-label">
            {t('decreaseAmount')}
          </div>
        </div>
        <div className="withdraw-balance-confirmation__body--card">
          <div className="withdraw-balance-confirmation__body--card-title">
            $ {formatPrice(Number(formData.amount) - Number(formData.tax))}{' '}
            <span>
              $ {formatPrice(Number(formData.tax))} ({formData.commission}%)
            </span>
          </div>
          <div className="withdraw-balance-confirmation__body--card-label">
            {t('willBeCredited')}
          </div>
        </div>
        <div className="withdraw-balance-confirmation__body--card">
          <div className="withdraw-balance-confirmation__body--card-title">
            {formatCard(formData.card ? formData.card : formData.crypto)}
          </div>
          <div className="withdraw-balance-confirmation__body--card-label">
            {t('cardNumber')}
          </div>
        </div>
      </div>
      <div className="withdraw-balance-confirmation__actions">
        <Button
          block
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          disabled={isLoadingWithdrawRequest}
          onClick={() => {
            onConfirm();
          }}
        >
          {t('decreaseFundsBtn')} $ {formatPrice(Number(formData.amount))}
        </Button>
      </div>
      <ModalTeleport centered modalId={'withdrawPassword'} width={427}>
        <WithdrawBalancePassword
          onSubmitPassword={onSubmitPassword}
          modalId={'withdrawPassword'}
        />
      </ModalTeleport>
    </div>
  );
};

export { WithdrawBalanceConfirmation };
