const ArrowLightDownIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33301 10.6666L7.52827 12.8618C7.78862 13.1222 8.21073 13.1222 8.47108 12.8618L10.6663 10.6666M7.99967 12.6666L7.99967 3.33325"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export { ArrowLightDownIcon };
