const CardGlowIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 157 245"
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_f_600_76243)">
      <circle cx="78.4287" cy="104" r="32" fill="currentColor" />
    </g>
    <defs>
      <filter
        id="filter0_f_600_76243"
        x="-103.571"
        y="-78"
        width="364"
        height="364"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="75"
          result="effect1_foregroundBlur_600_76243"
        />
      </filter>
    </defs>
  </svg>
);

export { CardGlowIcon };
