import React, { useEffect, useState } from 'react';
import { Avatar, Drawer, Radio, RadioChangeEvent } from 'antd';
import { NavLink } from 'react-router-dom';
import { Badge } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import IconRenderer from 'hoc/IconRenderer';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore } from 'store';
import {
  useMutateLogout,
  useNotificationSocket,
  useQueryFetchNotificationList,
  useQueryFetchProfileInfo,
} from '../../../../hooks/api';

// types
import { ERadioButtonSizeType, EAvatarSizeType } from 'types/units';

// components
import { HeaderNav } from '../components/HeaderNav';
import { HeaderApps } from '../components/HeaderApps';
import { HeaderTrades } from '../components/HeaderTrades';
import { HeaderNoticeDrawer } from './HeaderNoticeDrawer';
import { ArrowRightIcon } from 'components/icons';

// styles
import './index.scss';

// data
import { games } from '../components/data';

const HeaderDrawer = () => {
  const { t } = useTranslation();
  const { drawer, headerTopOffset, sDrawerClose, sDrawerOpen } = useAppStore();
  const { width } = useWindowSize();
  const { gameType, sSetGameType } = useAppStore();
  const { accessToken: isAuth } = useAuthStore();
  const { mutate: mutateLogout } = useMutateLogout();
  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);
  const [enableFetchNotifications, setEnableFetchNotifications] =
    useState<boolean>(false);

  const [notifications, setNotifications] = useState<any>([]);
  const { data: notificationList, isLoading: isLoadingNotificationList } =
    useQueryFetchNotificationList(enableFetchNotifications);

  useEffect(() => {
    if (notificationList) {
      setNotifications(notificationList);
    }
  }, [notificationList]);

  const lastNotification = useNotificationSocket(
    enableFetchNotifications,
    profileData?.id,
  );

  useEffect(() => {
    if (isAuth && profileData) {
      setEnableFetchNotifications(true);
    }
  }, [isAuth, profileData]);

  useEffect(() => {
    if (lastNotification) {
      setNotifications([lastNotification, ...notifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotification]);
  const onClose = () => {
    sDrawerClose('headerDrawer');
  };

  const onChangeGameRadio = ({ target: { value } }: RadioChangeEvent) => {
    sSetGameType(value);
    window.location.reload();
  };

  const onOpenNoticeDrawer = () => {
    sDrawerOpen('headerNoticeDrawer');
  };

  const onLogout = () => {
    mutateLogout();
    sDrawerClose('headerDrawer');
  };

  const getIcon = Comp => {
    return <Comp />;
  };

  return (
    <>
      <Drawer
        placement="left"
        destroyOnClose={true}
        closable={false}
        onClose={onClose}
        open={drawer['headerDrawer']}
        getContainer={false}
        rootClassName={'header-drawer'}
        width={276}
        rootStyle={{ height: `calc(100vh - ${headerTopOffset}px)` }}
      >
        <div className="header-drawer__content">
          {width <= MEDIA.M1024 && (
            <>
              <HeaderTrades />

              <Radio.Group
                className="header-drawer__radio-game"
                value={gameType}
                size={ERadioButtonSizeType.Middle}
                onChange={onChangeGameRadio}
              >
                {games?.length &&
                  games.map(game => {
                    return (
                      <Radio.Button value={game.value} key={game.value}>
                        {IconRenderer(game.icon)}
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>

              {isAuth && !isLoadingProfileData && profileData && (
                <div className="header-drawer__card">
                  <div
                    className="header-drawer__profile"
                    onClick={() => {
                      onOpenNoticeDrawer();
                    }}
                  >
                    <Avatar
                      className="header-drawer__avatar"
                      size={EAvatarSizeType.Small}
                      src={profileData.avatar?.small}
                    >
                      {profileData.username.charAt(0)}
                    </Avatar>
                    <span className="header-drawer__name">
                      {profileData.username}
                    </span>
                    <Badge
                      count={notifications ? notifications.length : 0}
                      overflowCount={5}
                    />
                    <div className="header-drawer__profile-actions">
                      <ArrowRightIcon />
                    </div>
                  </div>
                  <ul>
                    <li>
                      <NavLink
                        to={'/dashboard/profile'}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t('settings')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={'/history/purchase'}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t('history')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={'/dashboard/referral'}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t('refSystem')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={'/deals/trade'}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t('bargain')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={'/dashboard/referral'}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t('promoCode')}
                      </NavLink>
                    </li>
                    <li
                      className="header-drawer__profile-nav--danger"
                      onClick={() => {
                        onLogout();
                      }}
                    >
                      {t('logout')}
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
          {width <= MEDIA.M1280 && <HeaderNav />}
          {width <= MEDIA.M1280 && <HeaderApps />}
        </div>
      </Drawer>
      {width <= MEDIA.M1024 && <HeaderNoticeDrawer />}
    </>
  );
};

export { HeaderDrawer };
