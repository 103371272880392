const BlogIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 13.7671 1.67697 15.4367 2.43404 16.9092C2.4814 17.0013 2.48243 17.0893 2.46016 17.1523L1.53188 19.7793C0.985728 21.3249 2.47455 22.8138 4.02015 22.2676L6.54274 21.3762C6.60823 21.3531 6.7001 21.3554 6.79451 21.4077C8.33751 22.2632 10.113 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25Z"
      fill="currentColor"
    />
    <circle cx="7.0498" cy="12.0498" r="1.25" fill="currentColor" />
    <circle cx="12.0498" cy="12.0498" r="1.25" fill="currentColor" />
    <circle cx="17.0498" cy="12.0498" r="1.25" fill="currentColor" />
  </svg>
);

export { BlogIcon };
