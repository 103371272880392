export enum ERadioButtonStyleType {
  Outline = 'outline',
  Solid = 'solid',
}

export enum ERadioButtonSizeType {
  Large = 'large',
  Middle = 'middle',
  Small = 'small',
}
