import React, { useEffect } from 'react';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { useAppStore } from 'store';
import { EOtpType } from 'types/models';
import { useTranslation } from 'react-i18next';

import useHttpClient from 'hooks/general/useHttpClient';
import { ThemeConfig } from 'antd';

interface IProps {
  theme?: ThemeConfig;
}
const withErrorHandler =
  (
    WrappedComponent: React.FC<IProps>,
    $api: AxiosInstance,
    $apiAuth: AxiosInstance,
  ) =>
  (props: JSX.IntrinsicAttributes & IProps) => {
    const { t } = useTranslation();
    const { sModalOpen, sSetOtpType } = useAppStore();
    const [apiError, clearApiError] = useHttpClient($api);
    const [apiAuthError, clearapiAuthError] = useHttpClient($apiAuth);

    useEffect(() => {
      if (apiError) {
        toast.error(apiError.message);
        clearApiError();
      }
      if (apiError?.code === 403) {
        sModalOpen('blockWarning');
      }
      if (apiError?.code === 499) {
        toast.error(t('toastNeed2Fa'));
        sSetOtpType(EOtpType.Confirm);
        sModalOpen('twoFactorModal');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiError]);

    useEffect(() => {
      if (apiAuthError) {
        toast.error(apiAuthError.message);
        clearapiAuthError();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiAuthError]);

    return <WrappedComponent {...props} />;
  };

export default withErrorHandler;
