export function setCatalogGrid(type: string) {
  localStorage.setItem('catalog_grid', type);
}

export function setCatalogStack(type: string) {
  localStorage.setItem('catalog_stack', type);
}

export function setGameType(type: string) {
  localStorage.setItem('game_type', type);
}
