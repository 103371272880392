import React from 'react';
// utils

// locales

// store

// types

// components(atoms)

// styles
import './index.scss';

// data

// func
const DashboardBlock = ({ children, title, description, gradient = false }) => {
  return (
    <div className={'dashboard__main--block' + (gradient ? ' gradient' : '')}>
      <div className="dashboard__main--block-left">
        <div className="dashboard__main--block-title">{title}</div>
        <div className="dashboard__main--block-desc">{description}</div>
      </div>
      <div className="dashboard__main--block-right">{children}</div>
    </div>
  );
};
export { DashboardBlock };
