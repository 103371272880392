// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

//hooks
import { useQueryFetchProductInfo } from '../../../hooks/api';

// utils

// locales

// store
import { useAppStore } from 'store';

// types

// components(atoms)
import { ProductSEO } from './SEO';
import { ProductAside } from './ProductAside';
import { ProductRequests } from './ProductRequests';
import { ProductSimilar } from './ProductSimilar';
import { ProductStats } from './ProductStats';
import { ProductInfo } from './ProductInfo';
import { ModalTeleport } from 'components/atoms';
import { OfferTradeModal } from 'components/features/';

// styles
import './index.scss';

// data

// func
const Product = () => {
  const { slug: productSlug } = useParams();
  const { data: productData, isLoading: isLoadingProductInfo } =
    useQueryFetchProductInfo(productSlug);

  const [isLoadedProductRequests, setIsLoadedProductRequests] = useState(false);
  const [isLoadedProductSimilar, setIsLoadedProductSimilar] = useState(false);
  const [isLoadedProductStats, setIsLoadedProductStats] = useState(false);

  useEffect(() => {
    if (
      !isLoadingProductInfo &&
      productData &&
      isLoadedProductRequests &&
      isLoadedProductSimilar &&
      isLoadedProductStats
    ) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productData,
    isLoadingProductInfo,
    isLoadedProductRequests,
    isLoadedProductSimilar,
    isLoadedProductStats,
  ]);

  return (
    <>
      <ProductSEO
        title={productData ? (productData?.originalMarketName as string) : ''}
        desc={productData ? (productData?.description as string) : ''}
        image={
          productData
            ? productData.image
              ? (productData.image as string)
              : (productData.preview as string)
            : ''
        }
      />
      <div className="product">
        <div className="app-container">
          <div className="product__grid">
            <div className="product__aside">
              <ProductAside productSlug={productSlug} />
            </div>

            <div className="product__main">
              <ProductInfo />
              {productData && (
                <>
                  <ProductRequests
                    product={productData}
                    onLoaded={() => {
                      setIsLoadedProductRequests(true);
                    }}
                  />
                  <ProductSimilar
                    product={productData}
                    onLoaded={() => {
                      setIsLoadedProductSimilar(true);
                    }}
                  />
                  <ProductStats
                    marketName={productData.marketName}
                    extra={productData.exterior}
                    gameType={productData.provider}
                    onLoaded={() => {
                      setIsLoadedProductStats(true);
                    }}
                  />
                </>
              )}
              {productData && productData?.description?.length && (
                <div
                  className="product__desc"
                  dangerouslySetInnerHTML={{
                    __html: productData.description,
                  }}
                />
              )}
            </div>
          </div>
          <ModalTeleport
            centered
            modalId={'offerTrade'}
            width={1024}
            modalClass={'offer-trade-modal__wrapper'}
          >
            <OfferTradeModal modalId={'offerTrade'} />
          </ModalTeleport>
        </div>
      </div>
    </>
  );
};

export { Product };
