import { useQuery } from '@tanstack/react-query';

// services
import { GET_MARKET_FILTERS } from 'api/market';

export const useQueryAvailableFilters = params => {
  return useQuery(
    ['useQueryAvailableFilters'],
    () => GET_MARKET_FILTERS(params),
    {
      refetchOnMount: 'always',
      onSuccess: data => {},
      onError: (err: Error) => {
        const message = err ? err?.message : 'Error while fetching Profile';
        console.error(message);
        return message;
      },
    },
  );
};
