import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';

// locale

//stores
import { useAppStore } from 'store';

// types
import { ESelectSizeType } from 'types/units';

// styles
import './index.scss';

//data
import { ArrowDownIcon } from 'components/icons';
import { yupSync } from '../../../containers/user/Dashboard/DashboardLanguage/schema';
const { Option } = Select;

const CurrencyToggler = () => {
  const [form] = Form.useForm();

  const { sSetCurrentCurrency, currentCurrency, currencyList } = useAppStore();

  useEffect(() => {
    if (currentCurrency?.sku && currencyList) {
      form.setFieldsValue({ currency: currentCurrency.sku });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrency, currencyList]);
  const onChangeSelect = (value: string) => {
    sSetCurrentCurrency(currencyList.find(el => el.sku === value));
  };

  return (
    <div className="currency-toggler">
      {currentCurrency && currencyList && (
        <Form form={form}>
          <Form.Item
            name={'currency'}
            /*@ts-ignore*/
            rules={[yupSync]}
          >
            <Select
              className="ant-select--light"
              size={ESelectSizeType.Small}
              suffixIcon={<ArrowDownIcon />}
              onChange={onChangeSelect}
              dropdownAlign={{ offset: [0, 0] }}
              popupClassName={'currency-toggler__dropdown'}
            >
              {currencyList?.length &&
                currencyList.map(currency => {
                  return (
                    <Option
                      value={currency.sku}
                      label={currency.name}
                      key={currency.sku}
                    >
                      {currency.sign} {currency.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
export { CurrencyToggler };
