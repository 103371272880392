import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { EGridType } from 'types/units/grid';
import { EGameType } from 'types/models/game';
import { EOtpType } from 'types/models/otp';
import { setCatalogGrid, setCatalogStack, setGameType } from '../services/app';
import { TCurrencyListResponse, TVariablesResponse } from 'types/api';

type AppState = {
  commission_percentage: number;
  modal: object;
  drawer: object;
  headerTopOffset: number;
  isFixed: boolean;
  catalogGrid: EGridType | string;
  catalogStack: boolean;
  gameType: EGameType;
  currencyList: TCurrencyListResponse[];
  currentCurrency: object | any;
  variables: any;
  otpCode: string | null;
  otpType: EOtpType;
  sSetCommission: (commission_percentage: number) => void;
  sSetFixedStatus: (status: boolean) => void;
  sSetHeaderOffset: (status: boolean) => void;
  sModalOpen: (modalId: string) => void;
  sModalClose: (modalId: string) => void;
  sDrawerOpen: (modalId: string) => void;
  sDrawerClose: (modalId: string) => void;
  sSetCatalogGrid: (catalogGrid: EGridType | string) => void;
  sSetCatalogStack: (catalogStack: boolean) => void;
  sSetGameType: (gameType: EGameType) => void;
  sSetCurrencyList: (list: TCurrencyListResponse[]) => void;
  sSetCurrentCurrency: (currency: object | any) => void;
  sSetVariables: (list: TVariablesResponse[]) => void;
  sSetOtpType: (type: EOtpType) => void;
  sSetOtpCode: (code: string | null) => void;
};

const getCatalogGrid = () => {
  let catalogGrid = localStorage.getItem('catalog_grid');
  return catalogGrid ? catalogGrid : EGridType.Default;
};

const getCatalogStack = () => {
  let catalogStack = localStorage.getItem('catalog_stack');
  return catalogStack ? catalogStack === 'true' : true;
};

const getGameType = () => {
  let game = localStorage.getItem('game_type');
  return game ? (game as EGameType) : EGameType.CSGO;
};

const useAppStore = create(
  devtools<AppState>(
    (set, get) => ({
      commission_percentage: 0,
      modal: {},
      drawer: {},
      headerTopOffset: 0,
      catalogGrid: getCatalogGrid(),
      catalogStack: getCatalogStack(),
      gameType: getGameType(),
      isFixed: false,
      currencyList: [],
      currentCurrency: {},
      variables: [],
      otpType: EOtpType.Confirm,
      otpCode: null,
      sSetOtpCode: code => {
        set(state => ({
          otpCode: code,
        }));
      },
      sSetOtpType: type => {
        set(state => ({
          otpType: type,
        }));
      },
      sSetVariables: list => {
        set(state => ({
          variables: list,
        }));
      },
      sSetCommission: commission_percentage => {
        set(state => ({
          commission_percentage,
        }));
      },
      sSetCurrencyList: list => {
        set(state => ({
          currencyList: list,
        }));
      },
      sSetCurrentCurrency: currency => {
        localStorage.removeItem('currency');
        localStorage.setItem('currency', currency.sku);
        set(state => ({
          currentCurrency: currency,
        }));
      },
      sSetFixedStatus: status => {
        const body = document.querySelector('html');
        if (status) {
          body!.classList.add('app-fix');
        } else {
          body!.classList.remove('app-fix');
        }
        set(state => ({
          isFixed: status,
        }));
      },
      sSetHeaderOffset: status => {
        if (status) {
          let headerTopOffset = 0;
          let headerSticky = document.querySelector('.header-sticky');
          if (headerSticky) {
            headerTopOffset =
              headerSticky!.getBoundingClientRect().top +
              headerSticky!.clientHeight;
            set(state => ({
              headerTopOffset,
            }));
          }
        }
      },

      sModalOpen: modalId => {
        let modal = get().modal;

        modal[modalId] = true;

        set(state => ({
          modal,
        }));

        let isSome = Object.keys(modal).some(key => modal[key]);

        if (isSome) {
          get().sSetFixedStatus(true);
          get().sSetHeaderOffset(true);
        }
      },
      sModalClose: modalId => {
        let modal = get().modal;
        modal[modalId] = false;

        set(state => ({
          modal,
        }));

        let isEvery = Object.keys(modal).every(key => !modal[key]);

        if (isEvery) {
          setTimeout(() => {
            get().sSetFixedStatus(false);
            get().sSetHeaderOffset(false);
          }, 400);
        }
      },
      sSetCatalogGrid: catalogGrid => {
        setCatalogGrid(catalogGrid);
        set(state => ({
          catalogGrid,
        }));
      },
      sSetCatalogStack: catalogStack => {
        setCatalogStack(catalogStack.toString());
        set(state => ({
          catalogStack,
        }));
      },
      sSetGameType: gameType => {
        setGameType(gameType);
        set(state => ({
          gameType,
        }));
      },
      sDrawerOpen: drawerId => {
        let drawer = get().drawer;
        drawer[drawerId] = true;

        set(state => ({
          drawer,
        }));

        let isSome = Object.keys(drawer).some(key => drawer[key]);

        if (isSome) {
          get().sSetFixedStatus(true);
          get().sSetHeaderOffset(true);
        }
      },
      sDrawerClose: drawerId => {
        let drawer = get().drawer;
        drawer[drawerId] = false;

        set(state => ({
          drawer,
        }));

        let isEvery = Object.keys(drawer).every(key => !drawer[key]);

        if (isEvery) {
          setTimeout(() => {
            get().sSetFixedStatus(false);
            get().sSetHeaderOffset(false);
          }, 400);
        }
      },
    }),
    { name: 'accessToken' },
  ),
);
export { useAppStore };
