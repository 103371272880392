// useSocket.ts
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`; // Replace with your backend server URL

export const useSocket = (enabled, chatId, userId): any | null => {
  const [socket, setSocket] = useState<any | null>(null);
  const [lastMessage, setLastMessage] = useState<any | null>(null);
  useEffect(() => {
    if (enabled) {
      const socketInstance = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
      });
      socketInstance.on('connect', () => {
        const payload = {
          userId: userId.toString(),
          chatId: chatId.toString(),
        };
        socketInstance.emit('joinChat', payload);
      });

      // Add event listeners for any custom events you want to handle
      socketInstance.on('message', (data: any) => {
        setLastMessage(data);
        // You can update state or perform any other actions here
      });

      // Set the socket instance in state
      setSocket(socketInstance);

      // Cleanup the socket connection when the component unmounts
      return () => {
        socketInstance.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return lastMessage;
};

export default useSocket;
