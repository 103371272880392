import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import IconRenderer from 'hoc/IconRenderer';
import { useTranslation } from 'react-i18next';
// locale

// types
import { IPropsType } from './types';
import { EButtonTypeType } from 'types/units/button';
import { EMarketFilterCategoryType } from 'types/models';

// components
import { ArrowDownIcon, CheckAllIcon } from 'components/icons';
// styles
import './index.scss';
import { MEDIA } from '../../../../utils/constants';
import { useWindowSize } from 'react-use';

//data

const CategoryFilter = ({
  data,
  sku,
  currentValue,
  label,
  icon,
  isCompact,
  onSelect,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [isOpen, setIsOpen] = useState(false);
  const [isCurrentCategory, setIsCurrentCategory] = useState(false);
  useEffect(() => {
    // @ts-ignore
    let currentItem = data.some(item => currentValue?.indexOf(item.value) > -1);
    setIsCurrentCategory(!!currentItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);
  const onChangeSelect = (value: string) => {
    onSelect(value);
  };
  const getPopoverContent = () => {
    return (
      <div className="category-filter__dropdown">
        <ul
          className={
            'category-filter__content' +
            (isCompact ? ' category-filter__content--compact' : '')
          }
        >
          {data.map(item => (
            <li
              key={item.value}
              className={
                'category-filter__card' +
                // @ts-ignore
                (currentValue?.indexOf(item.value) > -1
                  ? ' category-filter__card--active'
                  : '')
              }
              onClick={() => onChangeSelect(item.value)}
            >
              {item.value !== `${EMarketFilterCategoryType.All}_${sku}` ? (
                <>
                  <div className="category-filter__card-title">
                    {item.title}
                  </div>
                  {item.image ? (
                    <div className="category-filter__card-block">
                      <img src={item.image} alt={item.title} />
                    </div>
                  ) : (
                    <div className="category-filter__card-block">
                      <span>{item.title}</span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="category-filter__card-title">
                    {t(item.title)}
                  </div>
                  <div className="category-filter__card-block">
                    <CheckAllIcon />
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="category-filter">
      <Popover
        placement={width > MEDIA.M425 ? 'bottomLeft' : 'bottom'}
        content={getPopoverContent()}
        trigger="click"
        open={isOpen}
        // @ts-ignore
        getPopupContainer={() => document.querySelector('.catalog__main')}
        onOpenChange={newOpen => setIsOpen(newOpen)}
        align={{ offset: [0, 12] }}
        overlayClassName={'category-filter__wrapper'}
      >
        <Button
          type={EButtonTypeType.Link}
          className={
            'category-filter__btn' +
            (isCurrentCategory ? ' category-filter__btn--selected' : '')
          }
        >
          {icon && (
            <img className="category-filter__icon" src={icon} alt={label} />
          )}
          {label}
          <ArrowDownIcon className="category-filter__arrow" />
        </Button>
      </Popover>
    </div>
  );
};
export { CategoryFilter };
