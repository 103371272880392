export const sortList: Array<{
  label: string;
  key: string;
  value: string;
}> = [
  {
    label: 'filterFeatured',
    key: 'featured',
    value: '0',
  },
  {
    label: 'filterQualityDesc',
    key: 'quality',
    value: 'DESC',
  },
  {
    label: 'filterQualityAsc',
    key: 'quality',
    value: 'ASC',
  },
  {
    label: 'filterPriceDesc',
    key: 'price',
    value: 'DESC',
  },
  {
    label: 'filterPriceAsc',
    key: 'price',
    value: 'ASC',
  },
];
