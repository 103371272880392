import React, { useEffect, useState } from 'react';
import { Button, Table, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
import {
  useQueryFetchActiveInventory,
  useMutationRemoveFromMarket,
  useQueryFetchUserBalance,
  usePendingInventorySocket,
  useQueryFetchProfileInfo,
} from 'hooks/api';
// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { EGameType } from 'types/models';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
import { EInventoryPendingType, EInventoryPendingState } from 'types/models';
// components
import { AppLoader, FloatRange, ModalTeleport } from 'components/atoms';
import { StatIcon } from 'components/icons';
import {
  PriceModal,
  EmptyCard,
  TableCellImage,
  TableCellStickers,
} from 'components/features';
import { InventoryPendingTimer } from './InventoryPendingTimer';
import { InventoryHead } from '../InventoryHead';
// styles
import './index.scss';

//data
import {
  formatDate,
  formatPrice,
  objectToString,
} from '../../../../utils/commons';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { queryClient } from '../../../../config/queryClient';

//data
const { Text } = Typography;
const transformData = arr => {
  return arr.map(el => {
    let { item, price, state, tradeOfferId, type, createdAt, tID, expiredAt } =
      el;
    let {
      sku,
      image,
      preview,
      name,
      float,
      shortenExterior,
      stickers,
      status,
      marketName,
      originalMarketName,
      provider,
      gems,
      exterior,
    } = item;
    return {
      tID: tID,
      createdAt: createdAt,
      expiredAt: expiredAt,
      id: sku,
      state: state,
      tradeOfferId: tradeOfferId,
      type: type,
      status: status,
      price: price,
      img: image ? image : preview,
      name: name,
      short: shortenExterior,
      float: float,
      marketName: marketName,
      provider: provider,
      originalMarketName: originalMarketName,
      exterior: exterior,
      isTransformed: true,
      stickers: stickers.map(el => {
        return { image: el.preview, title: el.title, price: el.valueExtra };
      }),
      gems: gems.map(gem => {
        return {
          image: gem.preview,
          title: gem.title,
          description: gem.valueExtra,
        };
      }),
    };
  });
};

const renderTextType = state => {
  if (
    [
      EInventoryPendingState.Created,
      EInventoryPendingState.NeedBuyerApprove,
      EInventoryPendingState.NeedSellerApprove,
    ].indexOf(state) > -1
  ) {
    return 'warning';
  } else if (state === EInventoryPendingState.Canceled) {
    return 'danger';
  } else {
    return 'success';
  }
};
const renderStateText = (state, t, type) => {
  if (state === EInventoryPendingState.Success) {
    return t('inventoryPendingStateSuccess');
  } else if (state === EInventoryPendingState.Canceled) {
    return t('inventoryPendingStateCanceled');
  } else if (state === EInventoryPendingState.NeedBuyerApprove) {
    if (type === EInventoryPendingType.Buy) {
      return t('inventoryPendingStateBuyer');
    } else {
      return t('inventoryPendingStateSellerReversse');
    }
  } else if (state === EInventoryPendingState.NeedSellerApprove) {
    if (type === EInventoryPendingType.Buy) {
      return t('inventoryPendingStateBuyerReverse');
    } else {
      return t('inventoryPendingStateSeller');
    }
  } else {
    return t('inventoryPendingStateCreated');
  }
};
const InventoryPending = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { gameType, sModalOpen } = useAppStore();
  const [enabled, setEnabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [removeDeal, setRemoveDeal] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [enabledSocket, setEnabledSocket] = useState<boolean>(false);
  useQueryFetchUserBalance(true);

  const [payload, setPayload] = useState<any>({
    provider: gameType,
  });

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfile,
  } = useQueryFetchProfileInfo(false);

  useEffect(() => {
    if (!isLoadingProfileData && profileData) {
      setEnabledSocket(true);
    }
  }, [profileData, isLoadingProfileData]);

  const {
    data: pendingInventoryData,
    isLoading: isLoadingPendingInventory,
    refetch: refetchPendingInventory,
  } = useQueryFetchActiveInventory({
    payload: payload,
    enabled,
  });

  const {
    data: removeFromMarketData,
    isLoading: isLoadingRemoveFromMarket,
    mutate: mutateRemoveFromMarket,
  } = useMutationRemoveFromMarket();

  const lastTrade = usePendingInventorySocket(enabledSocket, profileData?.id);

  const handleLastTrade = (data, isSocket) => {
    if (isSocket) {
      if (data.state !== EInventoryPendingState.Created) {
        if (tableData.find(el => el.tID === data.tID) !== undefined) {
          const tempTableData = cloneDeep(tableData);
          let editedObject = tempTableData.find(el => el.tID === data.tID);
          let editedObjectIndex = tempTableData.findIndex(
            el => el.tID === data.tID,
          );
          tempTableData[editedObjectIndex] = { ...editedObject, ...data };
          setTableData(tempTableData);
          // if (
          //   [
          //     EInventoryPendingState.Canceled,
          //     EInventoryPendingState.Success,
          //   ].indexOf(data.state) > -1
          // ) {
          //   setTimeout(() => {
          //     setTableData(tempTableData.filter(el => el.tID !== data.tID));
          //   }, 5000);
          // }
        }
      } else {
        if (data.item?.provider === gameType) {
          if (tableData.find(el => el.tID === data.tID) === undefined) {
            setTableData(prevData => {
              return [...transformData([data]), ...prevData];
            });
          }
        }
      }
    } else {
      if (tableData.find(el => el.tID === data.tID) === undefined) {
        setTableData(prevData => {
          return [...transformData([data]), ...prevData];
        });
      }
    }
  };

  useEffect(() => {
    if (lastTrade) {
      handleLastTrade(lastTrade, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTrade]);

  useEffect(() => {
    refetchProfile();
    setEnabled(true);
    const intervalId = setInterval(() => {
      setTableData(tableData => {
        return tableData.filter(obj => {
          return !(
            new Date(obj.expiredAt).getTime() <= Date.now() &&
            [
              EInventoryPendingState.Canceled,
              EInventoryPendingState.Success,
            ].indexOf(obj.state) !== -1
          );
        });
      });
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setTableData([]);
      queryClient.invalidateQueries(['useGetActiveInventory']);
    };
  }, []);

  useEffect(() => {
    //---change url

    // @ts-ignore
    if (payload?.page && Number(payload.page) > 1) {
      navigate(
        {
          pathname: '/inventory/pending',
          search: `?${objectToString({ page: payload.page })}`,
        },
        { replace: true, state: { preventScrollReset: enabled } },
      );
    } else {
      navigate(
        { pathname: '/inventory/pending' },
        { replace: true, state: { preventScrollReset: enabled } },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (pendingInventoryData && !isLoadingPendingInventory) {
      pendingInventoryData.forEach(el => {
        handleLastTrade(el, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInventoryData, isLoadingPendingInventory]);

  useEffect(() => {
    if (removeFromMarketData && !isLoadingRemoveFromMarket) {
      toast.success(t('toastSuccessDeletionPending'));
      refetchPendingInventory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFromMarketData, isLoadingRemoveFromMarket]);

  const onShowPriceStat = data => {
    setSelectedItem(data);
    sModalOpen('priceTrade');
  };

  const onAcceptProduct = data => {
    window.open(`https://steamcommunity.com/tradeoffer/${data}`);
  };

  const renderColumns = [
    {
      key: 'type',
      dataIndex: 'type',
      render: type => (
        <div className="ant-table-cell__status">
          {type === EInventoryPendingType.Sell
            ? t('InventoryPendingSell')
            : t('InventoryPendingBuy')}
        </div>
      ),
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: img => <TableCellImage src={img} gameType={gameType} alt={''} />,
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        <>
          <div className="ant-table-cell__text">{record.marketName.first}</div>
          <div className="ant-table-cell__type">{record.marketName.second}</div>
        </>
      ),
    },
    {
      key: 'float',
      dataIndex: 'float',
      render: (_, record) => (
        <>
          {record.provider === EGameType.CSGO && (
            <>
              {record.float > 0 && (
                <div className="ant-table-cell__float">
                  {record.short}
                  <span>{record.float}</span>
                </div>
              )}
              <FloatRange
                isTooltip={!record.float}
                size={EFloatRangeSizeType.Small}
                maxValue={record.float}
              />
            </>
          )}
        </>
      ),
    },
    {
      key: 'stickers',
      dataIndex: 'stickers',
      width: '50%',
      render: (_, record) => (
        <TableCellStickers
          gameType={record.provider}
          collection={
            record.provider === EGameType.CSGO ? record.stickers : record.gems
          }
        />
      ),
    },
    {
      key: 'price',
      dataIndex: 'price',
      render: price => (
        <div className="ant-table-cell__nowrap">
          {t('price')}:{' '}
          <span className="ant-table-cell__value">$ {formatPrice(price)}</span>
        </div>
      ),
    },
    {
      key: 'stat',
      render: (_, record) => (
        <Button
          type={EButtonTypeType.Text}
          className={'ant-btn-text--gray'}
          icon={<StatIcon />}
          onClick={() => onShowPriceStat(record)}
        />
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className="ant-table-cell__nowrap">
          {t('status')}:{' '}
          <Text type={renderTextType(record.state)}>
            {renderStateText(record.state, t, record.type)}{' '}
            {record.state !== EInventoryPendingState.Success &&
              record.state !== EInventoryPendingState.Canceled && (
                <InventoryPendingTimer expiredAt={record.expiredAt} />
              )}
          </Text>
        </div>
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <>
          {record.state === EInventoryPendingState.NeedBuyerApprove &&
            record.type === EInventoryPendingType.Buy && (
              <Button
                block
                size={EButtonSizeType.Middle}
                ghost
                disabled={!record.tradeOfferId}
                type={EButtonTypeType.Primary}
                onClick={() => onAcceptProduct(record?.tradeOfferId)}
              >
                {t('inventoryPendingBtnReceive')}
              </Button>
            )}
        </>
      ),
    },
  ];

  return (
    <div className="inventory">
      <>
        <InventoryHead
          isInventory={false}
          categories={null}
          search={null}
          sort={null}
          isSteamUpdateProcessed={undefined}
          onChangeCategory={undefined}
          onChangeSort={undefined}
          onReset={undefined}
          onChangeSearch={undefined}
          onSteamReload={undefined}
        />
        <div className="inventory-pending">
          {isLoadingPendingInventory && !pendingInventoryData ? (
            <div className="inventory-products__refetch-loader">
              <AppLoader type={ELoaderTypeType.Relative} />
            </div>
          ) : (
            <>
              <Table
                loading={isLoadingPendingInventory}
                showHeader={false}
                className="inventory-pending__table"
                columns={renderColumns}
                // @ts-ignore
                dataSource={tableData}
                rowKey="id"
                scroll={{ x: '1200px' }}
                locale={{
                  emptyText: (
                    <div className="ant-table-cell__table-empty">
                      <EmptyCard
                        size={ECardSizeType.Small}
                        color={ECardColorType.Light}
                        title={t('emptyRequestTitle')}
                        description={t('emptyRequestDesc')}
                      />
                    </div>
                  ),
                }}
                pagination={false}
              />
            </>
          )}
        </div>
      </>

      <ModalTeleport
        centered
        modalId={'priceTrade'}
        width={1330}
        modalClass={'price-modal__wrapper'}
      >
        <PriceModal product={selectedItem} modalId={'priceTrade'} />
      </ModalTeleport>
    </div>
  );
};

export { InventoryPending };
