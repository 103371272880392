import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getProfileInfo } from 'api/profile';
import { useProfileStore, useAuthStore } from 'store';
import type { TProfile } from 'types/models';

export const useQueryFetchProfileInfo = (
  enabled: boolean,
): UseQueryResult<TProfile> => {
  const { setProfile } = useProfileStore();
  const { sSetSteamId } = useAuthStore();
  return useQuery<TProfile, Error>(['fetchProfileInfo'], getProfileInfo, {
    enabled,
    onSuccess: data => {
      if (data?.uuid) {
        sSetSteamId(data?.uuid);
      }
      setProfile(data);
    },
    onError: err => {
      const message = err ? err?.message : 'Error while fetching Profile';
      console.error(message);
      return message;
    },
  });
};
