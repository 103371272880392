// core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//hooks

// utils

// locales

// types

// components(atoms)
import { EyeFilledIcon } from 'components/icons';

// styles
import './index.scss';

//data

const BlogCard = ({ article }) => {
  return (
    <RouterLink to={`/blog/${article.slug}`} className="blog__card">
      <div className="blog__card--image">
        <img src={article.image} alt={article.title} />
      </div>
      <div className="blog__card--views">
        <EyeFilledIcon />
        <span>{article.views}</span>
      </div>
      <div className="blog__card--title">{article.title}</div>
      <div
        className="blog__card--desc"
        dangerouslySetInnerHTML={{ __html: article.short_description }}
      ></div>
    </RouterLink>
  );
};
export { BlogCard };
