const KeyIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 16.6665L13 16.6665"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 12.6665L27 16.6665"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12.6665L22 16.6665"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="4"
      cy="4"
      r="4"
      transform="matrix(-1 0 0 1 13 12.6665)"
      stroke="currentColor"
      strokeWidth="1.254"
    />
  </svg>
);

export { KeyIcon };
