export enum ENewTradeViewModalType {
  Autocomplete = 'autocomplete',
  Product = 'product',
}

export interface IPropsType {
  modalId: string;
  onTradeCreated: () => void;
  data?: object | undefined | any;
}
