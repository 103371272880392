// core
import React, { useEffect } from 'react';
import { Switch, Space } from 'antd';

// utils

// locales

// store
import { TAppTheme, appThemes, useThemeStore } from 'store';

// types

// components(atoms)

// styles
import styles from './index.module.scss';

// data

// func
const ThemeToggler = () => {
  const { appTheme, setAppTheme } = useThemeStore();

  const onChangeHandler = () => {
    let currentTheme =
      appTheme !== appThemes.light ? appThemes.light : appThemes.dark;
    setAppTheme(currentTheme as TAppTheme);
  };

  return (
    <Space>
      <label>Theme:</label>
      <Switch
        style={styles.toggler}
        checked={appTheme === appThemes.dark}
        onChange={onChangeHandler}
      />
    </Space>
  );
};

export { ThemeToggler };
