import { useQuery } from '@tanstack/react-query';
import { useAppStore } from 'store';
//types

// services
import { GET_CURRENCY_LIST } from 'api/settings';

export const useQueryFetchCurrencyList = enabled => {
  const { sSetCurrencyList } = useAppStore();
  return useQuery(['useGetCurrencyList'], () => GET_CURRENCY_LIST(), {
    enabled,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      sSetCurrencyList(data);
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Product Info';
      console.error(message);
      return message;
    },
  });
};
