// core
import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
// utils

// store

// types

// components
import { ExtensionIcon, ArrowNextIcon } from 'components/icons';

// styles
import './index.scss';

// data
const { Link } = Typography;

const InstallExtensionModal = ({ modalId }: any) => {
  const { t } = useTranslation();

  return (
    <div className="install-extension-modal">
      <div className="install-extension-modal__icon">
        <ExtensionIcon />
      </div>
      <div className="install-extension-modal__title">
        {t('requiredExtensionTitle')}
      </div>
      <div className="install-extension-modal__text">
        {t('requiredExtensionText')}
      </div>
      <Link
        className="ant-btn ant-btn-primary ant-btn-block install-extension-modal__btn-market"
        href="https://chromewebstore.google.com/"
        target="_blank"
      >
        {t('requiredExtensionBtn')}
        <ArrowNextIcon />
      </Link>
    </div>
  );
};

export { InstallExtensionModal };
