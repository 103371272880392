import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
//types
// services
import { POST_MARKET_GET_ITEMS } from 'api/market';

export const useInfinityQueryMarketGetItems = ({ params, enabled }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (enabled) {
      queryClient.invalidateQueries([
        'useInfinityQueryMarketGetItems',
        JSON.stringify(params),
      ]);
    }
  }, [enabled, params, queryClient]);

  const queryInfo = useInfiniteQuery(
    ['useInfinityQueryMarketGetItems', JSON.stringify(params)],
    ({ pageParam = 1 }) => POST_MARKET_GET_ITEMS({ pageParam, params }),
    {
      enabled,
      keepPreviousData: true,
      getNextPageParam: lastPage =>
        lastPage?.meta?.hasNext ? lastPage.meta.page + 1 : undefined,
      onError: (err: Error) => {
        const message = err ? err?.message : 'Error while fetching Profile';
        console.error(message);
        return message;
      },
    },
  );
  const products = queryInfo.data?.pages.map(page => page.data).flat();
  const lastPage = queryInfo.data?.pages?.at(-1);

  return {
    ...queryInfo,
    marketProducts: products ? products : [],
    marketMeta: lastPage ? lastPage.meta : {},
  };
};
