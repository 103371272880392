const LockIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 13C3.25 10.3766 5.37665 8.25 8 8.25H16C18.6234 8.25 20.75 10.3766 20.75 13V17C20.75 19.6234 18.6234 21.75 16 21.75H8C5.37665 21.75 3.25 19.6234 3.25 17V13ZM9.46967 15.5303L10.9697 17.0303C11.2626 17.3232 11.7374 17.3232 12.0303 17.0303L15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697C14.7374 12.6768 14.2626 12.6768 13.9697 12.9697L11.5 15.4393L10.5303 14.4697C10.2374 14.1768 9.76256 14.1768 9.46967 14.4697C9.17678 14.7626 9.17678 15.2374 9.46967 15.5303Z"
      fill="currentColor"
    />
    <path
      d="M16 9V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7L8 9"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </svg>
);

export { LockIcon };
