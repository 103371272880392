const LockCrossIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4"
      y="9"
      width="16"
      height="12"
      rx="4"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M10.1214 17L14.1213 13"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1214 13L14.1213 17"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 9V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7L8 9"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </svg>
);

export { LockCrossIcon };
