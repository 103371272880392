// core
import React, { useEffect } from 'react';
//hooks
import { useMutationSendChatMessage } from '../../../../../hooks/api';
// utils
import { convertToFormData } from '../../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';
// types
import { EButtonSizeType, EButtonTypeType, EInputSizeType } from 'types/units';

// components(atoms)
import { Button, Form, Input, Upload } from 'antd';
import { SendIcon, PaperClipIcon } from 'components/icons';

// styles
import './index.scss';
import * as yup from 'yup';
//data
import { defaultValues } from './schema';

const SupportChatInput = ({ chatId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const sendMessageSchema = yup.object().shape({
    content: yup.string().max(500, t('validationMaxLength') + ' 500'),
    file: yup.mixed().test('fileSize', t('validationFileSize'), fileList => {
      if (!fileList) return true;
      if (!fileList.fileList.length) return true;
      return fileList.fileList[0].size <= 2000000;
    }),
  });

  const yupSync = {
    async validator({ field }, value) {
      await sendMessageSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const {
    data: sendMessageResponse,
    isLoading: isLoadingSendMessage,
    mutate: sendMessageMutate,
  } = useMutationSendChatMessage();

  useEffect(() => {
    if (!isLoadingSendMessage && sendMessageResponse) {
      form.resetFields();
      form.setFieldValue('file', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageResponse, isLoadingSendMessage]);

  const onSendMessage = payload => {
    if (payload.file || payload.content.length) {
      const { file, content } = payload;
      payload = {
        ...(content.length ? { content: content } : {}),
        ...(file && file.file?.status !== 'removed'
          ? { file: file.fileList[0].originFileObj }
          : {}),
      };
      let formData = convertToFormData(payload);
      sendMessageMutate({ payload: formData, chatId });
    }
  };
  const onAddFiles = () => {};
  const resetFile = () => {
    form.setFieldValue('file', null);
  };

  return (
    <div className="chat-input">
      <Form
        onFinish={onSendMessage}
        initialValues={defaultValues}
        form={form}
        layout="vertical"
      >
        <Form.Item
          name={'content'}
          /*@ts-ignore*/
          rules={[yupSync]}
        >
          <Input
            className="ant-input-group-wrapper--custom-addon"
            size={EInputSizeType.Small}
            addonAfter={
              <div className="chat-input__actions">
                <Form.Item
                  className="ant-form-item--flat"
                  name={'file'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                >
                  <Upload
                    className="ant-upload--input"
                    beforeUpload={() => false}
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    onRemove={() => {
                      resetFile();
                    }}
                  >
                    <Button
                      size={EButtonSizeType.Middle}
                      type={EButtonTypeType.Default}
                      onClick={() => {
                        onAddFiles();
                      }}
                      icon={<PaperClipIcon />}
                    />
                  </Upload>
                </Form.Item>
                <Button
                  size={EButtonSizeType.Middle}
                  type={EButtonTypeType.Default}
                  htmlType="submit"
                  icon={<SendIcon />}
                />
              </div>
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export { SupportChatInput };
