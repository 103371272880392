// core
import React, { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
//hooks
import { useQueryFetchArticle } from 'hooks/api';

// utils
import { formatDate } from '../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';

// components(atoms)
import { ArticleSEO } from './SEO';
import { Button } from 'antd';
import { EyeFilledIcon, ArrowLeftIcon } from 'components/icons';
import { BlogCard } from '../Blog/BlogCard';
import { AppLoader } from 'components/atoms';
// styles
import './index.scss';

//data

const Article = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { data: articleData, isLoading: isLoadingArticle } =
    useQueryFetchArticle(slug);

  useEffect(() => {
    if (!isLoadingArticle && articleData) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleData, isLoadingArticle]);

  return (
    <>
      <ArticleSEO
        title={articleData ? (articleData?.data?.title as string) : ''}
        desc={
          articleData ? (articleData?.data?.short_description as string) : ''
        }
        image={articleData ? (articleData?.data?.image as string) : ''}
      />
      <div className="article">
        <div className="app-container">
          <div className="article__wrapper">
            <div className="article__back">
              <RouterLink to={'/blog'}>
                <Button
                  type={EButtonTypeType.Default}
                  className={'ant-btn-ghost--grey'}
                  ghost
                  size={EButtonSizeType.Middle}
                >
                  <ArrowLeftIcon />
                  {t('back')}
                </Button>
              </RouterLink>
            </div>
            <div className="app-container app-container--xxs">
              {isLoadingArticle && !articleData && (
                <AppLoader type={ELoaderTypeType.Global} />
              )}
              {!isLoadingArticle && articleData && (
                <div className="article__wrap">
                  <div className="article__head">
                    <div className="article__head--left">
                      <h1 className="article__head--left-title">
                        {articleData.data.title}
                      </h1>
                      <div className="article__head--left-author">
                        {t('authorOfArticle')}{' '}
                        <span>{articleData.data.author}</span>
                      </div>
                    </div>
                    <div className="article__head--right">
                      <div className="article__head--right-timestamp">
                        {formatDate(articleData.data.published_at, 'LL')}
                      </div>
                      <div className="article__head--right-views">
                        <EyeFilledIcon />
                        {articleData.data.views}
                      </div>
                    </div>
                  </div>
                  <div className="article__banner">
                    <img
                      src={articleData.data.image}
                      alt={articleData.data.title}
                    />
                  </div>
                  <div
                    className="article__content"
                    dangerouslySetInnerHTML={{
                      __html: articleData.data.description,
                    }}
                  />
                  {articleData.data.related.data?.length ? (
                    <div className="article__related">
                      <div className="article__related--title">
                        {t('readRelated')}
                      </div>
                      <div className="article__related--item-container">
                        {articleData.data.related.data.map(item => {
                          return (
                            <div
                              className="article__related--item"
                              key={item.id}
                            >
                              <BlogCard article={item} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { Article };
