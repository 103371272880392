import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export function SupportFaqSEO() {
  // locales hook
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>
        {t('seoDefault.title')} | {t('pages.help')}
      </title>
      <meta name="description" content={t('seoDefault.description')} />

      <meta property="og:title" content={t('seoDefault.title')} />
      <meta property="og:description" content={t('seoDefault.description')} />
      <meta
        property="og:image"
        content={`${process.env.PUBLIC_URL}/sharing.png`}
      />

      <meta name="twitter:title" content={t('seoDefault.title')} />
      <meta name="twitter:description" content={t('seoDefault.description')} />
      <meta
        name="twitter:image"
        content={`${process.env.PUBLIC_URL}/sharing.png`}
      />
    </Helmet>
  );
}
