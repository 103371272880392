// @ts-ignore

import Locale from 'types/models/locale';
import { FlagUs } from '../components/icons/FlagUs';
import { FlagRu } from '../components/icons/FlagRu';

export const DEFAULT_LOCALE = 'en';

export const languagesList: Locale[] = [
  {
    id: 'en',
    short: 'EN',
    label: 'English',
    flag: FlagUs,
  },
  {
    id: 'ru',
    short: 'RU',
    label: 'Русский',
    flag: FlagRu,
  },
];
