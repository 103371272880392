import { queryStringToObject } from 'utils/commons';
import {
  EGameType,
  EMarketFilterCategoryType,
  EMarketFilterType,
  EMarketFilterUrlType,
  ESortType,
  ESortValueType,
  ISortOption,
} from 'types/models';

export const sortOptions: Array<ISortOption> = [
  {
    label: 'filterFeatured',
    value: 'recommended',
    type: null,
    typeValue: null,
  },
  {
    label: 'filterDateDesc',
    value: `${ESortType.createdAt}_${ESortValueType.DESC}`,
    type: ESortType.createdAt,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filterDateAsc',
    value: `${ESortType.createdAt}_${ESortValueType.ASC}`,
    type: ESortType.createdAt,
    typeValue: ESortValueType.ASC,
  },
  {
    label: 'filterPriceDesc',
    value: `${ESortType.price}_${ESortValueType.DESC}`,
    type: ESortType.price,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filterPriceAsc',
    value: `${ESortType.price}_${ESortValueType.ASC}`,
    type: ESortType.price,
    typeValue: ESortValueType.ASC,
  },
  {
    label: 'filterFloatDesc',
    value: `${ESortType.float}_${ESortValueType.DESC}`,
    type: ESortType.float,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filterFloatAsc',
    value: `${ESortType.float}_${ESortValueType.ASC}`,
    type: ESortType.float,
    typeValue: ESortValueType.ASC,
  },
  {
    label: 'filterDiscountDesc',
    value: `${ESortType.discount}_${ESortValueType.DESC}`,
    type: ESortType.discount,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filterDiscountAsc',
    value: `${ESortType.discount}_${ESortValueType.ASC}`,
    type: ESortType.discount,
    typeValue: ESortValueType.ASC,
  },
  // {
  //   label: 'filterQualityDesc',
  //   value: `${ESortType.quality}_${ESortValueType.DESC}`,
  //   type: ESortType.quality,
  //   typeValue: ESortValueType.DESC,
  // },
  // {
  //   label: 'filterQualityAsc',
  //   value: `${ESortType.quality}_${ESortValueType.ASC}`,
  //   type: ESortType.quality,
  //   typeValue: ESortValueType.ASC,
  // },
];

export const customFilterAttributes = {
  CheaperThanSteam: {
    order: 20,
    sku: 'CheaperThanSteam',
    title: 'Steam',
    urlType: EMarketFilterUrlType.Multiselect,
    type: EMarketFilterType.Side,
    values: [],
    possibilityValues: [{ title: 'Cheaper than on Steam', value: 'true' }],
  },
  Float: {
    order: 15,
    sku: 'Float',
    title: 'Float',
    urlType: EMarketFilterUrlType.Range,
    type: EMarketFilterType.Side,
    values: [],
    possibilityValues: [
      { title: 'from', value: '0' },
      { title: 'to', value: '1' },
    ],
  },
  CountSticker: {
    order: 10,
    sku: 'CountSticker',
    title: 'Stickers on the skin',
    urlType: EMarketFilterUrlType.Radio,
    type: EMarketFilterType.Side,
    values: [],
    possibilityValues: [
      { title: 'Нет', value: '0' },
      { title: '1', value: '1' },
      { title: '2', value: '2' },
      { title: '3', value: '3' },
      { title: '4', value: '4' },
      { title: '5', value: '5' },
    ],
  },
};
export const getInitSortAttributes = query => {
  let queryObject = queryStringToObject(query);
  let sortAttributes: undefined | ISortOption = undefined;

  if (queryObject.Sort) {
    sortAttributes = sortOptions.find(item => {
      return item.value === queryObject.Sort;
    });
  }
  return sortAttributes ? sortAttributes : sortOptions[0];
};

const createCategoryObject = (categoryObject, group, isHideAll = false) => {
  categoryObject.possibilityGroups = group.filterAttributes.reduce(
    (accGroupItem, groupItem) => {
      let { attribute, possibilityAttributeValues } = groupItem;
      return [
        ...accGroupItem,
        {
          sku: attribute.sku.trim(),
          title: attribute.title.trim(),
          icon: attribute.image ? attribute.image : attribute.preview,
          possibilityValues:
            isHideAll || attribute.sku === 'Other'
              ? [...possibilityAttributeValues]
              : [
                  ...possibilityAttributeValues,
                  {
                    image: null,
                    title: 'selectAll',
                    value: `${EMarketFilterCategoryType.All}_${attribute.sku}`,
                    valueExtra: possibilityAttributeValues.map(i => i.value),
                  },
                ],
        },
      ];
    },
    [],
  );

  return categoryObject;
};

export const getIniApiAttributes = (filtersData, query, gameType) => {
  let queryObject = queryStringToObject(query);

  if (
    typeof queryObject.GameType === 'string' &&
    queryObject.GameType !== gameType
  ) {
    queryObject = {
      GameType: gameType,
    };
  }

  let categoriesObject: any = {};

  let filterObject = {};
  if (gameType === EGameType.CSGO) {
    filterObject = { ...customFilterAttributes };
  } else {
    filterObject = {
      CheaperThanSteam: customFilterAttributes.CheaperThanSteam,
    };
  }

  let searchCollection = '';

  //---create structure
  filtersData.forEach(group => {
    if (group.type === EMarketFilterType.Top) {
      if (gameType === EGameType.CSGO) {
        categoriesObject = {
          ...categoriesObject,
          Weapon: createCategoryObject(
            {
              sku: 'Weapon',
              title: 'Weapon',
              urlType: EMarketFilterUrlType.Radio,
              type: EMarketFilterType.Top,
              dataValues: {},
              possibilityGroups: [],
            },
            group,
          ),
        };
      } else if (gameType === EGameType.Dota) {
        categoriesObject = {
          ...categoriesObject,
          CategoryDota: createCategoryObject(
            {
              sku: 'CategoryDota',
              title: 'CategoryDota',
              urlType: EMarketFilterUrlType.Multiselect,
              type: EMarketFilterType.Top,
              dataValues: {},
              possibilityGroups: [],
            },
            group,
          ),
        };
      }
    } else if (group.type === EMarketFilterType.Heroes) {
      //---dota

      categoriesObject = {
        ...categoriesObject,
        Heroes: createCategoryObject(
          {
            sku: 'Heroes',
            title: 'Heroes',
            urlType: EMarketFilterUrlType.Radio,
            type: EMarketFilterType.Top,
            dataValues: {},
            possibilityGroups: [],
          },
          group,
          true,
        ),
      };
    } else {
      let decoratedGroup = group.filterAttributes.reduce((accItem, item) => {
        if (
          group.type === EMarketFilterType.Side &&
          item.possibilityAttributeValues.every(i => i.isHide)
        ) {
          return accItem;
        } else {
          return {
            ...accItem,
            [item.attribute.sku]: {
              order: 1,
              sku: item.attribute.sku,
              title: item.attribute.title,
              urlType:
                group.type === EMarketFilterType.Price
                  ? EMarketFilterUrlType.Range
                  : EMarketFilterUrlType.Multiselect,
              type: group.type,
              values: [],
              possibilityValues:
                group.type === EMarketFilterType.Side
                  ? item.possibilityAttributeValues.filter(i => !i.isHide)
                  : item.possibilityAttributeValues,
            },
          };
        }
      }, {});

      filterObject = { ...filterObject, ...decoratedGroup };
    }
  });

  //---fill structure
  //---filters
  let filterCollection = Object.keys(filterObject).reduce((acc, key) => {
    let filterItem = filterObject[key];

    if (filterItem.type !== EMarketFilterType.Top) {
      if (filterItem.urlType === EMarketFilterUrlType.Range) {
        let values = [
          filterItem.possibilityValues[0].value,
          filterItem.possibilityValues[1].value,
        ];
        if (queryObject[`${key}_from`]) {
          values[0] = queryObject[`${key}_from`];
        } else if (queryObject[`${key}_to`]) {
          values[1] = queryObject[`${key}_to`];
        }
        return { ...acc, [key]: { ...filterItem, values } };
      } else {
        let queryValueString = queryObject[key];
        if (queryValueString && typeof queryValueString === 'string') {
          let queryValues = queryValueString.split(',');
          let values = queryValues.filter(qvIitem => {
            return filterItem.possibilityValues.some(
              pItem => pItem.value === qvIitem,
            );
          });
          return { ...acc, [key]: { ...filterItem, values } };
        }
        return { ...acc, [key]: { ...filterItem } };
      }
    }

    return acc;
  }, {});
  //---category
  let categoriesCollection = Object.keys(categoriesObject).reduce(
    (acc, key) => {
      let categoryItem = categoriesObject[key];

      let dataValues: object = {};

      if (categoryItem.urlType === EMarketFilterUrlType.Radio) {
        //---Radio
        let keyDataValue = key;
        let queryValueString = queryObject[keyDataValue];
        let dataValueGroup: { sku: any; values: any } = {
          sku: null,
          values: [],
        };

        if (queryValueString && typeof queryValueString === 'string') {
          let queryValueArray = queryValueString.split(',');

          queryValueArray.forEach(qvaItem => {
            categoryItem.possibilityGroups.forEach(pGroup => {
              let possibilityValue = pGroup.possibilityValues.find(
                pItem => pItem.value === qvaItem,
              );

              if (
                possibilityValue &&
                (!dataValueGroup.sku || dataValueGroup.sku === pGroup.sku)
              ) {
                dataValueGroup.sku = pGroup.sku;
                dataValueGroup.values = [
                  ...dataValueGroup.values,
                  possibilityValue.value,
                ];
              }
            });
          });
          dataValues = {
            ...dataValues,
            [keyDataValue]: dataValueGroup.values.join(','),
          };
        }
      } else {
        //---Mulitiselect
        categoryItem.possibilityGroups.forEach(pGroup => {
          let keyDataValue = pGroup.sku;
          let queryValueString = queryObject[keyDataValue];
          let dataValueGroup: { sku: any; values: any } = {
            sku: null,
            values: [],
          };

          if (queryValueString && typeof queryValueString === 'string') {
            let queryValueArray = queryValueString.split(',');

            queryValueArray.forEach(qvaItem => {
              let possibilityValue = pGroup.possibilityValues.find(
                pItem => pItem.value === qvaItem,
              );

              if (
                possibilityValue &&
                (!dataValueGroup.sku || dataValueGroup.sku === pGroup.sku)
              ) {
                dataValueGroup.sku = pGroup.sku;
                dataValueGroup.values = [
                  ...dataValueGroup.values,
                  possibilityValue.value,
                ];
              }
            });
            dataValues = {
              ...dataValues,
              [keyDataValue]: dataValueGroup.values.join(','),
            };
          }
        });
      }
      return { ...acc, [key]: { ...categoryItem, dataValues } };
    },
    {},
  );

  //---search
  let querySearchString = queryObject['NameSearch'];
  if (querySearchString && typeof querySearchString === 'string') {
    searchCollection = querySearchString;
  }

  return { filterCollection, categoriesCollection, searchCollection };
};

export const updateUrlParams = (
  filters,
  categories,
  sort,
  search,
  gameType,
) => {
  let filtersParams = Object.keys(filters).reduce((acc, key) => {
    let { values, possibilityValues, urlType } = filters[key];

    if (values.length) {
      if (
        urlType === EMarketFilterUrlType.Multiselect ||
        urlType === EMarketFilterUrlType.Radio
      ) {
        acc = { ...acc, [key]: values };
      } else if (urlType === EMarketFilterUrlType.Range) {
        if (values[0] !== possibilityValues[0].value) {
          acc = { ...acc, [`${key}_from`]: values[0] };
        }
        if (values[1] !== possibilityValues[1].value) {
          acc = { ...acc, [`${key}_to`]: values[1] };
        }
      } else {
        console.error('missed attr');
      }
    }
    return acc;
  }, {} as any);

  let sortParams = sort?.typeValue ? { Sort: [sort.value] } : {};
  let categoriesParams = Object.keys(categories).reduce((acc, key) => {
    let { dataValues, urlType } = categories[key];
    if (urlType === EMarketFilterUrlType.Radio) {
      if (dataValues[key]) {
        acc = { ...acc, [key]: dataValues[key] };
      }
    } else if (urlType === EMarketFilterUrlType.Multiselect) {
      Object.keys(dataValues).forEach(keyDataValue => {
        acc = { ...acc, [keyDataValue]: dataValues[keyDataValue] };
      });
    }

    return acc;
  }, {} as any);

  let searchParams = search ? { NameSearch: search } : {};

  return {
    ...filtersParams,
    ...categoriesParams,
    ...sortParams,
    ...searchParams,
    GameType: gameType,
  };
};
