// core
import React, { useEffect, useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { Area } from '@ant-design/plots';
import dayjs from 'dayjs';

// utils

//locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { IPropsType } from './types';
import { ERadioButtonSizeType, ERadioButtonStyleType } from 'types/units';
// components

// styles
import './index.scss';

// data
import { generalChartConfig, generalTooltipConfig } from 'config/charts';
import { dateTypes } from './data';
import { useQueryFetchPriceHistory } from '../../../../hooks/api';
import { EmptyCard } from '../../Cards/EmptyCard';
import { ECardSizeType } from '../../Cards/EmptyCard/types';

const PriceModal = ({ modalId, product }: IPropsType) => {
  const { t } = useTranslation();
  const { commission_percentage } = useAppStore();
  const [data, setData] = useState([]);
  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);
  const [filterData, setFilterData] = useState({
    ...dateTypes[0].range,
    extra: product?.exterior,
    provider: product.provider,
    firstName: product?.marketName.first,
    secondName: product?.marketName.second,
  });
  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 6 },
    animation: false,
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">$ {data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  useEffect(() => {
    if (!isLoadingRawChart && rawChartData) {
      setData(rawChartData);
    }
  }, [rawChartData, isLoadingRawChart]);

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        provider: product.provider,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        extra: product?.exterior,
        ...value,
      });
    } else {
      setFilterData({
        provider: product.provider,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        extra: product?.exterior,
      });
    }
  };

  return (
    <div className="price-modal">
      <div className="price-modal__content">
        <div className="price-modal__head">
          <div className="price-modal__title">{t('priceChart')}</div>
          <Radio.Group
            buttonStyle={ERadioButtonStyleType.Solid}
            value={chartDateType}
            size={ERadioButtonSizeType.Middle}
            onChange={onChangeChartDateType}
          >
            {dateTypes?.length &&
              dateTypes.map(item => {
                return (
                  <Radio.Button value={item.range} key={item.value}>
                    {t(item.value)}
                  </Radio.Button>
                );
              })}
          </Radio.Group>
        </div>

        <div className="price-modal__chart">
          {config?.data?.length ? (
            <>
              {/*@ts-ignore*/}
              <Area {...config} />
            </>
          ) : (
            <EmptyCard
              size={ECardSizeType.Minimal}
              title={t('emptyChartTitle')}
              description={t('emptyChartDesc')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { PriceModal };
