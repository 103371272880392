import React, { useEffect } from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';

//hooks
import { useMutateCloseSessions } from '../../../../../hooks/api';

// types
import { EButtonSizeType } from 'types/units';

// components(atoms)
import { Button } from 'antd';
import { LockLineIcon } from 'components/icons';

// styles
import './index.scss';
import { toast } from 'react-toastify';

// data

// func

const DashboardModalResetSeances = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();

  const {
    data: closeSessionsData,
    isLoading: isLoadingCloseSessions,
    mutate: mutateCloseSessions,
  } = useMutateCloseSessions();

  useEffect(() => {
    if (closeSessionsData && !isLoadingCloseSessions) {
      toast.success(t('toastResetSessions'));
      sModalClose(modalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCloseSessions, closeSessionsData]);
  const onCloseResetSeances = id => {
    sModalClose(id);
  };
  const onSubmitCloseResetSeances = () => {
    mutateCloseSessions();
  };

  return (
    <div className="dashboard__reset-seances">
      <LockLineIcon />
      <div className="dashboard__reset-seances--title">
        {t('resetAllSeances')}
      </div>
      <div className="dashboard__reset-seances--desc">
        {t('resetAllSeancesQuestion')}
      </div>
      <div className="dashboard__reset-seances--button-container">
        <div className="dashboard__reset-seances--button dashboard__reset-seances--button-wide">
          <Button
            onClick={() => {
              onSubmitCloseResetSeances();
            }}
            type="primary"
            disabled={isLoadingCloseSessions}
            block
            size={EButtonSizeType.Large}
          >
            {t('reset')}
          </Button>
        </div>
        <div className="dashboard__reset-seances--button">
          <Button
            onClick={() => {
              onCloseResetSeances(modalId);
            }}
            danger
            type="text"
            size={EButtonSizeType.Small}
          >
            {t('deny')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export { DashboardModalResetSeances };
