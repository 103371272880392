const CartFillIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38576 1.35699C9.74094 1.5701 9.85612 2.0308 9.64301 2.38599L6.64301 7.38599C6.4299 7.74117 5.9692 7.85634 5.61401 7.64323C5.25883 7.43012 5.14366 6.96943 5.35677 6.61424L8.35677 1.61424C8.56988 1.25906 9.03057 1.14388 9.38576 1.35699Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3858 7.64323C18.0306 7.85634 17.5699 7.74117 17.3568 7.38599L14.3568 2.38598C14.1437 2.0308 14.2588 1.5701 14.614 1.35699C14.9692 1.14388 15.4299 1.25906 15.643 1.61424L18.643 6.61424C18.8561 6.96943 18.7409 7.43012 18.3858 7.64323Z"
      fill="currentColor"
    />
    <path
      d="M18.0351 6H5.96486C3.72938 6 1.99546 7.84401 2.00001 10H22C22.0045 7.84401 20.2706 6 18.0351 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.282 18.808C19.9021 20.6666 18.2806 22 16.4002 22H7.59978C5.71942 22 4.09787 20.6666 3.71804 18.808L2.08312 10.808C1.57594 8.32624 3.45403 6 5.96486 6H18.0351C20.546 6 22.4241 8.32624 21.9169 10.808L20.282 18.808ZM9.0002 13.25C9.41441 13.25 9.7502 13.5857 9.7502 14L9.75019 18C9.75019 18.4142 9.41441 18.75 9.00019 18.75C8.58598 18.75 8.25019 18.4142 8.25019 18L8.2502 14C8.2502 13.5857 8.58598 13.25 9.0002 13.25ZM15.7502 14C15.7502 13.5857 15.4144 13.25 15.0002 13.25C14.586 13.25 14.2502 13.5857 14.2502 14V18C14.2502 18.4142 14.586 18.75 15.0002 18.75C15.4144 18.75 15.7502 18.4142 15.7502 18V14Z"
      fill="currentColor"
    />
  </svg>
);

export { CartFillIcon };
