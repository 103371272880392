import { useQuery } from '@tanstack/react-query';

//types
import { TTopProductsRequestParams } from 'types/api/market';

// services
import { GET_EXTERIOR_FILTER } from 'api/market';

export const useQueryFetchExteriorFilter = (enabled: boolean) => {
  return useQuery(['useGetExteriorFilter'], () => GET_EXTERIOR_FILTER(), {
    enabled,
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err
        ? err?.message
        : 'Error while fetching exterior filter';
      console.error(message);
      return message;
    },
  });
};
