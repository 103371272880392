import React, { useEffect } from 'react';

// utils
//hooks
import { useMutateVerifyPaymentPassword } from '../../../../../hooks/api';
// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType } from 'types/units';

// components(atoms)
import { Button, Input, Form } from 'antd';
import { LockLineIcon } from 'components/icons';

// styles
import './index.scss';
import * as yup from 'yup';
import { toast } from 'react-toastify';

// data

// func

const DashboardModalEnterPassword = ({ modalId, onSubmitPassword }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const {
    data: verifyData,
    isLoading: isLoadingVerifyData,
    mutate: mutateVerifyPassword,
  } = useMutateVerifyPaymentPassword();

  useEffect(() => {
    if (verifyData && !isLoadingVerifyData) {
      onSubmitPassword();
      sModalClose(modalId);
    } else if (verifyData === false && !isLoadingVerifyData) {
      form.setFieldValue('payPassword', '');
      toast.error(t('toastErrorVerifyPaymentPass'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVerifyData, verifyData]);

  const [form] = Form.useForm();
  const paymentPassSchema = yup.object().shape({
    payPassword: yup
      .string()
      .required(t('validationRequired'))
      .min(4, t('validationMinLength') + ' 4')
      .max(24, t('validationMaxLength') + ' 24'),
  });
  const yupSync = {
    async validator({ field }, value) {
      await paymentPassSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const onConfirm = payload => {
    mutateVerifyPassword(payload);
  };

  return (
    <div className="dashboard__create-payment-password">
      <div className="dashboard__create-payment-password--icon">
        <LockLineIcon />
      </div>
      <div className="dashboard__create-payment-password--title">
        {t('paymentPassword')}
      </div>
      <Form onFinish={onConfirm} form={form} layout="vertical">
        <div className="dashboard__create-payment-password--input">
          <Form.Item
            name={'payPassword'}
            /*@ts-ignore*/
            rules={[yupSync]}
          >
            <Input.Password
              addonBefore={t('paymentPassword')}
              placeholder="********"
            />
          </Form.Item>
        </div>
        <div className="dashboard__create-payment-password--button">
          <Button
            htmlType={'submit'}
            type="primary"
            block
            disabled={isLoadingVerifyData}
            size={EButtonSizeType.Large}
          >
            {t('nextStep')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export { DashboardModalEnterPassword };
