import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { REMOVE_ITEM_FROM_MARKET } from '../../../api/inventory';

export const useMutationRemoveFromMarket = () => {
  return useMutation(REMOVE_ITEM_FROM_MARKET, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create Ticket';
      console.error(message);
      return message;
    },
  });
};
