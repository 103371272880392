// core
import React from 'react';
import { Typography } from 'antd';

// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore, useAuthStore } from 'store';
// types

// components(atoms)
import { TwitterIcon, YoutubeIcon, TelegramIcon } from 'components/icons';

// styles
import './index.scss';
import { getVariable } from '../../../utils/commons';

// data
const { Link } = Typography;

// func
const Footer = () => {
  const { t } = useTranslation();
  const { variables } = useAppStore();
  return (
    <footer className="footer">
      <div className="footer__grid">
        <div className="footer__links">
          <div className="footer__group">
            <div className="footer__group-title">{t('navigation')}</div>
            <ul>
              <li>
                <Link href="/market">{t('market')}</Link>
              </li>
              <li>
                <Link href="/inventory/stock">{t('inventory')}</Link>
              </li>
              <li>
                <Link href="/history/purchase">{t('history')}</Link>
              </li>
              <li>
                <Link href="/deals/trade">{t('transactions')}</Link>
              </li>
              <li>
                <Link href="/support/faq">{t('support')}</Link>
              </li>
              <li>
                <Link href="/blog">{t('blog')}</Link>
              </li>
            </ul>
          </div>
          <div className="footer__group">
            <div className="footer__group-title">{t('marketProject')}</div>
            <ul>
              <li>
                <Link href="/pages/terms">{t('userPolicy')}</Link>
              </li>
              <li>
                <Link href="/pages/policy">{t('privacyPolicy')}</Link>
              </li>
              <li>
                <Link href="/pages/refund">{t('refundPolicy')}</Link>
              </li>
            </ul>
          </div>
          {/*<div className="footer__group">*/}
          {/*  <div className="footer__group-title">{t('aboutUs')}</div>*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      <Link href="/">{t('trustpilot')}</Link>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <Link href="/">{t('api')}</Link>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          <div className="footer__group">
            <div className="footer__group-title">{t('contactUs')}</div>
            <ul>
              <li>
                <Link
                  href={`mailto:${getVariable(variables, 'socials_email')}`}
                >
                  {getVariable(variables, 'socials_email')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ul className="footer__socials">
          <li>
            <a
              href={getVariable(variables, 'socials_twitter')}
              target="_blank"
              title=""
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a
              href={getVariable(variables, 'socials_youtube')}
              target="_blank"
              title=""
              rel="noreferrer"
            >
              <YoutubeIcon />
            </a>
          </li>
          <li>
            <a
              href={getVariable(variables, 'socials_telegram')}
              target="_blank"
              title=""
              rel="noreferrer"
            >
              <TelegramIcon />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__bottom">
        <div className="footer__copy">
          {t('footerCopyright')} {new Date().getFullYear()}.{' '}
          {t('footerCopyrightRight')}
        </div>
        <div className="footer__address">
          {getVariable(variables, 'socials_address')}
        </div>
      </div>
    </footer>
  );
};

export { Footer };
