import React, { useEffect, useRef, useState } from 'react';
import { MEDIA } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import useFixMissingScroll from 'hooks/general/useFixMissingScroll';
import { useWindowSize } from 'react-use';
// locale

// store
import { useAppStore, useInventoryStore } from 'store';

// types
import { EGridType } from 'types/units';
import { EProductCardType } from '../../../../../components/features/Cards/ProductCard/type';
import { ELoaderTypeType } from '../../../../../components/atoms/AppLoader/types';
// components
import { ProductCard } from 'components/features';

// styles
import './index.scss';

import { AppLoader } from 'components/atoms';
import { ScrollToTop } from '../../../../../components/atoms/ScrollToTop';

//data
const InventoryScrollable = ({
  inventoryItems,
  isFetchingNextInventoryItems,
  hasNextPage,
  fetchNextPage,
}) => {
  const { inventoryGrid } = useInventoryStore();
  const { gameType } = useAppStore();
  const { width } = useWindowSize();
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const scrollableRef = useRef<any>(null);

  const container = document.getElementById('scrollableDiv');
  function updateScrollState() {
    if (container) {
      const containerHeight = container.clientHeight;
      const contentHeight = container.scrollHeight;
      const scrollPosition = container.scrollTop;
      const scrolledHalf =
        scrollPosition > (contentHeight - containerHeight) / 4;
      setShowScrollButton(scrolledHalf);
    }
  }
  if (container) {
    container.addEventListener('scroll', updateScrollState);
  }
  useEffect(() => {
    if (container) {
      return () => {
        container.removeEventListener('scroll', updateScrollState);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFixMissingScroll({
    scrollableRef,
    isHasMoreItems: !!hasNextPage,
    isFetchingNextInventoryItems,
    fetchNextPage,
  });

  return (
    <div
      className="inventory-scrollable"
      id="scrollableDiv"
      ref={scrollableRef}
    >
      <InfiniteScroll
        dataLength={inventoryItems.length}
        scrollableTarget={width > MEDIA.M1024 ? 'scrollableDiv' : null}
        scrollThreshold="200px"
        next={() => {
          if (hasNextPage) fetchNextPage();
        }}
        hasMore={!!hasNextPage}
        loader={false}
      >
        <div
          className={
            'inventory-scrollable__grid' +
            (inventoryGrid === EGridType.Small && width > MEDIA.M1024
              ? ' inventory-scrollable__compact'
              : '')
          }
        >
          {inventoryItems.map(item => (
            <ProductCard
              key={item.id}
              cardType={EProductCardType.Inventory}
              gameType={gameType}
              size={inventoryGrid}
              item={item}
              isInventory={true}
            />
          ))}
        </div>
      </InfiniteScroll>
      <ScrollToTop
        customShow
        customShowFlag={showScrollButton}
        customSelector={'scrollableDiv'}
      />
      {isFetchingNextInventoryItems && (
        <div className="inventory-scrollable__loader">
          <AppLoader type={ELoaderTypeType.Absolute} />
        </div>
      )}
    </div>
  );
};

export { InventoryScrollable };
