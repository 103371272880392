import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { GET_PRODUCT_TRADES_DETAILS } from '../../../api/product';

export const useMutationFetchProductTradesDetails = () => {
  return useMutation(GET_PRODUCT_TRADES_DETAILS, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create payment pass';
      console.error(message);
      return message;
    },
  });
};
