// core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//hooks

// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import {
  EButtonSizeType,
  EButtonTypeType,
} from '../../../../types/units/button';

// components(atoms)
import { Button } from 'antd';

/*@ts-ignore*/
import HomeCtaImage from 'assets/img/HomeCtaImage.png';

// styles
import './index.scss';

//tempo

// func
const HomeCta = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="home-cta">
        <div className="app-container app-container--small">
          <div className="home-cta__wrap">
            <div className="home-cta__banner">
              <div className="home-cta__banner__bg" />
              <div className="home-cta__banner__image">
                <img src={HomeCtaImage} alt="Skinsty" />
              </div>
              <div className="home-cta__banner__wrap">
                <div className="home-cta__banner__title">{t('needToBuy')}</div>
                <div className="home-cta__banner__button">
                  <RouterLink to={'/market'}>
                    <Button
                      type={EButtonTypeType.Primary}
                      className={'ant-btn-primary--white'}
                      size={EButtonSizeType.Large}
                    >
                      {t('goToMarket')}
                    </Button>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { HomeCta };
