import { $api, $apiAuth } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { TProfile } from 'types/models/profile';

import type {
  IUpdateProfileRequestParams,
  IChangePasswordRequestParams,
  TSteamSettingsResponse,
  TSteamSettingsRequestParams,
  TSiteApiKeyResponse,
  TSendProfileInfoRequestParams,
  TUserBalanceResponse,
} from 'types/api/profile';

export const getProfileInfo = async () => {
  const response: AxiosResponse<TProfile> = await $apiAuth.get<TProfile>(
    'profile',
  );
  return response.data;
};

export const SEND_PROFILE_INFO = async (
  payload: TSendProfileInfoRequestParams,
) => {
  const url = `profile`;
  const response = await $apiAuth.put<TProfile>(url, payload);
  return response.data;
};

export const GET_STEAM_SETTINGS = async () => {
  const url = `steam/setting`;
  const response = await $apiAuth.get<TSteamSettingsResponse>(url);
  return response.data;
};

export const GET_SITE_KEY = async () => {
  const url = `generate-api-token`;
  const response = await $apiAuth.post<TSiteApiKeyResponse>(url);
  return response.data;
};

export const SHOW_SITE_KEY = async () => {
  const url = `get-api-token`;
  const response = await $apiAuth.get<TSiteApiKeyResponse>(url);
  return response.data;
};

export const SEND_STEAM_SETTINGS = async (
  payload: TSteamSettingsRequestParams,
) => {
  const url = `steam/setting`;
  const response = await $apiAuth.put<TSteamSettingsResponse>(url, payload);
  return response.data;
};

export const GET_USER_BALANCE = async () => {
  const url = `market/wallet/balance`;
  const response = await $api.get<TUserBalanceResponse>(url);
  return response.data;
};

export const updateProfileInfo = async (
  params: IUpdateProfileRequestParams,
): Promise<string> => {
  const response: AxiosResponse<string> = await $api.put<string>(
    apiEndpoints.profileUpdateInfo,
    params,
  );
  return response.data;
};

export const changePassword = async (
  params: IChangePasswordRequestParams,
): Promise<string> => {
  const response: AxiosResponse<string> = await $api.post<string>(
    apiEndpoints.profileChangePassword,
    params,
  );
  return response.data;
};

export const deleteProfileAccount = async (): Promise<string> => {
  const response: AxiosResponse<string> = await $api.delete<string>(
    apiEndpoints.profileDeleteAccount,
  );
  return response.data;
};

export const REGISTER_TWO_FACTOR = async () => {
  const url = `2fa/generate`;
  const response = await $apiAuth.get<any>(url);
  return response.data;
};

export const ENABLE_TWO_FACTOR = async (payload: any) => {
  const url = `2fa/on`;
  const response = await $apiAuth.post<any>(url, payload);
  return response.data;
};

export const DISABLE_TWO_FACTOR = async (payload: any) => {
  const url = `2fa/off`;
  const response = await $apiAuth.post<any>(url, payload);
  return response.data;
};

export const CREATE_PAYMENT_PASS = async (payload: any) => {
  const url = `profile/payment-password`;
  const response = await $apiAuth.put<any>(url, payload);
  return response.data;
};

export const VERIFY_PAYMENT_PASS = async (payload: any) => {
  const url = `user/pay-password/verify`;
  const response = await $apiAuth.post<any>(url, payload);
  return response.data;
};

export const CLOSE_ACTIVE_SESSIONS = async () => {
  const url = `profile/sessions/close`;
  const response = await $apiAuth.post<any>(url);
  return response.data;
};
export const DELETE_USER = async () => {
  const url = `profile/delete`;
  const response = await $apiAuth.post<any>(url);
  return response.data;
};
