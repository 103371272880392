export enum EButtonSizeType {
  Large = 'large',
  Middle = 'middle',
  Small = 'small',
}

export enum EButtonTypeType {
  Primary = 'primary',
  Link = 'link',
  Text = 'text',
  Default = 'default',
}

export enum EButtonEventType {
  Button = 'button',
  Submit = 'submit',
}
