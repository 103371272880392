import React from 'react';

const ExtensionIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M13.3305 2C11.6325 2 10.2561 3.37654 10.2561 5.07448V7.95375H5.76634C5.01168 7.95375 4.3999 8.56548 4.3999 9.32019V14.8835H6.69356C8.39155 14.8835 9.76804 16.26 9.76804 17.958C9.76804 19.656 8.39155 21.0325 6.69356 21.0325H4.3999V26.4982C4.3999 27.2529 5.01168 27.8647 5.76634 27.8647H12.3057V25.083C12.3057 23.385 13.6822 22.0085 15.3802 22.0085C17.0782 22.0085 18.4547 23.385 18.4547 25.083V27.8647H23.1396C23.8942 27.8647 24.506 27.2529 24.506 26.4982V21.4229H27.2877C28.9857 21.4229 30.3622 20.0464 30.3622 18.3484C30.3622 16.6505 28.9857 15.2739 27.2877 15.2739H24.506V9.32019C24.506 8.56548 23.8942 7.95375 23.1396 7.95375H16.405V5.07448C16.405 3.37654 15.0285 2 13.3305 2Z"
      fill="currentColor"
    />
  </svg>
);

export { ExtensionIcon };
