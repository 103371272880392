import React from 'react';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
import { EGameType } from 'types/models/game';
// components(atoms)

// styles
import './index.scss';

// data

// func
const TableCellImage = ({ alt, src, gameType }: IPropsType) => {
  return (
    <img
      src={src}
      className={
        'table-cell-img' +
        (gameType === EGameType.CSGO
          ? ' table-cell-img--csgo'
          : ' table-cell-img--dota')
      }
      alt={alt ? alt : ''}
    />
  );
};

TableCellImage.defaultProps = {
  gameType: EGameType.CSGO,
} as { gameType: EGameType };

export { TableCellImage };
