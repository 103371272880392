// core
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

// utils
import { $api } from 'utils/request';

// hooks
import { useAuthStore, useAppStore } from 'store';

import { POST_REFRESH_TOKEN } from 'api/auth';
// hook
const useHttpClient = (
  httpClientInstance: AxiosInstance,
): [any | null, () => void] => {
  //local error state
  const [error, setError] = useState<any>(null);
  // translation hook
  const { i18n } = useTranslation();
  const { setAccessToken, removeAccessToken } = useAuthStore();
  const { gameType, otpCode } = useAppStore();

  // request interceptor
  const reqInterceptor = httpClientInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      setError(null);

      // formatting headers
      const accessToken = localStorage.getItem('access_token');
      const currency = localStorage.getItem('currency');
      if (config.headers) {
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        if (otpCode?.length) {
          config.headers['X-TwoFactorAuth'] = `${otpCode}`;
        }

        config.headers['X-localization'] =
          i18n.language && i18n.language.length > 2
            ? i18n.language.slice(0, 2)
            : i18n.language;

        config.headers['Provider'] = gameType;
        config.headers['X-currency'] = currency ? currency : 'usd';
      }
      return config;
    },
    (error: AxiosError) => {
      let formattedMessage: string = JSON.stringify(error.response?.data);
      setError(formattedMessage);

      return Promise.reject(error);
    },
  );

  // response interceptor
  const resInterceptor = httpClientInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      // let data = res.status ? res.data : res;
      // return camelCaseKeys(res, { deep: true });
      // @ts-ignore
      return res;
    },
    async error => {
      let formattedMessage: string | null = null;
      let errorCode: number | null = null;

      const originalRequest = error.request;
      const requestConfig = error.config;
      if (error?.response) {
        errorCode = error.response?.status;
        // errors handling
        if (
          error.response?.status === 401 &&
          requestConfig &&
          !originalRequest.retry
        ) {
          try {
            originalRequest.retry = true;
            if (localStorage.getItem('refresh_token')) {
              const token = localStorage.getItem('refresh_token');
              localStorage.removeItem('access_token');
              localStorage.removeItem('expires_access_token');
              localStorage.removeItem('refresh_token');
              removeAccessToken();
              const data = await POST_REFRESH_TOKEN(token);
              if (data.accessToken) {
                const cookies = new Cookies(null, { path: '/' });

                cookies.set('access_token', data.accessToken, {
                  domain: window.location.hostname,
                  expires: new Date(data.expireAccessTokenEndDate),
                });
                localStorage.setItem('access_token', data.accessToken);
                localStorage.setItem(
                  'expires_access_token',
                  data.expireAccessTokenEndDate,
                );
                localStorage.setItem('refresh_token', data.refreshToken);
                setAccessToken(data.accessToken);
              }
            }
            const updatedConfig = {
              ...requestConfig,
              data: error?.config?.data
                ? JSON.parse(error.config.data)
                : undefined,
            };
            if (localStorage.getItem('refresh_token')) {
              return httpClientInstance(updatedConfig);
            } else {
              throw new Error('No refresh token provided!');
            }
          } catch (err) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires_access_token');
            localStorage.removeItem('refresh_token');
            removeAccessToken();
          }
        }

        if (error.response.data?.message) {
          formattedMessage = error.response.data.message;
        }

        if (error.response.data?.error?.message) {
          formattedMessage = error.response.data?.error?.message;
        }

        if (error.response.data?.errors) {
          formattedMessage = '';
          Object.keys(error.response.data.errors).forEach(errorKey => {
            error.response.data.errors[errorKey].forEach((line: string) => {
              formattedMessage += `\r\n${line}`;
            });
          });
        }

        // if (error.response.data) {
        //   formattedMessage = stripHtml(error.response.data);
        // }
      }
      setError({ message: formattedMessage, code: errorCode });

      return Promise.reject(error);
    },
  );

  // watch reqInterceptor & resInterceptor - eject request & response interceptors
  useEffect(
    () => () => {
      httpClientInstance.interceptors.request.eject(reqInterceptor);
      httpClientInstance.interceptors.response.eject(resInterceptor);
    },
    [
      reqInterceptor,
      resInterceptor,
      httpClientInstance.interceptors.request,
      httpClientInstance.interceptors.response,
    ],
  );

  const errorClearedHandler = () => {
    setError(null);
  };

  return [error, errorClearedHandler];
};

export default useHttpClient;
