import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { VERIFY_PAYMENT_PASS } from '../../../api/profile';

export const useMutateVerifyPaymentPassword = () => {
  return useMutation(VERIFY_PAYMENT_PASS, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while close sessions';
      console.error(message);
      return message;
    },
  });
};
