const PaymentVisaIcon = props => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="6.5" width="35" height="23" rx="3.5" fill="white" />
    <rect x="0.5" y="6.5" width="35" height="23" rx="3.5" stroke="#CCCCCC" />
    <g clipPath="url(#clip0_977_111174)">
      <path
        d="M23.924 13.8594C23.4806 13.6835 22.7773 13.4893 21.9077 13.4893C19.6878 13.4893 18.1246 14.673 18.115 16.3655C18.0966 17.6142 19.2342 18.3077 20.0851 18.7241C20.9548 19.1495 21.2505 19.4272 21.2505 19.8064C21.2416 20.3888 20.5477 20.6573 19.9005 20.6573C19.003 20.6573 18.5221 20.5189 17.7913 20.1949L17.4953 20.056L17.1808 22.0075C17.708 22.2477 18.6793 22.4609 19.6878 22.4702C22.0465 22.4702 23.5821 21.3048 23.6003 19.5012C23.6093 18.5115 23.0086 17.7532 21.7134 17.1335C20.9272 16.7357 20.4457 16.4675 20.4457 16.0605C20.4549 15.6905 20.8529 15.3115 21.7404 15.3115C22.4712 15.2929 23.0082 15.4686 23.4149 15.6444L23.6183 15.7367L23.924 13.8594Z"
        fill="url(#paint0_linear_977_111174)"
      />
      <path
        d="M15.775 22.3407H13.527L14.9331 13.6468H17.1809L15.775 22.3407Z"
        fill="url(#paint1_linear_977_111174)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6865 13.6468H27.9477C27.4115 13.6468 27.0041 13.8039 26.7728 14.3682L23.4337 22.3405H25.7924C25.7924 22.3405 26.1808 21.2675 26.2642 21.0364H29.1504C29.2149 21.3416 29.4186 22.3405 29.4186 22.3405H31.5L29.6865 13.6468ZM26.9117 19.2607C27.0968 18.7613 27.8092 16.8283 27.8092 16.8283C27.8054 16.8357 27.8344 16.7554 27.8776 16.6357L27.8781 16.6342L27.8784 16.6332C27.943 16.4544 28.0388 16.1889 28.1049 15.996L28.262 16.7451C28.262 16.7451 28.6877 18.8261 28.7801 19.2607H26.9117Z"
        fill="url(#paint2_linear_977_111174)"
      />
      <path
        d="M11.6495 13.6468L9.44798 19.5752L9.20741 18.3729L9.20719 18.3721L8.42118 14.3776C8.29174 13.8224 7.89395 13.6652 7.40372 13.6468H3.787L3.75 13.8225C4.63211 14.0481 5.42107 14.3731 6.11236 14.7773L8.11596 22.3314H10.4931L14.0265 13.6468H11.6495Z"
        fill="url(#paint3_linear_977_111174)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_977_111174"
        x1="3.75"
        y1="17.9986"
        x2="31.5"
        y2="17.9986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_977_111174"
        x1="3.75"
        y1="17.9986"
        x2="31.5"
        y2="17.9986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_977_111174"
        x1="3.75"
        y1="17.9986"
        x2="31.5"
        y2="17.9986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_977_111174"
        x1="3.75"
        y1="17.9986"
        x2="31.5"
        y2="17.9986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <clipPath id="clip0_977_111174">
        <rect
          width="27.75"
          height="8.98096"
          fill="white"
          transform="translate(3.75 13.4893)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { PaymentVisaIcon };
