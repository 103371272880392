// core
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import Cookies from 'universal-cookie';

// hooks

//store
import { useAuthStore, useExtStore } from 'store';
// locales

// types
import { EAlertType } from 'types/units';
// components(atoms)
import {
  LanguageToggler,
  CurrencyToggler,
  ModalTeleport,
} from 'components/atoms';
import { LogoIcon } from 'components/icons';
import { HeaderAlert } from '../HeaderAlert';
import { HeaderApps } from '../components/HeaderApps';
import { HeaderTrades } from '../components/HeaderTrades';

// styles
import './index.scss';
import { useQueryFetchProfileInfo } from '../../../../hooks/api';
import { BlockWarning } from '../../Modals/BlockWarning';
import { HackedAttentionModal } from '../../Modals/HackedAttentionModal';
import { useUserBanStatusSocket } from '../../../../hooks/api';
// data

// func
const cookies = new Cookies(null, { path: '/' });
const HeaderSimple = () => {
  const { width } = useWindowSize();
  const { accessToken, expiresAccessToken } = useAuthStore();
  const { sSetExtInstallStatus, sSetExtOnlineStatus } = useExtStore();
  const [enabledFetchBanStatus, setEnabledFetchBanStatus] =
    useState<boolean>(false);

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfileData,
  } = useQueryFetchProfileInfo(false);

  const banStatus = useUserBanStatusSocket(
    enabledFetchBanStatus,
    profileData?.id,
  );

  useEffect(() => {
    if (profileData && !isLoadingProfileData) {
      setEnabledFetchBanStatus(true);

      if (accessToken && expiresAccessToken) {
        cookies.set('access_token', accessToken, {
          domain: window.location.hostname,
          expires: new Date(expiresAccessToken),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      if (banStatus !== profileData?.isBanned) {
        refetchProfileData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banStatus]);

  const checkExtStatusInstall = () => {
    let chromeExtensionId = cookies.get('extension_installed_id');

    // @ts-ignore
    if (chromeExtensionId && chrome?.runtime) {
      // @ts-ignore
      chrome.runtime.sendMessage(
        chromeExtensionId,
        {
          from: 3,
          type: 'check_ext_init_status',
        },
        response => {
          // @ts-ignore
          if (chrome.runtime.lastError) {
            sSetExtInstallStatus(false);
            sSetExtOnlineStatus(false);
          } else {
            let { is_init, is_ext_online } = response;
            sSetExtInstallStatus(is_init);
            sSetExtOnlineStatus(is_ext_online);
          }
        },
      );
    } else {
      sSetExtInstallStatus(false);
      sSetExtOnlineStatus(false);
    }
  };

  useEffect(() => {
    checkExtStatusInstall();
    const extStatusInterval = setInterval(checkExtStatusInstall, 5000);
    return () => clearInterval(extStatusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="header-simple">
      <div className="header-simple__alerts">
        {profileData?.isBanned && (
          <HeaderAlert
            alertType={EAlertType.Warning}
            blockTime={profileData?.blockedUntil}
          />
        )}
      </div>
      <div className="header-simple__content">
        {width <= MEDIA.M1360 && (
          <RouterLink className="header-simple__logo" to={'/'}>
            <LogoIcon />
          </RouterLink>
        )}

        {width > MEDIA.M1280 && <HeaderApps />}

        <div className="header-simple__togglers">
          {width > MEDIA.M1024 && <HeaderTrades />}

          <div className="header-simple__lang">
            <LanguageToggler />
          </div>
          <div className="header-simple__curency">
            <CurrencyToggler />
          </div>
        </div>
      </div>
      <ModalTeleport
        centered
        modalId={'blockWarning'}
        width={500}
        modalClass={'block-warning-modal__wrapper'}
      >
        <BlockWarning
          blockedUntil={profileData?.blockedUntil}
          modalId={'blockWarning'}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'hackedAttention'}
        width={500}
        modalClass={'hacked-attention-modal__wrapper'}
      >
        <HackedAttentionModal modalId={'hackedAttention'} />
      </ModalTeleport>
    </div>
  );
};

export { HeaderSimple };
