// useSocket.ts
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`; // Replace with your backend server URL

export const useUserBalanceSocket = (enabled, userId): any | null => {
  const [socket, setSocket] = useState<any | null>(null);
  const [lastBalance, setLastBalance] = useState<any | null>(null);
  useEffect(() => {
    if (enabled) {
      const socketInstance = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
      });
      socketInstance.on('connect', () => {
        socketInstance.emit('join', userId.toString());
      });

      // Add event listeners for any custom events you want to handle
      socketInstance.on('balance', (data: any) => {
        setLastBalance(data);
        // You can update state or perform any other actions here
      });

      // Set the socket instance in state
      setSocket(socketInstance);

      // Cleanup the socket connection when the component unmounts
      return () => {
        socketInstance.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return lastBalance;
};

export default useUserBalanceSocket;
