const StickerBorderIcon = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.4894 14.823L49.6523 20.2848L49.1255 20.7829L51.7069 23.5138C53.5562 25.4701 53.5562 28.5299 51.7069 30.4862L49.1255 33.2171L49.6523 33.7152L44.4894 39.177L43.9625 38.679L41.3951 41.3951L38.679 43.9625L39.177 44.4894L33.7152 49.6523L33.2171 49.1255L30.4862 51.7069C28.5299 53.5562 25.4701 53.5562 23.5138 51.7069L20.7829 49.1255L20.2848 49.6523L14.823 44.4894L15.321 43.9625L12.6049 41.3951L10.0375 38.679L9.5106 39.177L4.34768 33.7152L4.87454 33.2171L2.29308 30.4862C0.443835 28.5299 0.443836 25.4701 2.29308 23.5138L4.87454 20.7829L4.34768 20.2848L9.5106 14.823L10.0375 15.321L12.6049 12.6049L15.321 10.0375L14.823 9.5106L20.2848 4.34768L20.7829 4.87454L23.5138 2.29308C25.4701 0.443835 28.5299 0.443836 30.4862 2.29308L33.2171 4.87455L33.7152 4.34768L39.177 9.5106L38.679 10.0375L41.3951 12.6049L43.9625 15.321L44.4894 14.823Z"
      stroke="#2B292E"
      strokeWidth="1.45"
      strokeDasharray="14.5 14.5"
    />
  </svg>
);

export { StickerBorderIcon };
