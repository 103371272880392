import { useQuery } from '@tanstack/react-query';

//types
import { TArticlesRequestParams } from '../../../types/api/blog';

// services
import { GET_PRODUCT_TRADES } from '../../../api/product';

export const useQueryFetchProductTrades = (query: TArticlesRequestParams) => {
  return useQuery(
    ['useGetProductTrades', query],
    () => GET_PRODUCT_TRADES(query),
    {
      refetchOnMount: 'always',
      // initialData: () => null,
      onSuccess: data => {
        return data;
      },
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching product trades';
        console.error(message);
        return message;
      },
    },
  );
};
