import snakeCaseKeys from 'snakecase-keys';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import moment from 'moment';

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export const formatDate = (date: Date, format: String) => {
  return moment(date).format(`${format}`);
};

export const formatPriceInputValue = (price: any) => {
  if (!price) {
    return '0.01';
  } else {
    let sanitizedValue = price.toString().replace(/[^\d.]/g, '');

    sanitizedValue = sanitizedValue.replace(/^0+(?=\d)/, '');

    let dotCount = sanitizedValue.split('.').length - 1;
    if (dotCount > 1) {
      let lastDotIndex = sanitizedValue.lastIndexOf('.');
      sanitizedValue =
        sanitizedValue.substring(0, lastDotIndex) +
        sanitizedValue.substring(lastDotIndex + 1);
    }

    if (parseFloat(sanitizedValue) === 0) {
      sanitizedValue = '0.01';
    }

    let dotIndex = sanitizedValue.indexOf('.');
    if (dotIndex !== -1) {
      let decimalPart = sanitizedValue.substring(dotIndex + 1);
      if (decimalPart.length > 2) {
        decimalPart = decimalPart.substring(0, 2);
        sanitizedValue =
          sanitizedValue.substring(0, dotIndex + 1) + decimalPart;
      }
    }
    return sanitizedValue;
  }
};

export const getVariable = (list: any, key: string) => {
  if (!list?.length) {
    return null;
  } else {
    const variable = list.find(el => el.key === key);
    if (variable !== undefined) {
      return variable?.value;
    } else {
      return null;
    }
  }
};
export const abbreviateNumber = (value: number, fractionDigits: number = 0) => {
  let tier = (Math.log10(Math.abs(value)) / 3) | 0;

  if (tier === 0) return value.toFixed(fractionDigits);

  let suffix = SI_SYMBOL[tier],
    scale = Math.pow(10, tier * 3);

  return (value / scale).toFixed(fractionDigits) + suffix;
};

export const objectToString = params =>
  queryString.stringify(params, { arrayFormat: 'comma' });

export const objectToQueryString = (sourceUrl, queryParams) => {
  let formattedUrl = sourceUrl;

  if (!isEmpty(queryParams)) {
    formattedUrl += '?';
    formattedUrl += objectToString(queryParams);
  }
  return formattedUrl;
};

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}
export const convertToFormData = (fields: object) => {
  const formData = new FormData();
  const transformedFields = snakeCaseKeys(fields, { deep: false });

  Object.keys(transformedFields).forEach(key => {
    if (
      Object.prototype.toString.call(transformedFields[key]) === '[object File]'
    ) {
      formData.append(key, transformedFields[key]);
      // formData.append(`${key}_name`, transformedFields[key].name);
    } else {
      formData.append(key, transformedFields[key]);
    }
  });

  return formData;
};

export const formatPrice = (
  number,
  toFractionDigit: number = 2,
  toString: boolean = true,
) => {
  let result: number | string = Number(number.toFixed(toFractionDigit));
  if (toString) {
    let parts = result.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts.length > 1) {
      if (parts[1].length === 1) {
        parts[1] = parts[1] + '0';
      }
    } else {
      parts[1] = '00';
    }
    result = parts.join('.');
  }
  return result;
};

export const formatPartsPrice = (number, toFractionDigit: number = 2) => {
  let parts = number.toFixed(toFractionDigit).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  if (parts.length > 1) {
    if (parts[1].length === 1) {
      parts[1] = parts[1] + '0';
    }
  } else {
    parts[1] = '00';
  }

  return { significantDigit: parts[0], fractionDigit: parts[1] };
};

export const formatFloat = (number: number, toFractionDigit: number = 6) => {
  return Number(number.toFixed(toFractionDigit));
};

export const pluralizate = (
  value: number,
  wordOne: string,
  wordSimple: string,
  wordMany: string,
): string => {
  let language = localStorage.getItem('i18nextLng'),
    localization =
      language && language.length > 2 ? language.slice(0, 2) : language,
    pluralWord = '';

  if (localization === 'en') {
    pluralWord = value === 1 ? wordOne : wordSimple;
  } else if (localization === 'ru' || localization === 'uk') {
    pluralWord =
      value % 10 === 1 && value % 100 !== 11
        ? wordOne
        : value % 10 >= 2 &&
          value % 10 <= 4 &&
          (value % 100 < 10 || value % 100 >= 20)
        ? wordSimple
        : wordMany;
  }
  return pluralWord;
};

export const stripHtml = html => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export function addUniqueObjectToArray<T extends Record<string, any>>(
  arr: T[],
  newObj: T,
  uniqueField: keyof T,
  rewrite: boolean = false,
): T[] {
  const index = arr.findIndex(obj => obj[uniqueField] === newObj[uniqueField]);

  if (index !== -1) {
    if (rewrite) {
      const newArr = [...arr];
      newArr[index] = newObj;
      return newArr;
    } else {
      return arr;
    }
  } else {
    return [...arr, newObj];
  }
}

export const calculateAsideScrollOffset = (
  element: HTMLElement,
  topOffset: number,
  currentScroll: number,
  prevScroll: number,
  elementTop: number,
) => {
  let scrollHeight = `calc(100vh - 32px - 88px)`;
  if (currentScroll < topOffset) {
    scrollHeight = `calc(100vh - 32px - 88px - (66px - ${currentScroll}px))`;
  }
  element.style.height = scrollHeight;
  // let elementHeight = element.clientHeight;
  // let diffHeight = elementHeight - (window.innerHeight - topOffset);
  //
  // if (diffHeight > 0) {
  //   if (currentScroll > prevScroll) {
  //     elementTop =
  //       -elementTop < diffHeight - topOffset
  //         ? elementTop + (prevScroll - currentScroll)
  //         : topOffset - diffHeight;
  //     element.style.top = `${elementTop}px`;
  //   } else if (currentScroll < prevScroll) {
  //     elementTop =
  //       elementTop < topOffset
  //         ? elementTop + (prevScroll - currentScroll)
  //         : topOffset;
  //     element.style.top = `${elementTop}px`;
  //   }
  // } else {
  //   element.style.top = `${topOffset}px`;
  // }

  return elementTop;
};

export const queryStringToObject = query => {
  return query ? queryString.parse(query.replace('?', '')) : {};
};
