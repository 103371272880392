import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { stripHtml } from '../../../utils/commons';

export function ArticleSEO({ title, desc, image }) {
  // locales hook
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>
        {t('seoDefault.title')} {title?.length ? '| ' + title : ''}
      </title>
      <meta
        name="description"
        content={desc?.length ? stripHtml(desc) : t('seoDefault.description')}
      />

      <meta
        name="keywords"
        content={title?.length ? title : t('seoDefault.title')}
      />

      <meta
        property="og:title"
        content={title?.length ? title : t('seoDefault.title')}
      />
      <meta
        property="og:description"
        content={desc?.length ? stripHtml(desc) : t('seoDefault.description')}
      />
      <meta
        property="og:image"
        content={
          image?.length ? image : `${process.env.PUBLIC_URL}/sharing.png`
        }
      />

      <meta
        name="twitter:title"
        content={title?.length ? title : t('seoDefault.title')}
      />
      <meta
        name="twitter:description"
        content={desc?.length ? stripHtml(desc) : t('seoDefault.description')}
      />
      <meta
        name="twitter:image"
        content={
          image?.length ? image : `${process.env.PUBLIC_URL}/sharing.png`
        }
      />
    </Helmet>
  );
}
