import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Button } from 'antd';
// utils

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components

// styles
import './index.scss';

// data
/*@ts-ignore*/
import Maskot from '../../../../assets/img/maskot.png';

const HackedAttentionModal = ({ modalId }) => {
  const { sModalClose } = useAppStore();
  const onConfirm = id => {
    sModalClose(id);
  };
  return (
    <div className="hacked-attention-modal">
      <div className="hacked-attention-modal__head">
        <img src={Maskot} alt="Skinsty" />
      </div>
      <div className="hacked-attention-modal__body">
        <div className="hacked-attention-modal__title">Внимание!</div>
        <Typography className="hacked-attention-modal__text">
          Возможно злоумышленники получили доступ к вашему аккаунту. Ниже
          описана исктрукция, как лишить злоумышленника доступа:
          <ol>
            <li>
              <NavLink to={'dashboard/security-and-payments'}>
                Смените пароль
              </NavLink>{' '}
              на аккаунте;
            </li>
            <li>
              <NavLink to={'dashboard/dashboard/steam-account'}>
                Пересоздайте
              </NavLink>{' '}
              API ключ Steam;
            </li>
            <li>
              <NavLink to={'dashboard/security-and-payments'}>
                Сбросьте все сессии
              </NavLink>{' '}
              у нас на площадке;
            </li>
          </ol>
        </Typography>
      </div>
      <div className="hacked-attention-modal__actions">
        <Button
          block
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          onClick={() => {
            onConfirm(modalId);
          }}
        >
          Хорошо
        </Button>
        <Typography.Text className="hacked-attention-modal__actions-link">
          <NavLink to={'support/faq'}>Связаться с тех. поддержкой</NavLink>
        </Typography.Text>
      </div>
    </div>
  );
};

export { HackedAttentionModal };
