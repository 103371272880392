const PayPalIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0269 15.7256C26.0108 15.8303 25.992 15.9374 25.9711 16.0473C25.2502 19.7638 22.7837 21.0477 19.6337 21.0477H18.0298C17.6446 21.0477 17.3199 21.3284 17.26 21.71L16.2062 28.4212C16.1672 28.6718 16.3595 28.8975 16.6112 28.8975H19.456C19.7927 28.8975 20.0789 28.6518 20.132 28.3182L20.1599 28.1732L20.6955 24.7605L20.7299 24.5733C20.7823 24.2386 21.0691 23.9927 21.4059 23.9927H21.8313C24.5874 23.9927 26.745 22.8693 27.3757 19.6181C27.639 18.2599 27.5027 17.1258 26.8056 16.3283C26.5947 16.0878 26.333 15.8882 26.0269 15.7256Z"
      fill="#298FC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2727 15.4239C25.1625 15.3916 25.0489 15.3624 24.9323 15.336C24.8151 15.3103 24.6951 15.2875 24.5715 15.2675C24.1391 15.1973 23.6652 15.1641 23.1577 15.1641H18.873C18.7673 15.1641 18.6671 15.188 18.5775 15.2313C18.3798 15.3267 18.233 15.5145 18.1974 15.7445L17.2859 21.5412L17.2598 21.7102C17.3197 21.3286 17.6444 21.0478 18.0296 21.0478H19.6335C22.7835 21.0478 25.25 19.7633 25.9709 16.0475C25.9924 15.9375 26.0106 15.8305 26.0267 15.7258C25.8444 15.6286 25.6468 15.5455 25.4341 15.4747C25.3816 15.4571 25.3274 15.4402 25.2727 15.4239Z"
      fill="#22284F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1968 15.7444C18.2324 15.5145 18.3791 15.3266 18.5768 15.2319C18.6671 15.1884 18.7667 15.1645 18.8723 15.1645H23.157C23.6645 15.1645 24.1384 15.1979 24.5709 15.2681C24.6945 15.2879 24.8144 15.3109 24.9317 15.3366C25.0482 15.3628 25.1618 15.3922 25.272 15.4243C25.3267 15.4407 25.3809 15.4577 25.434 15.4746C25.6467 15.5454 25.8444 15.6292 26.0267 15.7257C26.2412 14.3523 26.0249 13.4173 25.2854 12.5705C24.47 11.6382 22.9985 11.2393 21.1155 11.2393H15.6487C15.2641 11.2393 14.936 11.52 14.8765 11.9023L12.5996 26.3938C12.5547 26.6805 12.7749 26.9391 13.0629 26.9391H16.4378L18.1968 15.7444Z"
      fill="#28356A"
    />
  </svg>
);

export { PayPalIcon };
