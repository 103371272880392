import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Select, Button, Radio, Switch } from 'antd';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { useDebounce } from 'hooks/general';

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
import { useQuerySearchMarketItemsByName } from 'hooks/api';
// types
import {
  ESelectSizeType,
  ERadioButtonSizeType,
  EGridType,
  EButtonSizeType,
  EButtonTypeType,
} from 'types/units';
import type { RadioChangeEvent } from 'antd';
import { IPropsType } from '../CatalogAside/types';

// components
import { CategoryFilter } from 'components/features';
import {
  ArrowDownIcon,
  GridSmallIcon,
  GridDefaultIcon,
  FilterIcon,
} from 'components/icons';
import { AppAutocomplete } from 'components/atoms';
import { CatalogFilterDrawer } from '../CatalogFilterDrawer';

// styles
import './index.scss';

//data
import { sortOptions } from '../data';
import {
  EGameType,
  EMarketFilterCategoryType,
  EMarketFilterUrlType,
  ESortType,
} from 'types/models';
import { findIndex } from 'lodash';
import { array } from 'yup';

const { Option } = Select;
const CatalogHead = ({
  categories,
  filters,
  sort,
  search,
  onChangeCategory,
  onChangeSort,
  onChangeFilter,
  onChangeSearch,
  onReset,
  isDisabledReset,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const {
    gameType,
    catalogGrid,
    catalogStack,
    drawer,
    sSetCatalogGrid,
    sSetCatalogStack,
    sDrawerClose,
    sDrawerOpen,
  } = useAppStore();

  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>(undefined);
  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  const [filteredSortOptions, setFilteredSortOptions] =
    useState<any>(sortOptions);

  useEffect(() => {
    if (gameType !== EGameType.CSGO) {
      setFilteredSortOptions(
        filteredSortOptions.filter(el => el.type !== ESortType.float),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);

  const scrollRef = useRef<any>(null);

  const { data: searchData, isFetching: isFetchingSearch } =
    useQuerySearchMarketItemsByName({
      params: { nameSearch: debounceSearchValue, provider: gameType },
      enabled: !!debounceSearchValue,
    });

  const horizontalWheelScroll = event => {
    if (event.deltaY > 0) {
      scrollRef.current.scrollLeft += 100;
      event.preventDefault();
    } else {
      scrollRef.current.scrollLeft -= 100;
      event.preventDefault();
    }
  };

  useEffect(() => {
    let scrollEl = scrollRef?.current;
    if (scrollEl) {
      scrollEl.addEventListener('wheel', horizontalWheelScroll);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('wheel', horizontalWheelScroll);
      }
    };
  }, []);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  useEffect(() => {
    if (searchValue?.length === 0) {
      onChangeSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const onDebounceChange = () => {
    setDebounceSearchValue(searchValue);
  };

  const debouncedOnChange = useDebounce(onDebounceChange, 1000);

  const onChangeSortSelect = (value: string) => {
    let currentValue = filteredSortOptions.find(item => item.value === value);
    if (currentValue) {
      onChangeSort(currentValue);
    }
  };

  const onChangeGrid = ({ target: { value } }: RadioChangeEvent) => {
    sSetCatalogGrid(value);
  };

  const onChangeSwitch = (value: boolean) => {
    sSetCatalogStack(value);
  };

  const onSelectCategory = (value, item, category) => {
    let itemKey =
      category.urlType === EMarketFilterUrlType.Radio ? category.sku : item.sku;

    let prevValue = categories[category.sku].dataValues[itemKey];

    if (value.indexOf(EMarketFilterCategoryType.All) > -1) {
      //---all from category
      if (prevValue?.indexOf(value) > -1) {
        delete categories[category.sku].dataValues[itemKey];
      } else {
        categories[category.sku].dataValues[itemKey] = value;
      }
    } else {
      //---one from category
      if (prevValue) {
        let prevValuesArray = prevValue.split(',');
        let valueInArray = prevValuesArray.find(item => item === value);
        if (valueInArray) {
          //---delete item
          prevValuesArray = prevValuesArray.filter(item => item !== value);
        } else {
          //---check prevValue and add new item
          if (prevValue.indexOf(EMarketFilterCategoryType.All) > -1) {
            prevValuesArray = [value];
          } else if (
            item.possibilityValues.every(
              possibilityValue => possibilityValue.value !== prevValuesArray[0],
            )
          ) {
            prevValuesArray = [value];
          } else {
            prevValuesArray.push(value);
          }
        }

        //---update dataValues
        if (prevValuesArray.length) {
          categories[category.sku].dataValues[itemKey] =
            prevValuesArray.join(',');
        } else {
          delete categories[category.sku].dataValues[itemKey];
        }
      } else {
        //---add new value to empty dataValues
        categories[category.sku].dataValues[itemKey] = value;
      }
    }

    onChangeCategory({ ...categories });
  };

  const onCatalogFilterDrawerToggle = () => {
    if (drawer['catalogFilterDrawer']) {
      sDrawerClose('catalogFilterDrawer');
    } else {
      sDrawerOpen('catalogFilterDrawer');
    }
  };

  const onFocusSearch = (status: boolean) => {
    setSearchFocus(status);
  };

  const renderCategoryGroup = category => {
    return category.possibilityGroups.map((item: any) => {
      if (item.possibilityValues.length) {
        let currentValueKey =
          category.urlType === EMarketFilterUrlType.Radio
            ? category.sku
            : item.sku;

        return (
          <CategoryFilter
            key={item.sku}
            label={item.title}
            sku={item.sku}
            icon={item.icon}
            data={item.possibilityValues}
            currentValue={category.dataValues[currentValueKey]}
            isCompact={gameType === EGameType.Dota}
            onSelect={value => {
              onSelectCategory(value, item, category);
            }}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="catalog-head">
      <div
        className={
          'catalog-head__top' +
          (searchFocus ? ' catalog-head__top--search-focused' : '')
        }
      >
        {width <= MEDIA.M1024 && (
          <Button
            icon={<FilterIcon />}
            type={EButtonTypeType.Default}
            size={EButtonSizeType.Middle}
            className={
              'ant-btn-default--dark catalog-head__btn-filter' +
              (drawer['catalogFilterDrawer']
                ? ' catalog-head__btn-filter--active'
                : '')
            }
            onClick={() => onCatalogFilterDrawerToggle()}
          />
        )}
        <AppAutocomplete
          className="catalog-head__autocomplete"
          isGetPopupContainer={true}
          popupClassName="catalog-head__autocomplete-dropdown"
          current={searchValue}
          options={searchData}
          isLoading={isFetchingSearch}
          isDefault={!!debounceSearchValue}
          size={ESelectSizeType.Large}
          onSearch={value => {
            debouncedOnChange();
            setSearchValue(value);
          }}
          onInputKeyDown={keyEvent => {
            if (keyEvent.code === 'Enter') {
              onChangeSearch(searchValue);
            }
          }}
          onSelect={onChangeSearch}
          onReset={() => {
            debouncedOnChange();
            setSearchValue('');
          }}
          onFocus={() => onFocusSearch(true)}
          onBlur={() => onFocusSearch(false)}
        />
        {width > MEDIA.M1024 && (
          <Select
            className="catalog-head__sort"
            popupClassName="ant-select-dropdown-lg"
            size={ESelectSizeType.Large}
            suffixIcon={<ArrowDownIcon />}
            value={sort?.value}
            onChange={onChangeSortSelect}
            dropdownAlign={{ offset: [0, 0] }}
            placeholder={t(filteredSortOptions[0].label)}
            listHeight={266}
          >
            {filteredSortOptions?.length &&
              filteredSortOptions.map((el, index) => {
                return (
                  <Option value={el.value} label={t(el.label)} key={index}>
                    {t(el.label)}
                  </Option>
                );
              })}
          </Select>
        )}
        {width <= MEDIA.M1024 && (
          <CatalogFilterDrawer
            isDisabledReset={isDisabledReset}
            filters={filters}
            onChange={onChangeFilter}
            onReset={onReset}
          />
        )}
      </div>

      <div className="catalog-head__bottom">
        <div className="catalog-head__filters">
          <div
            className="catalog-head__categories catalog-head__scroll"
            ref={scrollRef}
          >
            {Object.keys(categories).length > 0 &&
              Object.values(categories).map(category => (
                // @ts-ignore
                <Fragment key={category.sku}>
                  {/* @ts-ignore*/}
                  {category.possibilityGroups.length > 0 && (
                    <div className="catalog-head__category">
                      {renderCategoryGroup(category)}
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
          <div className="catalog-head__switch">
            <Switch checked={catalogStack} onChange={onChangeSwitch} />
            <span>{t('itemsStack')}</span>
          </div>
        </div>

        {width > MEDIA.M1024 && (
          <div className="catalog-head__actions">
            <Radio.Group
              value={catalogGrid}
              size={ERadioButtonSizeType.Large}
              onChange={onChangeGrid}
            >
              <Radio.Button value={EGridType.Default}>
                <GridDefaultIcon />
              </Radio.Button>
              <Radio.Button value={EGridType.Small}>
                <GridSmallIcon />
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
      </div>
    </div>
  );
};

export { CatalogHead };
