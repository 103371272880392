const PaymentMasterCardIcon = props => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="6.5" width="35" height="23" rx="3.5" fill="white" />
    <rect x="0.5" y="6.5" width="35" height="23" rx="3.5" stroke="#CCCCCC" />
    <g clipPath="url(#clip0_512_141865)">
      <path
        d="M30.0001 18.0931C30.0001 22.1926 26.6833 25.5094 22.5838 25.5094C20.8533 25.5094 19.267 24.912 18.0104 23.9231C19.7408 22.5635 20.8327 20.4622 20.8327 18.0931C20.8327 15.724 19.7202 13.6227 18.0104 12.263C19.267 11.2742 20.8533 10.6768 22.5838 10.6768C26.6833 10.6768 30.0001 14.0141 30.0001 18.0931Z"
        fill="#F79E1B"
      />
      <path
        d="M18.0103 12.2627C18.0103 12.2627 18.0103 12.2627 18.0103 12.2627C19.7202 13.6224 20.8326 15.7236 20.8326 18.0927C20.8326 20.4618 19.7408 22.5631 18.0103 23.9228L17.9896 23.9228C16.2798 22.5837 15.1674 20.4619 15.1674 18.0928C15.1674 15.7237 16.2798 13.6224 17.9897 12.2627C17.9897 12.2627 17.9897 12.2628 17.9897 12.2627L18.0103 12.2627Z"
        fill="#FF5F00"
      />
      <path
        d="M15.1674 18.0931C15.1674 15.724 16.2798 13.6227 17.9897 12.263C16.733 11.2742 15.1468 10.6768 13.4163 10.6768C9.31674 10.6768 6 13.9935 6 18.0931C6 22.1926 9.31674 25.5094 13.4163 25.5094C15.1468 25.5094 16.733 24.912 17.9897 23.9231C16.2798 22.5841 15.1674 20.4622 15.1674 18.0931Z"
        fill="#EB001B"
      />
    </g>
    <defs>
      <clipPath id="clip0_512_141865">
        <rect
          width="24"
          height="14.8326"
          fill="white"
          transform="translate(6 10.6768)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { PaymentMasterCardIcon };
