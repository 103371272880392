import { useEffect } from 'react';
import { calculateAsideScrollOffset } from '../../utils/commons';

let prevScroll = 0;
let elementTop = 0;
export function useAsideScrollPosition(asideRef, topOffset) {
  useEffect(() => {
    const handleScroll = () => {
      if (asideRef.current) {
        let currentScroll = window.scrollY;
        elementTop = calculateAsideScrollOffset(
          asideRef.current,
          topOffset,
          currentScroll,
          prevScroll,
          elementTop,
        );
        prevScroll = currentScroll;
      }
    };
    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
