const ArrowRightIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53157 6.41438C9.20812 6.67313 9.15568 7.1451 9.41444 7.46855L13.0396 12L9.41444 16.5315C9.15568 16.855 9.20812 17.3269 9.53157 17.5857C9.85501 17.8444 10.327 17.792 10.5857 17.4685L14.5857 12.4685C14.8049 12.1946 14.8049 11.8054 14.5857 11.5315L10.5857 6.53151C10.327 6.20806 9.85501 6.15562 9.53157 6.41438Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowRightIcon };
