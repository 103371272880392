import { useQuery } from '@tanstack/react-query';

// services
import { GET_PRICE_HISTORY } from 'api/market';
import { IPriceHistoryRequestParams } from 'types/api';

export const useQueryFetchPriceHistory = (
  params: IPriceHistoryRequestParams,
  enabled?: boolean,
) => {
  return useQuery(
    ['useQueryFetchPriceHistory', params, enabled],
    () => GET_PRICE_HISTORY(params),
    {
      enabled,
      onSuccess: data => {},
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching Price History';
        console.error(message);
        return message;
      },
    },
  );
};
