import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useAuthStore } from './auth';
import { useAppStore } from './app';

type CartState = {
  cart: Array<any>;
  cartProduct: any;
  sSetCartProduct: (cartProduct: any) => void;
  sSetCartData: (cart: Array<any>) => void;
};

const useCartStore = create(
  devtools<CartState>(
    (set, get) => ({
      cart: localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart') || '[]')
        : [],
      cartProduct: null,
      sSetCartData: cart => {
        if (useAuthStore.getState().accessToken) {
          localStorage.setItem('cart', JSON.stringify(cart));
          set(state => ({
            cart,
          }));
        } else {
          useAppStore.getState().sModalOpen('steamAuth');
        }
      },
      sSetCartProduct: cartProduct => {
        set(state => ({
          cartProduct,
        }));
      },
    }),
    { name: 'accessToken' },
  ),
);
export { useCartStore };
