import { useQuery } from '@tanstack/react-query';
import { useAppStore } from 'store';
//types

// services
import { GET_VARIABLES_LIST } from 'api/settings';

export const useQueryFetchVariables = enabled => {
  const { sSetVariables } = useAppStore();
  return useQuery(['useGetVariables'], () => GET_VARIABLES_LIST(), {
    enabled,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      sSetVariables(data?.data);
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Product Info';
      console.error(message);
      return message;
    },
  });
};
