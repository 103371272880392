import React from 'react';

// utils
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { Button } from 'antd';

// styles
import './index.scss';

// data
/*@ts-ignore*/
// import Maskot from 'assets/img/maskot.png';
import Maskot from 'assets/img/maskotDuck.png';
import { HeaderAlertTimer } from '../../Header/HeaderAlert/HeaderAlertTimer';

const BlockWarning = ({ modalId, blockedUntil }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const onConfirm = id => {
    sModalClose(id);
  };
  return (
    <div className="withdraw-balance-confirmation block-warning-modal">
      <div className="withdraw-balance-confirmation__head withdraw-balance-confirmation__head--small">
        <div className="withdraw-balance-confirmation__head--bg">
          <img src={Maskot} alt="Skinsty" />
        </div>
      </div>
      <div className="withdraw-balance-confirmation__desc">
        <div className="withdraw-balance-confirmation__desc--title">
          <span>{t('blockTitle')}</span>
          <HeaderAlertTimer targetDate={blockedUntil} />
        </div>
        <div className="withdraw-balance-confirmation__desc--content">
          {t('blockDesc')}
        </div>
      </div>
      <div className="withdraw-balance-confirmation__actions withdraw-balance-confirmation__actions--light">
        <Button
          block
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          onClick={() => {
            onConfirm(modalId);
          }}
        >
          {t('blockBtn')}
        </Button>
      </div>
    </div>
  );
};

export { BlockWarning };
