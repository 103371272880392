import React, { useEffect } from 'react';
import { Button, Typography, Tooltip } from 'antd';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { formatPrice } from 'utils/commons';
import moment from 'moment/moment';

// locale
import { useTranslation } from 'react-i18next';

// store
import {
  useMutationReadNotification,
  useMutationAcceptTrade,
  useMutationRejectTrade,
} from 'hooks/api';

// types
import { IPropsType } from './types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { ENotificationsActionType, ENotificationsType } from 'types/api';

// components
import { CloseIcon, HelpIcon, NotificationTooltipIcon } from 'components/icons';

// styles
import './index.scss';
import { EInventoryPendingType } from 'types/models';
import { InventoryPendingTimer } from '../../../../../../containers/user/Inventory/InventoryPending/InventoryPendingTimer';
import { InfoTooltip, StickerTooltip } from 'components/features';

//data

const { Text } = Typography;
const HeaderNotice = ({ data, onClosed }: IPropsType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    mutate: mutateReadNotification,
    isLoading: isLoadingReadNotification,
    isSuccess: isSuccessReadNotification,
  } = useMutationReadNotification();

  const {
    mutate: mutateAcceptTrade,
    isLoading: isLoadingAcceptTrade,
    isSuccess: isSuccessAcceptTrade,
  } = useMutationAcceptTrade();

  const {
    mutate: mutateRejectTrade,
    isLoading: isLoadingRejectTrade,
    isSuccess: isSuccessRejectTrade,
  } = useMutationRejectTrade();

  useEffect(() => {
    if (
      (!isLoadingAcceptTrade && isSuccessAcceptTrade) ||
      (!isLoadingRejectTrade && isSuccessRejectTrade)
    ) {
      mutateReadNotification(data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingAcceptTrade,
    isSuccessAcceptTrade,
    isLoadingRejectTrade,
    isSuccessRejectTrade,
  ]);

  useEffect(() => {
    if (!isLoadingReadNotification && isSuccessReadNotification) {
      onClosed(data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingReadNotification, isSuccessReadNotification]);

  const onTradeAction = (actionType, data) => {
    if (actionType === ENotificationsActionType.Accept) {
      mutateAcceptTrade({
        transactionId: data.info.txnID,
        tradeOfferId: data.info.tradeOfferId,
      });
    } else if (ENotificationsActionType.Reject) {
      mutateRejectTrade({
        transactionId: data.info.txnID,
        tradeOfferId: data.info.tradeOfferId,
      });
    }
  };

  const onClose = () => {
    mutateReadNotification(data.id);
  };

  const onInventoryPending = () => {
    mutateReadNotification(data.id);
    navigate('/inventory/pending');
  };
  const onInventorySale = () => {
    mutateReadNotification(data.id);
    navigate(`/inventory/sales?show=${data.info?.itemId}`);
  };

  const getImg = () => {
    if (
      [
        ENotificationsType.ItemPropose,
        ENotificationsType.ItemProposeRejected,
        ENotificationsType.ItemRequestToSend,
        ENotificationsType.ItemBought,
        ENotificationsType.ItemRequestToAccept,
        ENotificationsType.ItemSold,
      ].indexOf(data.type) > -1
    ) {
      return (
        <img
          src={data.info?.icon}
          alt={data.title}
          className="header-notice__img"
        />
      );
    } else if (data.type === ENotificationsType.BalanceIncrease) {
      return (
        <div className="header-notice__icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 21V7M7 14L21 14"
              stroke="#62CC3D"
              strokeWidth="2.625"
              strokeLinecap="round"
            />
          </svg>
        </div>
      );
    } else if (data.type === ENotificationsType.BalanceCashOut) {
      return (
        <div className="header-notice__icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.33276 18.6666L13.1745 22.5083C13.6301 22.9639 14.3688 22.9639 14.8244 22.5083L18.6661 18.6666M13.9994 22.1666L13.9994 5.83325"
              stroke="#8D5DD9"
              strokeWidth="2.625"
              strokeLinecap="round"
            />
          </svg>
        </div>
      );
    } else if (data.type === ENotificationsType.SupportTicket) {
      return (
        <div className="header-notice__icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.33276"
              y="4.66675"
              width="23.3333"
              height="18.6667"
              rx="4.16667"
              stroke="#A66DFF"
              strokeWidth="2"
            />
            <path
              d="M6.99951 10.5L12.9995 15C13.5921 15.4444 14.4069 15.4444 14.9995 15L20.9995 10.5"
              stroke="#A66DFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    } else if (data.type === ENotificationsType.BalanceRequestCashOut) {
      return (
        <div className="header-notice__icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 15.1666L12.6695 16.9022C13.0874 17.2365 13.6944 17.1825 14.0468 16.7798L17.5 12.8333"
              stroke="#F2BA4D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="14"
              cy="15.1665"
              r="10.5"
              stroke="#F2BA4D"
              strokeWidth="1.5"
            />
            <path
              d="M19.6039 2.33325C22.0814 3.41663 24.1848 5.19639 25.6666 7.42506M8.39593 2.33325C5.91845 3.41663 3.81507 5.19639 2.33325 7.42506"
              stroke="#F2BA4D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M24.5 25.6665L21.8833 22.1665M3.5 25.6665L6.1165 22.1665"
              stroke="#F2BA4D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    } else {
      return null;
    }
  };

  const getPercentage = (price: number, proposePrice: number) => {
    const percentage: any = 100 - (100 / price) * proposePrice;
    return `${percentage > 0 ? '-' : '+'}${Math.abs(percentage).toFixed(0)}`;
  };
  const getContent = t => {
    return (
      <div className="header-notice__content">
        {data.title && (
          <div className="header-notice__title">
            {data.type === ENotificationsType.ItemProposeRejected ? (
              <>{t('proposeWasRejected')}</>
            ) : (
              <>
                {t(data.title)}
                {(data.type === ENotificationsType.ItemPropose ||
                  data.type === ENotificationsType.ItemRequestToAccept ||
                  data.type === ENotificationsType.ItemProposeRejected) &&
                  data.infoLink?.link && (
                    <>
                      {' '}
                      <a
                        href={data.infoLink.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.infoLink.title}
                      </a>
                    </>
                  )}
              </>
            )}
          </div>
        )}
        {data.text && (
          <>
            {data.type === ENotificationsType.SupportTicket ? (
              <div className="header-notice__support">{data.text}</div>
            ) : (
              <div className="header-notice__subtitle">{data.text}</div>
            )}
          </>
        )}
        {(data.type === ENotificationsType.ItemPropose ||
          data.type === ENotificationsType.ItemProposeRejected) &&
          data.info && (
            <div className="header-notice__price-grid">
              {data.info.proposePrice &&
              data.info.proposePrice !== data.info.price ? (
                <>
                  <Text
                    className="header-notice__price"
                    type={
                      data.info.price < data.info.proposePrice
                        ? 'success'
                        : 'danger'
                    }
                    delete
                  >
                    {formatPrice(data.info.price)} USD
                  </Text>

                  <Text
                    className="header-notice__price"
                    type={
                      data.info.price > data.info.proposePrice
                        ? 'success'
                        : 'danger'
                    }
                  >
                    {formatPrice(data.info?.proposePrice)} USD
                  </Text>

                  <Text className="header-notice__percent">
                    ({getPercentage(data.info.price, data.info.proposePrice)}%)
                  </Text>
                </>
              ) : (
                <Text type={'success'}>
                  {formatPrice(data.info.proposePrice)} USD
                </Text>
              )}
            </div>
          )}
        {data.type === ENotificationsType.ItemRequestToAccept && data.info && (
          <div className="header-notice__price-grid">
            {data.info.proposePrice &&
            data.info.proposePrice !== data.info.price ? (
              <>
                <Text
                  className="header-notice__price"
                  type={
                    data.info.price < data.info.proposePrice
                      ? 'success'
                      : 'danger'
                  }
                  delete
                >
                  {formatPrice(data.info.price)} USD
                </Text>

                <Text
                  className="header-notice__price"
                  type={
                    data.info.price > data.info.proposePrice
                      ? 'success'
                      : 'danger'
                  }
                >
                  {formatPrice(data.info?.proposePrice)} USD
                </Text>

                <Text className="header-notice__percent">
                  ({getPercentage(data.info.price, data.info.proposePrice)}%)
                </Text>
              </>
            ) : (
              <>
                {data.info.type === EInventoryPendingType.Sell ? (
                  <Text type={'success'}>
                    +{formatPrice(data.info.proposePrice)} USD
                  </Text>
                ) : (
                  <Text type={'danger'}>
                    -{formatPrice(data.info.proposePrice)} USD
                  </Text>
                )}
              </>
            )}
          </div>
        )}
        {data.type === ENotificationsType.BalanceIncrease && (
          <Text className="header-notice__price" type={'success'}>
            +{formatPrice(data.info?.amount)} USD
          </Text>
        )}
        {data.type === ENotificationsType.BalanceCashOut && (
          <div className="header-notice__price header-notice__balance-out">
            {formatPrice(data.info?.amount)} USD
          </div>
        )}
        {data.type === ENotificationsType.BalanceRequestCashOut && (
          <div className="header-notice__price header-notice__balance-request-out">
            {formatPrice(data.info?.amount)} USD
          </div>
        )}
        {data.type === ENotificationsType.ItemBought && (
          <Text className="header-notice__price" type={'danger'}>
            -{formatPrice(data.info?.proposePrice)} USD
          </Text>
        )}
        {data.type === ENotificationsType.ItemSold && (
          <Text className="header-notice__price" type={'success'}>
            +{formatPrice(data.info?.proposePrice)} USD
          </Text>
        )}
        {data.type === ENotificationsType.ItemRequestToSend && (
          <Text className="header-notice__price" type={'success'}>
            +{formatPrice(data.info?.proposePrice)} USD
          </Text>
        )}
      </div>
    );
  };
  const getAction = () => {
    if (data.type === ENotificationsType.ItemPropose) {
      return (
        <Button
          size={EButtonSizeType.Small}
          type={EButtonTypeType.Text}
          onClick={() => onInventorySale()}
          className={
            'header-notice__bargain ant-btn ant-btn-sm ant-btn-text ant-btn-text--success'
          }
        >
          {t('goToDeals')}
        </Button>
      );
    } else if (
      data.type === ENotificationsType.ItemRequestToAccept &&
      data.actions.length > 0
    ) {
      return (
        <div className="header-notice__offer-actions">
          <>
            {data.actions.map((action, index) => {
              return (
                <Button
                  key={index}
                  size={EButtonSizeType.Small}
                  type={EButtonTypeType.Text}
                  disabled={
                    action.type === ENotificationsActionType.Accept
                      ? isLoadingAcceptTrade
                      : isLoadingRejectTrade
                  }
                  onClick={() => onTradeAction(action.type, data)}
                  className={
                    'header-notice__btn ' +
                    (action.type === 'accept'
                      ? 'ant-btn-text--success'
                      : action.type === 'reject'
                      ? 'ant-btn-text--error'
                      : '')
                  }
                >
                  {action.title}
                </Button>
              );
            })}
          </>
        </div>
      );
    } else if (
      data.type === ENotificationsType.ItemRequestToAccept &&
      data.actions.length === 0
    ) {
      return (
        <Button
          size={EButtonSizeType.Small}
          type={EButtonTypeType.Text}
          onClick={() => onInventoryPending()}
          className={'header-notice__btn ant-btn-text--success'}
        >
          {data.info.type === EInventoryPendingType.Sell
            ? t('notificationsGiveItem')
            : t('notificationsTakeItem')}
        </Button>
      );
    } else if (
      [
        ENotificationsType.BalanceIncrease,
        ENotificationsType.BalanceCashOut,
        ENotificationsType.BalanceRequestCashOut,
        ENotificationsType.ItemBought,
        ENotificationsType.ItemSold,
      ].indexOf(data.type) > -1
    ) {
      return (
        <div className="header-notice__balance">
          {t('balance')}: {formatPrice(data.info?.balance)} USD
        </div>
      );
    } else if (data.type === ENotificationsType.SupportTicket) {
      return (
        <RouterLink
          className="header-notice__chat ant-btn ant-btn-sm ant-btn-text ant-btn-text--success"
          to={`/support/ticket/${data.info?.supportId}`}
        >
          {t('goToChat')}
        </RouterLink>
      );
    } else if (data.type === ENotificationsType.ItemRequestToSend) {
      return (
        <div className="header-notice__user-product-actions">
          {t('left')}{' '}
          <span>
            {moment(data.date)
              .add(1000 * data.info?.timeLeft)
              .format('HH:mm')}
          </span>
          <Tooltip placement="top" title={t('noticeTooltipTitle')}>
            <HelpIcon className="header-notice__user-product-btn-help" />
          </Tooltip>
          <>
            {data.actions.map((action, index) => {
              return (
                <Button
                  key={index}
                  size={EButtonSizeType.Small}
                  type={EButtonTypeType.Text}
                  onClick={() => onTradeAction(action.type, data)}
                  className="header-notice__btn ant-btn-text--success"
                >
                  {action.title}
                </Button>
              );
            })}
          </>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="header-notice">
      <div className="header-notice__body">
        {getImg()}
        {getContent(t)}

        <CloseIcon
          className="header-notice__btn-close"
          onClick={() => onClose()}
        />
      </div>
      <div className="header-notice__footer">
        <span>{moment(data.date).calendar()}</span>
        {data.type === ENotificationsType.ItemRequestToAccept &&
          data.info.type === EInventoryPendingType.Sell && (
            <div className="header-notice__footer-timer">
              Осталось: <InventoryPendingTimer expiredAt={data.expiredAt} />{' '}
              <Tooltip
                overlayClassName={data.id}
                placement="top"
                title={<InfoTooltip text={t('notificationTimerTooltip')} />}
              >
                <NotificationTooltipIcon />
              </Tooltip>
            </div>
          )}
        {getAction()}
      </div>
    </div>
  );
};

export { HeaderNotice };
