import { create } from 'zustand';

type ExtState = {
  isExtInstalled: boolean;
  isExtOnline: boolean;
  sSetExtInstallStatus: (isExtInstalled: boolean) => void;
  sSetExtOnlineStatus: (isExtOnline: boolean) => void;
};

const useExtStore = create<ExtState>((set, get) => ({
  isExtInstalled: false,
  isExtOnline: false,
  sSetExtInstallStatus: isExtInstalled => {
    set(state => ({
      isExtInstalled,
    }));
  },
  sSetExtOnlineStatus: isExtOnline => {
    set(state => ({
      isExtOnline,
    }));
  },
}));
export { useExtStore };
