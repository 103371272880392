import { useQuery } from '@tanstack/react-query';

// services
import { GET_INVENTORY_ITEMS_BY_NAME } from 'api/inventory';

export const useQuerySearchInventoryItemsByName = ({
  params,
  steamId,
  enabled,
}) => {
  return useQuery(
    ['useQuerySearchInventoryItemsByName', params],
    () => GET_INVENTORY_ITEMS_BY_NAME(params, steamId),
    {
      enabled,
      onSuccess: data => {},
      onError: (err: Error) => {
        const message = err ? err?.message : 'Error while fetching Profile';
        console.error(message);
        return message;
      },
    },
  );
};
