import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    let { hash, state } = location;
    if (state?.preventScrollReset) {
      return undefined;
    }

    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        window.scrollTo({
          left: 0,
          top: element!.offsetTop,
          behavior: 'smooth',
        });
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    }
  }, [location]);
}
