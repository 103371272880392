import { useMutation } from '@tanstack/react-query';
import { ACCEPT_TRADE } from '../../../api/inventory';

export const useMutationAcceptTrade = () => {
  return useMutation(ACCEPT_TRADE, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while accept trade';
      console.error(message);
      return message;
    },
  });
};
