import * as React from 'react';

export enum ERoutePermissionsType {
  Auth = 'auth',
}

export interface IPropsType {
  children: React.ReactElement;
  permissions: ERoutePermissionsType;
}
