import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { CLOSE_ACTIVE_SESSIONS } from '../../../api/profile';

export const useMutateCloseSessions = () => {
  return useMutation(CLOSE_ACTIVE_SESSIONS, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while close sessions';
      console.error(message);
      return message;
    },
  });
};
