import { useQuery } from '@tanstack/react-query';
import { GET_STEAM_SETTINGS } from '../../../api/profile';

//store
import { useAuthStore } from 'store';
export const useQueryFetchSteamSettings = () => {
  const { sSetSteamId } = useAuthStore();
  return useQuery(['useGetSteamSettings'], () => GET_STEAM_SETTINGS(), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      if (data?.steamId) {
        sSetSteamId(data?.steamId);
      }
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Article';
      console.error(message);
      return message;
    },
  });
};
