const StatIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 9V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H16"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 23 2)"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M7 14.75L10.5 10.75L13.5 13.25L17 9.25"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { StatIcon };
