// core
import React from 'react';

// utils

// store

// types
import { IPropsType } from './types';

// components
import { SuccessIcon } from 'components/icons';

// styles
import './index.scss';

const StickerFilterItem = ({ isChose, data, onChange }: IPropsType) => {
  const onChangeItem = () => {
    onChange(isChose);
  };

  return (
    <div
      className="sticker-filter-item"
      onClick={() => {
        onChangeItem();
      }}
    >
      <div className="sticker-filter-item__img">
        <img src={data.image} alt={data.title} />
      </div>
      <div className="sticker-filter-item__content">
        <div className="sticker-filter-item__title">{data.title}</div>
        {/*<div className="sticker-filter-item__type">!!!type!!!</div>*/}
      </div>
      <div className="sticker-filter-item__actions">
        {isChose && <SuccessIcon />}
      </div>
    </div>
  );
};
export { StickerFilterItem };
