import { useQuery } from '@tanstack/react-query';

//types
import { TTopProductsRequestParams } from 'types/api/market';

// services
import { GET_TOP_PRODUCTS } from 'api/market';

export const useQueryFetchTopProducts = (query: TTopProductsRequestParams) => {
  return useQuery(['useGetTopProducts', query], () => GET_TOP_PRODUCTS(query), {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching top products';
      console.error(message);
      return message;
    },
  });
};
