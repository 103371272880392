import { useCallback, useEffect } from 'react';
function useFixMissingScroll({
  scrollableRef,
  isHasMoreItems,
  isFetchingNextInventoryItems,
  fetchNextPage,
}) {
  const fetchCb = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (scrollableRef?.current) {
      let element = scrollableRef.current;
      const isHasScroll = element
        ? element.scrollHeight > element.clientHeight
        : false;
      if (!isHasScroll && isHasMoreItems && !isFetchingNextInventoryItems) {
        setTimeout(() => {
          fetchCb();
        }, 100);
      }
    }
  }, [scrollableRef, fetchCb, isHasMoreItems, isFetchingNextInventoryItems]);
}

export default useFixMissingScroll;
