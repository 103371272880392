// core
import React from 'react';

//locales
import { useTranslation } from 'react-i18next';

//hooks

// utils

// locales

// types
import { EGameType } from 'types/models';

// components(atoms)
import { HomeAssortmentBlock } from '../HomeAssortmentBlock';
/*@ts-ignore*/
import HomeAssortmentBlockCS from 'assets/img/HomeAssortmentBlockCS.png';
/*@ts-ignore*/
import HomeAssortmentBlockDota from 'assets/img/HomeAssortmentBlockDota.png';

// styles
import './index.scss';

//tempo

// func
const HomeAssortment = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="home-assortment">
        <div className="app-container">
          <div className="home-assortment__wrap">
            <div className="home-assortment__title">
              {t('assortmentOfItems')}
            </div>
            <div className="home-assortment__desc">
              {t('popularItemsForYou')}
            </div>
            <div className="home-assortment__block-wrap">
              <div className="home-assortment__block">
                <HomeAssortmentBlock
                  title={'CS2'}
                  background={HomeAssortmentBlockCS}
                  gameType={EGameType.CSGO}
                />
              </div>
              <div className="home-assortment__block">
                <HomeAssortmentBlock
                  title={'DOTA 2'}
                  background={HomeAssortmentBlockDota}
                  gameType={EGameType.Dota}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeAssortment };
