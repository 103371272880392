const LinkIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4142 13.7279L18.5355 11.6066C20.4882 9.65398 20.4882 6.48816 18.5355 4.53553V4.53553C16.5829 2.58291 13.4171 2.58291 11.4645 4.53553L9.34315 6.65685M13.5858 16.5563L11.4645 18.6777C9.51184 20.6303 6.34602 20.6303 4.3934 18.6777V18.6777C2.44078 16.725 2.44078 13.5592 4.3934 11.6066L6.51472 9.48528"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M13.5859 9.48539L9.34326 13.728"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { LinkIcon };
