import { useMutation } from '@tanstack/react-query';
//types

// services
import { POST_INVENTORY_ALL_ITEMS } from 'api/inventory';

export const useMutationInventoryAllProducts = () => {
  return useMutation(POST_INVENTORY_ALL_ITEMS, {
    onSuccess: (data, variables, context) => {
      // After success call
    },
    onError: (err: Error) => {
      // After error call
      const message = err ? err?.message : 'Error while fetching Profile';
      console.error(message);
      return message;
    },

    onSettled: (data, error, variables, context) => {
      // After error or success call... doesn't matter!
    },
  });
};
