import React from 'react';
// core

// utils
// locales
//store

// types
import { IPropsType } from './types';

// components(atoms)

// styles
import './index.scss';

// data

// func

const InfoTooltip = ({ className, text }: IPropsType) => {
  return (
    <div className={'info-tooltip ' + (className ? className : '')}>{text}</div>
  );
};

export { InfoTooltip };
