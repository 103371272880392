const CopyIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.8 8.8V7.2C8.8 5.43269 10.2327 4 12 4L16.8 4C18.5673 4 20 5.43269 20 7.2V12C20 13.7673 18.5673 15.2 16.8 15.2H15.2M8.8 8.8H7.2C5.43269 8.8 4 10.2327 4 12V16.8C4 18.5673 5.43269 20 7.2 20H12C13.7673 20 15.2 18.5673 15.2 16.8V15.2M8.8 8.8H12C13.7673 8.8 15.2 10.2327 15.2 12V15.2"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </svg>
);

export { CopyIcon };
