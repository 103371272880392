import moment, { MomentInput } from 'moment';

export const dateTypes: Array<{
  value: string;
  range: any;
}> = [
  {
    value: 'week',
    range: {
      from: moment().subtract(1, 'week').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
  {
    value: 'month',
    range: {
      from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
  {
    value: 'month6',
    range: {
      from: moment().subtract(6, 'month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
  {
    value: 'year',
    range: {
      from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
  {
    value: 'all',
    range: '',
  },
];
