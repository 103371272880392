import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// utils

// locales

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, ESelectSizeType } from 'types/units';

// components(atoms)
import { Button, Space, Select, Form } from 'antd';
import { DashboardBlock } from '../DashboardBlock';
import { ArrowDownIcon } from 'components/icons';
import { MobileBottomNav } from 'components/features';
import { DashboardHead } from '../DashboardHead';

// styles
import './index.scss';

// data
import { languagesList } from 'locales/data';
import { yupSync } from './schema';
const { Option } = Select;

// func
const DashboardLanguage = () => {
  const { sSetCurrentCurrency, currentCurrency, currencyList } = useAppStore();

  const { i18n, t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (currentCurrency?.sku && currencyList) {
      form.setFieldsValue({ currency: currentCurrency.sku });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrency, currencyList]);

  const onChangeLocale = language => {
    i18n.changeLanguage(language);
    window.location.reload();
  };

  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  // remove current language
  const updatedLangList = languagesList.map(lang => {
    return {
      label: lang.short,
      value: lang.id,
      data: lang,
    };
  });

  // current locale language
  const currentLanguage = languagesList.find(
    lang => lang.id === localization,
  )?.id;
  const onChangeCurrency = (value: string) => {
    sSetCurrentCurrency(currencyList.find(el => el.sku === value));
  };

  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        <DashboardHead title={t('languageAndCurrency')}>
          <></>
        </DashboardHead>
        <DashboardBlock
          title={t('languageAndCurrencyTitle')}
          description={t('languageAndCurrencyDesc')}
        >
          <Form form={form}>
            <div className="dashboard__main--form">
              <div className="dashboard__main--form-connector">
                <div className="dashboard__main--form-input select">
                  <Space.Compact
                    className="ant-space-compact--combined"
                    style={{ width: '100%' }}
                  >
                    <div className="ant-space-compact--prepend">
                      {t('language')}
                    </div>
                    <Select
                      className="ant-select--light"
                      size={ESelectSizeType.Middle}
                      suffixIcon={<ArrowDownIcon />}
                      defaultValue={currentLanguage}
                      onChange={onChangeLocale}
                      dropdownAlign={{ offset: [0, 0] }}
                    >
                      {updatedLangList?.length &&
                        updatedLangList.map(lang => {
                          return (
                            <Option
                              value={lang.value}
                              label={lang.label}
                              key={lang.value}
                            >
                              <span
                                role="img"
                                className="lang-toggler__option-flag"
                                aria-label={lang.label}
                              >
                                {lang.data.flag}
                              </span>
                              {lang.label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space.Compact>
                </div>
                <div className="dashboard__main--form-input select">
                  <Space.Compact
                    className="ant-space-compact--combined"
                    style={{ width: '100%' }}
                  >
                    <div className="ant-space-compact--prepend">
                      {t('currency')}
                    </div>
                    <Form.Item
                      name={'currency'}
                      /*@ts-ignore*/
                      rules={[yupSync]}
                    >
                      <Select
                        className="ant-select--light"
                        size={ESelectSizeType.Middle}
                        suffixIcon={<ArrowDownIcon />}
                        onChange={onChangeCurrency}
                        dropdownAlign={{ offset: [0, 0] }}
                      >
                        {currencyList?.length &&
                          currencyList.map(currency => {
                            return (
                              <Option
                                value={currency.sku}
                                label={currency.name}
                                key={currency.sku}
                              >
                                {currency.sign} {currency.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </div>
              </div>
            </div>
          </Form>
        </DashboardBlock>
      </div>
    </div>
  );
};
export { DashboardLanguage };
