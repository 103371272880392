import { useQuery } from '@tanstack/react-query';

//types

// services
import { GET_AUTH_LINK } from 'api/auth';

export const useQueryGetAuthLink = enabled => {
  return useQuery(['useGetAuthLink'], () => GET_AUTH_LINK(), {
    enabled,
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching auth link';
      console.error(message);
      return message;
    },
  });
};
