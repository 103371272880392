const ChromeIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_549_51272)">
      <path
        d="M12 22.5C17.796 22.5 22.5 17.796 22.5 12C22.5 6.204 17.796 1.5 12 1.5C6.204 1.5 1.5 6.204 1.5 12C1.5 17.796 6.204 22.5 12 22.5Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0007 16.1998C14.3212 16.1998 16.2007 14.3203 16.2007 11.9998C16.2007 9.6793 14.3212 7.7998 12.0007 7.7998C9.68016 7.7998 7.80066 9.6793 7.80066 11.9998C7.80066 14.3203 9.68016 16.1998 12.0007 16.1998Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6285 7.80002C18.4575 7.10702 15.171 7.10702 12 7.80002"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.54761 5.76318L3.56861 5.82618C4.62911 8.86068 6.25661 11.6747 8.36711 14.1002"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8247 22.4371C12.9877 20.0536 14.6152 17.2396 15.6022 14.1841L15.6337 14.1001"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_549_51272">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ChromeIcon };
