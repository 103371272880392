const Link2Icon = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3865 17.2805C15.209 19.103 18.1637 19.103 19.9862 17.2805L24.7002 12.5665C26.5227 10.744 26.5227 7.78927 24.7002 5.96682C22.8778 4.14438 19.923 4.14438 18.1006 5.96682L15.7435 8.32385"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.1579 13.5095C15.3355 11.6871 12.3807 11.6871 10.5583 13.5095L5.84421 18.2236C4.02177 20.046 4.02177 23.0008 5.84421 24.8232C7.66666 26.6457 10.6214 26.6457 12.4439 24.8232L14.8009 22.4662"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export { Link2Icon };
