import React, { useEffect, useState } from 'react';

// locale

//stores

// types
import { IPropsType } from './types';
// styles
import { ScrollTopArrowIcon } from 'components/icons';
import './index.scss';

//data
const ScrollToTop = ({
  placement,
  customShow,
  customShowFlag,
  customSelector,
}: IPropsType) => {
  const [showButton, setShowButton] = useState<any>(false);
  function scrollToTop() {
    if (!customShow) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      let container = document.getElementById(customSelector);
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }
  function updateScrollState() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    setShowButton(scrollPosition >= window.innerHeight / 2);
  }

  if (!customShow) {
    window.addEventListener('scroll', updateScrollState);
  }
  useEffect(() => {
    if (customShow) {
      setShowButton(customShowFlag);
    }
  }, [customShow, customShowFlag]);
  useEffect(() => {
    if (!customShow) {
      return () => {
        window.removeEventListener('scroll', updateScrollState);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      type="button"
      onClick={scrollToTop}
      className={`scroll-to-top ${showButton ? 'active' : ''} ${
        customShow ? 'absolute' : ''
      } ${placement}`}
    >
      <ScrollTopArrowIcon />
    </button>
  );
};

ScrollToTop.defaultProps = {
  placement: '',
  customShowFlag: false,
  customShow: false,
  customSelector: '',
} as { placement: string };

export { ScrollToTop };
