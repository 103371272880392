import * as yup from 'yup';

export const createSteamSchema = yup.object().shape({
  currency: yup.string(),
});

export const yupSync = {
  async validator({ field }, value) {
    await createSteamSchema.validateSyncAt(field, { [field]: value });
  },
};
