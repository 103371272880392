// core
import React from 'react';
import { useNavigate } from 'react-router-dom';
//hooks

//store
import { useAppStore } from 'store';

// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components(atoms)
import { Button } from 'antd';

// styles
import './../index.scss';

//data

const SupportModalDone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sModalClose } = useAppStore();
  const onDone = () => {
    sModalClose('SupportModalDone');
    navigate('/support/tickets');
  };
  return (
    <div className="support-modal">
      <div className="support-modal__wrap">
        <div className="support-modal__content">
          <div className="support-modal__content-title">
            {t('modalDoneTitle')}
          </div>
          <div className="support-modal__content-desc">
            {t('modalDoneDesc')}
          </div>
        </div>
        <div className="support-modal__actions support-modal__actions--closable">
          <Button
            size={EButtonSizeType.Middle}
            type={EButtonTypeType.Primary}
            ghost
            onClick={() => {
              onDone();
            }}
          >
            {t('modalDoneBtn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export { SupportModalDone };
