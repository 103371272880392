import React from 'react';
import { Select } from 'antd';

// locale
import { useTranslation } from 'react-i18next';

// types
import { IPropsType } from './types';

// components

// styles
import './index.scss';
import { AppLoader } from 'components/atoms';
import { ELoaderTypeType } from '../../../atoms/AppLoader/types';
import { ArrowDownIcon, CloseIcon } from 'components/icons';

//data

const SelectSearchFilter = ({ data, current, onChange }: IPropsType) => {
  const { t } = useTranslation();

  const onChangeFilter = (value: string) => {
    onChange(value ? [value] : []);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div className="select-search-filter">
      <Select
        className="ant-select--light"
        showSearch
        placeholder={t('selectItem')}
        optionFilterProp="children"
        value={current}
        onChange={onChangeFilter}
        filterOption={filterOption}
        dropdownAlign={{ offset: [0, 0] }}
        options={data.map(i => {
          return { label: i.title, value: i.value };
        })}
        // @ts-ignore
        allowClear
        suffixIcon={<ArrowDownIcon />}
        notFoundContent={
          <div className="select-search-filter__not-found">
            {t('nothingFound')}
          </div>
        }
      />
    </div>
  );
};
export { SelectSearchFilter };
