import React, { useEffect, useState } from 'react';
// types
import { Button } from 'antd';

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore } from 'store';
//hooks
import { useQueryFetchProductTrades } from '../../../../hooks/api';
// types
import { EButtonTypeType } from 'types/units';

// components
import { ModalTeleport } from 'components/atoms';
import { NewTradeModal, RequestComparisonModal } from 'components/features';
// styles
import './index.scss';
import { formatPrice } from '../../../../utils/commons';

//data

const ProductRequests = ({ product, onLoaded }) => {
  const { t } = useTranslation();
  const { accessToken: isAuth } = useAuthStore();
  const { sModalOpen } = useAppStore();

  const [selectedRequest, setSelectedRequest] = useState<any>(null);

  const [tradesQuery, setTradesQuery] = useState({
    nameSearch: product.marketName.second.length
      ? product.marketName.first + ' | ' + product.marketName.second
      : product.marketName.first,
  });

  const { data: requestList, isLoading: requestListLoading } =
    useQueryFetchProductTrades(tradesQuery);

  useEffect(() => {
    if (!requestListLoading && requestList) {
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList, requestListLoading]);

  const onCreateRequest = () => {
    if (isAuth) {
      sModalOpen('newTrade');
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onShowRequests = el => {
    if (isAuth) {
      setSelectedRequest(el);
      sModalOpen('requestComparison');
    } else {
      sModalOpen('steamAuth');
    }
  };

  return (
    <div className="product-requests" id="purchase_requests">
      <div className="product-requests__head">
        <div className="product-requests__title">{t('productRequest')}</div>
        <div className="product-requests__actions">
          <Button
            type={EButtonTypeType.Primary}
            className="product-requests__btn-create"
            onClick={() => {
              onCreateRequest();
            }}
          >
            {t('productCreateRequest')}
          </Button>
        </div>
      </div>
      <div className="product-requests__grid">
        {!requestListLoading && requestList?.length ? (
          requestList.map((el, index) => {
            return (
              <div
                className="product-requests__card"
                onClick={() => onShowRequests(el)}
                key={index}
              >
                <div className="product-requests__card-title">
                  <span>
                    {el.isAverage ? t('more') : ''} $ {formatPrice(el.price)}
                  </span>
                </div>
                <div className="product-requests__card-text">
                  {el.tradeCount} {t('productRequests')}
                </div>
              </div>
            );
          })
        ) : (
          <div className="product-requests__grid--empty">
            {t('emptyRequestDesc')}
          </div>
        )}
      </div>
      <ModalTeleport
        centered
        modalId={'newTrade'}
        width={'auto'}
        modalClass={'new-save-modal__wrapper'}
      >
        <NewTradeModal
          onTradeCreated={() => {}}
          modalId={'newTrade'}
          data={product}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'requestComparison'}
        width={1300}
        modalClass={'request-trade-modal__wrapper'}
      >
        <RequestComparisonModal
          name={
            product.marketName.second.length
              ? product.marketName.first + ' | ' + product.marketName.second
              : product.marketName.first
          }
          request={selectedRequest}
          modalId={'requestComparison'}
        />
      </ModalTeleport>
    </div>
  );
};

export { ProductRequests };
