export const CSGOItem: {
  title: string;
  weapon: string;
  id: number;
  price: number;
  sale: number;
  float: number;
  image: string;
  rarity: string;
  stickers: Array<any>;
  quality: string;
} = {
  quality: 'FN',
  stickers: [
    {
      id: 1,
      title: 'tornado',
      image:
        'https://s-wiki.cs.money/csgo/econ/stickers/bf2042/tornado_chaos_foil_large.png',
    },
    {
      id: 2,
      title: 'howl',
      image:
        'https://s-wiki.cs.money/csgo/econ/stickers/community01/howling_dawn_large.png',
    },
    {
      id: 3,
      title: 'tornado',
      image:
        'https://s-wiki.cs.money/csgo/econ/stickers/bf2042/tornado_chaos_foil_large.png',
    },
    {
      id: 4,
      title: 'howl',
      image:
        'https://s-wiki.cs.money/csgo/econ/stickers/community01/howling_dawn_large.png',
    },
  ],
  rarity: 'restricted',
  float: 0.3232,
  sale: 38,
  price: 337.25,
  title: 'Гипноз',
  weapon: 'Desert Eagle',
  image:
    'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLJTitH_si_k4-0m_7zO6-fzj5QuZN03uvH99T32Ve3_UBlYDqiIdKVIQBqYgnRr1frx-7thpW-v4OJlyUwDcxXZA',
  id: 2,
};

export const DOTAItem: {
  title: string;
  slot: string;
  id: number;
  price: number;
  sale: number;
  image: string;
  rarity: string;
  gems: Array<any>;
} = {
  gems: [
    {
      id: 1,
      title: 'tornado',
      image:
        'https://steamcdn-a.akamaihd.net/apps/570/icons/econ/sockets/gem_animation.5382a038dccfcc17afca6e4c429993fed96a5482.png',
    },
    {
      id: 2,
      title: 'howl',
      image:
        'https://steamcdn-a.akamaihd.net/apps/570/icons/econ/sockets/gem_spectator.9dbff8e11aea0425dc376bec672c03dc92f7bab9.png',
    },
    {
      id: 3,
      title: 'tornado',
      image:
        'https://steamcdn-a.akamaihd.net/apps/570/icons/econ/sockets/gem_animation.5382a038dccfcc17afca6e4c429993fed96a5482.png',
    },
    {
      id: 4,
      title: 'howl',
      image:
        'https://steamcdn-a.akamaihd.net/apps/570/icons/econ/sockets/gem_spectator.9dbff8e11aea0425dc376bec672c03dc92f7bab9.png',
    },
  ],
  rarity: 'immortal',
  sale: 38,
  price: 337.25,
  title: 'Righteous Thunderbolt',
  slot: 'Оружие',
  image:
    'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUorA5OA0DZQOy52cbdVGJjIAVHubTqJgJz27zSdChR09C_k4ifmf76NITGk2pB58tOh-bF8IC7iQWxrUBqYTr1ddCcJANrMFyDqAW5w-jp15a_upvMyCEwvih3sHmJlgv330-giAy4aA',
  id: 2,
};
