const GridDefaultIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.5"
      y="1.5"
      width="7"
      height="7"
      rx="2.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="1.5"
      y="11.5"
      width="7"
      height="7"
      rx="2.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="11.5"
      y="1.5"
      width="7"
      height="7"
      rx="2.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="11.5"
      y="11.5"
      width="7"
      height="7"
      rx="2.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export { GridDefaultIcon };
