// core
import React from 'react';
import { Button, Input, Form, InputNumber, Tooltip } from 'antd';
import { MEDIA } from 'utils/constants';
import { formatFloat, formatPrice } from 'utils/commons';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
// utils

// locales

// store

import { useAppStore, useInventoryStore } from 'store';

// types
import { IPropsType } from './types';
import { EGameType } from 'types/models';
import { EInputSizeType, EInputNumberSizeType } from 'types/units';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';

// store

// components
import { TrashIcon, MinusIcon, PlusIcon, EyeIcon } from 'components/icons';
import { FloatRange } from 'components/atoms';
import { StickerTooltip } from 'components/features';

// styles
import './index.scss';

const getPrices = (minMarketPrice, minAutoBuyPrice, t) => {
  return (
    <div className="inventory-expose-item__prices">
      <div className="inventory-expose-item__price">
        {t('minPrice')}
        <span>
          {minMarketPrice ? `$${formatPrice(minMarketPrice)}` : '$0.01'}
        </span>
      </div>
      <div className="inventory-expose-item__price">
        {t('autobuy')}
        <span>
          {minAutoBuyPrice ? `$${formatPrice(minAutoBuyPrice)}` : '---'}
        </span>
      </div>
    </div>
  );
};

const getStickers = (collection, sku, gameType) => {
  return (
    <div className="inventory-expose-item__stickers inventory-expose-item__stickers--small">
      {collection.map(item => (
        <Tooltip
          overlayClassName={'ant-tooltip--small'}
          placement="top"
          title={
            gameType === EGameType.CSGO ? (
              <StickerTooltip
                small
                title={item.title}
                price={item.valueExtra}
              />
            ) : (
              <StickerTooltip
                small
                title={item.title}
                description={item.valueExtra}
              />
            )
          }
        >
          <img
            src={item.preview}
            key={`${sku}__${item.title.replace(/\s+/g, '')}`}
            alt={item.title}
            className="inventory-expose-item__sticker"
          />
        </Tooltip>
      ))}
    </div>
  );
};
const InventoryExposeItem = ({ data, windowWidth, onDelete }: IPropsType) => {
  const { t } = useTranslation();
  const { gameType, commission_percentage, sModalOpen } = useAppStore();
  const { inventoryExpose, sSetInventoryExpose, sSetInventoryExposeModal } =
    useInventoryStore();

  const [form] = Form.useForm();

  const exposeProductSchema = yup.object().shape({
    buyerPrice: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validationAmount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validationRequired'))
      .min(0.03, t('validationMinValue', { min: 0.03 }))
      .max(99999, t('validationMaxValue', { max: 99999 }))
      .typeError(t('validationIsNumeric')),
    yourPrice: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validationAmount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .min(0.03, t('validationMinValue', { min: 0.03 }))
      .max(99999, t('validationMaxValue', { max: 99999 }))
      .required(t('validationRequired'))
      .positive(t('validationPositive'))
      .typeError(t('validationIsNumeric')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await exposeProductSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const onShowDetails = () => {
    sSetInventoryExposeModal(data);
    sModalOpen('sellProduct');
  };

  const onDeleteItem = () => {
    onDelete(data.id);
  };

  const onIncrease = () => {
    data._saleCount++;
    let index = inventoryExpose.findIndex(item => item.id === data.id);
    if (index > -1) {
      inventoryExpose[index] = data;
      sSetInventoryExpose(inventoryExpose);
    }
  };

  const onDecrease = () => {
    data._saleCount--;
    let index = inventoryExpose.findIndex(item => item.id === data.id);
    if (index > -1) {
      inventoryExpose[index] = data;
      sSetInventoryExpose(inventoryExpose);
    }
  };

  const onChangeCount = value => {
    if (value) {
      data._saleCount = value;
      let index = inventoryExpose.findIndex(item => item.id === data.id);
      if (index > -1) {
        inventoryExpose[index] = data;
        sSetInventoryExpose(inventoryExpose);
      }
    }
  };

  const onChangeBuyerPrice = ({ target: { value } }) => {
    let numberValue = Number(value) ? Number(value) : 0;
    let yourPrice = (numberValue * (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('yourPrice', yourPrice);
    let index = inventoryExpose.findIndex(item => item.id === data.id);
    if (index > -1) {
      data.price = numberValue;
      data._netPrice = yourPrice;
      inventoryExpose[index] = data;
      sSetInventoryExpose(inventoryExpose);
    }
  };

  const onChangeYourPrice = ({ target: { value } }) => {
    let numberValue = Number(value) ? Number(value) : 0;
    let buyerPrice = (numberValue / (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('buyerPrice', buyerPrice);
    let index = inventoryExpose.findIndex(item => item.id === data.id);
    if (index > -1) {
      data.price = Number(buyerPrice);
      data._netPrice = numberValue;
      inventoryExpose[index] = data;
      sSetInventoryExpose(inventoryExpose);
    }
  };
  const onSetMinPrice = () => {
    let marketPrice = data.minMarketPrice ? data.minMarketPrice : 0.01;

    form.setFieldValue('buyerPrice', marketPrice.toFixed(2));

    let yourPrice = (marketPrice * (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('yourPrice', yourPrice);

    let index = inventoryExpose.findIndex(item => item.id === data.id);

    if (index > -1) {
      data.price = marketPrice;
      data._netPrice = yourPrice;
      inventoryExpose[index] = data;
      sSetInventoryExpose(inventoryExpose);
    }
  };

  // @ts-ignore
  return (
    <div className="inventory-expose-item">
      <div className="inventory-expose-item__left">
        <div
          className="inventory-expose-item__img"
          onClick={() => onShowDetails()}
        >
          <img src={data.preview} alt={data.name} />
          <div className="inventory-expose-item__img-mask">
            <EyeIcon />
          </div>
        </div>
        <div className="inventory-expose-item__info">
          <div className="inventory-expose-item__title">
            {data.marketName.first}
          </div>
          <div className="inventory-expose-item__label">
            {data.marketName.second}
          </div>
          <div className="inventory-expose-item__content">
            {gameType === EGameType.CSGO ? (
              <>
                {data.float > 0 && (
                  <div className="inventory-expose-item__float">
                    <div className="inventory-expose-item__float-grid">
                      {data.shortenExterior}
                      <span>{formatFloat(data.float)}</span>
                    </div>
                    <FloatRange
                      isTooltip={true}
                      size={EFloatRangeSizeType.Small}
                      maxValue={data.float}
                    />
                  </div>
                )}

                {data.stickers?.length > 0 &&
                  getStickers(data.stickers, data.sku, gameType)}
              </>
            ) : (
              <>
                {data.gems?.length > 0 &&
                  getStickers(data.gems, data.sku, gameType)}
              </>
            )}
            {/*<>*/}
            {/*  <div className="inventory-expose-item__label">{data.slot}</div>*/}
            {/*</>*/}

            {windowWidth > MEDIA.M768 &&
              getPrices(data.minMarketPrice, data.minAutoBuyPrice, t)}
          </div>
        </div>
      </div>
      {windowWidth <= MEDIA.M768 &&
        getPrices(data.minMarketPrice, data.minAutoBuyPrice, t)}
      <Form
        form={form}
        layout="vertical"
        className="inventory-expose-item__right"
        initialValues={{
          buyerPrice: formatPrice(data.price, 2, false),
          yourPrice: formatPrice(
            data.price - data.price * commission_percentage,
            2,
            false,
          ),
        }}
      >
        {/*@ts-ignore*/}
        {(values, formInstance) => {
          return (
            <>
              <div className="inventory-expose-item__input">
                <label>{t('buyerPays')}</label>
                <Form.Item
                  className="ant-form-item--flat"
                  name={'buyerPrice'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                >
                  <Input
                    size={EInputSizeType.Small}
                    prefix={'$'}
                    onChange={onChangeBuyerPrice}
                    suffix={
                      <Button
                        className={
                          'ant-btn-default--light inventory-expose-item__input-btn'
                        }
                        onClick={() => onSetMinPrice()}
                      >
                        {t('minPrice')}
                      </Button>
                    }
                  />
                </Form.Item>
              </div>
              <div className="inventory-expose-item__input">
                <label>{t('youGet')}</label>
                <Form.Item
                  className="ant-form-item--flat"
                  name={'yourPrice'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                >
                  <Input
                    size={EInputSizeType.Small}
                    prefix={'$'}
                    onChange={onChangeYourPrice}
                    suffix={
                      <Button
                        className={
                          'ant-btn-default--light inventory-expose-item__input-btn'
                        }
                        onClick={() => onSetMinPrice()}
                      >
                        {t('minPrice')}
                      </Button>
                    }
                  />
                </Form.Item>
              </div>

              {(windowWidth > MEDIA.M1024 || inventoryExpose.length > 1) && (
                <div className="inventory-expose-item__form-grid">
                  <div className="inventory-expose-item__input">
                    <label>{t('autobuyCount')}</label>
                    <div className="inventory-expose-item__counter">
                      <Button
                        ghost
                        className="ant-btn-ghost--grey inventory-expose-item__btn-count"
                        icon={<MinusIcon />}
                        disabled={data._saleCount === 1}
                        onClick={() => onDecrease()}
                      />
                      <InputNumber
                        controls={false}
                        className="inventory-expose-item__input-count"
                        size={EInputNumberSizeType.Small}
                        min={1}
                        max={data.count}
                        value={data._saleCount}
                        onChange={onChangeCount}
                      />
                      <Button
                        ghost
                        className="ant-btn-ghost--grey inventory-expose-item__btn-count"
                        icon={<PlusIcon />}
                        disabled={data._saleCount >= data.count}
                        onClick={() => onIncrease()}
                      />
                    </div>
                  </div>
                  <Button
                    ghost
                    className={
                      'ant-btn-ghost--error inventory-expose-item__btn-delete'
                    }
                    icon={<TrashIcon />}
                    onClick={() => onDeleteItem()}
                  />
                </div>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};
export { InventoryExposeItem };
