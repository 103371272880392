import React from 'react';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
import { EGameType } from 'types/models/game';
// components(atoms)

// styles
import './index.scss';
import { Tooltip } from 'antd';
import { StickerTooltip } from 'components/features';

// data

// func
const getCellStikers = (collection, gameType) => {
  if (gameType === EGameType.CSGO) {
    return collection.map((item, index) => (
      <Tooltip
        key={index}
        overlayClassName={item.title}
        placement="top"
        title={<StickerTooltip title={item.title} price={item.price} />}
      >
        <img src={item.image} alt={item.title} />
      </Tooltip>
    ));
  } else {
    return collection.map((item, index) => (
      <Tooltip
        key={index}
        overlayClassName={item.title}
        placement="top"
        title={
          <StickerTooltip title={item.title} description={item.description} />
        }
      >
        <img src={item.image} alt={item.title} />
      </Tooltip>
    ));
  }
};

const TableCellStickers = ({ collection, gameType }: IPropsType) => {
  return (
    <div
      className={
        'table-cell-stickers' +
        (gameType === EGameType.CSGO
          ? ' table-cell-stickers--csgo'
          : ' table-cell-stickers--dota')
      }
    >
      {getCellStikers(collection, gameType)}
    </div>
  );
};

TableCellStickers.defaultProps = {
  gameType: EGameType.CSGO,
} as { gameType: EGameType };

export { TableCellStickers };
