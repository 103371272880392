const BagIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6.42871C2.89543 6.42871 2 7.32414 2 8.42871V10.0835C2 10.9667 2.57934 11.7453 3.42531 11.9991L10.8506 14.2267C11.6004 14.4516 12.3996 14.4516 13.1494 14.2267L20.5747 11.9991C21.4207 11.7453 22 10.9667 22 10.0835V8.42871C22 7.32414 21.1046 6.42871 20 6.42871H4ZM12.75 9.85742C12.75 9.44321 12.4142 9.10742 12 9.10742C11.5858 9.10742 11.25 9.44321 11.25 9.85742V11.5717C11.25 11.9859 11.5858 12.3217 12 12.3217C12.4142 12.3217 12.75 11.9859 12.75 11.5717V9.85742Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0835 13.0123V16.9996C2.0835 19.623 4.21015 21.7496 6.8335 21.7496H17.1668C19.7902 21.7496 21.9168 19.623 21.9168 16.9996V13.0121C21.6401 13.1932 21.3344 13.3371 21.0057 13.4357L13.5804 15.6633C12.5495 15.9725 11.4505 15.9725 10.4196 15.6633L2.99429 13.4357C2.66571 13.3371 2.36012 13.1933 2.0835 13.0123ZM2.14511 10.8315L12.0002 12.4998L21.8549 10.8316C21.6323 11.3828 21.1694 11.8205 20.5747 11.999L13.1494 14.2265C12.3996 14.4515 11.6004 14.4515 10.8506 14.2265L3.42531 11.999C2.83058 11.8205 2.36763 11.3827 2.14511 10.8315ZM11.9995 13.9997L12.0001 13.9998L12 13.9998L11.9995 13.9997Z"
      fill="currentColor"
    />
    <path
      d="M15.3333 6.42855V5C15.3333 3.89543 14.4379 3 13.3333 3H10.6666C9.56206 3 8.66663 3.89543 8.66663 5L8.66663 6.42855"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export { BagIcon };
