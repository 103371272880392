const YoutubeIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.0632 5.00133C22.1961 3.97165 20.5935 3.55359 17.5432 3.55359H6.45677C3.32903 3.55359 1.70323 4.00262 0.843871 5.10198C0 6.17036 0 7.74198 0 9.9252V14.0826C0 18.3097 0.99871 20.4465 6.45677 20.4465H17.5432C20.191 20.4465 21.6619 20.0749 22.6065 19.1691C23.5819 18.24 23.9923 16.7149 23.9923 14.0826V9.9252C24 7.62585 23.9381 6.03875 23.0632 5.00133ZM15.4065 12.5729L10.3742 15.2052C10.2581 15.2671 10.1419 15.2904 10.0181 15.2904C9.87871 15.2904 9.73936 15.2517 9.61548 15.182C9.38323 15.0426 9.24387 14.7871 9.24387 14.5162V9.26714C9.24387 8.99617 9.38323 8.74843 9.61548 8.60907C9.84774 8.46972 10.1342 8.46198 10.3742 8.58585L15.4065 11.2026C15.6619 11.3342 15.8245 11.5975 15.8245 11.8917C15.8245 12.1781 15.6619 12.4413 15.4065 12.5729Z"
      fill="currentColor"
    />
  </svg>
);

export { YoutubeIcon };
