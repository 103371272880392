import React from 'react';

// locale
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

// types
import { ESelectSizeType } from 'types/units';

// components
import { ArrowDownIcon } from 'components/icons';
import IconRenderer from '../../../hoc/IconRenderer';
// styles
import './index.scss';

//data
import { languagesList } from 'locales/data';

const { Option } = Select;

const LanguageToggler = props => {
  // translation hook
  const { i18n } = useTranslation();

  const onChangeSelect = language => {
    i18n.changeLanguage(language);
    window.location.reload();
  };

  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  // remove current language
  const updatedLangList = languagesList.map(lang => {
    return {
      label: lang.short,
      value: lang.id,
      data: lang,
    };
  });

  // current locale language
  const currentLanguage = languagesList.find(
    lang => lang.id === localization,
  )?.id;

  return (
    <div className="lang-toggler">
      <Select
        className="ant-select--light"
        size={ESelectSizeType.Small}
        suffixIcon={<ArrowDownIcon />}
        defaultValue={currentLanguage}
        onChange={onChangeSelect}
        dropdownAlign={{ offset: [0, 0] }}
        popupClassName={'lang-toggler__dropdown'}
      >
        {updatedLangList?.length &&
          updatedLangList.map(lang => {
            return (
              <Option value={lang.value} label={lang.label} key={lang.value}>
                <span
                  role="img"
                  className="lang-toggler__option-flag"
                  aria-label={lang.label}
                >
                  {IconRenderer(lang.data.flag)}
                </span>
                {lang.label}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export { LanguageToggler };
