import React, { useState } from 'react';
import { Select, Space } from 'antd';
import { NavLink } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
// types
import { IPropsType } from './types';
import { ESelectSizeType } from 'types/units';
import { ArrowDownIcon } from 'components/icons';
import { AppAutocomplete } from 'components/atoms';
// styles

import './index.scss';
//data
import { sortList } from './data';
// components
const { Option } = Select;
const HistoryHead = ({
  filterData,
  onChangeFilter,
  onChangeSearch,
  filterEnabled,
}: IPropsType) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<any[]>([]);
  const onSearch = (value: string) => {
    onChangeSearch(value);
    // setOptions(value ? new Array(getRandomInt(5)).join('.').split('.') : []);
  };
  const onChangeSortValue = (value: string) => {
    let item = sortList.find(el => el.label === value);
    if (!item || item.value === '0') {
      onChangeFilter(null);
    } else {
      onChangeFilter({ key: item?.key, data: item?.value });
    }
  };
  return (
    <div className="history-head">
      <div className="app-container">
        <div
          className={`history-head__content ${
            filterEnabled ? '' : 'history-head__content--empty'
          }`}
        >
          <div className="history-head__title">{t('historyTitle')}</div>
          <div className="history-head__actions-wrap">
            {filterEnabled && (
              <div className="history-head__filters">
                <AppAutocomplete
                  className="deals-head__autocomplete"
                  options={options}
                  size={ESelectSizeType.Large}
                  onSearch={onSearch}
                  isDefault={false}
                />

                <Select
                  className="deals-head__sort"
                  popupClassName="ant-select-dropdown-lg"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  onChange={onChangeSortValue}
                  dropdownAlign={{ offset: [0, 0] }}
                  placeholder={t(sortList[0].label)}
                >
                  {sortList?.length &&
                    sortList.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.label}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            )}
            <div className="history-head__actions">
              <Space.Compact className="ant-space-compact--nav history-head__tabs">
                <NavLink
                  to="/history/purchase"
                  className={({ isActive }) =>
                    isActive ? ' ant-space-compact-nav--active' : ''
                  }
                >
                  {t('historyBuy')}
                </NavLink>
                <NavLink
                  to="/history/sale"
                  className={({ isActive }) =>
                    isActive ? ' ant-space-compact-nav--active' : ''
                  }
                >
                  {t('historySell')}
                </NavLink>
                <NavLink
                  to="/history/replenishment"
                  className={({ isActive }) =>
                    isActive ? ' ant-space-compact-nav--active' : ''
                  }
                >
                  {t('historyReplenishment')}
                </NavLink>
                <NavLink
                  to="/history/withdrawal"
                  className={({ isActive }) =>
                    isActive ? ' ant-space-compact-nav--active' : ''
                  }
                >
                  {t('historyWithdraw')}
                </NavLink>
              </Space.Compact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HistoryHead };
