import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
// locale

// store
import { useAppStore, useAuthStore } from 'store';

// types

// components

// styles
import './index.scss';

// data
import { HeaderNotices } from '../../components/HeaderNotices';
import {
  useNotificationSocket,
  useQueryFetchNotificationList,
  useQueryFetchProfileInfo,
} from '../../../../../hooks/api';

const HeaderNoticeDrawer = () => {
  const { accessToken: isAuth } = useAuthStore();

  const { drawer, headerTopOffset, sDrawerClose } = useAppStore();
  //
  const [enableFetchNotifications, setEnableFetchNotifications] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>([]);

  const { data: notificationList, isLoading: isLoadingNotificationList } =
    useQueryFetchNotificationList(enableFetchNotifications);

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);

  const lastNotification = useNotificationSocket(
    enableFetchNotifications,
    profileData?.id,
  );

  useEffect(() => {
    if (notificationList) {
      setNotifications(notificationList);
    }
  }, [notificationList]);

  useEffect(() => {
    if (isAuth && profileData) {
      setEnableFetchNotifications(true);
    }
  }, [isAuth, profileData]);

  useEffect(() => {
    if (lastNotification) {
      setNotifications([lastNotification, ...notifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotification]);

  const onClose = () => {
    sDrawerClose('headerNoticeDrawer');
  };

  const onReadComplete = list => {
    setNotifications(list);
  };

  return (
    <Drawer
      placement="left"
      destroyOnClose={true}
      closable={false}
      onClose={onClose}
      open={drawer['headerNoticeDrawer']}
      getContainer={false}
      rootClassName={'header-notice-drawer'}
      rootStyle={{ height: `calc(100vh - ${headerTopOffset}px)` }}
      width={320}
    >
      <HeaderNotices
        onClose={onClose}
        onReadComplete={onReadComplete}
        notice={notifications}
      />
    </Drawer>
  );
};

export { HeaderNoticeDrawer };
