import React from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
// core

// utils

// locales

//store

// types
import { IPropsType } from './types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components(atoms)
import { TrashIcon, SuccessIcon, CartFillIcon } from 'components/icons';
import { InfoTooltip } from 'components/features';

// styles
import './index.scss';

// data

// func

const AppBtnCart = ({
  className,
  size,
  isInCart,
  isTooltip,
  onClickAdd,
  onClickRemove,
  disabled,
}: IPropsType) => {
  const { t } = useTranslation();
  return (
    <>
      {isInCart ? (
        <Button
          disabled={disabled}
          size={size}
          className="app-btn-cart--remove"
          onClick={() => (onClickRemove ? onClickRemove() : null)}
          icon={
            <span className="app-btn-cart__icons">
              <TrashIcon className="app-btn-cart__icon-trash" />
              <SuccessIcon className="app-btn-cart__icon-success" />
            </span>
          }
        />
      ) : (
        <>
          {isTooltip ? (
            <Tooltip
              overlayClassName={'ant-tooltip--small'}
              placement="top"
              title={<InfoTooltip text={t('tooltipCartBtn')} />}
            >
              <Button
                disabled={disabled}
                ghost
                size={size}
                type={EButtonTypeType.Primary}
                onClick={() => (onClickAdd ? onClickAdd() : null)}
                icon={<CartFillIcon />}
              />
            </Tooltip>
          ) : (
            <Button
              disabled={disabled}
              ghost
              size={size}
              type={EButtonTypeType.Primary}
              onClick={() => (onClickAdd ? onClickAdd() : null)}
              icon={<CartFillIcon />}
            />
          )}
        </>
      )}
    </>
  );
};

AppBtnCart.defaultProps = {
  isInCart: false,
  size: EButtonSizeType.Middle,
  disabled: false,
} as { isInCart: boolean; size: EButtonSizeType; disabled: boolean };

export { AppBtnCart };
