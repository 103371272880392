// core
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

//hooks
import { useQueryFetchTopProducts } from '../../../../hooks/api/market/useQueryFetchTopProducts';

// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import { EProductCardType } from 'components/features/Cards/ProductCard/type';
import { EGridType } from 'types/units';
// components(atoms)
import { ArrowRightLightIcon } from 'components/icons';
import { ProductCard } from 'components/features';

// styles
import './index.scss';
import { useAppStore } from 'store';

//tempo

// func
const HomeAssortmentBlock = ({ title, background, gameType }) => {
  const { t } = useTranslation();
  const [provider, setProvider] = useState({
    provider: gameType,
  });
  const navigate = useNavigate();
  const { sSetGameType } = useAppStore();
  const { data: topProductsData, isLoading: isLoadingTopProducts } =
    useQueryFetchTopProducts(provider);
  const redirectToMarket = provider => {
    sSetGameType(provider);
    navigate(`/market`);
  };

  return (
    <>
      <div className="home-assortment-block">
        <div className="home-assortment-block__bg">
          <img src={background} alt={title} />
        </div>
        <div className="home-assortment-block__wrap">
          <div className="home-assortment-block__head">
            <div className="home-assortment-block__head-title">{title}</div>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              className="home-assortment-block__head-link"
              onClick={() => {
                redirectToMarket(gameType);
              }}
            >
              <span>{t('goToMarket')}</span>
              <ArrowRightLightIcon />
            </a>
          </div>
          <div className="home-assortment-block__body">
            {!isLoadingTopProducts && topProductsData?.length ? (
              topProductsData.map(item => {
                return (
                  <div
                    className="home-assortment-block__body-item"
                    key={item.id}
                  >
                    <ProductCard
                      cardType={EProductCardType.Assortment}
                      gameType={gameType}
                      item={item}
                      size={EGridType.Default}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeAssortmentBlock };
