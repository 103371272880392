import { $apiAuth, $api } from 'utils/request';

// types
import { TCurrencyListResponse, TVariablesResponse } from 'types/api/settings';

// services

export const GET_BANNER_LIST = async () => {
  const url = `sliders`;
  const response = await $api.get<any>(url);
  return response.data.data;
};
export const GET_CURRENCY_LIST = async () => {
  const url = `currencies`;
  const response = await $apiAuth.get<TCurrencyListResponse[]>(url);
  return response.data;
};
export const GET_VARIABLES_LIST = async () => {
  const url = `config/variables`;
  const response = await $api.get<TVariablesResponse>(url);
  return response.data;
};

export const GET_STATIC_PAGE = async (slug: string) => {
  const url = `pages/${slug}`;
  const response = await $api.get<any>(url);
  return response.data;
};
