// core
import React, { useEffect, useState } from 'react';
//hooks

import {
  useMutationChangeTicketStatus,
  useQueryFetchTicket,
} from '../../../../../hooks/api';

//store
import { useAppStore } from 'store';

// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components(atoms)
import { Button } from 'antd';

// styles
import './../index.scss';
import {
  ESupportTicketStateType,
  ESupportTicketStatusType,
} from 'types/models';

//data

const SupportModalAsk = ({ id, ticket }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();
  const [payload, setPayload] = useState<any | null>({
    content: ticket.content,
    email: ticket.email,
    state: ticket.state,
    status: ticket.status,
    subject: ticket.subject,
  });

  const {
    data: changeTicketStatusResponse,
    isLoading: isLoadingChangeTicketStatus,
    mutate: mutateChangeTicketStatus,
  } = useMutationChangeTicketStatus();
  const {
    data: fetchTicketResponse,
    isLoading: isLoadingFetchTicket,
    refetch: refetchTicket,
  } = useQueryFetchTicket(false, id);

  useEffect(() => {
    if (changeTicketStatusResponse && !isLoadingChangeTicketStatus) {
      refetchTicket(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTicketStatusResponse, isLoadingChangeTicketStatus]);
  const onDone = () => {
    const { state, status, ...fields } = payload;
    const newValue = {
      state: ESupportTicketStateType.Done,
      status: ESupportTicketStatusType.Done,
      ...fields,
    };
    setPayload(newValue);
    mutateChangeTicketStatus({ payload: newValue, id: id });
    sModalOpen('SupportModalDone');
    sModalClose('SupportModalAsk');
  };
  const onDecline = () => {
    const { state, status, ...fields } = payload;
    const newValue = {
      state: ESupportTicketStateType.Pending,
      status: ESupportTicketStatusType.Declined,
      ...fields,
    };
    setPayload(newValue);
    mutateChangeTicketStatus({ payload: newValue, id: id });
    sModalOpen('SupportModalHelp');
    sModalClose('SupportModalAsk');
  };
  return (
    <div className="support-modal">
      <div className="support-modal__wrap support-modal__wrap--spaced">
        <div className="support-modal__content">
          <div className="support-modal__content-title">
            {t('modalAskTitle')}
          </div>
          <div className="support-modal__content-desc">{t('modalAskDesc')}</div>
        </div>
        <div className="support-modal__actions">
          <Button
            size={EButtonSizeType.Middle}
            type={EButtonTypeType.Default}
            className={'ant-btn-ghost--success'}
            ghost
            onClick={() => {
              onDone();
            }}
          >
            {t('modalAskDone')}
          </Button>
          <Button
            size={EButtonSizeType.Middle}
            type={EButtonTypeType.Default}
            className={'ant-btn-ghost--error'}
            ghost
            onClick={() => {
              onDecline();
            }}
          >
            {t('modalAskNotDone')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export { SupportModalAsk };
