import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

export const appThemes = {
  light: 'light',
  dark: 'dark',
};

export type TAppTheme = 'light' | 'dark';

type ThemeState = {
  appTheme: TAppTheme;
  setAppTheme: (payload: TAppTheme) => void;
};

const useThemeStore = create(
  devtools(
    persist<ThemeState>(
      set => ({
        appTheme:
          (localStorage.getItem('theme') as TAppTheme) || appThemes.light,
        setAppTheme: (payload: TAppTheme) => {
          set(state => ({
            appTheme: payload,
          }));
        },
      }),
      { name: 'theme' },
    ),
  ),
);
export { useThemeStore };
