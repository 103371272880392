// hook
import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

export function useDebounce(callback: () => any, time: number) {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return debouncedCallback;
}
