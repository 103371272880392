import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Table, Switch } from 'antd';
import { formatDate, formatPrice } from '../../../../utils/commons';
import { toast } from 'react-toastify';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
//hooks
import {
  useMutationFetchActiveAutobuyList,
  useMutationRemoveActiveAutobuy,
  useMutationChangeAutobuyPrice,
  useMutationChangeAutobuyStatus,
  useMutationCreateAutobuy,
} from '../../../../hooks/api';
// types
import { EButtonSizeType, EButtonTypeType, EInputSizeType } from 'types/units';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';

// components
import {
  TableCellImage,
  EmptyCard,
  RemoveTradeModal,
  PriceModal,
} from 'components/features';
import { ModalTeleport } from 'components/atoms';
import {
  TrashIcon,
  EditIcon,
  SuccessIcon,
  ArrowRightIcon,
  ArrowLeftLightIcon,
  StatIcon,
} from 'components/icons';
import { DealsHead } from '../DealsHead';

// styles
import './index.scss';

//data

//data
const transformData = arr => {
  return arr.map(el => {
    let { active, countBought, countToBuy, createdAt, price, item, id } = el;
    let { image, preview, provider, name, marketName } = item;
    return {
      id: id,
      provider: provider,
      img: image ? image : preview,
      date: formatDate(createdAt, 'DD.MM.YYYY'),
      name: name,
      marketName: marketName,
      auto_price: price,
      count: countBought,
      total: countToBuy,
      active: active,
    };
  });
};
const DealsAutobuy = () => {
  const { t } = useTranslation();
  const { sModalOpen } = useAppStore();
  const inputRef = useRef<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [editingId, setEditingId] = useState<any>(null);
  const [removingId, setRemovingId] = useState<any>(null);
  const [newPrice, setNewPrice] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>({
    search: '',
    quality: 'DESC',
    price: 'DESC',
    page: 1,
    take: 10,
  });

  const {
    data: activeAutobuyListData,
    isLoading: activeAutobuyListLoading,
    mutate: mutateActiveAutobuyList,
  } = useMutationFetchActiveAutobuyList();

  const {
    data: removeAutoBuyData,
    isLoading: removeAutoBuyLoading,
    mutate: mutateRemoveAutoBuy,
  } = useMutationRemoveActiveAutobuy();

  const {
    data: changeAutoBuyPrice,
    isLoading: changeAutoBuyPriceLoading,
    mutate: mutateChangeAutoBuyPrice,
  } = useMutationChangeAutobuyPrice();

  const {
    data: changeAutoBuyStatus,
    isLoading: changeAutoBuyStatusLoading,
    mutate: mutateChangeAutoBuyStatus,
  } = useMutationChangeAutobuyStatus();

  useEffect(() => {
    mutateActiveAutobuyList(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  useEffect(() => {
    if (activeAutobuyListData && !activeAutobuyListLoading) {
      setTableData(transformData(activeAutobuyListData.data));
    }
  }, [activeAutobuyListData, activeAutobuyListLoading]);
  useEffect(() => {
    if (removeAutoBuyData && !removeAutoBuyLoading) {
      setRemovingId(null);
      mutateActiveAutobuyList(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAutoBuyData, removeAutoBuyLoading]);

  useEffect(() => {
    if (changeAutoBuyStatus && !changeAutoBuyStatusLoading) {
      mutateActiveAutobuyList(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeAutoBuyStatus, changeAutoBuyStatusLoading]);

  useEffect(() => {
    if (!changeAutoBuyPriceLoading && changeAutoBuyPrice) {
      setEditingId(null);
      mutateActiveAutobuyList(filterData);
      toast.success(t('toastSuccessChangedAutoPrice'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeAutoBuyPrice, changeAutoBuyPriceLoading]);
  const onRemoveTradeDeal = data => {
    setRemovingId(data.id);
    sModalOpen('removeTrade');
  };

  const refreshTable = () => {
    mutateActiveAutobuyList(filterData);
  };
  const onShowPriceStat = data => {
    setSelectedItem(data);
    sModalOpen('priceDeals');
  };
  const onEditTradeDeal = data => {
    setNewPrice(data.auto_price);
    setEditingId(data.id);
  };

  const onSaveTradeDeal = data => {
    let { value } = inputRef!.current!.input;

    mutateChangeAutoBuyPrice({
      id: data.id,
      data: {
        price: value,
      },
    });
  };
  const onTableChange = pagination => {
    setFilterData({ ...filterData, page: pagination.current });
  };

  const onRemoveAutoBuy = () => {
    mutateRemoveAutoBuy(removingId);
  };

  const onChangeSwitch = (data, event) => {
    mutateChangeAutoBuyStatus({
      id: event.target.id,
      data: {
        status: data,
      },
    });
  };

  const columns = [
    {
      key: 'date',
      dataIndex: 'date',
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: (_, record) => (
        <TableCellImage src={record.img} gameType={record.provider} alt={''} />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        <>
          <div className="ant-table-cell__text">{record.marketName.first}</div>
          <div className="ant-table-cell__type">{record.marketName.second}</div>
        </>
      ),
    },
    {
      key: 'old_price',
      dataIndex: 'old_price',
      width: '10%',
      render: (_, record) => (
        <div className="ant-table-cell__nowrap">
          <Switch
            id={record.id}
            checked={record.active}
            loading={changeAutoBuyStatusLoading}
            onChange={(value, event) => onChangeSwitch(value, event)}
          />
        </div>
      ),
    },
    {
      key: 'auto_price',
      dataIndex: 'auto_price',
      width: '50%',
      render: auto_price => (
        <div className="ant-table-cell__nowrap">
          {t('autobuy')}:{' '}
          <span className="ant-table-cell__value">
            $ {formatPrice(auto_price)}
          </span>
        </div>
      ),
    },
    {
      key: 'count',
      dataIndex: 'count',
      width: '10%',
      render: (_, record) => (
        <div className="ant-table-cell__nowrap">
          {t('autobuyCount')}:{' '}
          <span className="ant-table-cell__value">
            {record.count}/{record.total}
          </span>
        </div>
      ),
    },
    {
      key: 'stat',
      render: (_, record) => (
        <Button
          type={EButtonTypeType.Text}
          className={'ant-btn-text--gray'}
          icon={<StatIcon />}
          onClick={() => onShowPriceStat(record)}
        />
      ),
    },
    {
      key: 'new_price',
      dataIndex: 'new_price',
      editable: true,
      render: (_, record) => {
        if (record.id === editingId) {
          return (
            <div className="ant-table-cell-editable">
              {/*<Form.Item>*/}
              <Input
                ref={inputRef}
                size={EInputSizeType.Large}
                prefix={'$'}
                suffix={
                  <Button
                    type={EButtonTypeType.Default}
                    className="ant-btn-default--light"
                    icon={<SuccessIcon />}
                    disabled={changeAutoBuyPriceLoading}
                    onClick={() => {
                      onSaveTradeDeal(record);
                    }}
                  />
                }
                defaultValue={record.auto_price}
              />
              {/*</Form.Item>*/}
            </div>
          );
        } else {
          return (
            <div className="deals-autobuy__table-request">
              <div className="ant-table-cell__nowrap">
                {t('tradeNewPrice')}:{' '}
                <span className="ant-table-cell__value">
                  $ {formatPrice(record.auto_price)}
                </span>
              </div>
              <Button
                disabled={editingId}
                size={EButtonSizeType.Middle}
                icon={<EditIcon />}
                type={EButtonTypeType.Text}
                onClick={() => onEditTradeDeal(record)}
              />
            </div>
          );
        }
      },
    },
    {
      key: 'action',
      render: (_, record) => (
        <Button
          size={EButtonSizeType.Middle}
          disabled={editingId}
          icon={<TrashIcon />}
          type={EButtonTypeType.Text}
          className="ant-btn-text--error"
          onClick={() => onRemoveTradeDeal(record)}
        />
      ),
    },
  ];

  return (
    <>
      <DealsHead
        filterData={filterData}
        onChangeQuality={value => {
          setFilterData({ ...filterData, quality: value });
        }}
        onChangePrice={value => {
          setFilterData({ ...filterData, price: value });
        }}
        onChangeSearch={value => {
          setFilterData({ ...filterData, search: value });
        }}
        enabled={true}
        createRequest={true}
        onCreateRequest={() => {
          refreshTable();
        }}
      />
      <div className="deals-autobuy">
        <div className="app-container">
          <Table
            showHeader={false}
            loading={activeAutobuyListLoading}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            pagination={
              activeAutobuyListLoading
                ? false
                : {
                    position: ['bottomCenter'],
                    current: activeAutobuyListData?.meta?.page,
                    pageSize: activeAutobuyListData?.meta?.take,
                    total: activeAutobuyListData?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            className="deals-autobuy__table"
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '850px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    color={ECardColorType.Light}
                    title={t('tradesEmptyTitle')}
                    description={t('tradesEmptyDesc')}
                  />
                </div>
              ),
            }}
          />
          <ModalTeleport
            centered
            modalId={'removeTrade'}
            width={453}
            modalClass={'remove-save-modal__wrapper'}
          >
            <RemoveTradeModal
              onRemove={onRemoveAutoBuy}
              income
              modalId={'removeTrade'}
            />
          </ModalTeleport>
          <ModalTeleport
            centered
            modalId={'priceDeals'}
            width={1330}
            modalClass={'price-modal__wrapper'}
          >
            <PriceModal product={selectedItem} modalId={'priceDeals'} />
          </ModalTeleport>
        </div>
      </div>
    </>
  );
};

export { DealsAutobuy };
