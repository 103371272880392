import { EGameType } from 'types/models';

import { CSGOIcon, DOTA2Icon } from 'components/icons';
export const games: Array<{
  label: string;
  value: string;
  icon: any;
}> = [
  {
    label: 'CS2',
    value: EGameType.CSGO,
    icon: CSGOIcon,
  },
  {
    label: 'DOTA 2',
    value: EGameType.Dota,
    icon: DOTA2Icon,
  },
];
