import { $api } from 'utils/request';
import { objectToQueryString } from '../utils/commons';

// types
import type {
  IInventoryItemsRequestPayload,
  IInventoryItemsResponse,
  IInventorySearchRequestParams,
  TMarketSearchResponse,
  TInventorySteamIdRequestParam,
} from 'types/api';

import type { AxiosResponse } from 'axios';

import {
  TInventoryAcceptTradeRequestParams,
  TInventoryRejectTradeRequestParams,
  TInventoryAllItemsResponse,
  TInventoryItemsToMarketRequestPayload,
  IInventorySteamFetchRequestParams,
} from 'types/api';

// services
export const POST_INVENTORY_ITEMS = async ({
  pageParam,
  payload,
}: {
  pageParam: number;
  payload: IInventoryItemsRequestPayload;
}) => {
  const url = `inventory`;
  const response: AxiosResponse<IInventoryItemsResponse> = await $api.post<any>(
    url,
    {
      ...payload,
      page: pageParam,
      take: 36,
      group: true,
    },
  );
  return response.data;
};

export const POST_INVENTORY_ALL_ITEMS = async (payload: any) => {
  const url = `inventory/all-sell-bar`;
  const response: AxiosResponse<TInventoryAllItemsResponse> =
    await $api.post<any>(url, {
      ...payload,
      group: true,
      sort: { price: 'DESC' },
    });
  return response.data;
};

export const POST_INVENTORY_ITEMS_TO_MARKET = async (
  payload: TInventoryItemsToMarketRequestPayload,
) => {
  const url = `inventory/item-to-market`;
  const response: AxiosResponse = await $api.post<any>(url, { items: payload });
  return response.data;
};

export const GET_INVENTORY_ITEMS_BY_NAME = async (
  params: IInventorySearchRequestParams,
  steamId: TInventorySteamIdRequestParam,
) => {
  const url = objectToQueryString(`inventory/search/${steamId}`, params);
  const response = await $api.get<TMarketSearchResponse>(url);
  return response.data;
};

export const GET_INVENTORY_STEAM_UPDATE = async (
  params: IInventorySteamFetchRequestParams,
) => {
  const url = objectToQueryString(`inventory/steam-fetch`, params);
  const response = await $api.get(url);
  return response.data;
};
export const GET_PENDING_INVENTORY = async ({ payload }: any) => {
  const url = `inventory`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const GET_ACTIVE_INVENTORY = async ({ payload }: any) => {
  const url = `market/trading`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const REMOVE_ITEM_FROM_MARKET = async (payload: any) => {
  const url = `inventory/remove-item-from-market`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const ACCEPT_TRADE = async ({ transactionId, tradeOfferId }: any) => {
  const url = `market/trade/accept/${transactionId}`;
  const response = await $api.post<any>(url, { tradeOfferId });
  return response.data;
};

export const REJECT_TRADE = async ({ transactionId, tradeOfferId }: any) => {
  const url = `market/trade/reject/${transactionId}`;
  const response = await $api.post<any>(url, { tradeOfferId });
  return response.data;
};
