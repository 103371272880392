import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

// store
// type
// components

// styles
import './index.scss';

const MobileBottomNav = ({ children, className, breakPoint }) => {
  const { width } = useWindowSize();
  const ref = useRef(null);

  useEffect(() => {
    const appLayout = document.querySelector('.app-layout');
    if (appLayout && ref) {
      if (width <= breakPoint) {
        // @ts-ignore
        appLayout.style.paddingBottom = `${ref.current!.clientHeight}px`;
      } else {
        // @ts-ignore
        appLayout.style.paddingBottom = '0px';
      }
    }

    return () => {
      if (appLayout && ref) {
        // @ts-ignore
        appLayout.style.paddingBottom = '0px';
      }
    };
  }, [breakPoint, width]);

  return (
    <div
      ref={ref}
      className={(width <= breakPoint ? 'mobile-bottom-nav ' : '') + className}
    >
      {children}
    </div>
  );
};

MobileBottomNav.defaultProps = {
  children: null,
  className: '',
  breakPoint: 768,
} as {
  children: any;
  className: string;
  breakPoint: number;
};
export { MobileBottomNav };
