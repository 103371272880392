export const selectItems = [
  {
    label: 'filterBlogByViews',
    value: 'views',
  },
  {
    label: 'filterBlogByDate',
    value: 'published_at',
  },
];
