import React, { useEffect, useState } from 'react';
import { Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
// locale

// store
import { useMutationSendSteamSettings } from 'hooks/api';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { Link2Icon, LinkIcon } from 'components/icons';
import { MobileBottomNav } from 'components/features';

// styles
import './index.scss';
import { toast } from 'react-toastify';
import * as yup from 'yup';

//data
const InventoryTrade = ({ onUpdate }) => {
  const createSteamSchema = yup.object().shape({
    tradeOfferLink: yup.string(),
  });

  const yupSync = {
    async validator({ field }, value) {
      await createSteamSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { t } = useTranslation();
  const {
    data: sendSteamData,
    isLoading: isLoadingSendSteam,
    mutate: mutateSendSteamSettings,
  } = useMutationSendSteamSettings();

  const [form] = Form.useForm();

  useEffect(() => {
    if (sendSteamData) {
      toast.success(t('toastSteamSettings'));
      onUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSteamData, isLoadingSendSteam]);

  const onSubmitForm = payload => {
    mutateSendSteamSettings(payload);
  };

  const onGetTradeLink = () => {
    window.open(
      'https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url',
      '_blank',
    );
  };

  const onSaveTradeLink = () => {
    form.submit();
  };

  return (
    <div className="inventory-trade">
      <div className="inventory-trade__icon">
        <Link2Icon />
      </div>
      <div className="inventory-trade__title">Трейд-ссылка</div>
      <div className="inventory-trade__text">
        Чтобы взаимодействовать с маркетом, укажите трейд--ссылку
      </div>
      <Form onFinish={onSubmitForm} form={form} layout="vertical">
        <Form.Item
          name={'tradeOfferLink'}
          /*@ts-ignore*/
          rules={[yupSync]}
        >
          <Input
            className="inventory-trade__input ant-input-group-wrapper--custom-addon"
            addonBefore={
              <>
                <LinkIcon />
                <span>{t('tradeLink')}</span>
              </>
            }
            addonAfter={
              <Button
                className="ant-btn-text--primary"
                type={EButtonTypeType.Text}
                onClick={() => onGetTradeLink()}
              >
                {t('get')}
              </Button>
            }
          />
        </Form.Item>
      </Form>
      <MobileBottomNav breakPoint={768} className="inventory-trade__action">
        <Button
          type="primary"
          className="inventory-trade__btn-save"
          size={EButtonSizeType.Large}
          block
          disabled={isLoadingSendSteam}
          onClick={() => onSaveTradeLink()}
        >
          {t('save')}
        </Button>
      </MobileBottomNav>
    </div>
  );
};

export { InventoryTrade };
