const FlagUs = props => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_589_64703)">
      <path d="M0 0H24V16H0V0Z" fill="white" />
      <path
        d="M0 0H24V1.23041H0V0ZM0 2.46082H24V3.69122H0V2.46082ZM0 4.92163H24V6.15204H0V4.92163ZM0 7.38245H24V8.61286H0V7.38245ZM0 9.84794H24V11.0784H0V9.84794ZM0 12.3088H24V13.5392H0V12.3088ZM0 14.7696H24V16H0V14.7696Z"
        fill="#D80027"
      />
      <path d="M0 0H12V8.61286H0V0Z" fill="#2E52B2" />
      <path
        d="M2.23626 6.49845L2.04913 5.89962L1.84328 6.49845H1.22574L1.72632 6.85868L1.53919 7.45751L2.04913 7.08792L2.54504 7.45751L2.35322 6.85868L2.86317 6.49845H2.23626ZM4.87018 6.49845L4.67837 5.89962L4.48188 6.49845H3.86433L4.36492 6.85868L4.17779 7.45751L4.67837 7.08792L5.18363 7.45751L4.9965 6.85868L5.49708 6.49845H4.87018ZM7.51346 6.49845L7.31229 5.89962L7.12515 6.49845H6.49358L7.00819 6.85868L6.8117 7.45751L7.31229 7.08792L7.82691 7.45751L7.63042 6.85868L8.131 6.49845H7.51346ZM10.1427 6.49845L9.95556 5.89962L9.75907 6.49845H9.13685L9.64211 6.85868L9.45498 7.45751L9.95556 7.08792L10.4608 7.45751L10.2597 6.85868L10.7743 6.49845H10.1427ZM4.67837 3.52301L4.48188 4.12184H3.86433L4.36492 4.49143L4.17779 5.08091L4.67837 4.71599L5.18363 5.08091L4.9965 4.49143L5.49708 4.12184H4.87018L4.67837 3.52301ZM2.04913 3.52301L1.84328 4.12184H1.22574L1.72632 4.49143L1.53919 5.08091L2.04913 4.71599L2.54504 5.08091L2.35322 4.49143L2.86317 4.12184H2.23626L2.04913 3.52301ZM7.31229 3.52301L7.12515 4.12184H6.49358L7.00819 4.49143L6.8117 5.08091L7.31229 4.71599L7.82691 5.08091L7.63042 4.49143L8.131 4.12184H7.51346L7.31229 3.52301ZM9.95556 3.52301L9.75907 4.12184H9.13685L9.64211 4.49143L9.45498 5.08091L9.95556 4.71599L10.4608 5.08091L10.2597 4.49143L10.7743 4.12184H10.1427L9.95556 3.52301ZM2.04913 1.15576L1.84328 1.74524H1.22574L1.72632 2.11483L1.53919 2.70898L2.04913 2.33939L2.54504 2.70898L2.35322 2.11483L2.86317 1.74524H2.23626L2.04913 1.15576ZM4.67837 1.15576L4.48188 1.74524H3.86433L4.36492 2.11483L4.17779 2.70898L4.67837 2.33939L5.18363 2.70898L4.9965 2.11483L5.49708 1.74524H4.87018L4.67837 1.15576ZM7.31229 1.15576L7.12515 1.74524H6.49358L7.00819 2.11483L6.8117 2.70898L7.31229 2.33939L7.82691 2.70898L7.63042 2.11483L8.131 1.74524H7.51346L7.31229 1.15576ZM9.95556 1.15576L9.75907 1.74524H9.13685L9.64211 2.11483L9.45498 2.70898L9.95556 2.33939L10.4608 2.70898L10.2597 2.11483L10.7743 1.74524H10.1427L9.95556 1.15576Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_589_64703">
        <rect width="24" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { FlagUs };
