// core
import React from 'react';
import { Outlet } from 'react-router-dom';

//hooks
// utils
// locales
// store
// types

// components(atoms)
import { HistorySEO } from './SEO';
// styles
import './index.scss';

// data

// func
const History = () => {
  return (
    <>
      <HistorySEO />
      <div className="history">
        {/*TABS*/}
        <Outlet />
      </div>
    </>
  );
};

export { History };
