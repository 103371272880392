import { $api } from '../utils/request';
import {
  TWithdrawCreateRequestPayload,
  TWithdrawCreateResponse,
  TWithdrawMethodsResponse,
} from 'types/api';

export const GET_WITHDRAW_METHODS = async () => {
  const url = `wallet/withdraw/form`;
  const response = await $api.get<TWithdrawMethodsResponse>(url);
  return response.data;
};

export const CREATE_WITHDRAW_REQUEST = async (
  payload: TWithdrawCreateRequestPayload,
) => {
  const url = `wallet/withdraw/new`;
  const response = await $api.post<TWithdrawCreateResponse>(url, payload);
  return response.data;
};
