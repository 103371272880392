// core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//hooks
import { useQueryFetchTicketList } from '../../../../hooks/api';
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params';
// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components(atoms)
import { SupportTicketsSEO } from './SEO';
import { EmptyCard } from 'components/features';
import { SupportTicketsCard } from './SupportTicketsCard';
import { Button, Pagination } from 'antd';

// styles
import './index.scss';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';
import { AppLoader } from 'components/atoms';

//data
// import { ticketsList } from './mockData';

const SupportTickets = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    take: withDefault(NumberParam, 8),
  });
  const { page: pageParam, take: takeParam } = query;

  const { data: ticketList, isLoading: isLoadingTicketList } =
    useQueryFetchTicketList(query);
  return (
    <>
      <SupportTicketsSEO />
      <div className="tickets">
        <div className="app-container app-container--small">
          <div className="tickets__wrap">
            <div className="tickets__head">
              <div className="tickets__title">{t('techSupport')}</div>
              <div className="tickets__nav">
                <RouterLink to="/support/tickets/create">
                  <Button
                    type={EButtonTypeType.Primary}
                    size={EButtonSizeType.Middle}
                  >
                    {t('newTicket')}
                  </Button>
                </RouterLink>
              </div>
            </div>
            <div className="tickets__content">
              {isLoadingTicketList ? (
                <div className="tickets__loader">
                  <AppLoader type={ELoaderTypeType.Component} />
                </div>
              ) : (
                <>
                  {ticketList?.data.length ? (
                    ticketList.data.map((ticket, index) => {
                      return (
                        <div className="tickets__content--item" key={index}>
                          <SupportTicketsCard ticket={ticket} />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <EmptyCard
                        title={t('emptyTicketsTitle')}
                        description={t('emptyTicketsDesc')}
                      />
                      <RouterLink to="/support/tickets/create">
                        <Button
                          type={EButtonTypeType.Primary}
                          size={EButtonSizeType.Large}
                        >
                          {t('newTicket')}
                        </Button>
                      </RouterLink>
                    </>
                  )}
                </>
              )}
              {ticketList?.meta && (
                <Pagination
                  defaultCurrent={1}
                  current={pageParam}
                  pageSize={takeParam}
                  total={ticketList.meta.itemsCount}
                  showSizeChanger={false}
                  hideOnSinglePage={true}
                  onChange={page => setQuery({ page: page })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SupportTickets };
