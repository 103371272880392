const ArrowLeftLightIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4685 6.41438C14.7919 6.67313 14.8444 7.1451 14.5856 7.46855L10.9604 12L14.5856 16.5315C14.8444 16.855 14.7919 17.3269 14.4685 17.5857C14.145 17.8444 13.6731 17.792 13.4143 17.4685L9.41432 12.4685C9.19519 12.1946 9.19519 11.8054 9.41432 11.5315L13.4143 6.53151C13.6731 6.20806 14.145 6.15562 14.4685 6.41438Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeftLightIcon };
