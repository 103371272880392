import React from 'react';
import { Tooltip } from 'antd';

// locale
// types
import { EFloatRangeSizeType, IPropsType } from './types';

// styles
import './index.scss';

//data
import { floatRanges } from './data';
import { useTranslation } from 'react-i18next';

const getStyle = ({ minValue, maxValue }, minGenVal = 0, maxGenVal) => {
  let left = 0,
    width = 0;
  if (minGenVal === maxGenVal && maxGenVal > 0) {
    minGenVal = minGenVal - 0.003;
  }

  if (minGenVal < maxGenVal) {
    let percent = 100 / (maxValue - minValue);
    if (minGenVal >= minValue && minGenVal <= maxValue) {
      left = percent * (minGenVal - minValue);
    }

    if (left) {
      if (maxGenVal >= minValue && maxGenVal <= maxValue) {
        width = percent * (maxGenVal - minGenVal);
      } else {
        width = 100 - left;
      }
    } else {
      if (minGenVal <= minValue) {
        if (maxGenVal > maxValue) {
          width = 100;
        } else if (maxGenVal > minValue) {
          width = percent * (maxGenVal - minValue);
        }
      }
    }
  }

  return {
    width: `${width}%`,
    left: `${left}%`,
  };
};

const FloatRange = ({
  minValue,
  maxValue,
  size,
  isTooltip,
  isTooltipValue,
}: IPropsType) => {
  const { t } = useTranslation();
  return (
    <div className="float-range">
      <ul>
        {floatRanges.map((item, index) => (
          <Tooltip
            trigger={isTooltip ? 'hover' : []}
            key={index}
            placement="top"
            overlayClassName={'float-range__tooltip'}
            title={
              <>
                {maxValue ? (
                  <>
                    {isTooltipValue && (
                      <div className="float-range__tooltip-value">
                        {maxValue}
                      </div>
                    )}
                    <div className="float-range__tooltip-range">
                      {item.minTitle} - {item.maxTitle}
                    </div>
                    <div className="float-range__tooltip-title">
                      {t(item.label)}
                    </div>
                  </>
                ) : (
                  <div className="float-range__tooltip-title">
                    {t('floatNotAvailable')}
                  </div>
                )}
              </>
            }
          >
            <li>
              <span
                className="float-range__bg"
                style={{
                  height: size === EFloatRangeSizeType.Small ? '2px' : '3px',
                }}
              />
              <span
                className="float-range__thumb"
                style={{
                  ...getStyle(item, minValue, maxValue),
                  height: size === EFloatRangeSizeType.Small ? '2px' : '3px',
                }}
              />
            </li>
          </Tooltip>
        ))}
      </ul>
    </div>
  );
};

FloatRange.defaultProps = {
  minValue: 0,
  maxValue: 0,
  size: EFloatRangeSizeType.Default,
  isTooltip: false,
  isTooltipValue: false,
} as IPropsType;

export { FloatRange };
