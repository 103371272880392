// core
import React from 'react';
//hooks

// utils

// locales
import { useTranslation } from 'react-i18next';

// types
import {
  ESupportTicketStateType,
  ESupportTicketStatusType,
} from '../../../../../types/models/supportTicket';
// components(atoms)

// styles
import './index.scss';
//data
const renderStatus = (type, status, t) => {
  if (type === true) {
    switch (status) {
      case ESupportTicketStatusType.Done:
        return (
          <span className="tickets__card--status-value tickets__card--status-value--green">
            {t('ticketStatusDone')}
          </span>
        );
      case ESupportTicketStatusType.Progress:
        return (
          <span className="tickets__card--status-value tickets__card--status-value--blue">
            {t('ticketStatusPending')}
          </span>
        );
      case ESupportTicketStatusType.Declined:
        return (
          <span className="tickets__card--status-value tickets__card--status-value--red">
            {t('ticketStatusDeclined')}
          </span>
        );
      default:
        return <span className="tickets__card--status-value">{status}</span>;
    }
  } else {
    switch (status) {
      case ESupportTicketStateType.Done:
        return (
          <span className="tickets__card--status-value tickets__card--status-value--green">
            {t('ticketStatusWithAnswer')}
          </span>
        );
      case ESupportTicketStateType.Pending:
        return (
          <span className="tickets__card--status-value tickets__card--status-value--gray">
            {t('ticketStatusWithoutAnswer')}
          </span>
        );
      default:
        return <span className="tickets__card--status-value">{status}</span>;
    }
  }
};
const SupportTicketsStatus = ({ type, status }) => {
  const { t } = useTranslation();
  return renderStatus(type, status, t);
};

export { SupportTicketsStatus };
