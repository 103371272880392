// core
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppStore } from 'store';
//hooks
import { useQueryFetchSupportFaq } from '../../../../hooks/api';
// utils
import { getVariable } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';

// types

// components(atoms)
import { PlusIcon, CloseIcon } from 'components/icons';

// styles
import './index.scss';

//tempo
import { faqList } from './mockData';
import { Collapse } from 'antd';

// func
const HomeFaq = ({ onLoaded }) => {
  const { t } = useTranslation();
  const { variables } = useAppStore();
  const [filter, setFilter] = useState({
    topic: 'sell',
  });
  const { data: faqData, isLoading: isLoadingFaqData } =
    useQueryFetchSupportFaq(filter);

  useEffect(() => {
    if (!isLoadingFaqData && faqData) {
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqData, isLoadingFaqData]);

  return (
    <>
      <div className="home-faq">
        <div className="app-container app-container--small">
          <div className="home-faq__wrap">
            <div className="home-faq__content">
              <div className="home-faq__content-icon">
                <img
                  src={getVariable(variables, 'home_faq_icon')}
                  alt="Skinsty"
                />
              </div>
              <div className="home-faq__content-title">
                {t('oftenQuestions')}
              </div>
              <div className="home-faq__content-desc">
                {t('knowProjectBetter')}
              </div>
              <div className="home-faq__content-subtitle">
                {t('stillQuestions')}
                <RouterLink to={'/support/tickets/create'}>
                  {t('contactUsForQuestions')}
                </RouterLink>
              </div>
            </div>
            <div className="home-faq__item__wrapper">
              {faqData?.data?.length ? (
                faqData.data.map((item, index) => {
                  return (
                    <div className="home-faq__item" key={index}>
                      <Collapse
                        size="large"
                        expandIcon={({ isActive }) =>
                          !isActive ? <PlusIcon /> : <CloseIcon />
                        }
                        items={[
                          {
                            key: index,
                            label: item.q,
                            children: (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.a,
                                }}
                              ></div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeFaq };
