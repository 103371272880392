import { useQuery } from '@tanstack/react-query';
//types
// services
import { GET_INVENTORY_STEAM_UPDATE } from 'api/inventory';
import { IInventorySteamFetchRequestParams } from 'types/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const useQueryInventorySteamUpdate = (
  params: IInventorySteamFetchRequestParams,
) => {
  const { t } = useTranslation();

  return useQuery(
    ['useQueryInventorySteamUpdate'],
    () => GET_INVENTORY_STEAM_UPDATE(params),
    {
      enabled: false,
      staleTime: 0,

      onSuccess: data => {
        toast.success(t('inventoryWillUpdate'));
      },
      onError: (err: Error) => {
        const message = err ? err?.message : 'Error while fetching Profile';
        console.error(message);
        return message;
      },
    },
  );
};
