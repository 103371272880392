import React, { useEffect } from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';

import { useMutationCreatePaymentPass } from '../../../../../hooks/api';

// types
import { EButtonSizeType } from 'types/units';

// components(atoms)
import { Button, Input, Form } from 'antd';
import { LockCrossIcon } from 'components/icons';

// styles
import './index.scss';

// data
import * as yup from 'yup';
import { toast } from 'react-toastify';
// func

const DashboardModalCreatePaymentPassword = ({ modalId, onCreate }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const [form] = Form.useForm();
  const createPaymentPassSchema = yup.object().shape({
    paymentPassword: yup
      .string()
      .min(4, t('validationMinLength') + ' 4')
      .max(24, t('validationMaxLength') + ' 24')
      .required(t('validationRequired')),

    repeatPaymentPassword: yup
      .string()
      .min(4, t('validationMinLength') + ' 4')
      .max(24, t('validationMaxLength') + ' 24')
      .oneOf([yup.ref('paymentPassword')], t('validationSameAs'))
      .required(t('validationRequired')),
  });
  const yupSync = {
    async validator({ field }, value) {
      await createPaymentPassSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const {
    data: createPassData,
    isLoading: isLoadingCreatePass,
    mutate: mutateCreatePass,
  } = useMutationCreatePaymentPass();

  useEffect(() => {
    if (createPassData && !isLoadingCreatePass) {
      toast.success(t('toastCreatedPaymentPass'));
      sModalClose(modalId);
      onCreate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPassData, isLoadingCreatePass]);
  const onCreatePass = payload => {
    mutateCreatePass({ paymentPassword: payload.paymentPassword });
  };

  return (
    <div className="dashboard__create-payment-password">
      <div className="dashboard__create-payment-password--icon">
        <LockCrossIcon />
      </div>
      <div className="dashboard__create-payment-password--title">
        {t('creatingOfPaymentPass')}
      </div>
      <Form onFinish={onCreatePass} form={form} layout="vertical">
        <div className="dashboard__create-payment-password--input">
          <Form.Item
            name={'paymentPassword'}
            /*@ts-ignore*/
            rules={[yupSync]}
          >
            <Input.Password
              addonBefore={t('paymentPassTitle')}
              placeholder="***********"
            />
          </Form.Item>
        </div>
        <div className="dashboard__create-payment-password--input">
          <Form.Item
            name={'repeatPaymentPassword'}
            /*@ts-ignore*/
            rules={[
              {
                validator: async (_, value) => {
                  try {
                    await createPaymentPassSchema.fields.repeatPaymentPassword.validate(
                      value,
                    );
                  } catch (error) {
                    if (
                      form.getFieldValue('paymentPassword') !==
                      form.getFieldValue('repeatPaymentPassword')
                    ) {
                      throw new Error(error.errors[0]);
                    }
                  }
                },
              },
            ]}
          >
            <Input.Password
              addonBefore={t('repeatPassword')}
              placeholder="***********"
            />
          </Form.Item>
        </div>
        <div className="dashboard__create-payment-password--button">
          <Button
            htmlType={'submit'}
            type="primary"
            block
            size={EButtonSizeType.Large}
          >
            {t('save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export { DashboardModalCreatePaymentPassword };
