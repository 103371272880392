const DuckyIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4995 6.4668C17.5594 7.62413 16.0578 9.2637 15.1746 9.93881C16.2277 11.1812 16.4995 11.9301 16.7712 13.6661C17.3555 18.0232 13.4138 19.5322 11.3699 19.7422C9.04851 20.2301 3.89625 20.2391 1.858 16.3723C-0.180256 12.5054 1.38241 9.47361 2.41852 8.44108C2.48532 8.38936 2.59871 8.33045 2.71255 8.33106C2.86064 9.27439 3.13112 10.0823 3.62081 11.1528C4.69849 13.0332 6.25823 14.4339 8.71995 15.0972L8.72186 15.0977L8.72377 15.0982C9.68054 15.3182 10.4 15.4139 11.1179 15.3516C11.736 15.298 12.2939 15.187 12.63 15.0972C12.9304 15.017 13.1092 14.7082 13.0295 14.4075C12.9497 14.1067 12.6416 13.928 12.3413 14.0082C12.067 14.0815 11.5737 14.1809 11.0223 14.2287C10.4 14.2287 9.55805 14.0821 9.10395 13.968C6.91333 13.3985 5.57432 12.2963 4.59685 10.5908C4.47062 10.3389 4.36469 10.1092 4.27516 9.89352C5.38666 10.5778 6.65353 10.5369 7.15162 10.5209L7.15165 10.5209C7.20988 10.519 7.25761 10.5175 7.29335 10.5175C6.98081 10.1362 6.78945 9.33745 6.73283 8.98571C6.54599 7.69222 6.93665 4.90439 9.99404 4.10106C13.493 3.45431 14.7839 6.10938 14.9198 6.4668C15.4124 6.05832 16.1937 6.09236 16.4995 6.4668ZM12.86 7.31469C12.86 7.86698 12.4132 8.31469 11.862 8.31469C11.3108 8.31469 10.864 7.86698 10.864 7.31469C10.864 6.76241 11.3108 6.31469 11.862 6.31469C12.4132 6.31469 12.86 6.76241 12.86 7.31469Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2321 9.96795C23.2321 10.2861 22.9742 10.5439 22.6561 10.5439H17.8561C17.5379 10.5439 17.2801 10.2861 17.2801 9.96795C17.2801 9.64983 17.5379 9.39195 17.8561 9.39195H22.6561C22.9742 9.39195 23.2321 9.64983 23.2321 9.96795Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.288 12.96C19.9699 12.96 19.712 12.7021 19.712 12.384L19.712 7.58396C19.712 7.26585 19.9699 7.00796 20.288 7.00796C20.6062 7.00796 20.864 7.26584 20.864 7.58396L20.864 12.384C20.864 12.7021 20.6062 12.96 20.288 12.96Z"
      fill="currentColor"
    />
  </svg>
);

export { DuckyIcon };
