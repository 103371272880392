import { useQuery } from '@tanstack/react-query';

import { SHOW_SITE_KEY } from 'api/profile';

export const useQueryShowSiteApiKey = () => {
  return useQuery(['useGetShowSiteApiKey'], () => SHOW_SITE_KEY(), {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Article';
      console.error(message);
      return message;
    },
  });
};
