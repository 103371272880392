const LoadingBigIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    {...props}
  >
    <path
      d="M21 1L21 7"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 35L21 41"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 21L35 21"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 21L1 21"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.1421 6.85795L30.8994 11.1006"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1001 30.8999L6.85742 35.1426"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.85795 6.85795L11.1006 11.1006"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.899 30.8999L35.1416 35.1426"
      stroke="#A66DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { LoadingBigIcon };
