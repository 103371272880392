const KeyBorderedIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="5"
      stroke="currentColor"
      strokeWidth="1.254"
    />
    <path
      d="M17.5 12.5L10.5 12.5"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10.5L17.5 12.5"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10.5L15 12.5"
      stroke="currentColor"
      strokeWidth="1.254"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 10.5 10.5)"
      stroke="currentColor"
      strokeWidth="1.254"
    />
  </svg>
);

export { KeyBorderedIcon };
