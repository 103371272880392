const SendIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 12H10M7.45352 4.26033L18.6842 9.87225C20.4386 10.7489 20.4386 13.2511 18.6842 14.1278L7.45352 19.7397C5.45607 20.7378 3.30682 18.6853 4.21382 16.6458L5.85018 12.9662C6.12373 12.3511 6.12373 11.6489 5.85018 11.0338L4.21382 7.35425C3.30682 5.31474 5.45607 3.26221 7.45352 4.26033Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

export { SendIcon };
