import React, { useEffect, useState } from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';

//hooks
import {
  useMutationRegisterTwoFactor,
  useMutationEnableTwoFactor,
  useMutationDisableTwoFactor,
  useQueryFetchProfileInfo,
} from '../../../../hooks/api';

// store
import { useAppStore } from 'store';

// types
// components(atoms)
import { Form } from 'antd';
import { LockLineIcon } from 'components/icons';
import { InputOTP } from 'antd-input-otp';

// styles
import './index.scss';
import * as yup from 'yup';
import { EOtpType } from 'types/models';
import { toast } from 'react-toastify';

// data

// func

const TwoFactorModal = ({ modalId, type }) => {
  const { t } = useTranslation();
  const { sModalClose, sSetOtpCode, sSetOtpType } = useAppStore();
  const [enabledFetchProfile, setEnabledFetchProfile] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const twoFactorSchema = yup.object().shape({
    password: yup.mixed().required(t('validationRequired')),
  });
  const yupSync = {
    async validator({ field }, value) {
      await twoFactorSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const { refetch: refetchProfile } =
    useQueryFetchProfileInfo(enabledFetchProfile);
  const {
    data: registerTwoFactorData,
    isLoading: isLoadingRegisterTwoFactor,
    mutate: mutateRegisterTwoFactor,
  } = useMutationRegisterTwoFactor();
  const {
    data: enableTwoFactorData,
    isLoading: isLoadingEnableTwoFactor,
    mutate: mutateEnableTwoFactor,
  } = useMutationEnableTwoFactor();
  const {
    data: disableTwoFactorData,
    isLoading: isLoadingDisableTwoFactor,
    mutate: mutateDisableTwoFactor,
  } = useMutationDisableTwoFactor();

  useEffect(() => {
    if (type && type === EOtpType.Register) {
      mutateRegisterTwoFactor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (enableTwoFactorData && !isLoadingEnableTwoFactor) {
      toast.success(t('toastTwoFactorEnabled'));
      setEnabledFetchProfile(true);
      refetchProfile();
      sSetOtpType(EOtpType.Confirm);
      sModalClose(modalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableTwoFactorData, isLoadingEnableTwoFactor]);

  useEffect(() => {
    if (disableTwoFactorData && !isLoadingDisableTwoFactor) {
      toast.success(t('toastTwoFactorDisabled'));
      setEnabledFetchProfile(true);
      refetchProfile();
      sSetOtpType(EOtpType.Confirm);
      sModalClose(modalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableTwoFactorData, isLoadingDisableTwoFactor]);
  const onConfirm = payload => {
    if (type === EOtpType.Confirm) {
      sSetOtpCode(payload.password.join(''));
      sModalClose(modalId);
    } else if (type === EOtpType.Register) {
      mutateEnableTwoFactor({ code: payload.password.join('') });
    } else if (type === EOtpType.Reset) {
      mutateDisableTwoFactor({ code: payload.password.join('') });
    }
  };

  return (
    <div className="dashboard__create-payment-password">
      <div className="two-factor--icon">
        <LockLineIcon />
      </div>
      <div className="two-factor--title">{t('2faTitle')}</div>
      {type === EOtpType.Register ? (
        <>
          {registerTwoFactorData && !isLoadingRegisterTwoFactor && (
            <>
              <div className="two-factor--qr">
                <img src={registerTwoFactorData.qr} alt={t('2faRegister')} />
              </div>
              <div className="two-factor--text">{t('or')}</div>
              <div className="two-factor--text">
                {registerTwoFactorData.secret}
              </div>
            </>
          )}
          <div className="two-factor--desc">{t('2faRegister')}</div>
        </>
      ) : (
        <div className="two-factor--desc">{t('2faEnterCode')}</div>
      )}
      <Form onFinish={onConfirm} form={form} layout="vertical">
        <div className="two-factor--input">
          <Form.Item
            name={'password'}
            /*@ts-ignore*/
            rules={[yupSync]}
          >
            <InputOTP autoSubmit={form} inputType="numeric" />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export { TwoFactorModal };
