import React, { useEffect, useState } from 'react';
import { Select, Button, Popover, Badge } from 'antd';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import IconRenderer from 'hoc/IconRenderer';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore, useCartStore } from 'store';

//hooks
import {
  useQueryFetchProfileInfo,
  useQueryFetchUserBalance,
  useUserBalanceSocket,
} from '../../../../../hooks/api';

// types
import { EButtonSizeType, EButtonTypeType, ESelectSizeType } from 'types/units';
import { EGameType } from 'types/models';

// components
import {
  ArrowDownIcon,
  CartFillIcon,
  ArrowRightIcon,
  PlusIcon,
  MinusIcon,
} from 'components/icons';

import { ModalTeleport } from 'components/atoms';
import {
  CartModal,
  WithdrawBalanceForm,
  WithdrawBalancePassword,
  WithdrawBalanceConfirmation,
  AddBalanceForm,
  TwoFactorModal,
} from 'components/features';

// styles
import './index.scss';

//data
import { games } from '../../components/data';
import { formatPrice } from '../../../../../utils/commons';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const HeaderActions = () => {
  const { t } = useTranslation();
  const { accessToken: isAuth } = useAuthStore();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { gameType, sSetGameType, sModalOpen, otpType } = useAppStore();
  const { cart } = useCartStore();
  const [summary, setSummary] = useState<number>(0);
  const [enabledFetchBalance, setEnabledFetchBalance] =
    useState<boolean>(false);

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);
  const [balance, setBalance] = useState<any>(null);
  const [enabledSocketBalance, setEnabledSocketBalance] = useState(false);
  const lastBalance = useUserBalanceSocket(
    enabledSocketBalance,
    profileData?.id,
  );
  useEffect(() => {
    if (profileData && !isLoadingProfileData) {
      setEnabledSocketBalance(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const { data: userBalance, isLoading: isLoadingUserBalance } =
    useQueryFetchUserBalance(enabledFetchBalance);

  useEffect(() => {
    if (userBalance && !isLoadingUserBalance) {
      setBalance(userBalance);
    }
  }, [userBalance, isLoadingUserBalance]);

  useEffect(() => {
    if (lastBalance) {
      setBalance({
        ...balance,
        amount: lastBalance.newBalance,
        reserved: lastBalance.reservedBalance,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastBalance]);

  useEffect(() => {
    if (isAuth) {
      setEnabledFetchBalance(true);
    }
  }, [isAuth]);

  useEffect(() => {
    let summaryCount = cart.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    setSummary(summaryCount);
  }, [cart]);

  const onChangeGameSelect = (value: EGameType) => {
    sSetGameType(value);
    window.location.reload();
  };

  const onShowCart = () => {
    sModalOpen('cart');
  };

  const onGoBalanceSettings = () => {
    navigate('/dashboard/security-and-payments');
  };

  const onGetMoney = () => {
    sModalOpen('withdrawForm');
  };
  const onAddMoney = () => {
    sModalOpen('addBalanceForm');
  };

  const getContent = () => {
    return (
      <div className="header-actions__balance-content">
        <div className="header-actions__balance-head">
          <div className="header-actions__balance-title">
            {t('yourBalance')}
          </div>
          <Button
            size={EButtonSizeType.Small}
            className="ant-btn-default--light header-actions__balance-btn"
            onClick={() => onGoBalanceSettings()}
          >
            {t('settings')}
            <ArrowRightIcon />
          </Button>
        </div>
        <div className="header-actions__balance-num">
          $ {formatPrice(balance?.amount)}
        </div>
        {balance && balance.reserved > 0 && (
          <div className="header-actions__balance-freeze">
            <span>$ {formatPrice(balance?.reserved)}</span> {t('freezed')}
          </div>
        )}
        <div className="header-actions__balance-actions">
          <Button type={EButtonTypeType.Primary} onClick={() => onAddMoney()}>
            {t('increaseBalance')} <PlusIcon />
          </Button>
          <Button
            type={EButtonTypeType.Primary}
            className="ant-btn-primary--success"
            onClick={() => onGetMoney()}
          >
            {t('decreaseBalance')} <MinusIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="header-actions">
      {width > MEDIA.M1024 && (
        <Select
          className="ant-select--light header-actions__select-game"
          size={ESelectSizeType.Middle}
          suffixIcon={<ArrowDownIcon />}
          value={gameType}
          onChange={onChangeGameSelect}
          dropdownAlign={{ offset: [0, 0] }}
          // @ts-ignore
          getPopupContainer={() => document.querySelector('.header-sticky')}
        >
          {games?.length &&
            games.map(game => {
              return (
                <Option value={game.value} label={game.label} key={game.value}>
                  <span
                    role="img"
                    className="header-actions__game-option-flag"
                    aria-label={game.label}
                  >
                    {IconRenderer(game.icon)}
                  </span>
                  {game.label}
                </Option>
              );
            })}
        </Select>
      )}

      {isAuth && (
        <>
          {!isLoadingUserBalance && userBalance && balance && (
            <Popover
              autoAdjustOverflow={true}
              placement={width > MEDIA.M425 ? 'bottomLeft' : 'bottom'}
              content={getContent}
              trigger="click"
              // @ts-ignore
              getPopupContainer={() => document.querySelector('.header-sticky')}
              overlayClassName={'header-actions__balance-popover'}
            >
              <Button className="header-actions__btn-balance">
                <div className="header-actions__btn-content">
                  $ <span>{formatPrice(balance?.amount)}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="8" fill="#8D5DD9" />
                    <path
                      d="M12 16V8M8 12L16 12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Button>
            </Popover>
          )}

          {cart?.length > 0 ? (
            <Badge count={cart ? cart.length : 0} overflowCount={10}>
              <Button
                type={EButtonTypeType.Primary}
                className="header-actions__btn-cart"
                onClick={() => onShowCart()}
              >
                <CartFillIcon /> ${' '}
                <span className="header-actions__btn-summary">
                  {formatPrice(summary)}
                </span>
              </Button>
            </Badge>
          ) : (
            <Button
              icon={<CartFillIcon />}
              className="header-actions__btn-cart"
              onClick={() => onShowCart()}
            />
          )}

          <ModalTeleport
            centered
            modalId={'cart'}
            width={800}
            modalClass={'cart-modal__wrapper'}
          >
            <CartModal modalId={'cart'} />
          </ModalTeleport>
          <ModalTeleport centered modalId={'withdrawForm'} width={785}>
            <WithdrawBalanceForm modalId={'withdrawForm'} />
          </ModalTeleport>
          <ModalTeleport centered modalId={'addBalanceForm'} width={785}>
            <AddBalanceForm modalId={'addBalanceForm'} />
          </ModalTeleport>
          <ModalTeleport centered modalId={'twoFactorModal'} width={425}>
            <TwoFactorModal type={otpType} modalId={'twoFactorModal'} />
          </ModalTeleport>
        </>
      )}
    </div>
  );
};

export { HeaderActions };
