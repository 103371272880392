export enum EFloatRangeSizeType {
  Default = 'default',
  Small = 'small',
}
export interface IPropsType {
  minValue?: number;
  maxValue?: number;
  size?: EFloatRangeSizeType;
  isTooltip?: boolean;
  isTooltipValue?: boolean;
}
