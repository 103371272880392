export enum EMarketFilterType {
  Stickers = 'Stickers',
  Price = 'Price',
  Side = 'Side',
  Top = 'Top',
  Heroes = 'Heroes',
}

export enum EMarketFilterUnitedType {
  And = 'and',
  Or = 'or',
}

export enum EMarketFilterUrlType {
  Range = 'Range',
  Multiselect = 'Multiselect',
  Radio = 'Radio',
}

export enum EMarketFilterCategoryType {
  All = 'CheckedAll',
  Single = 'Single',
}
