// core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//hooks

// utils

// locales
import { useTranslation } from 'react-i18next';

// types

// components(atoms)
import { ArrowRightIcon } from 'components/icons';
import { SupportTicketsStatus } from '../SupportTicketsStatus';

// styles
import './index.scss';

//data

const SupportTicketsCard = ({ ticket }) => {
  const { t } = useTranslation();
  return (
    <RouterLink to={`/support/ticket/${ticket.id}`}>
      <div className="tickets__card">
        <div className="tickets__card--left">
          <div className="tickets__card--title">{ticket.subject}</div>
          <div className="tickets__card--desc">{ticket.content}</div>
        </div>
        <div className="tickets__card--right">
          <div className="tickets__card--status">
            {t('status')}:{' '}
            <SupportTicketsStatus type={true} status={ticket.status} />
          </div>
          <div className="tickets__card--state">
            {t('state')}:{' '}
            <SupportTicketsStatus type={false} status={ticket.state} />
          </div>
          <div className="tickets__card--icon">
            <ArrowRightIcon />
          </div>
        </div>
      </div>
    </RouterLink>
  );
};

export { SupportTicketsCard };
