// core
import React from 'react';
import { Typography } from 'antd';

// utils

// store

// types
import { IPropsType } from './types';
// components
import { InventoryExpose } from 'containers/user/Inventory/InventoryProducts/InventoryExpose';

// styles
import './index.scss';

// data
const { Link } = Typography;

const MobileExposeModal = ({ modalId, onExposedMultiple }: IPropsType) => {
  // locales hook

  return (
    <div className="mobile-expose-modal">
      <InventoryExpose onExposedMultiple={onExposedMultiple} />
    </div>
  );
};

export { MobileExposeModal };
