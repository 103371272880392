import React, { useState, useEffect } from 'react';
import { useQueryFetchNotificationList } from '../../../../../hooks/api';

interface TimerProps {
  expiredAt: Date;
}

const InventoryPendingTimer: React.FC<TimerProps> = ({ expiredAt }) => {
  const [timeRemaining, setTimeRemaining] = useState<any>(null);
  const [enabledRefetch, setEnabledRefetch] = useState(false);
  const [once, setOnce] = useState<boolean>(true);
  const { refetch: refetchNotificationList } =
    useQueryFetchNotificationList(false);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const currentTime = new Date().getTime();

      // Convert expiredAt string to Date object
      const expirationTime = new Date(expiredAt).getTime();

      const remainingTime = expirationTime - currentTime;
      if (remainingTime <= 0) {
        // If current time is more than expiredAt, set remaining time to null
        setTimeRemaining(null);
        setEnabledRefetch(true);
      } else {
        // Calculate minutes and seconds from remaining milliseconds
        const minutes = Math.floor(remainingTime / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        // Set the remaining time in seconds
        setTimeRemaining(minutes * 60 + seconds);
      }
    };

    calculateTimeRemaining();

    const timerInterval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredAt]);

  useEffect(() => {
    if (enabledRefetch && once) {
      refetchNotificationList();
      setOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledRefetch, once]);

  if (timeRemaining === null) {
    return <span>00:00</span>;
  }

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;

  return <span className="timer__time">{formattedTime}</span>;
};

export { InventoryPendingTimer };
