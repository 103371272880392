import { useMutation } from '@tanstack/react-query';
import { REJECT_TRADE } from '../../../api/inventory';

export const useMutationRejectTrade = () => {
  return useMutation(REJECT_TRADE, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while reject trade';
      console.error(message);
      return message;
    },
  });
};
