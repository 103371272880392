const EtheriumIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8496 21.5474L20.0471 30.2811V25.2091L13.8496 21.5474Z"
      fill="#8A92B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0469 25.2091V30.2816L26.2485 21.5474L20.0469 25.2091Z"
      fill="#62688F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0469 10.0884V24.0357L26.2444 20.372L20.0469 10.0884Z"
      fill="#62688F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0473 17.5552L20.0469 24.0356L26.2448 20.3723L20.0473 17.5552Z"
      fill="#454A75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8496 20.372L20.0471 24.0357V10.0884L13.8496 20.372Z"
      fill="#8A92B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0476 17.5552L13.8496 20.3719L20.0471 24.0356L20.0476 17.5552Z"
      fill="#62688F"
    />
  </svg>
);

export { EtheriumIcon };
