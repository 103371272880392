import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { TProfile } from 'types/models/profile';

type ProfileState = {
  profile: TProfile | null;
  setProfile: (payload: TProfile) => void;
};

const useProfileStore = create(
  devtools(
    persist<ProfileState>(
      set => ({
        profile: null,
        setProfile: (payload: TProfile) => {
          set(state => ({
            profile: payload,
          }));
        },
      }),
      { name: 'profile' },
    ),
  ),
);
export { useProfileStore };
