// core
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';

// hooks
import { useQueryFetchProfileInfo } from '../../../../hooks/api';
//store
import { useAuthStore, useAppStore } from 'store';

// locales

// types

// components(atoms)
import { HeaderProfile } from './HeaderProfile';
import { HeaderActions } from './HeaderActions';
import { HeaderNav } from '../components/HeaderNav';
import { HeaderDrawer } from 'components/features';
import { LogoIcon, BurgerIcon, CloseIcon } from 'components/icons';

// styles
import './index.scss';

// data

// func
const HeaderSticky = () => {
  const { accessToken: isAuth } = useAuthStore();
  const { drawer, sDrawerClose, sDrawerOpen } = useAppStore();
  const { width } = useWindowSize();
  const [enabledFetchProfile, setEnabledFetchProfile] =
    useState<boolean>(false);

  // eslint-disable-next-line no-empty-pattern
  const {} = useQueryFetchProfileInfo(enabledFetchProfile);

  useEffect(() => {
    if (isAuth) {
      setEnabledFetchProfile(true);
    }
  }, [isAuth]);
  const onHeaderDrawerToggle = () => {
    if (drawer['headerDrawer']) {
      sDrawerClose('headerDrawer');
    } else {
      sDrawerOpen('headerDrawer');
    }
  };

  return (
    <div className="header-sticky">
      <div className="header-sticky__left">
        {width > MEDIA.M1360 && (
          <RouterLink className="header-sticky__logo" to={'/'}>
            <LogoIcon />
          </RouterLink>
        )}
        {width > MEDIA.M1280 ? (
          <HeaderNav />
        ) : (
          <div
            className="header-sticky__btn-drawer"
            onClick={() => onHeaderDrawerToggle()}
          >
            {drawer['headerDrawer'] ? <CloseIcon /> : <BurgerIcon />}
          </div>
        )}
      </div>

      <div className="header-sticky__right">
        <HeaderActions />
        <HeaderProfile />
      </div>

      {width <= MEDIA.M1280 && <HeaderDrawer />}
    </div>
  );
};

export { HeaderSticky };
