// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//store
import { useAppStore } from 'store';

//hooks
import { useQueryFetchStaticPage } from '../../../hooks/api';

// utils

// locales

// types
import { ELoaderTypeType } from '../../../components/atoms/AppLoader/types';
// components(atoms)
import { TextPageSEO } from './SEO';
import { AppLoader } from 'components/atoms';

// styles
import './index.scss';

//tempo

// func
const TextPage = () => {
  const { slug } = useParams();
  const [enabledFetch, setEnabledFetch] = useState(false);
  const { data: pageData, isLoading: isLoadingPageData } =
    useQueryFetchStaticPage(slug ? slug : '', enabledFetch);

  useEffect(() => {
    if (slug?.length) {
      setEnabledFetch(true);
    }
  }, [slug]);

  return (
    <>
      <TextPageSEO
        title={pageData ? (pageData?.data?.content[0]?.title as string) : ''}
      />
      {pageData && !isLoadingPageData ? (
        <div className="text-page">
          <div className="app-container app-container--small">
            {pageData.data.content.map((el, index) => {
              return (
                <div className="text-page-wrap" key={index}>
                  <h1 className="text-page-title">{el.title}</h1>
                  <div
                    className="text-page-content"
                    dangerouslySetInnerHTML={{
                      __html: el.description,
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <AppLoader type={ELoaderTypeType.Global} />
      )}
    </>
  );
};

export { TextPage };
