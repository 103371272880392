import { useQuery } from '@tanstack/react-query';

//types
// services
import { GET_WITHDRAW_METHODS } from 'api/wallet';

export const useQueryFetchWithdrawMethods = () => {
  return useQuery(['useGetWithdrawMethods'], () => GET_WITHDRAW_METHODS(), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err
        ? err?.message
        : 'Error while fetching withdraw methods';
      console.error(message);
      return message;
    },
  });
};
