export const floatRanges: Array<{
  label: string;
  minValue: number;
  maxValue: number;
  minTitle: string;
  maxTitle: string;
}> = [
  {
    label: 'floatFactoryNew',
    minValue: 0.0,
    maxValue: 0.07,
    minTitle: '0.00',
    maxTitle: '0.07',
  },
  {
    label: 'floatMinimalWear',
    minValue: 0.07,
    maxValue: 0.15,
    minTitle: '0.07',
    maxTitle: '0.15',
  },
  {
    label: 'floatFieldTested',
    minValue: 0.15,
    maxValue: 0.37,
    minTitle: '0.15',
    maxTitle: '0.37',
  },
  {
    label: 'floatWellWorn',
    minValue: 0.37,
    maxValue: 0.44,
    minTitle: '0.37',
    maxTitle: '0.44',
  },
  {
    label: 'floatBattleScarred',
    minValue: 0.44,
    maxValue: 1,
    minTitle: '0.44',
    maxTitle: '1.00',
  },
];
