// core
import React from 'react';
import { Button } from 'antd';

//hooks

// utils

// locales
import { useTranslation } from 'react-i18next';
// types

// components(atoms)
import { Page404SEO } from './SEO';

// styles
import './index.scss';

//tempo
import { toast } from 'react-toastify';
import { NotificationLetterIcon } from 'components/icons';
import { EmptyCard } from 'components/features';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { Link as RouterLink } from 'react-router-dom';

const onShowNotice = () => {
  toast.success('success Login');
  toast.info('info Login');
  toast.error('error Login');
  toast.warning('warning Login');
  toast('toast Login', {
    icon: () => <NotificationLetterIcon />,
  });
  toast('toast Login');
};

// func
const Page404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Page404SEO />
      <div className="page-404">
        <EmptyCard isError title="404" description={t('missingDesc')} />
        <RouterLink to={'/market'}>
          <Button size={EButtonSizeType.Large} type={EButtonTypeType.Primary}>
            {t('goToHomePage')}
          </Button>
        </RouterLink>
      </div>
    </>
  );
};

export { Page404 };
