import React, { useEffect } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// store
import { useAuthStore } from 'store/auth';
import { useAppStore } from 'store';

//type
import { IPropsType, ERoutePermissionsType } from './types';

const RoutePermissions = ({
  children,
  permissions,
}: IPropsType): React.ReactElement | null => {
  const { sModalOpen } = useAppStore();
  let location = useLocation();
  const navigate = useNavigate();
  const { accessToken: isAuth } = useAuthStore();
  // auth
  useEffect(() => {
    if (!isAuth) {
      if (permissions.includes(ERoutePermissionsType.Auth) && !isAuth) {
        // toast.error('Unauthenticated');
        sModalOpen('steamAuth');
      }
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
  // all permission matched
  return children;
};
export default RoutePermissions;
