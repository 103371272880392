import { $api } from 'utils/request';

import { stringify } from 'query-string';

import {
  TAssignReferralPayload,
  TReferralChartResponse,
  TReferralLinkResponse,
  TReferralChartRequestPayload,
} from '../types/api';

export const ASSIGN_REFERRAL = async (payload: TAssignReferralPayload) => {
  const url = `referral/link/${payload}`;
  const response = await $api.get(url);
  return response.data;
};

export const SET_REFERRAL_LINK = async (payload: any) => {
  const url = `referral/url`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const GET_REFERRAL_LINK = async () => {
  const url = `referral/income/summary`;
  const response = await $api.get<TReferralLinkResponse>(url);
  return response.data;
};

export const GET_REFERRAL_CHART = async (
  query: TReferralChartRequestPayload,
) => {
  const url = `referral/income/history?${stringify(query)}`;
  const response = await $api.get<TReferralChartResponse>(url);
  return response.data;
};
