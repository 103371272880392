import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { CREATE_AUTOBUY_REQUEST } from '../../../api/transactions';

export const useMutationCreateAutobuy = () => {
  return useMutation(CREATE_AUTOBUY_REQUEST, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create autobuy';
      console.error(message);
      return message;
    },
  });
};
