import { useInfiniteQuery } from '@tanstack/react-query';
//types
// services
import { POST_INVENTORY_ITEMS } from 'api/inventory';

export const useInfinityQueryInventoryItems = ({ payload, enabled }) => {
  const queryInfo = useInfiniteQuery(
    ['useInfinityQueryInventoryItems', JSON.stringify(payload)],
    ({ pageParam = 1 }) => POST_INVENTORY_ITEMS({ pageParam, payload }),
    {
      enabled,
      keepPreviousData: true,
      staleTime: 0,
      refetchOnMount: () => 'always',
      getNextPageParam: lastPage =>
        lastPage?.meta?.hasNext ? lastPage.meta.page + 1 : undefined,
      onError: (err: Error) => {
        const message = err ? err?.message : 'Error while fetching inventory';
        console.error(message);
        return message;
      },
    },
  );
  const allItems = queryInfo.data?.pages.map(page => page.data).flat();
  const lastPage = queryInfo.data?.pages?.at(-1);

  return {
    ...queryInfo,
    inventoryItems: allItems ? allItems : [],
    inventoryMeta: lastPage ? lastPage.meta : {},
  };
};
