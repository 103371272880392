import { useMutation } from '@tanstack/react-query';

//types

// services
import { ASSIGN_REFERRAL } from '../../../api/referral';

export const useMutationAssignReferral = () => {
  return useMutation(ASSIGN_REFERRAL, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while assigning referral';
      console.error(message);
      return message;
    },
  });
};
