import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { SEND_CHAT_MESSAGE } from '../../../api/support';

export const useMutationSendChatMessage = () => {
  return useMutation(SEND_CHAT_MESSAGE, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create Ticket';
      console.error(message);
      return message;
    },
  });
};
