import { useQuery } from '@tanstack/react-query';
import { useAppStore } from 'store';
//types

// services
import { GET_BANNER_LIST } from 'api/settings';

export const useQueryFetchBanner = () => {
  return useQuery(['useGetBannerList'], () => GET_BANNER_LIST(), {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching banner';
      console.error(message);
      return message;
    },
  });
};
