import {
  MasterCardIcon,
  VisaCardIcon,
  BitcoinIcon,
  EtheriumIcon,
  PayPalIcon,
} from 'components/icons';

export const countryList = [
  {
    id: 'en',
    label: 'United States',
    flag: '🇺🇸',
  },
  {
    id: 'uk',
    label: 'Україна',
    flag: '🇺🇦',
  },
  {
    id: 'ru',
    label: 'Russia',
    flag: '🇦🇴',
  },
];

export const cardList = [
  {
    label: 'MasterCard',
    tax: 12,
    icon: MasterCardIcon,
    id: 1,
  },
  {
    label: 'Visa card',
    tax: 12,
    icon: VisaCardIcon,
    id: 2,
  },
  {
    label: 'Paypal',
    tax: 9,
    icon: PayPalIcon,
    id: 3,
  },
  {
    label: 'Bitcoin',
    tax: 3,
    icon: BitcoinIcon,
    id: 4,
  },
  {
    label: 'Etherium',
    tax: 3,
    icon: EtheriumIcon,
    id: 5,
  },
];
