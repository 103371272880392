const ArrowRightLightIcon = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7144 16L20.0072 12.7071C20.3978 12.3166 20.3978 11.6834 20.0072 11.2929L16.7144 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M19.7144 12L5.71436 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export { ArrowRightLightIcon };
