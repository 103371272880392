const CardSmallGlowIcon = props => (
  <svg
    width="146"
    height="127"
    viewBox="0 0 146 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_870_86090)">
      <circle cx="73" r="50" fill="currentColor" />
    </g>
    <defs>
      <filter
        id="filter0_f_870_86090"
        x="-63"
        y="-136"
        width="272"
        height="272"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="50"
          result="effect1_foregroundBlur_870_86090"
        />
      </filter>
    </defs>
  </svg>
);

export { CardSmallGlowIcon };
