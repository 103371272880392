const EditIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6021 3.8944C14.6265 2.86849 16.2879 2.86787 17.313 3.89299L19.8049 6.38485C20.8213 7.40123 20.8318 9.04664 19.8285 10.076L10.5954 19.5492C9.9134 20.249 8.97799 20.6436 8.00124 20.6435L5.2491 20.6434C4.04092 20.6434 3.07606 19.6362 3.12687 18.4282L3.24503 15.6192C3.28321 14.7114 3.66048 13.8511 4.30219 13.2084L13.6021 3.8944ZM16.4298 4.77755C15.8928 4.24057 15.0226 4.2409 14.486 4.77828L12.7346 6.53234L17.1922 10.99L18.9341 9.20285C19.4596 8.66367 19.4541 7.80179 18.9217 7.2694L16.4298 4.77755ZM5.18607 14.0923L11.8514 7.41689L16.3197 11.8852L9.70092 18.6761C9.25409 19.1346 8.64124 19.3931 8.0013 19.3931L5.24915 19.3929C4.75167 19.3929 4.35437 18.9782 4.37529 18.4808L4.49345 15.6718C4.51846 15.077 4.76564 14.5134 5.18607 14.0923ZM20.5148 20.5702C20.8599 20.5702 21.1396 20.2903 21.1396 19.945C21.1396 19.5997 20.8599 19.3197 20.5148 19.3197H14.3931C14.0481 19.3197 13.7683 19.5997 13.7683 19.945C13.7683 20.2903 14.0481 20.5702 14.3931 20.5702H20.5148Z"
      fill="currentColor"
    />
  </svg>
);

export { EditIcon };
