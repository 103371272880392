import { useMutation } from '@tanstack/react-query';
import { READ_NOTIFICATION } from '../../../api/notifications';

export const useMutationReadNotification = () => {
  return useMutation(READ_NOTIFICATION, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while accept trade';
      console.error(message);
      return message;
    },
  });
};
