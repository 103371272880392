const HelpIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.4" cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.75C11.3096 7.75 10.75 8.30964 10.75 9C10.75 9.41421 10.4142 9.75 10 9.75C9.58579 9.75 9.25 9.41421 9.25 9C9.25 7.48122 10.4812 6.25 12 6.25C13.5188 6.25 14.75 7.48122 14.75 9C14.75 9.54634 14.5899 10.0576 14.3138 10.4866C14.1409 10.7554 13.9387 11.0119 13.7523 11.2436C13.7182 11.286 13.6847 11.3275 13.6518 11.3683C13.4971 11.5597 13.3551 11.7354 13.2239 11.9158C12.9003 12.3605 12.75 12.6947 12.75 13V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V13C11.25 12.2007 11.6424 11.5398 12.011 11.0333C12.1667 10.8192 12.3373 10.6081 12.4925 10.4162C12.5236 10.3777 12.554 10.3401 12.5836 10.3033C12.7688 10.0731 12.9265 9.87065 13.0524 9.67495C13.1774 9.48068 13.25 9.24996 13.25 9C13.25 8.30964 12.6904 7.75 12 7.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.25C12.4142 15.25 12.75 15.5858 12.75 16V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V16C11.25 15.5858 11.5858 15.25 12 15.25Z"
      fill="currentColor"
    />
  </svg>
);

export { HelpIcon };
