// core
import React from 'react';

//hooks
import { useAppStore } from 'store';
// utils
import { getVariable } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';

// types

// components(atoms)

// styles
import './index.scss';

//tempo

// func
const HomeSecurity = () => {
  const { t } = useTranslation();
  const { variables } = useAppStore();
  return (
    <>
      <div className="home-security">
        <div className="app-container app-container--small">
          <div className="home-security__wrap">
            <div className="home-security__image">
              <img
                src={getVariable(variables, 'home_security_image')}
                alt="Skinsty"
              />
            </div>
            <div className="home-security__content">
              <div className="home-security__content-icon">
                <img
                  src={getVariable(variables, 'home_security_icon')}
                  alt="Skinsty"
                />
              </div>
              <div className="home-security__content-title">
                {t('fullSecurity')} <br /> {t('fromScammers')}
              </div>
              <div className="home-security__content-desc">
                {t('InCaseOfReplacement')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { HomeSecurity };
