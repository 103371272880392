import React, { useState, useEffect } from 'react';
import { useQueryFetchProfileInfo } from '../../../../../hooks/api';

const HeaderAlertTimer = ({ targetDate }) => {
  const { refetch: refetchProfile } = useQueryFetchProfileInfo(false);
  const calculateTimeRemaining = () => {
    const now = new Date().getTime(); // Convert current date to timestamp
    const target = new Date(targetDate).getTime(); // Convert target date to timestamp
    const difference = target - now;

    if (difference <= 0) {
      // Время истекло
      refetchProfile();
      return { hours: '00', minutes: '00', seconds: '00' };
    }

    const totalSeconds = Math.floor(difference / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formatTime = time => (time < 10 ? `0${time}` : time);

    return {
      hours: formatTime(hours),
      minutes: formatTime(minutes),
      seconds: formatTime(seconds),
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  return (
    <>{`${timeRemaining.hours}:${timeRemaining.minutes}:${timeRemaining.seconds}`}</>
  );
};

export { HeaderAlertTimer };
