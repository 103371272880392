import { useMutation } from '@tanstack/react-query';

//types

// services
import { BUY_CART } from '../../../api/cart';

export const useMutationBuyCart = () => {
  return useMutation(BUY_CART, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while buy cart';
      console.error(message);
      return message;
    },
  });
};
