const MasterCardIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_580_65354)">
      <path
        d="M33.3336 20.1036C33.3336 24.6587 29.6483 28.344 25.0933 28.344C23.1705 28.344 21.408 27.6802 20.0117 26.5815C21.9345 25.0707 23.1476 22.736 23.1476 20.1036C23.1476 17.4713 21.9116 15.1365 20.0117 13.6258C21.408 12.5271 23.1705 11.8633 25.0933 11.8633C29.6483 11.8633 33.3336 15.5714 33.3336 20.1036Z"
        fill="#F79E1B"
      />
      <path
        d="M20.0104 13.6257C20.0104 13.6257 20.0104 13.6257 20.0104 13.6257C21.9102 15.1365 23.1463 17.4712 23.1463 20.1036C23.1463 22.7359 21.9331 25.0707 20.0104 26.5814L19.9874 26.5814C18.0876 25.0935 16.8516 22.7359 16.8516 20.1036C16.8516 17.4713 18.0876 15.1365 19.9875 13.6258C19.9874 13.6258 19.9875 13.6258 19.9875 13.6258L20.0104 13.6257Z"
        fill="#FF5F00"
      />
      <path
        d="M16.852 20.1036C16.852 17.4713 18.088 15.1365 19.9879 13.6258C18.5916 12.5271 16.8291 11.8633 14.9064 11.8633C10.3513 11.8633 6.66602 15.5485 6.66602 20.1036C6.66602 24.6587 10.3513 28.344 14.9064 28.344C16.8291 28.344 18.5916 27.6802 19.9879 26.5815C18.088 25.0936 16.852 22.736 16.852 20.1036Z"
        fill="#EB001B"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_65354">
        <rect
          width="26.6667"
          height="16.4807"
          fill="white"
          transform="translate(6.66602 11.8633)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { MasterCardIcon };
