export enum ELoaderTypeType {
  Global = 'global',
  Absolute = 'absolute',
  Relative = 'relative',
  Component = 'component',
}

export enum ELoaderThemeType {
  Light = 'light',
  Dark = 'dark',
}

export interface IPropsType {
  className?: string;
  type?: ELoaderTypeType;
  theme?: ELoaderThemeType;
  size?: number;
}
