import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { SEND_STEAM_SETTINGS } from '../../../api/profile';

export const useMutationSendSteamSettings = () => {
  return useMutation(SEND_STEAM_SETTINGS, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create Ticket';
      console.error(message);
      return message;
    },
  });
};
