import { useQuery } from '@tanstack/react-query';

//types
import { IRelatedProductsRequestParams } from 'types/api/product';

// services
import { GET_RELATED_PRODUCTS } from 'api/product';

export const useQueryFetchRelatedProducts = ({
  id,
  payload,
}: IRelatedProductsRequestParams) => {
  return useQuery(
    ['useGetRelatedProducts', { id, payload }],
    () => GET_RELATED_PRODUCTS({ id, payload }),
    {
      refetchOnMount: () => 'always',
      onSuccess: data => {
        return data;
      },
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching Related products';
        console.error(message);
        return message;
      },
    },
  );
};
