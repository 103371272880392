const FlagRu = props => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_589_64698)">
      <path d="M0 -0.00292969V15.997H24V-0.00292969H0Z" fill="white" />
      <path d="M0 0H24V16.0002H0V0Z" fill="#0052B4" />
      <path d="M0 0H24V5.3332H0V0Z" fill="white" />
      <path d="M0 10.6665H24V15.9997H0V10.6665Z" fill="#D80027" />
    </g>
    <defs>
      <clipPath id="clip0_589_64698">
        <rect width="24" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { FlagRu };
