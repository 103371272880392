import React, { useEffect, useState } from 'react';
// locale

// store

// types
import { EGameType } from 'types/models';

// components
import { ProductCardCSGO } from './ProductCardCSGO';
import { ProductCardDota } from './ProductCardDota';
// styles
import './index.scss';
import { useCartStore, useInventoryStore } from 'store';

//data

const ProductCard = ({
  item,
  size,
  gameType,
  cardType,
  isInventory = false,
}) => {
  const { cart } = useCartStore();
  const { inventoryExpose } = useInventoryStore();

  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [isInExpose, setIsInExpose] = useState<boolean>(false);

  useEffect(() => {
    let findData = cart.find(i => i.id === item.id);

    setIsInCart(!!findData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cart)]);

  useEffect(() => {
    let findData = inventoryExpose.find(i => i.id === item.id);

    setIsInExpose(!!findData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inventoryExpose)]);

  return (
    <div className="product-card">
      {gameType === EGameType.CSGO && (
        <ProductCardCSGO
          isInCart={isInCart}
          isInExpose={isInExpose}
          data={item}
          size={size}
          cardType={cardType}
          isInventory={isInventory}
        />
      )}
      {gameType === EGameType.Dota && (
        <ProductCardDota
          isInCart={isInCart}
          isInExpose={isInExpose}
          data={item}
          size={size}
          cardType={cardType}
          isInventory={isInventory}
        />
      )}
    </div>
  );
};

export { ProductCard };
