import moment from 'moment';
export const selectData = [
  {
    label: 'filterReferalSelectAll',
    value: '',
  },
  {
    label: 'filterReferalSelectToday',
    value: 'today',
  },
  {
    label: 'filterReferalSelectYesterday',
    value: 'yesterday',
  },
];

export const dataRangeValue = [
  {
    value: null,
    id: '',
  },
  {
    value: {
      from: moment().format('YYYY-MM-DD'),
      to: moment().add(1, 'day').format('YYYY-MM-DD'),
    },
    id: 'today',
  },
  {
    value: {
      from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
    id: 'yesterday',
  },
];
