const FileIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.4"
      d="M7 22C8.86748 22 10.4361 20.7202 10.8766 18.9899C11.0128 18.4547 11.4477 18 12 18H19V5C19 3.34315 17.6569 2 16 2H6C4.34315 2 3 3.34315 3 5V18C3 20.2091 4.79086 22 7 22Z"
      fill="currentColor"
    />
    <path
      d="M19 22C20.8675 22 22.4361 20.7202 22.8766 18.9899C23.0128 18.4547 22.5523 18 22 18H19H12C11.4477 18 11.0128 18.4547 10.8766 18.9899C10.4361 20.7202 8.86748 22 7 22H19Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 7C6.25 6.58579 6.58579 6.25 7 6.25H15C15.4142 6.25 15.75 6.58579 15.75 7C15.75 7.41421 15.4142 7.75 15 7.75H7C6.58579 7.75 6.25 7.41421 6.25 7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 12C6.25 11.5858 6.58579 11.25 7 11.25L11 11.25C11.4142 11.25 11.75 11.5858 11.75 12C11.75 12.4142 11.4142 12.75 11 12.75L7 12.75C6.58579 12.75 6.25 12.4142 6.25 12Z"
      fill="currentColor"
    />
  </svg>
);

export { FileIcon };
