// core
import React from 'react';
//hooks

// utils
import { formatDate } from '../../../../../utils/commons';
// locales

// types
import { EAvatarSizeType } from '../../../../../types/units/avatar';
import './index.scss';
import { EChatUserType } from '../../../../../types/models/chatUser';
// components(atoms)
import { Avatar } from 'antd';

// styles

//data

const SupportChatMessage = ({ message }) => {
  return (
    <div
      className={
        'message' +
        (message.member?.role === EChatUserType.User ? ' message--user' : '')
      }
    >
      <div className="message__avatar">
        <Avatar size={EAvatarSizeType.Default} src={message.member?.avatar}>
          {message.member?.username.charAt(0)}
        </Avatar>
      </div>
      <div className="message__content">
        <div className="message__content--name">
          {message.member?.username
            ? message.member?.username
            : message.memberId}
        </div>
        {message.content?.length ? (
          <div
            className="message__content--text"
            dangerouslySetInnerHTML={{ __html: message.content }}
          />
        ) : (
          ''
        )}
        {message.attachments?.length
          ? message.attachments.map(el => {
              return (
                <div className="message__content--image" key={el.id}>
                  <img src={el.url} alt={message.content} />
                </div>
              );
            })
          : ''}
        <div className="message__content--timestamp">
          {formatDate(message.updatedAt, 'LT')}
        </div>
      </div>
    </div>
  );
};
export { SupportChatMessage };
