export const faqFilters: Array<{
  title: string;
  value: string | undefined;
}> = [
  {
    title: 'filterFaqAll',
    value: undefined,
  },
  {
    title: 'filterFaqSell',
    value: 'sell',
  },
  {
    title: 'filterFaqBuy',
    value: 'buy',
  },
  {
    title: 'filterFaqOther',
    value: 'other',
  },
];
