export enum EMediaSizeType {
  S375 = 375,
  S480 = 480,
  S768 = 768,
  S1024 = 1024,
  S1140 = 1140,
  S1280 = 1280,
  S1440 = 1440,
  S1680 = 1680,
  S1920 = 1920,
}
