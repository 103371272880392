import React from 'react';

// utils

// locales

// store

// types

// components(atoms)
import { HeaderSimple, HeaderSticky, Footer } from 'components/features';

// styles
import './index.scss';

// data

// func
const UserLayout = ({ children }) => {
  // hooks

  return (
    <div className="app-layout app-layout--user">
      <div className="app-layout__header">
        <HeaderSimple />
      </div>
      <div className="app-layout__content">
        <HeaderSticky />
        {children}
      </div>
      <div className="app-layout__footer">
        <Footer />
      </div>
    </div>
  );
};

export { UserLayout };
