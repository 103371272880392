// useSocket.ts
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`; // Replace with your backend server URL

export const useInventorySocket = (enabled, userId): any | null => {
  const [inventoryStatus, setInventoryStatus] = useState<any | null>({
    totalItems: 0,
    processedItems: 0,
  });
  useEffect(() => {
    if (enabled) {
      const socketInstance = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
      });
      socketInstance.on('connect', () => {
        socketInstance.emit('join', userId.toString());
      });

      socketInstance.on('inventoryFetchStart', (data: any) => {
        setInventoryStatus({ totalItems: data.totalItems, processedItems: 0 });
      });
      socketInstance.on('inventoryFetch', (data: any) => {
        setInventoryStatus(prevData => {
          return { ...prevData, processedItems: data.processedItems };
        });
      });
      // socketInstance.on('inventoryFetchFinish', (data: any) => {
      //   setInventoryStatus(prevData => {
      //     return { ...prevData, processed: data.totalItems };
      //   });
      //   // You can update state or perform any other actions here
      // });

      // Cleanup the socket connection when the component unmounts
      return () => {
        socketInstance.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return inventoryStatus;
};

export default useInventorySocket;
