import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
//types

// services
import { POST_INVENTORY_ITEMS_TO_MARKET } from 'api/inventory';
import { useTranslation } from 'react-i18next';

export const useMutationInventoryItemsToMarket = () => {
  const { t } = useTranslation();
  return useMutation(POST_INVENTORY_ITEMS_TO_MARKET, {
    onSuccess: (data, variables, context) => {
      // toast.success(t('toastProductsSale'));
    },
    onError: (err: Error) => {
      // After error call
      const message = err ? err?.message : 'Error while fetching Profile';
      console.error(message);
      return message;
    },

    onSettled: (data, error, variables, context) => {
      // After error or success call... doesn't matter!
    },
  });
};
