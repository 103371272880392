import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useWindowSize } from 'react-use';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MEDIA } from 'utils/constants';
// core
// utils
// locales
import { useTranslation } from 'react-i18next';
// store
import {
  useAppStore,
  useAuthStore,
  useCartStore,
  useInventoryStore,
} from 'store';

// types
import { IPropsType } from './types';
import {
  EButtonSizeType,
  EButtonTypeType,
  EInventoryAsideType,
} from 'types/units';
import { EProductCardType } from '../type';
// components(atoms)
import { ArrowRightIcon } from 'components/icons';
import { AppBtnCart } from 'components/atoms';
// styles
import './index.scss';
import { formatPrice } from '../../../../../utils/commons';
import {
  useMutationBuyCart,
  useMutationCheckCart,
} from '../../../../../hooks/api';
import { toast } from 'react-toastify';

// data

// func
const ProductCardActions = ({
  data,
  cardType,
  isInCart,
  isInExpose,
}: IPropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessToken: isAuth } = useAuthStore();
  const { width } = useWindowSize();
  const {
    modal,
    commission_percentage,
    sModalOpen,
    sSetHeaderOffset,
    sSetGameType,
  } = useAppStore();
  const { cart, sSetCartData, sSetCartProduct } = useCartStore();
  const {
    inventoryAside,
    inventoryExpose,
    inventoryProduct,
    sSetInventoryExpose,
    sSetInventoryExposeModal,
    sSetInventoryAside,
    sSetInventoryProduct,
  } = useInventoryStore();
  const {
    data: buyProductData,
    isLoading: isLoadingBuyProduct,
    mutate: mutateBuyProduct,
  } = useMutationBuyCart();
  const {
    data: checkCartData,
    isLoading: isLoadingCheckCart,
    mutate: mutateCheckCart,
  } = useMutationCheckCart();

  useEffect(() => {
    if (!isLoadingCheckCart && checkCartData) {
      if (checkCartData[0].available) {
        mutateBuyProduct([{ sku: checkCartData[0].sku }]);
      } else {
        toast.error(t('toastErrorCheckBuy'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCartData, isLoadingCheckCart]);

  useEffect(() => {
    if (!isLoadingBuyProduct && buyProductData) {
      toast.success(t('toastItemBought'));
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyProductData, isLoadingBuyProduct]);

  const onBuy = data => {
    if (isAuth) {
      mutateCheckCart([{ sku: data.sku }]);
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onCartAdd = data => {
    sSetCartData([...cart, data]);
  };

  const onCartRemove = data => {
    let newCartData = cart.filter(item => item.id !== data.id);
    sSetCartData(newCartData);
  };

  const onExpose = data => {
    let product = { ...data };
    if (inventoryAside !== EInventoryAsideType.Expose) {
      sSetInventoryAside(EInventoryAsideType.Expose);
    }
    sSetHeaderOffset(true);
    let productPrice = product.price ? product.price : product.marketPrice;
    sSetInventoryExpose([
      ...inventoryExpose,
      {
        ...product,
        price: productPrice,
        _saleCount: 1,
        _netPrice: formatPrice(
          productPrice - productPrice * commission_percentage,
          2,
          false,
        ),
      },
    ]);
  };

  const onUnExpose = data => {
    let newExposeData = inventoryExpose.filter(item => item.id !== data.id);
    sSetInventoryExpose(newExposeData);
  };
  const onView = data => {
    if (data.id === inventoryProduct?.id) {
      sSetInventoryProduct(null);
      sSetInventoryExposeModal(null);
      if (inventoryExpose.length > 0) {
        sSetInventoryAside(EInventoryAsideType.Expose);
      } else {
        sSetInventoryAside(EInventoryAsideType.Empty);
      }
    } else {
      sSetInventoryProduct(data);
      if (width < MEDIA.M1024) {
        sSetInventoryExposeModal(data);
        sModalOpen('sellProduct');
      } else {
        sSetInventoryAside(EInventoryAsideType.Product);
      }
    }
  };
  const redirectToMarket = provider => {
    sSetGameType(provider);
    navigate(`/market`);
  };
  return (
    <div className="product-card-actions">
      <div className="product-card-actions__grid">
        {cardType === EProductCardType.Market && (
          <>
            <Button
              type={EButtonTypeType.Primary}
              block
              className="product-card-actions__btn-buy"
              disabled={isLoadingBuyProduct || data.isOwn}
              onClick={() => {
                onBuy(data);
              }}
            >
              {data.isOwn ? t('yourItem') : t('buyItem')}
            </Button>

            <AppBtnCart
              disabled={data.isOwn}
              size={EButtonSizeType.Middle}
              isInCart={isInCart}
              onClickAdd={() => onCartAdd(data)}
              onClickRemove={() => onCartRemove(data)}
            />
          </>
        )}
        {cardType === EProductCardType.Inventory && (
          <>
            <Button
              type={EButtonTypeType.Primary}
              block
              className={
                'product-card-actions__btn-expose' +
                (isInExpose ? ' product-card-actions__btn-expose--active' : '')
              }
              onClick={() => {
                isInExpose ? onUnExpose(data) : onExpose(data);
              }}
            >
              {isInExpose ? t('selectedItem') : t('selectItem')}
            </Button>

            {/*                ((data.id === inventoryProduct?.id && width > MEDIA.M1024) ||
                (data.id === inventoryProduct?.id &&
                  width <= MEDIA.M1024 &&
                  modal['mobileExpose'] === true)*/}

            <Button
              type={EButtonTypeType.Primary}
              ghost
              className={
                'product-card-actions__btn-view' +
                (data.id === inventoryProduct?.id
                  ? ' product-card-actions__btn-view--active'
                  : '')
              }
              onClick={() => {
                onView(data);
              }}
              icon={<ArrowRightIcon />}
            />
          </>
        )}
        {cardType === EProductCardType.Assortment && (
          <>
            <Button
              onClick={() => {
                redirectToMarket(data.provider);
              }}
              type={EButtonTypeType.Primary}
              block
            >
              {t('toMarket')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export { ProductCardActions };
