import React, { useState } from 'react';
import { Select, Space, Button } from 'antd';
import { useWindowSize } from 'react-use';
import { NavLink } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';

// types
import { ESelectSizeType, EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { ArrowDownIcon } from 'components/icons';
import { ModalTeleport, AppAutocomplete } from 'components/atoms';
import { NewTradeModal } from 'components/features';
// styles
import './index.scss';

//data
import { sortPrice, sortQuality } from './data';
const { Option } = Select;

const getRandomInt = (max: number, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const DealsHead = ({
  enabled,
  filterData,
  onChangeQuality,
  onChangePrice,
  onChangeSearch,
  createRequest,
  onCreateRequest,
}) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const { sModalOpen } = useAppStore();

  const [options, setOptions] = useState<any[]>([]);

  const onSearch = (value: string) => {
    onChangeSearch(value);
    // setOptions(value ? new Array(getRandomInt(5)).join('.').split('.') : []);
  };

  const onChangeSortPrice = (value: string) => {
    onChangePrice(value);
  };

  const onChangeSortQuality = (value: string) => {
    onChangeQuality(value);
  };

  const onCreateAutoRequest = () => {
    onCreateRequest();
  };

  const onCreateTrade = () => {
    sModalOpen('newTrade');
  };

  return (
    <div className="deals-head">
      <div className="app-container">
        <div className="deals-head__content">
          <div className="deals-head__title">{t('transactions')}</div>
          <div className="deals-head__actions">
            <Space.Compact className="ant-space-compact--nav deals-head__tabs">
              <NavLink
                to="/deals/trade"
                className={({ isActive }) =>
                  isActive ? ' ant-space-compact-nav--active' : ''
                }
              >
                {t('bargain')}
              </NavLink>
              <NavLink
                to="/deals/autobuy"
                className={({ isActive }) =>
                  isActive ? ' ant-space-compact-nav--active' : ''
                }
              >
                {t('autobuy')}
              </NavLink>
              {/*<NavLink*/}
              {/*  to="/deals/sale"*/}
              {/*  className={({ isActive }) =>*/}
              {/*    isActive ? ' ant-space-compact-nav--active' : ''*/}
              {/*  }*/}
              {/*>*/}
              {/*  {t('historySell')}*/}
              {/*</NavLink>*/}
            </Space.Compact>

            {enabled && (
              <>
                <AppAutocomplete
                  className="deals-head__autocomplete"
                  options={options}
                  size={ESelectSizeType.Large}
                  onSearch={onSearch}
                  isDefault={false}
                />

                <Select
                  className="deals-head__sort"
                  popupClassName="ant-select-dropdown-lg"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  defaultValue={filterData.quality}
                  onChange={onChangeSortQuality}
                  dropdownAlign={{ offset: [0, 0] }}
                  placeholder={t(sortQuality[0].label)}
                >
                  {sortQuality?.length &&
                    sortQuality.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.value}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
                <Select
                  className="deals-head__sort"
                  popupClassName="ant-select-dropdown-lg"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  defaultValue={filterData.price}
                  onChange={onChangeSortPrice}
                  dropdownAlign={{ offset: [0, 0] }}
                  placeholder={t(sortPrice[0].label)}
                >
                  {sortPrice?.length &&
                    sortPrice.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.value}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
              </>
            )}
          </div>
          {createRequest && (
            <>
              <Button
                type={EButtonTypeType.Primary}
                size={EButtonSizeType.Large}
                className="deals-head__btn-create"
                onClick={() => {
                  onCreateTrade();
                }}
              >
                {t('newRequest')}
              </Button>
              <ModalTeleport
                centered
                modalId={'newTrade'}
                width={'auto'}
                modalClass={'new-save-modal__wrapper'}
              >
                <NewTradeModal
                  onTradeCreated={onCreateAutoRequest}
                  modalId={'newTrade'}
                />
              </ModalTeleport>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DealsHead };
