import { useQuery } from '@tanstack/react-query';

//types
// services
import { GET_STATIC_PAGE } from 'api/settings';

export const useQueryFetchStaticPage = (slug: string, enabled: boolean) => {
  return useQuery(['useGetStaticPage'], () => GET_STATIC_PAGE(slug), {
    enabled,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching static page';
      console.error(message);
      return message;
    },
  });
};
