import React from 'react';
import { Checkbox } from 'antd';

// locale

// types
import { IPropsType } from './types';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

// components

// styles
import './index.scss';

//data

const MultipleFilter = ({ data, current, onChange }: IPropsType) => {
  const onChangeFilter = (checkedValues: CheckboxValueType[]) => {
    onChange(checkedValues);
  };

  return (
    <div className="multiple-filter">
      <Checkbox.Group
        className="multiple-filter__group"
        onChange={onChangeFilter}
        value={current}
      >
        {data.map(item => (
          <div className="multiple-filter__item" key={item.value}>
            <Checkbox value={item.value}>
              <span
                style={item.valueExtra ? { color: `#${item.valueExtra}` } : {}}
              >
                {item.title}
              </span>
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
    </div>
  );
};
export { MultipleFilter };
