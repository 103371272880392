import { useQuery } from '@tanstack/react-query';
import { GET_USER_BALANCE } from '../../../api/profile';

export const useQueryFetchUserBalance = (enabled: boolean) => {
  return useQuery(['useGetUserBalance'], () => GET_USER_BALANCE(), {
    enabled,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching user balance';
      console.error(message);
      return message;
    },
  });
};
