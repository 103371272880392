// core
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';

// utils
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType, EInputSizeType } from 'types/units';
import { IPropsType } from './types';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';

// components
import { EmptyCard } from 'components/features';
import {
  ResetIcon,
  StickerEmptyIcon,
  SearchIcon,
  StickerBorderIcon,
} from 'components/icons';
import { StickerFilterItem } from './StickerFilterItem';

// styles
import './index.scss';
import { useWindowSize } from 'react-use';
import { MEDIA } from '../../../../utils/constants';

// data

const StickerFilterModal = ({
  modalId,
  data,
  current,
  onChange,
}: IPropsType) => {
  // locales hook
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { sModalClose } = useAppStore();

  const [search, setSearch] = useState<string>('');

  const [defaultsPacks, setDefaultsPacks] = useState<Array<any>>([]);
  const [packs, setPacks] = useState<Array<any>>([]);
  const [currentPack, setCurrentPack] = useState<{
    [key: string]: any;
  } | null>(null);
  const [currentStickers, setCurrentStickers] = useState<Array<any>>([]);
  const scrollRef = useRef<any>(null);
  const horizontalWheelScroll = event => {
    if (event.deltaY > 0) {
      scrollRef.current.scrollLeft += 100;
      event.preventDefault();
    } else {
      scrollRef.current.scrollLeft -= 100;
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (data.length) {
      let groups = data.reduce((acc, item) => {
        let titleParts = item.title.split('|');
        let packTitle = 'General';
        let packSku = 'General';
        if (titleParts.length > 1) {
          packTitle = titleParts.at(-1).trim();
          packSku = packTitle.replace(/ /g, '_');
        }

        return {
          ...acc,
          [packSku]: {
            title: packTitle,
            sku: packSku,
            stickers: acc[packSku]?.stickers.length
              ? [...acc[packSku].stickers, item]
              : [item],
          },
        };
      }, {});
      let values = Object.values(groups);
      setPacks(values);
      setDefaultsPacks(values);
    }

    let scrollEl = scrollRef?.current;
    if (scrollEl) {
      scrollEl.addEventListener('wheel', horizontalWheelScroll);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('wheel', horizontalWheelScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.length) {
      let currentCollection = current.map(value => {
        return data.find(sticker => sticker.value === value);
      });
      setCurrentStickers(currentCollection);
    }
  }, [data, current]);

  const onChangeSearch = ({ target: { value } }) => {
    if (currentPack) {
      setCurrentPack(null);
    }
    setSearch(value);

    let filteredPacks = defaultsPacks.reduce((acc, itemPack) => {
      let filteredStickers = itemPack.stickers.filter(itemSticker => {
        return (
          itemSticker.title.toUpperCase().indexOf(value.toUpperCase()) !== -1
        );
      });

      if (filteredStickers.length) {
        return [...acc, { ...itemPack, stickers: filteredStickers }];
      }

      return acc;
    }, []);

    setPacks(filteredPacks);
  };

  const onChangeSticker = (sticker: any, isChose: boolean) => {
    if (isChose) {
      setCurrentStickers(list =>
        list.filter(item => item.value !== sticker!.value),
      );
    } else if (currentStickers.length < 5) {
      setCurrentStickers([...currentStickers, sticker]);
    }
  };

  const onChoosePack = pack => {
    if (currentPack?.sku !== pack.sku) {
      setCurrentPack(pack);
    } else {
      setCurrentPack(null);
    }
  };

  const onReset = () => {
    setCurrentStickers([]);
  };
  const onConfirm = () => {
    sModalClose(modalId);
    onChange(currentStickers.map(sticker => sticker.value));
  };

  const checkStickerChoose = (stickerValue: string) => {
    return !!currentStickers.find(sticker => sticker.value === stickerValue);
  };

  return (
    <div className="sticker-filter-modal">
      <div className="sticker-filter-modal__grid">
        <div className="sticker-filter-modal__aside">
          {width <= MEDIA.M1024 && (
            <div className="sticker-filter-modal__title">
              {t('stickerSettingsTitle')}
            </div>
          )}

          <Input
            className="sticker-filter-modal__search ant-input--ghost"
            prefix={<SearchIcon />}
            value={search}
            size={EInputSizeType.Middle}
            placeholder={t('search')}
            onChange={onChangeSearch}
          />
          <div className="sticker-filter-modal__packs">
            <div
              className="sticker-filter-modal__packs-scroll"
              ref={width <= MEDIA.M1024 ? scrollRef : null}
            >
              <ul className="sticker-filter-modal__packs-list">
                {packs.map(pack => (
                  <li
                    key={pack.sku}
                    className={
                      'sticker-filter-modal__packs-item' +
                      (pack.sku === currentPack?.sku
                        ? ' sticker-filter-modal__packs-item--active'
                        : '')
                    }
                    onClick={() => onChoosePack(pack)}
                  >
                    {pack.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="sticker-filter-modal__main">
          {width > MEDIA.M1024 && (
            <div className="sticker-filter-modal__title">
              {t('stickerSettingsTitle')}
            </div>
          )}

          {currentPack?.stickers?.length > 0 ? (
            <div
              className={
                'sticker-filter-modal__list' +
                (currentPack!.stickers.length > 4
                  ? ' sticker-filter-modal__list--scroll'
                  : '')
              }
            >
              <div className="sticker-filter-modal__scroll">
                {currentPack!.stickers.map(sticker => (
                  <StickerFilterItem
                    key={sticker.value}
                    data={sticker}
                    isChose={checkStickerChoose(sticker.value)}
                    onChange={isChose => onChangeSticker(sticker, isChose)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="sticker-filter-modal__empty">
              <EmptyCard
                size={ECardSizeType.Small}
                title={t('emptyStickerTitle')}
                description={t('emptyStickerDesc')}
              />
            </div>
          )}
        </div>
      </div>
      <div className="sticker-filter-modal__footer">
        <ul className="sticker-filter-modal__current">
          {[1, 2, 3, 4, 5].map((item, index) => (
            <li key={index}>
              {currentStickers[index] ? (
                <div className="sticker-filter-modal__current-img">
                  <StickerBorderIcon />
                  <img
                    src={currentStickers[index].image}
                    alt={currentStickers[index].title}
                  />
                </div>
              ) : (
                <StickerEmptyIcon />
              )}
            </li>
          ))}
        </ul>
        <div className="sticker-filter-modal__actions">
          <Button
            size={EButtonSizeType.Middle}
            ghost
            className={'ant-btn-ghost--grey'}
            icon={<ResetIcon />}
            onClick={() => onReset()}
          />

          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Middle}
            onClick={() => {
              onConfirm();
            }}
            className="sticker-filter-modal__btn-confirm"
          >
            {t('confirmBtn')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { StickerFilterModal };
