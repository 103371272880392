export enum ESortValueType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ESortType {
  createdAt = 'createdAt',
  name = 'name',
  price = 'price',
  quality = 'quality',
  float = 'float',
  discount = 'discount',
}

export interface ISortOption {
  label: string;
  value: string;
  type: ESortType | null;
  typeValue: ESortValueType | null;
}
