export const lightTheme = {
  appBackground: '#131115',
  colorPrimary: '#8D5DD9',
  colorTextBase: '#ffffff',
  colorText: '#939199',
  fontFamily: 'Inter',
};
export const darkTheme = {
  appBackground: '#131115',
  colorPrimary: '#8D5DD9',
  colorTextBase: '#ffffff',
  colorText: '#939199',
  fontFamily: 'Inter',
};
