const LogoIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7065.05 2099.13"
    {...props}
  >
    <path
      className="cls-1"
      fill="#8d5dd9"
      strokeWidth="0px"
      d="m1040.83,1090.15l-272.8,272.8c-45.21,45.21-95.32,80.14-148.93,103.81-53.35,23.56-109.01,35.5-165.43,35.5-56.42,0-112.08-11.94-165.43-35.5-53.61-23.67-103.71-58.6-148.93-103.81-45.22-45.22-80.14-95.32-103.81-148.93C11.94,1160.67,0,1105.01,0,1048.59c0-56.42,11.94-112.08,35.5-165.43,23.67-53.61,58.6-103.71,103.81-148.93L828.76,44.79c21.43-21.43,47.42-35.87,75.17-41.76,27.18-5.77,54.98-3.26,80.38,7.26,25.4,10.52,46.83,28.4,61.97,51.71,15.45,23.79,23.62,52.38,23.62,82.68v510.24h-112.3V144.67c0-15.19-5.93-26.35-16.27-30.64-10.34-4.28-22.43-.58-33.17,10.16L218.72,813.64c-142.73,142.73-142.73,327.18,0,469.91,142.73,142.73,327.18,142.73,469.91,0l272.8-272.8,79.41,79.41Z"
    />
    <path
      className="cls-1"
      fill="#8d5dd9"
      strokeWidth="0px"
      d="m1315.23,1364.89l-689.44,689.44c-21.43,21.43-47.42,35.87-75.17,41.76-27.18,5.77-54.98,3.26-80.38-7.26-25.4-10.52-46.83-28.4-61.97-51.71-15.45-23.79-23.62-52.38-23.62-82.68v-508.66s112.3,0,112.3,0v508.66c0,15.19,5.93,26.36,16.27,30.64,10.34,4.28,22.43.58,33.17-10.16l689.44-689.44c142.73-142.73,142.73-327.18,0-469.91-142.73-142.73-327.18-142.73-469.91,0l-272.89,272.89-79.41-79.41,272.89-272.89c45.21-45.21,95.32-80.14,148.93-103.81,53.35-23.56,109.01-35.5,165.43-35.5,56.42,0,112.08,11.94,165.43,35.5,53.61,23.67,103.71,58.6,148.93,103.81,45.22,45.22,80.14,95.32,103.81,148.93,23.56,53.35,35.5,109.01,35.5,165.43,0,56.42-11.94,112.08-35.5,165.43-23.67,53.61-58.6,103.71-103.81,148.93Z"
    />
    <circle
      className="cls-1"
      fill="#8d5dd9"
      strokeWidth="0px"
      cx="1001.22"
      cy="1050.54"
      r="117.54"
    />
    <circle
      className="cls-1"
      fill="#8d5dd9"
      strokeWidth="0px"
      cx="454.02"
      cy="1048.24"
      r="117.54"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m2346.61,1482.35c-103.4,0-187.62-22.84-252.71-68.5-65.08-45.66-104.96-109.94-119.64-192.86l168.46-25.98c10.48,44.1,34.74,78.86,72.79,104.31,38.05,25.45,85.41,38.17,142.11,38.17,51.43,0,91.7-10.48,120.83-31.47,29.11-20.99,43.68-49.85,43.68-86.61,0-21.5-5.12-38.95-15.36-52.34-10.24-13.38-31.62-26.11-64.16-38.17-32.54-12.07-82.12-27.03-148.77-44.88-72.96-18.89-130.83-39.22-173.58-61.01-42.78-21.77-73.23-47.22-91.31-76.35-18.11-29.13-27.16-64.43-27.16-105.89,0-51.43,13.63-96.29,40.93-134.6,27.28-38.32,65.72-67.96,115.32-88.97,49.58-20.99,107.72-31.49,174.36-31.49s123.08,10.24,173.97,30.71c50.9,20.48,92.09,49.46,123.59,86.97,31.49,37.54,50.39,81.49,56.7,131.87l-168.46,30.69c-5.78-40.93-24.94-73.2-57.48-96.82-32.54-23.6-74.79-36.47-126.73-38.59-49.87-2.1-90.29,6.19-121.25,24.81-30.96,18.62-46.44,44.22-46.44,76.74,0,18.92,5.9,34.93,17.72,48.05,11.8,13.11,34.76,25.72,68.89,37.78,34.1,12.07,84.49,26.5,151.13,43.29,71.37,18.38,128.05,38.71,170.02,61.01,41.98,22.3,72.03,48.8,90.14,79.49,18.11,30.71,27.18,67.84,27.18,111.4,0,83.98-30.59,149.84-91.73,197.59-61.14,47.78-145.5,71.64-253.07,71.64Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m2825.22,1459.51l.78-1133.59h166.9v692.75l333.76-409.35h209.42l-352.67,425.1,388.87,425.1h-223.55l-355.82-409.35v409.35h-167.68Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m3627.36,469.98v-159.81h164.54v159.81h-164.54Zm0,989.52v-850.19h164.54v850.19h-164.54Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m3987.9,1459.51v-850.19h147.21v245.61h19.67v604.58h-166.88Zm597.48,0v-418c0-33.05-2.75-67.03-8.26-101.94-5.51-34.91-16.02-67.3-31.49-97.24-15.48-29.91-37.25-54.04-65.33-72.42-28.08-18.36-64.69-27.55-109.81-27.55-29.4,0-57.24,4.88-83.46,14.58-26.23,9.7-49.19,25.33-68.86,46.83-19.7,21.52-35.17,49.87-46.46,85.02s-16.92,77.93-16.92,128.32l-102.33-38.59c0-77.13,14.43-145.23,43.29-204.27,28.86-59.04,70.3-105.09,124.37-138.14,54.07-33.05,119.39-49.61,196.03-49.61,58.77,0,108.11,9.46,147.99,28.35,39.88,18.89,72.3,43.68,97.24,74.37,24.91,30.71,43.95,64.16,57.06,100.38,13.11,36.22,22.04,71.64,26.76,106.28,4.73,34.64,7.09,65.06,7.09,91.29v472.34h-166.9Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m5242.7,1482.35c-103.4,0-187.62-22.84-252.71-68.5-65.08-45.66-104.96-109.94-119.64-192.86l168.46-25.98c10.48,44.1,34.74,78.86,72.79,104.31,38.05,25.45,85.41,38.17,142.11,38.17,51.43,0,91.7-10.48,120.83-31.47,29.11-20.99,43.68-49.85,43.68-86.61,0-21.5-5.12-38.95-15.36-52.34-10.24-13.38-31.62-26.11-64.16-38.17-32.54-12.07-82.12-27.03-148.77-44.88-72.96-18.89-130.83-39.22-173.58-61.01-42.78-21.77-73.23-47.22-91.31-76.35-18.11-29.13-27.16-64.43-27.16-105.89,0-51.43,13.63-96.29,40.93-134.6,27.28-38.32,65.72-67.96,115.32-88.97,49.58-20.99,107.72-31.49,174.36-31.49s123.08,10.24,173.97,30.71c50.9,20.48,92.09,49.46,123.59,86.97,31.49,37.54,50.39,81.49,56.7,131.87l-168.46,30.69c-5.78-40.93-24.94-73.2-57.48-96.82-32.54-23.6-74.79-36.47-126.73-38.59-49.87-2.1-90.29,6.19-121.25,24.81s-46.44,44.22-46.44,76.74c0,18.92,5.9,34.93,17.72,48.05,11.8,13.11,34.76,25.72,68.89,37.78,34.1,12.07,84.49,26.5,151.13,43.29,71.37,18.38,128.05,38.71,170.02,61.01,41.98,22.3,72.03,48.8,90.14,79.49,18.11,30.71,27.18,67.84,27.18,111.4,0,83.98-30.59,149.84-91.73,197.59-61.14,47.78-145.5,71.64-253.07,71.64Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m5627.64,741.58v-132.26h580.96v132.26h-580.96Zm580.96,717.93c-53.53,10.48-106.01,14.94-157.45,13.38-51.43-1.58-97.36-11.55-137.77-29.91-40.42-18.36-70.84-47.22-91.29-86.61-18.38-35.15-28.23-70.96-29.54-107.43-1.32-36.47-1.95-77.81-1.95-124V373.16h165.32v743.91c0,34.13.39,63.77,1.17,88.95.78,25.2,6.14,46.46,16.14,63.77,18.89,32.54,48.95,51.04,90.14,55.5,41.2,4.46,89.61,2.75,145.23-5.12v139.33Z"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#fff"
      d="m6439.22,1837.36l164.54-447.91,2.36,132.24-370.79-912.37h171.61l277.11,704.57h-50.39l264.51-704.57h166.88l-469.17,1228.04h-156.67Z"
    />
  </svg>
);

export { LogoIcon };
