import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { Button, Drawer, Table } from 'antd';

// locale
import { useTranslation } from 'react-i18next';

//hooks
import {
  useMutationCheckCart,
  useQueryFetchRelatedProducts,
} from '../../../../hooks/api';

// store
import { useAppStore, useAuthStore, useCartStore } from 'store';
// types
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';

import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { EGameType } from 'types/models';
// components
import {
  EmptyCard,
  TableCellImage,
  TableCellStickers,
} from 'components/features';
import { FloatRange, AppBtnCart } from 'components/atoms';
import {
  ArrowRightIcon,
  ArrowLeftLightIcon,
  CloseIcon,
  FilterIcon,
} from 'components/icons';

import { ProductSimilarActions } from './ProductSimilarActions';
// styles
import './index.scss';

//data
import { formatFloat, formatPrice } from '../../../../utils/commons';
import { useMutationBuyCart } from '../../../../hooks/api';
import { toast } from 'react-toastify';

const ProductSimilar = ({ product, onLoaded }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessToken: isAuth } = useAuthStore();
  const { drawer, sModalOpen, sDrawerOpen, sDrawerClose } = useAppStore();
  const { cart, sSetCartData, sSetCartProduct } = useCartStore();
  const { width } = useWindowSize();
  const [tableData, setTableData] = useState<any>();
  const [payloadData, setPayloadData] = useState<any>({
    page: 1,
    take: 10,
    provider: product.provider,
    // price: {
    //   from: 200,
    //   to: 2000,
    // },
    // statTrack: false,
  });
  const { slug } = useParams();
  const { data: relatedList, isLoading: isLoadingRelatedList } =
    useQueryFetchRelatedProducts({ id: slug, payload: payloadData });
  useEffect(() => {
    if (!isLoadingRelatedList && relatedList?.data) {
      setTableData(transformData(relatedList.data));
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedList, isLoadingRelatedList]);

  const {
    data: buyProductData,
    isLoading: isLoadingBuyProduct,
    mutate: mutateBuyProduct,
  } = useMutationBuyCart();

  const {
    data: checkCartData,
    isLoading: isLoadingCheckCart,
    mutate: mutateCheckCart,
  } = useMutationCheckCart();

  useEffect(() => {
    if (!isLoadingCheckCart && checkCartData) {
      if (checkCartData[0].available) {
        mutateBuyProduct([{ sku: checkCartData[0].sku }]);
      } else {
        toast.error(t('toastErrorCheckBuy'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCartData, isLoadingCheckCart]);

  useEffect(() => {
    if (!isLoadingBuyProduct && buyProductData) {
      toast.success(t('toastItemBought'));
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyProductData, isLoadingBuyProduct]);
  const transformData = list => {
    return list.map(el => {
      let {
        id,
        image,
        preview,
        float,
        price,
        stickers,
        tag,
        gems,
        provider,
        sku,
        marketName,
        isOwn,
      } = el;
      let stickerImages = stickers.map(sticker => {
        return {
          image: sticker.preview,
          title: sticker.title,
          price: sticker.valueExtra,
        };
      });
      let gemsImages = gems.map(gem => {
        return {
          image: gem.preview,
          title: gem.title,
          description: gem.valueExtra,
        };
      });
      let skinImage = image ? image : preview;
      return {
        id: id,
        sku: sku,
        isOwn: isOwn,
        img: skinImage,
        float: float,
        stickers: stickerImages,
        gems: gemsImages,
        price: price,
        rarity: tag.rarity.title,
        rarityColor: tag.rarity.valueExtra,
        provider: provider,
        marketName: marketName,
      };
    });
  };
  const onFilterDrawerToggle = () => {
    if (drawer['similarProductDrawer']) {
      sDrawerClose('similarProductDrawer');
    } else {
      sDrawerOpen('similarProductDrawer');
    }
  };

  const onTableChange = pagination => {
    setPayloadData({ ...payloadData, page: pagination.current });
  };
  const onBuy = data => {
    if (isAuth) {
      mutateCheckCart([{ sku: data.sku }]);
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onBargain = data => {
    if (isAuth) {
      sSetCartProduct(relatedList.data.find(el => el.id === data.id));
      sModalOpen('offerTrade');
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onCartAdd = data => {
    sSetCartData([...cart, relatedList.data.find(el => el.id === data.id)]);
  };

  const onCartRemove = data => {
    let newCartData = cart.filter(item => item.id !== data.id);
    sSetCartData(newCartData);
  };

  const isInCart = data => {
    return !!cart.find(i => i.id === data.id);
  };

  const getDynamicColumns = () => {
    const columns = [
      {
        key: 'img',
        dataIndex: 'img',
        render: (_, record) => (
          <TableCellImage
            src={record.img}
            gameType={record.provider}
            alt={''}
          />
        ),
      },
      {
        key: 'name',
        dataIndex: 'name',
        render: (_, record) => (
          <>
            <div className="ant-table-cell__text">
              {record.marketName.first}
            </div>
            <div className="ant-table-cell__type">
              {record.marketName.second}
            </div>
          </>
        ),
      },
      {
        key: 'float',
        dataIndex: 'float',
        width: '25%',
        render: (_, record) => (
          <>
            {record.provider === EGameType.CSGO ? (
              <>
                {record.float > 0 && (
                  <div className="product-similar__table-float">
                    <div className="ant-table-cell__float">
                      <span>{formatFloat(record.float)}</span>
                    </div>
                    <FloatRange
                      size={EFloatRangeSizeType.Small}
                      maxValue={record.float}
                      isTooltip={true}
                    />
                  </div>
                )}
              </>
            ) : (
              <div
                className="ant-table-cell__rarity"
                style={{ color: '#' + record.rarityColor }}
              >
                {record.rarity}
              </div>
            )}
          </>
        ),
      },
      {
        key: 'stickers',
        dataIndex: 'stickers',
        width: '30%',
        render: (_, record) => (
          <TableCellStickers
            gameType={record.provider}
            collection={
              record.provider === EGameType.CSGO ? record.stickers : record.gems
            }
          />
        ),
      },
      {
        key: 'price',
        dataIndex: 'price',
        width: '30%',
        render: price => (
          <span className="ant-table-cell__nowrap ant-table-cell__value">
            $ {formatPrice(price)}
          </span>
        ),
      },
      {
        key: 'action',
        render: (_, record) => (
          <div className="product-similar__table-actions">
            <Button
              size={EButtonSizeType.Middle}
              type={EButtonTypeType.Primary}
              onClick={() => onBuy(record)}
              disabled={isLoadingBuyProduct || record.isOwn}
            >
              {record.isOwn ? t('yourItem') : t('buyItem')}
            </Button>
            <Button
              disabled={record.isOwn}
              size={EButtonSizeType.Middle}
              ghost
              type={EButtonTypeType.Primary}
              onClick={() => onBargain(record)}
            >
              {t('bargainItem')}
            </Button>
            <AppBtnCart
              disabled={record.isOwn}
              size={EButtonSizeType.Middle}
              isInCart={isInCart(record)}
              onClickAdd={() => onCartAdd(record)}
              onClickRemove={() => onCartRemove(record)}
            />
          </div>
        ),
      },
    ];
    return width > MEDIA.M768
      ? columns
      : columns.filter(col => col.dataIndex !== 'name');
  };

  return (
    <div className="product-similar" id="similar_products">
      <div className="product-similar__head">
        <div className="product-similar__title">{t('similarItems')}</div>

        {width > MEDIA.M1280 ? (
          <ProductSimilarActions
            gameType={product.provider}
            canStatTrack={product.canStatTrack}
            onChangeFilter={value => {
              setPayloadData({ ...payloadData, ...value });
            }}
          />
        ) : (
          <Button
            type={EButtonTypeType.Default}
            className={'ant-btn-default--dark'}
            icon={
              drawer['similarProductDrawer'] ? <CloseIcon /> : <FilterIcon />
            }
            onClick={() => onFilterDrawerToggle()}
          />
        )}
      </div>
      <div className="product-similar__table">
        <Table
          showHeader={false}
          onChange={pagination => {
            onTableChange(pagination);
          }}
          loading={isLoadingRelatedList}
          pagination={
            isLoadingRelatedList
              ? false
              : {
                  position: ['bottomCenter'],
                  current: relatedList.meta?.page,
                  pageSize: relatedList.meta?.take,
                  total: relatedList.meta?.itemsCount,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  itemRender: (page, type, originalElement) => {
                    if (type === 'next') {
                      return <ArrowRightIcon />;
                    } else if (type === 'prev') {
                      return <ArrowLeftLightIcon />;
                    }
                    return originalElement;
                  },
                }
          }
          className="inventory-pending__table"
          columns={getDynamicColumns()}
          dataSource={tableData}
          rowKey="id"
          scroll={{ x: '780px' }}
          locale={{
            emptyText: (
              <div className="ant-table-cell__table-empty">
                <EmptyCard
                  size={ECardSizeType.Small}
                  color={ECardColorType.Light}
                  title={t('emptySimilarTitle')}
                  description={t('emptySimilarDesc')}
                />
              </div>
            ),
          }}
        />
      </div>

      {width <= MEDIA.M1280 && (
        <Drawer
          placement={'left'}
          destroyOnClose={true}
          closable={false}
          onClose={onFilterDrawerToggle}
          open={drawer['similarProductDrawer']}
          // @ts-ignore
          getContainer={() => {
            return document.querySelector('.product__grid');
          }}
          rootClassName={'product-similar__drawer'}
          width={320}
          closeIcon={<CloseIcon />}
        >
          <ProductSimilarActions
            gameType={product.provider}
            canStatTrack={product.canStatTrack}
            onChangeFilter={value => {
              setPayloadData({ ...payloadData, ...value });
            }}
          />
        </Drawer>
      )}
    </div>
  );
};

export { ProductSimilar };
