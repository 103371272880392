import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { ACCEPT_BARGAIN } from '../../../api/transactions';

export const useMutationAcceptBargain = () => {
  return useMutation(ACCEPT_BARGAIN, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while reject bargain';
      console.error(message);
      return message;
    },
  });
};
