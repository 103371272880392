// core
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
//hooks
import { useQueryFetchSupportFaq } from '../../../../hooks/api';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
// utils

// store
import { useAuthStore } from 'store';

// locales
import { useTranslation } from 'react-i18next';
// types
import {
  EButtonSizeType,
  EButtonTypeType,
  ERadioButtonSizeType,
  ERadioButtonStyleType,
} from 'types/units';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';

// components(atoms)
import { Button, Collapse, Radio, RadioChangeEvent } from 'antd';
import { SupportFaqSEO } from './SEO';
import { EmptyCard } from 'components/features';
import { PlusIcon, CloseIcon } from 'components/icons';
import { AppLoader } from 'components/atoms';

// styles
import './index.scss';

//data
import { faqFilters } from './data';

const SupportFaq = () => {
  const { accessToken: isAuth } = useAuthStore();
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    topic: withDefault(StringParam, undefined),
  });
  const { topic: topicParam } = query;
  const { data: faqListData, isLoading: isLoadingFaqListData } =
    useQueryFetchSupportFaq(query);
  const onChangeDateSelect = ({ target: { value } }: RadioChangeEvent) => {
    setQuery({ topic: value });
  };

  useEffect(() => {
    if (!isLoadingFaqListData && faqListData) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqListData, isLoadingFaqListData]);

  return (
    <>
      <SupportFaqSEO />
      <div className="faq">
        <div className="app-container app-container--small">
          <div className="faq__wrap">
            <div className="faq__head">
              <div className="faq__title">{t('faq')}</div>
              <div className="faq__filter">
                <div className="faq__filter--item-wrap">
                  <Radio.Group
                    buttonStyle={ERadioButtonStyleType.Solid}
                    value={topicParam}
                    size={ERadioButtonSizeType.Middle}
                    onChange={onChangeDateSelect}
                  >
                    {faqFilters?.length &&
                      faqFilters.map((item, index) => (
                        <Radio.Button value={item.value} key={index}>
                          {t(item.title)}
                        </Radio.Button>
                      ))}
                  </Radio.Group>
                </div>
                {isAuth && (
                  <div className="faq__filter--nav">
                    <RouterLink to="/support/tickets">
                      <Button
                        type={EButtonTypeType.Primary}
                        size={EButtonSizeType.Middle}
                      >
                        {t('techSupport')}
                      </Button>
                    </RouterLink>
                  </div>
                )}
              </div>
            </div>
            <div className="faq__content">
              {isLoadingFaqListData ? (
                <div className="faq__loader">
                  <AppLoader type={ELoaderTypeType.Component} />
                </div>
              ) : (
                <>
                  {faqListData?.data?.length ? (
                    faqListData.data.map((item, index) => {
                      return (
                        <div className="faq__content--item" key={index}>
                          <Collapse
                            size="large"
                            expandIcon={({ isActive }) =>
                              !isActive ? <PlusIcon /> : <CloseIcon />
                            }
                            items={[
                              {
                                key: index,
                                label: item.q,
                                children: (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.a,
                                    }}
                                  ></div>
                                ),
                              },
                            ]}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <EmptyCard
                      title={t('emptyFaqTitle')}
                      description={t('emptyFaqDesc')}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SupportFaq };
