// core
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'antd';

// utils
import { formatPrice } from '../../../../utils/commons';
//hooks
import {
  useMutationCheckCart,
  useMutationBuyCart,
} from '../../../../hooks/api';

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore, useCartStore } from 'store';

// types
import {
  EButtonSizeType,
  EButtonTypeType,
  EBadgeStatusType,
} from 'types/units';

import { IPropsType } from './types';

// components
import { CartItem } from './CartItem';
import { EmptyCard } from 'components/features';

// styles
import './index.scss';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// data

const CartModal = ({ modalId }: IPropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // locales hook
  const { sModalClose } = useAppStore();
  const { cart, sSetCartData } = useCartStore();
  const [summary, setSummary] = useState<number>(0);

  const {
    data: checkCartData,
    isLoading: isLoadingCheckCart,
    mutate: mutateCheckCart,
  } = useMutationCheckCart();

  const {
    data: buyCartData,
    isLoading: isLoadingBuyCart,
    mutate: mutateBuyCart,
  } = useMutationBuyCart();

  useEffect(() => {
    if (cart && cart.length) {
      const payload = cart.map(el => {
        return { sku: el.sku };
      });
      mutateCheckCart(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingCheckCart && checkCartData) {
      let newCartData = cart.filter(item => {
        return checkCartData.some(
          checkedItem =>
            checkedItem.sku === item.sku && checkedItem.available === true,
        );
      });
      if (newCartData.length !== cart.length) {
        toast.error(t('toastErrorCart'));
      }
      sSetCartData(newCartData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCartData, isLoadingCheckCart]);

  useEffect(() => {
    if (buyCartData && !isLoadingBuyCart) {
      toast.success(t('toastItemsBought'));
      sModalClose(modalId);
      sSetCartData([]);
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCartData, isLoadingBuyCart]);

  useEffect(() => {
    let summaryCount = cart.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    setSummary(summaryCount);
  }, [cart]);

  const onDeleteAll = () => {
    sSetCartData([]);
  };

  const onDeleteItem = data => {
    let newCartData = cart.filter(item => item.id !== data.id);
    sSetCartData(newCartData);
  };

  const onPay = () => {
    const payload = cart.map(el => {
      return { sku: el.sku };
    });
    mutateBuyCart(payload);
  };

  return (
    <div className="cart-modal">
      <div className="cart-modal__head">
        <div className="cart-modal__title">{t('cart')}</div>
        <div className="cart-modal__count">
          <Badge status={EBadgeStatusType.Default} />
          {t('items')} {cart.length}
        </div>
        <div className="cart-modal__actions">
          {summary > 0 && (
            <Button
              type={EButtonTypeType.Primary}
              className="ant-btn-primary--error"
              size={EButtonSizeType.Small}
              onClick={() => {
                onDeleteAll();
              }}
            >
              {t('resetAll')}
            </Button>
          )}
        </div>
      </div>
      <div className="cart-modal__content">
        {cart?.length > 0 ? (
          <div
            className={
              'cart-modal__list' +
              (cart.length > 3 ? ' cart-modal__list--scroll' : '')
            }
          >
            <div className="cart-modal__scroll">
              {cart.map(item => (
                <CartItem data={item} key={item.id} onDelete={onDeleteItem} />
              ))}
            </div>
          </div>
        ) : (
          <div className="cart-modal__empty">
            <EmptyCard
              title={t('emptyCartTitle')}
              description={t('emptyCartDesc')}
            />
          </div>
        )}
      </div>
      <div className="cart-modal__footer">
        <div className="cart-modal__summary">
          {t('total')}: <span>$ {summary ? formatPrice(summary) : '0.00'}</span>
        </div>
        <Button
          disabled={!summary}
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Middle}
          loading={isLoadingBuyCart}
          onClick={() => {
            onPay();
          }}
          className="cart-modal__btn-pay"
        >
          {t('pay')} {summary ? '$ ' + formatPrice(summary) : ''}
        </Button>
      </div>
    </div>
  );
};

export { CartModal };
