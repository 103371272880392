export const dataTypes: Array<{
  label: string;
  value: string;
}> = [
  {
    label: 'Закаленное в боях',
    value: 'from250',
  },
  {
    label: 'Поношеное',
    value: 'from350',
  },
  {
    label: 'После полевых испытаний',

    value: 'from1250',
  },
  {
    label: 'Немного поношеное',
    value: 'from2250',
  },
  {
    label: 'Прямо с завода',
    value: 'from3250',
  },
];
