import { useQuery } from '@tanstack/react-query';

//types
import { TTicketRequestParams } from 'types/api/support';

// services
import { GET_TICKET } from 'api/support';

export const useQueryFetchTicket = (enabled, id: TTicketRequestParams) => {
  return useQuery(['useGetTicket', id], () => GET_TICKET(id), {
    enabled,
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching ticket';
      console.error(message);
      return message;
    },
  });
};
