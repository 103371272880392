import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { CHANGE_TICKET_STATUS } from '../../../api/support';

export const useMutationChangeTicketStatus = () => {
  return useMutation(CHANGE_TICKET_STATUS, {
    onSuccess: data => {},
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while create Ticket';
      console.error(message);
      return message;
    },
  });
};
