// core
import React, { useEffect, useState } from 'react';
import { Area } from '@ant-design/plots';
import { Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { generalChartConfig, generalTooltipConfig } from 'config/charts';
import dayjs from 'dayjs';
// utils

// locales

// store
import { useQueryFetchPriceHistory } from 'hooks/api';

import { useAppStore, useInventoryStore } from 'store';

// types
import { ERadioButtonSizeType, ERadioButtonStyleType } from 'types/units';

// store

// components

// styles
import './index.scss';

// data
import { dateTypes } from './data';

const InventoryExposeChart = ({ exposeItem }) => {
  const { t } = useTranslation();
  const { gameType } = useAppStore();

  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);
  const [filterData, setFilterData] = useState<any>(null);

  const [data, setData] = useState([]);
  const [enabledFetchChart, setEnabledFetchChart] = useState(false);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData, enabledFetchChart);

  useEffect(() => {
    if (exposeItem) {
      let { marketName, exterior } = exposeItem;
      setFilterData({
        ...dateTypes[4].range,
        firstName: marketName.first,
        secondName: marketName.second || null,
        provider: gameType,
        extra: exterior || null,
      });
      setEnabledFetchChart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposeItem]);

  useEffect(() => {
    if (rawChartData && !isLoadingRawChart) {
      setData(rawChartData);
    }
  }, [isLoadingRawChart, rawChartData]);

  const config = {
    data,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 3 },
    animation: false,
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">$ {data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        ...value,
        firstName: exposeItem?.marketName.first,
        secondName: exposeItem?.marketName.second,
        provider: gameType,
        extra: exposeItem?.exterior,
      });
    } else {
      setFilterData({
        firstName: exposeItem?.marketName.first,
        secondName: exposeItem?.marketName.second,
        provider: gameType,
        extra: exposeItem?.exterior,
      });
    }
  };

  // @ts-ignore
  return (
    <div className="inventory-expose-chart">
      <div className="inventory-expose-chart__filter">
        <Radio.Group
          buttonStyle={ERadioButtonStyleType.Solid}
          value={chartDateType}
          size={ERadioButtonSizeType.Middle}
          onChange={onChangeChartDateType}
        >
          {dateTypes?.length &&
            dateTypes.map(item => {
              return (
                <Radio.Button value={item.range} key={item.value}>
                  {t(item.value)}
                </Radio.Button>
              );
            })}
        </Radio.Group>
      </div>
      <div className="inventory-expose-chart__chart">
        {/*@ts-ignore*/}
        <Area {...config} />
      </div>
    </div>
  );
};
export { InventoryExposeChart };
//121
