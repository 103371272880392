import { useQuery } from '@tanstack/react-query';

//types

// services
import { GET_ACTIVE_INVENTORY } from 'api/inventory';

export const useQueryFetchActiveInventory = ({ payload, enabled }) => {
  return useQuery(
    ['useGetActiveInventory', { payload }],
    () => GET_ACTIVE_INVENTORY({ payload }),
    {
      enabled,
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: () => 'always',
      onSuccess: data => {
        return data;
      },
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching pending inventory';
        console.error(message);
        return message;
      },
    },
  );
};
