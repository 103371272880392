const BitcoinIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8478 19.431C20.5085 19.6062 21.946 19.9873 22.1966 18.988C22.4522 17.9656 21.0556 17.6568 20.3717 17.5056C20.2942 17.4884 20.2259 17.4733 20.1702 17.4594L19.6872 19.3893C19.7332 19.4007 19.7872 19.415 19.8478 19.431Z"
      fill="#F7931A"
    />
    <path
      d="M19.0993 22.5382C19.8906 22.7466 21.6211 23.2025 21.8965 22.1013C22.1783 20.9754 20.5003 20.6003 19.6817 20.4174C19.5903 20.3969 19.5095 20.3789 19.4435 20.3625L18.9107 22.4894C18.9649 22.5028 19.0283 22.5195 19.0993 22.5382Z"
      fill="#F7931A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8568 20.0538C28.8568 24.916 24.9154 28.8576 20.0534 28.8576C15.1914 28.8576 11.25 24.916 11.25 20.0538C11.25 15.1916 15.1914 11.25 20.0534 11.25C24.9154 11.25 28.8568 15.1916 28.8568 20.0538ZM22.1684 16.7829C23.393 17.2034 24.2887 17.8331 24.113 19.005C23.9854 19.8632 23.5079 20.2782 22.8735 20.4235C23.7439 20.8749 24.0405 21.7315 23.7649 22.7673C23.2415 24.2579 21.9976 24.3834 20.3431 24.0719L19.9414 25.6742L18.9715 25.4331L19.3677 23.8523C19.1163 23.7901 18.8593 23.7235 18.5946 23.6525L18.1968 25.241L17.228 24.9999L17.6292 23.3943L15.6758 22.9033L16.1576 21.7969C16.1576 21.7969 16.8727 21.9857 16.8628 21.9719C17.1374 22.0396 17.2595 21.8613 17.3076 21.743L18.3963 17.3939C18.4084 17.1886 18.3371 16.9299 17.9442 16.8319C17.9591 16.8214 17.2396 16.6574 17.2396 16.6574L17.4977 15.6253L19.454 16.108L19.8518 14.5206L20.8211 14.7617L20.4315 16.3178C20.6924 16.3767 20.9543 16.4367 21.209 16.5L21.5959 14.9538L22.5657 15.1949L22.1684 16.7829Z"
      fill="#F7931A"
    />
  </svg>
);

export { BitcoinIcon };
