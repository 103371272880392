import { useQuery } from '@tanstack/react-query';

//types
import { TTicketListRequestParams } from 'types/api/support';

// services
import { GET_TICKET_LIST } from 'api/support';

export const useQueryFetchTicketList = (query: TTicketListRequestParams) => {
  return useQuery(['useGetTicketList', query], () => GET_TICKET_LIST(query), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching tickets list';
      console.error(message);
      return message;
    },
  });
};
