export const sortQuality: Array<{
  label: string;
  value: string;
}> = [
  {
    label: 'filterQualityDesc',
    value: 'DESC',
  },
  {
    label: 'filterQualityAsc',
    value: 'ASC',
  },
];

export const sortPrice: Array<{
  label: string;
  value: string;
}> = [
  {
    label: 'filterPriceDesc',
    value: 'DESC',
  },
  {
    label: 'filterPriceAsc',
    value: 'ASC',
  },
];
