const BargainIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.23903 20.0292L1.64973 19.821H1.64973L2.23903 20.0292ZM3.77027 21.5605L3.9785 22.1498H3.9785L3.77027 21.5605ZM7.15817 20.7518L6.85512 21.2984L7.15817 20.7518ZM3.10104 16.5662L3.65688 16.2805L3.10104 16.5662ZM3.16731 17.4022L3.7566 17.6104L3.16731 17.4022ZM2.625 12C2.625 6.82233 6.82233 2.625 12 2.625V1.375C6.13197 1.375 1.375 6.13197 1.375 12H2.625ZM3.65688 16.2805C2.99747 14.998 2.625 13.5434 2.625 12H1.375C1.375 13.7468 1.79702 15.3969 2.54521 16.852L3.65688 16.2805ZM2.82832 20.2375L3.7566 17.6104L2.57801 17.194L1.64973 19.821L2.82832 20.2375ZM3.56204 20.9712C3.10629 21.1322 2.66727 20.6932 2.82832 20.2375L1.64973 19.821C1.1386 21.2675 2.53198 22.6609 3.9785 22.1498L3.56204 20.9712ZM6.08463 20.0798L3.56204 20.9712L3.9785 22.1498L6.50109 21.2584L6.08463 20.0798ZM12 21.375C10.3522 21.375 8.80544 20.9504 7.46123 20.2052L6.85512 21.2984C8.38005 22.1438 10.1348 22.625 12 22.625V21.375ZM21.375 12C21.375 17.1777 17.1777 21.375 12 21.375V22.625C17.868 22.625 22.625 17.868 22.625 12H21.375ZM12 2.625C17.1777 2.625 21.375 6.82233 21.375 12H22.625C22.625 6.13197 17.868 1.375 12 1.375V2.625ZM6.50109 21.2584C6.60328 21.2223 6.73228 21.2303 6.85512 21.2984L7.46123 20.2052C7.05405 19.9794 6.55384 19.914 6.08463 20.0798L6.50109 21.2584ZM2.54521 16.852C2.60688 16.972 2.61276 17.0956 2.57801 17.194L3.7566 17.6104C3.91601 17.1593 3.86166 16.6788 3.65688 16.2805L2.54521 16.852Z"
      fill="currentColor"
    />
    <circle
      cx="9.79834"
      cy="9.73986"
      r="0.75"
      transform="rotate(45 9.79834 9.73986)"
      fill="currentColor"
    />
    <circle
      cx="14.2014"
      cy="14.1427"
      r="0.75"
      transform="rotate(45 14.2014 14.1427)"
      fill="currentColor"
    />
    <path
      d="M9.76115 14.2388L14.2388 9.76123"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { BargainIcon };
