import React, { useState } from 'react';
import IconRenderer from 'hoc/IconRenderer';
// utils

// store
import { useAppStore } from 'store';

//locales
import { useTranslation } from 'react-i18next';

// types
import {
  EInputSizeType,
  EButtonSizeType,
  EButtonTypeType,
  ESelectSizeType,
} from 'types/units';

// components
import { Button, Input, Select } from 'antd';
import { ArrowDownIcon } from 'components/icons';

// styles
import './index.scss';

// data
import { countryList, cardList } from './mockdata';

const { Option } = Select;
const AddBalanceForm = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();

  const [currentCountry, setCurrentCountry] = useState<string>(
    countryList[0].id,
  );

  const [currentCard, setCurrentCard] = useState<{
    [key: string]: any;
  } | null>(null);

  const onConfirm = id => {
    sModalClose(id);
    sModalOpen('withdrawConfirmation');
  };
  const onChangeCountry = (value: string) => {
    setCurrentCountry(value);
  };
  const onChooseCard = card => {
    if (currentCard?.id !== card.id) {
      setCurrentCard(card);
    } else {
      setCurrentCard(null);
    }
  };

  return (
    <div className="withdraw-balance-form">
      <div className="withdraw-balance-form__grid">
        <div className="withdraw-balance-form__aside">
          <div className="withdraw-balance-form__main--title mobile">
            {t('increaseFunds')}
          </div>
          <Select
            className={'ant-select--light ant-select--line'}
            size={ESelectSizeType.Middle}
            suffixIcon={<ArrowDownIcon />}
            defaultValue={currentCountry}
            onChange={onChangeCountry}
            dropdownAlign={{ offset: [0, 0] }}
          >
            {countryList?.length &&
              countryList.map(country => {
                return (
                  <Option
                    value={country.id}
                    label={country.label}
                    key={country.id}
                  >
                    <span
                      role="img"
                      className="lang-toggler__option-flag"
                      aria-label={country.label}
                    >
                      {country.flag}
                    </span>
                    {country.label}
                  </Option>
                );
              })}
          </Select>
          <div className="withdraw-balance-form__aside--list">
            {cardList?.length &&
              cardList.map(card => {
                return (
                  <div
                    className={
                      'withdraw-balance-form__aside--list-item' +
                      (card.id === currentCard?.id
                        ? ' withdraw-balance-form__aside--list-item--active'
                        : '')
                    }
                    onClick={() => onChooseCard(card)}
                    key={card.id}
                  >
                    <div className="withdraw-balance-form__aside--list-item-label">
                      {card.icon
                        ? IconRenderer(
                            card.icon,
                            'withdraw-balance-form__aside--list-item-icon',
                          )
                        : null}
                      <span>{card.label}</span>
                    </div>
                    <div className="withdraw-balance-form__aside--list-item-value">
                      {card.tax}%
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="withdraw-balance-form__main">
          <div className="withdraw-balance-form__main--title desktop">
            {t('increaseFunds')}
          </div>
          <div className="withdraw-balance-form__main--balance">
            {t('yourBalance')}: $ <span>1,210</span>.64
          </div>
          <div className="withdraw-balance-form__main--form">
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                Адрес
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                placeholder={'Киев, улица Ильи 15'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw-balance-form__footer">
        <div className="withdraw-balance-form__footer--value">
          <h3>{t('total')}: $ 353</h3>
          <span>{t('commission')}: $ 22.78</span>
        </div>
        <div className="withdraw-balance-form__footer--actions">
          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            onClick={() => {
              onConfirm(modalId);
            }}
          >
            {t('increaseBalance')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddBalanceForm };
