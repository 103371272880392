const TwitterIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.4 5.54669C21.6266 5.89335 20.8 6.10669 19.9466 6.21335C20.8266 5.68002 21.4933 4.85335 21.8133 3.84002C20.9866 4.32002 20.08 4.69335 19.0933 4.88002C18.32 4.05335 17.2 3.52002 15.9733 3.52002C13.6266 3.52002 11.7066 5.44002 11.7066 7.78669C11.7066 8.13335 11.7333 8.45335 11.8133 8.74669C8.26663 8.56002 5.11996 6.88002 3.0133 4.29335C2.63996 4.93335 2.42663 5.65335 2.42663 6.42669C2.42663 7.92002 3.1733 9.20002 4.31996 9.97335C3.62663 9.94669 2.95996 9.76002 2.39996 9.44002C2.39996 9.46669 2.39996 9.46669 2.39996 9.49335C2.39996 11.5734 3.86663 13.28 5.8133 13.68C5.46663 13.7867 5.06663 13.84 4.6933 13.84C4.42663 13.84 4.15996 13.8134 3.8933 13.76C4.42663 15.4667 5.99996 16.6934 7.86663 16.72C6.39996 17.8667 4.55996 18.56 2.55996 18.56C2.2133 18.56 1.86663 18.5334 1.54663 18.5067C3.43996 19.7067 5.67996 20.4267 8.07996 20.4267C15.92 20.4267 20.2133 13.92 20.2133 8.29335C20.2133 8.10669 20.2133 7.92002 20.2133 7.73335C21.0933 7.14669 21.84 6.40002 22.4 5.54669Z"
      fill="currentColor"
    />
  </svg>
);

export { TwitterIcon };
