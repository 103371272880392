import React from 'react';
import { Typography } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';

// locale
import { useTranslation } from 'react-i18next';

// store
import { useExtStore } from 'store';

// types

// components
import {
  ChromeIcon,
  PCIcon,
  MobileIcon,
  ArrowRightIcon,
} from 'components/icons';

// styles
import './index.scss';

//data
const { Link } = Typography;

const HeaderApps = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { isExtInstalled } = useExtStore();
  return (
    <ul className="header-apps">
      {!isExtInstalled && (
        <li>
          <Link href="/">
            <ChromeIcon />
            <span>{t('installExtension')}</span>
            {width <= MEDIA.M1280 && (
              <div className="header-apps__actions">
                <ArrowRightIcon />
              </div>
            )}
          </Link>
        </li>
      )}
      {/*<li>*/}
      {/*  <Link href="/">*/}
      {/*    <PCIcon />*/}
      {/*    <span>{t('downloadDesktopVersion')}</span>*/}
      {/*    {width <= MEDIA.M1280 && (*/}
      {/*      <div className="header-apps__actions">*/}
      {/*        <ArrowRightIcon />*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Link>*/}
      {/*</li>*/}
      {/*<li>*/}
      {/*  <Link href="/">*/}
      {/*    <MobileIcon />*/}
      {/*    <span>{t('downloadMobileVersion')}</span>*/}
      {/*    {width <= MEDIA.M1280 && (*/}
      {/*      <div className="header-apps__actions">*/}
      {/*        <ArrowRightIcon />*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Link>*/}
      {/*</li>*/}
    </ul>
  );
};
export { HeaderApps };
