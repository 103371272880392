const EyeFilledIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.25C7.91882 5.25 4.36847 7.51686 2.54256 10.8556C2.15249 11.5688 2.15248 12.431 2.54254 13.1443C4.36843 16.4831 7.91882 18.75 12 18.75C16.0812 18.75 19.6315 16.4831 21.4574 13.1444C21.8475 12.4312 21.8475 11.569 21.4575 10.8557C19.6316 7.51694 16.0812 5.25 12 5.25ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C12.6805 14.25 13.2904 13.9479 13.7029 13.4706C13.0168 13.3328 12.5 12.7267 12.5 12C12.5 11.2733 13.0168 10.6673 13.7029 10.5294C13.2904 10.0521 12.6805 9.75 12 9.75ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
      fill="currentColor"
    />
  </svg>
);

export { EyeFilledIcon };
