import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { REJECT_ALL_OFFERS } from '../../../api/transactions';

export const useMutationRejectAll = () => {
  return useMutation(REJECT_ALL_OFFERS, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while reject bargain';
      console.error(message);
      return message;
    },
  });
};
