const CartIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2C2 1.58579 2.33579 1.25 2.75 1.25H4.75C6.26878 1.25 7.5 2.48122 7.5 4V5C7.5 5.41421 7.16421 5.75 6.75 5.75C6.33579 5.75 6 5.41421 6 5V4C6 3.30964 5.44036 2.75 4.75 2.75H2.75C2.33579 2.75 2 2.41421 2 2Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M6 4H18C20.2091 4 22 5.79086 22 8V13C22 15.2091 20.2091 17 18 17H10C7.79086 17 6 15.2091 6 13V4Z"
      fill="currentColor"
    />
    <circle cx="9.5" cy="20.5" r="1.5" fill="currentColor" />
    <circle cx="18.5" cy="20.5" r="1.5" fill="currentColor" />
    <path d="M18 4H6V8H22C22 5.79086 20.2091 4 18 4Z" fill="currentColor" />
  </svg>
);

export { CartIcon };
