import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, RadioChangeEvent, Tooltip } from 'antd';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { formatPrice } from '../../../../utils/commons';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore, useCartStore } from 'store';

//hooks
import {
  useQueryFetchProductInfo,
  useMutationBuyCart,
  useMutationCheckCart,
} from 'hooks/api';

// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { EGameType } from 'types/models';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
// components
import {
  ArrowRightIcon,
  BargainIcon,
  SteamIcon,
  StickerEmptyIcon,
  CSGOIcon,
} from 'components/icons';

import { FloatRange, AppBtnCart, AppLoader } from 'components/atoms';
import { InfoTooltip, StickerTooltip } from 'components/features';

// styles
import './index.scss';

//data
import { dataTypes } from './data';
/*@ts-ignore*/
import ProductBg from 'assets/img/productBg.png';
import { toast } from 'react-toastify';

const GetStickers = ({ width, stickers, t, gameType, maxCountSticker }) => {
  let array: any = [];
  const minimumLength = gameType === EGameType.CSGO ? maxCountSticker : 5;
  if (stickers?.length === minimumLength) {
    array = stickers;
  } else {
    const nullCount = minimumLength - stickers.length;
    array = [...stickers, ...Array(nullCount).fill(null)];
  }

  return array.map((item, index) => (
    <li key={index}>
      {item ? (
        <>
          <Tooltip
            overlayClassName={'product-info__tooltip ant-tooltip--blur'}
            placement={width > MEDIA.M1024 ? 'right' : 'top'}
            title={
              gameType === EGameType.CSGO ? (
                <StickerTooltip title={item.title} price={item.valueExtra} />
              ) : (
                <StickerTooltip
                  title={item.title}
                  description={item.valueExtra}
                />
              )
            }
          >
            <img src={item.preview} alt={item.title} />
          </Tooltip>
          {/*{gameType === EGameType.CSGO && <div>{10 + index * 12}%</div>}*/}
        </>
      ) : (
        <>
          <StickerEmptyIcon />
          <div>{t('empty')}</div>
        </>
      )}
    </li>
  ));
};

const RenderPriceDiscount = (price, marketPrice) => {
  let percent = '0.00';

  if (price) {
    percent = ((100 / marketPrice) * price - 100).toFixed(2);
  }

  return (
    <span
      className={
        'product-info__discount-percent' +
        (Number(percent) > 0
          ? ' product-info__discount-percent--overpayment'
          : '')
      }
    >
      {Number(percent) > 300 ? '>300' : percent}%
    </span>
  );
};
const ProductInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessToken: isAuth } = useAuthStore();
  const { width } = useWindowSize();
  const { sModalOpen } = useAppStore();
  const { cart, sSetCartData, sSetCartProduct } = useCartStore();
  const [currentQuality, setCurrentQuality] = useState<any>(dataTypes[0].value);
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const { slug } = useParams();
  const { data: productData, isLoading: isLoadingProductInfo } =
    useQueryFetchProductInfo(slug);

  const {
    data: buyProductData,
    isLoading: isLoadingBuyProduct,
    mutate: mutateBuyProduct,
  } = useMutationBuyCart();

  const {
    data: checkCartData,
    isLoading: isLoadingCheckCart,
    mutate: mutateCheckCart,
  } = useMutationCheckCart();

  useEffect(() => {
    if (!isLoadingCheckCart && checkCartData) {
      if (checkCartData[0].available) {
        mutateBuyProduct([{ sku: checkCartData[0].sku }]);
      } else {
        toast.error(t('toastErrorCheckBuy'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCartData, isLoadingCheckCart]);

  useEffect(() => {
    if (!isLoadingBuyProduct && buyProductData) {
      toast.success(t('toastItemBought'));
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyProductData, isLoadingBuyProduct]);

  useEffect(() => {
    if (productData) {
      let findData = cart.find(i => i.id === productData.id);
      setIsInCart(!!findData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cart), productData]);

  const onChangeDateSelect = ({ target: { value } }: RadioChangeEvent) => {
    setCurrentQuality(value);
  };

  const onBuy = () => {
    if (isAuth) {
      mutateCheckCart([{ sku: productData.sku }]);
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onBargain = () => {
    if (isAuth) {
      sSetCartProduct(productData);
      sModalOpen('offerTrade');
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onCartAdd = () => {
    sSetCartData([...cart, productData]);
  };

  const onCartRemove = productId => {
    let newCartData = cart.filter(item => item.id !== productId);
    sSetCartData(newCartData);
  };

  const onViewInGame = url => {
    window.open(url, '_top');
  };

  return (
    <div className="product-info" id="info">
      {isLoadingProductInfo ? (
        <div className="product-info__loader">
          <AppLoader type={ELoaderTypeType.Component} />
        </div>
      ) : (
        <>
          {!isLoadingProductInfo && productData && (
            <>
              <div className="product-info__head">
                <div className="product-info__title">
                  {productData.provider === EGameType.CSGO
                    ? productData.marketName.second
                      ? productData.marketName.first +
                        ' | ' +
                        productData.marketName.second
                      : productData.marketName.first
                    : productData.marketName.first}
                </div>
                {productData.provider === EGameType.CSGO && (
                  <div className="product-info__actions ant-radio-group ant-radio-group-solid ant-radio-group-middle">
                    {productData.wears?.map((item, index) => {
                      return (
                        <RouterLink
                          to={`/market/${item.itemId}`}
                          className={`ant-radio-button-wrapper ${
                            !item.itemId && ' ant-radio-button-wrapper-disabled'
                          } ${
                            item.name === productData.exterior &&
                            ' ant-radio-button-wrapper-checked'
                          }`}
                          key={index}
                        >
                          <span>{item.name}</span>
                        </RouterLink>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="product-info__grid">
                <div className="product-info__aside">
                  <div className="product-info__card product-info__card--steam">
                    ${' '}
                    {productData.isInMarket ? (
                      <>
                        {formatPrice(productData.price)}
                        <div className="product-card-csgo__head-sale">
                          <SteamIcon />
                          {RenderPriceDiscount(
                            productData.price,
                            productData.marketPrice,
                          )}
                        </div>
                      </>
                    ) : (
                      <>{formatPrice(productData.marketPrice)}</>
                    )}
                  </div>
                  {productData.provider === EGameType.CSGO &&
                    productData.float > 0 && (
                      <div className="product-info__card product-info__card--float">
                        <div className="product-info__card-float-grid">
                          {t('floatValue')}
                          <span>{productData.float}</span>
                        </div>
                        <FloatRange
                          size={EFloatRangeSizeType.Default}
                          isTooltip={false}
                          maxValue={productData.float}
                        />
                      </div>
                    )}
                  <div className="product-info__card product-info__card--rarity">
                    {t('rarity')}
                    <span
                      style={{ color: `#${productData.tag.rarity.valueExtra}` }}
                    >
                      {productData.tag.rarity.title}
                    </span>
                  </div>

                  {productData.provider === EGameType.CSGO &&
                  (productData?.collections?.length ||
                    productData?.crates?.length) ? (
                    <div className="product-info__card product-info__card--collection">
                      {t('collection')}
                      <ul className="product-info__collection">
                        {productData?.collections?.map((el, index) => {
                          return (
                            <li key={`collection${index}`}>
                              <img src={el.image} alt={el.name} />
                              <span>{el.name}</span>
                            </li>
                          );
                        })}
                        {productData?.crates?.map((el, index) => {
                          return (
                            <li key={`cart${index}`}>
                              <img src={el.image} alt={el.name} />
                              <span>{el.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                  {productData.isInMarket && (
                    <div className="product-info__aside-actions">
                      <Button
                        block
                        disabled={isLoadingBuyProduct || productData.isOwn}
                        size={EButtonSizeType.Large}
                        type={EButtonTypeType.Primary}
                        onClick={() => onBuy()}
                      >
                        {productData.isOwn ? t('yourItem') : t('buyItem')}
                      </Button>

                      <Tooltip
                        overlayClassName={'ant-tooltip--small'}
                        placement="top"
                        title={<InfoTooltip text={t('tooltipBargainBtn')} />}
                      >
                        <Button
                          disabled={productData.isOwn}
                          size={EButtonSizeType.Large}
                          ghost
                          type={EButtonTypeType.Primary}
                          onClick={() => onBargain()}
                          icon={<BargainIcon />}
                        />
                      </Tooltip>

                      <AppBtnCart
                        disabled={productData.isOwn}
                        size={EButtonSizeType.Large}
                        isTooltip={true}
                        isInCart={isInCart}
                        onClickAdd={() => onCartAdd()}
                        onClickRemove={() => onCartRemove(productData.id)}
                      />
                    </div>
                  )}
                </div>
                <div className="product-info__main">
                  <ul
                    className={
                      'product-info__stickers' +
                      (productData.provider === EGameType.CSGO
                        ? ' product-info__stickers--csgo'
                        : ' product-info__stickers--dota')
                    }
                  >
                    {productData.provider === EGameType.CSGO ? (
                      <>
                        {productData.maxCountSticker > 0 && (
                          <>
                            {GetStickers({
                              width: width,
                              stickers: productData.stickers,
                              t: t,
                              gameType: productData.provider,
                              maxCountSticker: productData.maxCountSticker,
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {GetStickers({
                          width: width,
                          stickers: productData.gems,
                          t: t,
                          gameType: productData.provider,
                          maxCountSticker: productData.maxCountSticker,
                        })}
                      </>
                    )}
                  </ul>
                  <div className="product-info__banner">
                    <div
                      className="product-info__gradient"
                      style={{
                        backgroundImage:
                          width > MEDIA.M1360
                            ? `radial-gradient(circle at 50% 60%, #${productData.tag.rarity.valueExtra}CC 10%, #131215 65%), url(${ProductBg})`
                            : `radial-gradient(ellipse at 50% 50%, #${productData.tag.rarity.valueExtra}CC 20%, #131215 75%), url(${ProductBg})`,
                      }}
                    ></div>
                    <div className="product-info__banner-img">
                      <img
                        src={
                          productData.image
                            ? productData.image
                            : productData.preview
                        }
                        alt={productData.name}
                      />
                    </div>
                    {width > MEDIA.M1024 &&
                      productData.provider === EGameType.CSGO &&
                      productData.isInMarket && (
                        <div className="product-info__banner-actions">
                          <div className="product-info__banner-game">
                            {t('openGameAfterPress')}
                            <CSGOIcon />
                          </div>
                          <Button
                            ghost
                            disabled={!productData.actions.length}
                            type={EButtonTypeType.Primary}
                            onClick={() => {
                              onViewInGame(productData.actions[0].link);
                            }}
                          >
                            {t('checkInGame')}
                            <ArrowRightIcon />
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export { ProductInfo };
