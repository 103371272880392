const MessageIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.99963"
      y="4"
      width="20"
      height="16"
      rx="4.16667"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M5.99963 9L10.9996 12.75C11.5922 13.1944 12.407 13.1944 12.9996 12.75L17.9996 9"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { MessageIcon };
