import React, { useEffect, useState } from 'react';
import { Input, Form } from 'antd';

// locale

// types
import { IPropsType } from './types';
import { EInputSizeType } from 'types/units';

// styles
import './index.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

//data

const PriceFilter = ({ data, title, className, onChange }: IPropsType) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const controlsSchema = yup.object().shape({
    priceFrom: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validationAmount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validationRequired'))
      .positive(t('validationPositive'))
      .typeError(t('validationIsNumeric')),
    priceTo: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validationAmount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validationRequired'))
      .positive(t('validationPositive'))
      .typeError(t('validationIsNumeric')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await controlsSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    form.setFieldsValue({ priceFrom: data[0], priceTo: data[1] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const onChangePrice = name => {
    setTimeout(() => {
      if (!form.getFieldError(name).length) {
        let priceRange = [...data];
        priceRange[name === 'priceFrom' ? 0 : 1] = form.getFieldValue(name);
        onChange(priceRange);
      }
    }, 500);
  };

  return (
    <div className={'price-filter' + (className ? ` ${className}` : '')}>
      <div className="price-filter__title">{title}</div>
      <Form form={form} layout="vertical" className="price-filter__grid">
        <Form.Item
          name={'priceFrom'}
          /*@ts-ignore*/
          rules={[yupSync]}
        >
          <Input
            prefix="$"
            value={data[0]}
            size={EInputSizeType.Small}
            onChange={event => onChangePrice('priceFrom')}
          />
        </Form.Item>
        <div className="price-filter__divider"></div>
        <Form.Item
          name={'priceTo'}
          /*@ts-ignore*/
          rules={[yupSync]}
        >
          <Input
            prefix="$"
            value={data[1]}
            size={EInputSizeType.Small}
            onChange={event => onChangePrice('priceTo')}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export { PriceFilter };
