import React from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType } from 'types/units';

// components(atoms)
import { Button, Input, Form } from 'antd';
import { LockLineIcon, TrashIcon } from 'components/icons';

// styles
import './index.scss';
import * as yup from 'yup';

// data

// func

const DeleteAccountModal = ({ modalId, onDeleteAccount }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const onConfirm = () => {
    onDeleteAccount();
    sModalClose(modalId);
  };
  const onClose = () => {
    sModalClose(modalId);
  };

  return (
    <div className="delete-account">
      <div className="delete-account--icon">
        <TrashIcon />
      </div>
      <div className="delete-account--title">{t('deleteAccTitle')}</div>
      <div className="delete-account--desc">{t('deleteAccDesc')}</div>
      <div className="delete-account--button">
        <Button
          onClick={() => {
            onConfirm();
          }}
          type="primary"
          block
          size={EButtonSizeType.Large}
        >
          {t('deleteAccount')}
        </Button>
      </div>
      <div className="delete-account--button">
        <Button
          onClick={() => {
            onClose();
          }}
          danger
          type="text"
          block
          size={EButtonSizeType.Small}
        >
          {t('deny')}
        </Button>
      </div>
    </div>
  );
};
export { DeleteAccountModal };
