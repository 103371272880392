import React, { useState, useEffect, useRef } from 'react';
import { Button, Tooltip } from 'antd';
import { Area } from '@ant-design/plots';
import { NavLink } from 'react-router-dom';
import { formatFloat, formatPrice } from 'utils/commons';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useInventoryStore } from 'store';
import { useQueryFetchPriceHistory } from '../../../../../hooks/api';
// types
import {
  EButtonSizeType,
  EButtonTypeType,
  EInventoryAsideType,
} from 'types/units';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EGameType } from 'types/models/game';

// components
import {
  SteamIcon,
  ArrowRightIcon,
  StickerEmptyIcon,
  CSGOIcon,
  CloseBigIcon,
} from 'components/icons';

import { FloatRange } from 'components/atoms';
import { EmptyCard, StickerTooltip } from 'components/features';
// styles
import './index.scss';

//data
import { generalChartConfig } from 'config/charts';
/*@ts-ignore*/
import ProductBg from 'assets/img/productBg.png';
import { ECardSizeType } from '../../../../../components/features/Cards/EmptyCard/types';

const GetStickers = ({ stickers, t, gameType, maxCountSticker }) => {
  let array: any = [];
  const minimumLength = gameType === EGameType.CSGO ? maxCountSticker : 5;
  if (stickers?.length === minimumLength) {
    array = stickers;
  } else {
    const nullCount = minimumLength - stickers.length;
    array = [...stickers, ...Array(nullCount).fill(null)];
  }

  return array.map((item, index) => (
    <li key={index}>
      {item ? (
        <>
          <Tooltip
            overlayClassName={
              'inventory-product__banner-tooltip ant-tooltip--blur'
            }
            placement="top"
            title={
              gameType === EGameType.CSGO ? (
                <StickerTooltip title={item.title} price={item.valueExtra} />
              ) : (
                <StickerTooltip
                  title={item.title}
                  description={item.valueExtra}
                />
              )
            }
          >
            <img src={item.preview} alt={item.title} />
          </Tooltip>
        </>
      ) : (
        <>
          <StickerEmptyIcon />
          <div>{t('empty')}</div>
        </>
      )}
    </li>
  ));
};
const InventoryProduct = () => {
  const { t } = useTranslation();
  const { gameType, sModalOpen } = useAppStore();
  const {
    inventoryProduct,
    inventoryExpose,
    sSetInventoryAside,
    sSetInventoryProduct,
    sSetInventoryExposeModal,
  } = useInventoryStore();

  const [data, setData] = useState([]);

  const [chartName, setChartName] = useState<{
    firstName: string;
    secondName: string | null;
    extra: string | null;
  } | null>(null);
  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(
      { ...chartName!, provider: gameType },
      !!chartName,
    );

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 6 },
    yAxis: { ...yAxis, tickCount: 3 },
  };

  useEffect(() => {
    if (inventoryProduct) {
      let { marketName, exterior } = inventoryProduct;
      setChartName({
        firstName: marketName.first,
        secondName: marketName.second || null,
        extra: exterior || null,
      });
    } else {
      setChartName(null);
    }
  }, [inventoryProduct]);

  useEffect(() => {
    if (!isLoadingRawChart && rawChartData) {
      setData(rawChartData);
    }
  }, [rawChartData, isLoadingRawChart]);

  const onClose = () => {
    if (inventoryExpose.length > 0) {
      sSetInventoryAside(EInventoryAsideType.Expose);
    } else {
      sSetInventoryAside(EInventoryAsideType.Empty);
    }

    sSetInventoryProduct(null);
  };

  const onSell = () => {
    sSetInventoryExposeModal(inventoryProduct);
    sModalOpen('sellProduct');
  };

  const onViewInGame = url => {
    window.open(url, '_top');
  };

  const getProductColor = product => {
    return product.tag?.rarity?.valueExtra
      ? product.tag.rarity.valueExtra
      : product.nameColor;
  };

  return (
    <div className="inventory-product">
      <div className="inventory-product__scroll">
        <div
          className={
            'inventory-product__card inventory-product__banner' +
            (gameType === EGameType.Dota
              ? ' inventory-product__banner--small'
              : '')
          }
        >
          <div
            className="inventory-product__banner-bg"
            style={{
              backgroundImage: `radial-gradient(ellipse at 50% 50%, #${getProductColor(
                inventoryProduct,
              )}CC 20%, #131215 75%), url(${ProductBg})`,
            }}
          />
          <div className="inventory-product__banner-content">
            <CloseBigIcon
              className="inventory-product__banner-btn-close"
              onClick={() => onClose()}
            />
            <div className="inventory-product__banner-img">
              <img
                src={
                  inventoryProduct.image
                    ? inventoryProduct.image
                    : inventoryProduct.preview
                }
                alt={inventoryProduct.title}
              />
            </div>
            <ul className="inventory-product__banner-stickers">
              {inventoryProduct.provider === EGameType.CSGO ? (
                <>
                  {inventoryProduct.maxCountSticker > 0 && (
                    <>
                      {GetStickers({
                        stickers: inventoryProduct.stickers,
                        t: t,
                        gameType: inventoryProduct.provider,
                        maxCountSticker: inventoryProduct.maxCountSticker,
                      })}
                    </>
                  )}
                </>
              ) : (
                <>
                  {GetStickers({
                    stickers: inventoryProduct.gems,
                    t: t,
                    gameType: inventoryProduct.provider,
                    maxCountSticker: inventoryProduct.maxCountSticker,
                  })}
                </>
              )}
            </ul>
            {gameType === EGameType.CSGO && (
              <div className="inventory-product__banner-actions">
                <div className="inventory-product__banner-game">
                  {t('youWillOpenCs')}
                  <CSGOIcon />
                </div>
                <Button
                  ghost
                  type={EButtonTypeType.Primary}
                  onClick={() => {
                    onViewInGame(inventoryProduct.actions[0].link);
                  }}
                >
                  {t('checkInGame')}
                  <ArrowRightIcon />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="inventory-product__card inventory-product__actions">
          <div className="inventory-product__actions-left">
            <span>$ {formatPrice(inventoryProduct.marketPrice)}</span>
            <SteamIcon />
          </div>
          <div className="inventory-product__actions-right">
            <NavLink
              to={`/market/${inventoryProduct.id}`}
              className={'ant-btn ant-btn-primary ant-btn-background-ghost'}
            >
              <span>{t('onMarket')}</span>
              <ArrowRightIcon />
            </NavLink>
            <Button
              type={EButtonTypeType.Primary}
              onClick={() => {
                onSell();
              }}
              className="inventory-product__btn-sell"
            >
              {t('sellTitle')}
            </Button>
          </div>
        </div>
        <div className="inventory-product__grid">
          {gameType === EGameType.CSGO && inventoryProduct.float > 0 && (
            <div className="inventory-product__card inventory-product__float">
              <div className="inventory-product__float-grid">
                {inventoryProduct.shortenExterior}
                <span>{formatFloat(inventoryProduct.float)}</span>
              </div>
              <div className="inventory-product__float-range">
                <FloatRange
                  isTooltip={true}
                  size={EFloatRangeSizeType.Small}
                  maxValue={inventoryProduct.float}
                />
              </div>
            </div>
          )}
          {inventoryProduct?.tag?.rarity && (
            <div className="inventory-product__card inventory-product__rarity">
              {t('rarity')}
              <span
                style={{ color: `#${inventoryProduct.tag.rarity.valueExtra}` }}
              >
                {inventoryProduct.tag.rarity.title}
              </span>
            </div>
          )}
        </div>
        <div className="inventory-product__card inventory-product__chart">
          {config?.data?.length ? (
            <div className="inventory-product__chart-content">
              {/*@ts-ignore*/}
              <Area {...config} />
            </div>
          ) : (
            <EmptyCard
              isHideImg={true}
              size={ECardSizeType.Minimal}
              title={t('emptyChartTitle')}
              description={t('emptyChartDesc')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { InventoryProduct };
