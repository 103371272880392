import React, { useState } from 'react';
import { Badge, Typography } from 'antd';

// locale
import { useTranslation } from 'react-i18next';
// types

// components

// styles
import './index.scss';
import { useExtStore } from 'store';

//data
const { Link } = Typography;

const HeaderTrades = () => {
  const { t } = useTranslation();
  const { isExtOnline, isExtInstalled } = useExtStore();

  return (
    <div className="header-trades">
      {isExtOnline ? (
        <>
          <span>{t('tradesActive')}</span>
          <Badge status="success" />
        </>
      ) : (
        <>
          <span>{t('tradesDisabled')}</span>
          {/*<Link underline onClick={onTradeToggle}>*/}
          {/*  {t('enable')}*/}
          {/*</Link>*/}
          <Badge status="error" />
        </>
      )}
    </div>
  );
};
export { HeaderTrades };
