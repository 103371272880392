// core
import React from 'react';
import { Outlet } from 'react-router-dom';

//hooks
// utils
// locales
// store
// types

// components(atoms)
import { InventorySEO } from './SEO';
// styles
import './index.scss';

// data

// func
const Inventory = () => {
  return (
    <>
      <InventorySEO />
      {/*TABS*/}
      <Outlet />
    </>
  );
};

export { Inventory };
