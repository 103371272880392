const TelegramIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3009 11.2749C8.40013 9.06033 11.7948 7.58884 13.4994 6.87495C18.3509 4.84983 19.3707 4.50016 20.0264 4.4856C20.1721 4.4856 20.4926 4.51473 20.7111 4.68956C20.8859 4.83526 20.9297 5.02466 20.9588 5.17035C20.9879 5.31604 21.0171 5.62199 20.9879 5.8551C20.7257 8.62325 19.5893 15.3397 19.0065 18.4283C18.7588 19.7396 18.2781 20.1766 17.8118 20.2203C16.792 20.3078 16.0198 19.5502 15.0437 18.9091C13.4994 17.9038 12.6398 17.2774 11.1391 16.2867C9.40541 15.1503 10.5272 14.5238 11.5179 13.5039C11.7802 13.2417 16.2529 9.16231 16.3404 8.79808C16.3549 8.75438 16.3549 8.57955 16.2529 8.49213C16.151 8.40471 16.0053 8.43385 15.8887 8.46299C15.7284 8.49213 13.2808 10.1239 8.51668 13.3437C7.81736 13.8245 7.19088 14.0576 6.62269 14.043C5.99621 14.0284 4.80153 13.6933 3.89824 13.402C2.80555 13.0523 1.9314 12.8629 2.00425 12.251C2.04795 11.9305 2.48503 11.6099 3.3009 11.2749Z"
      fill="currentColor"
    />
  </svg>
);

export { TelegramIcon };
