import { $api } from 'utils/request';

// types
import type {
  TMarketFilterResponse,
  IMarketGetItemsRequestPayload,
  IMarketGetItemsResponse,
  TMarketSearchResponse,
} from 'types/api/market';
import type { AxiosResponse } from 'axios';
import { objectToQueryString } from '../utils/commons';
import {
  IMarketGetFiltersRequestParams,
  IMarketSearchRequestParams,
  IPriceHistoryRequestParams,
  IPriceHistoryResponse,
  TTopProductsRequestParams,
  TTopProductsResponse,
} from 'types/api/market';
import { stringify } from 'query-string';

// services
export const GET_MARKET_FILTERS = async (
  params: IMarketGetFiltersRequestParams,
) => {
  const url = objectToQueryString('filters', params);
  const response = await $api.get<TMarketFilterResponse>(url);
  return response.data;
};

export const POST_MARKET_GET_ITEMS = async ({
  pageParam,
  params,
}: {
  pageParam: number;
  params: IMarketGetItemsRequestPayload;
}) => {
  const url = `market`;
  const response: AxiosResponse<IMarketGetItemsResponse> = await $api.post<any>(
    url,
    {
      ...params,
      page: pageParam,
      take: 36,
    },
  );
  return response.data;
};

export const GET_MARKET_ITEMS_BY_NAME = async (
  params: IMarketSearchRequestParams,
) => {
  const url = objectToQueryString('market/search', params);
  const response = await $api.get<TMarketSearchResponse>(url);
  return response.data;
};

export const GET_TOP_PRODUCTS = async (query: TTopProductsRequestParams) => {
  const url = `market/top?${stringify(query)}`;
  const response = await $api.get<TTopProductsResponse>(url);
  return response.data;
};

export const GET_RANDOM_PRODUCTS = async () => {
  const url = `buy-market-items`;
  const response = await $api.get<TTopProductsResponse>(url);
  return response.data;
};

export const GET_PRICE_HISTORY = async (query: any) => {
  const url = `/steam-market/priceHistory?${stringify(query)}`;
  const response = await $api.get<IPriceHistoryResponse>(url);
  return response.data;
};

export const GET_EXTERIOR_FILTER = async () => {
  const url = `filter/Exterior`;
  const response = await $api.get<any>(url);
  return response.data;
};

export const GET_SEARCH_ALL_PRODUCTS = async (query: any) => {
  const url = `catalog/search?${stringify(query)}`;
  const response = await $api.get<any>(url);
  return response.data;
};
