import { useQuery } from '@tanstack/react-query';

//types
import { TChatMessagesRequestParams } from 'types/api/support';

// services
import { GET_CHAT_MESSAGES } from 'api/support';

export const useQueryFetchChatMessages = (
  enabled,
  id: TChatMessagesRequestParams,
) => {
  return useQuery(['useGetChatMessages', id], () => GET_CHAT_MESSAGES(id), {
    enabled,
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching chat messages';
      console.error(message);
      return message;
    },
  });
};
