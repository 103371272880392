import React from 'react';
import { Drawer } from 'antd';

// locale

// store
import { useAppStore } from 'store';

// types
import { IPropsType } from './types';
// components
import { CatalogAside } from '../CatalogAside';

// styles
import './index.scss';

//data

const CatalogFilterDrawer = ({
  filters,
  onChange,
  onReset,
  isDisabledReset,
}: IPropsType) => {
  const { headerTopOffset, drawer, sDrawerClose } = useAppStore();

  const onClose = () => {
    sDrawerClose('catalogFilterDrawer');
  };

  return (
    <Drawer
      placement="left"
      destroyOnClose={true}
      closable={false}
      onClose={onClose}
      open={drawer['catalogFilterDrawer']}
      // getContainer={false}
      rootClassName={'catalog-filter-drawer'}
      width={310}
      rootStyle={{ height: `calc(100vh - ${headerTopOffset}px - 106px)` }}
    >
      <div className="catalog-filter-drawer__content">
        <CatalogAside
          isDisabledReset={isDisabledReset}
          filters={filters}
          onChange={onChange}
          onReset={onReset}
        />
      </div>
    </Drawer>
  );
};

export { CatalogFilterDrawer };
