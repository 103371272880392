import { useQuery } from '@tanstack/react-query';

//types
import { TArticlesRequestParams } from 'types/api/blog';

// services
import { GET_ARTICLES } from 'api/blog';

export const useQueryFetchArticles = (query: TArticlesRequestParams) => {
  return useQuery(['useGetArticles', query], () => GET_ARTICLES(query), {
    // initialData: () => null,
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err ? err?.message : 'Error while fetching Articles';
      console.error(message);
      return message;
    },
  });
};
